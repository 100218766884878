var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: {
                title: _vm.$i18n.t("common.commodity"),
                width: "80%",
                "append-to-body": ""
              }
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.idFuzzy,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "idFuzzy", $$v)
                          },
                          expression: "searchData.idFuzzy"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: {
                            width: "180px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            placeholder: _vm.$i18n.t("common.state"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchData.goodStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.searchData, "goodStatus", $$v)
                            },
                            expression: "searchData.goodStatus"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Listed"),
                              value: "true"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Notlisted"),
                              value: "false"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: {
                            width: "180px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            placeholder: _vm.$i18n.t("common.Enableornot"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchData.status,
                            callback: function($$v) {
                              _vm.$set(_vm.searchData, "status", $$v)
                            },
                            expression: "searchData.status"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.yes"),
                              value: "true"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.no"),
                              value: "false"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.searchClick }
                        },
                        [
                          _vm._v(
                            "\n                                   " +
                              _vm._s(_vm.$i18n.t("common.query")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "el-icon-search",
                            type: "primary",
                            size: "small"
                          },
                          on: { click: _vm.cleanQuery }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$i18n.t("common.Reset")) +
                              "\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "20px 0px 20px 20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.add(null)
                    }
                  }
                },
                [_vm._v("    " + _vm._s(_vm.$i18n.t("common.Add1")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.delAll()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.Batchdeletion")))]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "pagedata",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.pageData.list, border: "" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.goodsId",
                  label: _vm.$t("common.ProductID"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.goodsCode",
                  label: _vm.$t("common.SKU_EAN_Encoding"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            "SKU:" + _vm._s(scope.row.goodsMergeListMgrDto.sku)
                          )
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            "EAN:" +
                              _vm._s(scope.row.goodsMergeListMgrDto.barCode)
                          )
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$i18n.t("common.coding")) +
                              ":" +
                              _vm._s(scope.row.goodsMergeListMgrDto.goodsCode)
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.imageUrl",
                  label: _vm.$i18n.t("common.Productimage1"),
                  align: "center",
                  width: "125"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("listImage", {
                          attrs: {
                            imgUrl: scope.row.goodsMergeListMgrDto.imageUrl
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.treeList",
                  label: _vm.$i18n.t("common.Category"),
                  align: "center",
                  height: "55",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(
                        scope.row.goodsMergeListMgrDto.treeList,
                        function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _vm._l(item.parents, function(item2, index2) {
                                return _c("span", { key: index2 }, [
                                  _c("span", [
                                    _vm._v(_vm._s(item2.title) + ">")
                                  ])
                                ])
                              }),
                              _c("span", [_vm._v(_vm._s(item.title))])
                            ],
                            2
                          )
                        }
                      )
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.title",
                  label: _vm.$i18n.t("common.ProductName"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.goodsSpecials",
                  label: _vm.$i18n.t("common.Countrieslisted"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(
                        scope.row.goodsMergeListMgrDto.goodsSpecials,
                        function(s, index) {
                          return s.shelf
                            ? _c("span", { key: index }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$dict.getDictValue(
                                        "countrys",
                                        s.countryCode
                                      )
                                    )
                                ),
                                _c("br")
                              ])
                            : _vm._e()
                        }
                      )
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.brandCode",
                  label: _vm.$i18n.t("common.brand"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.goodsMergeListMgrDto.brandCode
                          ? _c("span", [
                              _vm._v(
                                _vm._s(scope.row.goodsMergeListMgrDto.brandCode)
                              )
                            ])
                          : scope.row.goodsMergeListMgrDto &&
                            scope.row.goodsMergeListMgrDto.treeList &&
                            scope.row.goodsMergeListMgrDto.treeList.length > 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.goodsMergeListMgrDto.treeList[0]
                                    .brandCode
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v("-->\n                 "),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.goodsSpecials",
                  label: _vm.$t("common.inventory"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(
                        scope.row.goodsMergeListMgrDto.goodsSpecials,
                        function(s, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(s.countryCode) +
                                ":" +
                                _vm._s(s.ecomRemainQty)
                            ),
                            _c("br")
                          ])
                        }
                      )
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$t("common.ProductPromotion"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.isShowGoodsPromotion(
                          scope.row.goodsMergeListMgrDto.promotionRuleCountrys,
                          "NEW"
                        )
                          ? _c(
                              "span",
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "text-align": "left",
                                      display: "block"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("common.NewUserPromotion"))
                                    ),
                                    _c("br")
                                  ]
                                ),
                                _vm._l(
                                  scope.row.goodsMergeListMgrDto
                                    .promotionRuleCountrys,
                                  function(promotion, index) {
                                    return promotion.userType == "NEW"
                                      ? _c("span", { key: index }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$dict.getDictValue(
                                                  "countrys",
                                                  promotion.countryCode
                                                )
                                              ) +
                                              ":" +
                                              _vm._s(
                                                _vm.getPromotionGoodsPrice(
                                                  promotion,
                                                  scope.row.goodsMergeListMgrDto
                                                    .prices
                                                )
                                              )
                                          ),
                                          _c("br")
                                        ])
                                      : _vm._e()
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.isShowGoodsPromotion(
                          scope.row.goodsMergeListMgrDto.promotionRuleCountrys,
                          "OLD"
                        )
                          ? _c(
                              "span",
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "text-align": "left",
                                      display: "block"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("common.RegularUserPromotion")
                                      )
                                    ),
                                    _c("br")
                                  ]
                                ),
                                _vm._l(
                                  scope.row.goodsMergeListMgrDto
                                    .promotionRuleCountrys,
                                  function(promotion, index) {
                                    return promotion.userType == "OLD"
                                      ? _c("span", { key: index }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$dict.getDictValue(
                                                  "countrys",
                                                  promotion.countryCode
                                                )
                                              ) +
                                              ":" +
                                              _vm._s(
                                                _vm.getPromotionGoodsPrice(
                                                  promotion,
                                                  scope.row.goodsMergeListMgrDto
                                                    .prices
                                                )
                                              )
                                          ),
                                          _c("br")
                                        ])
                                      : _vm._e()
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        !scope.row.goodsMergeListMgrDto.promotionRuleCountrys ||
                        scope.row.goodsMergeListMgrDto.promotionRuleCountrys
                          .length == 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "common.ThereAreCurrentlyNoProductPromotions"
                                  )
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.prices",
                  label: _vm.$i18n.t("common.fixedprice"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(
                        scope.row.goodsMergeListMgrDto.prices,
                        function(s, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(s.countryCode) +
                                ":" +
                                _vm._s(s.resultPrice.toFixed(2))
                            ),
                            _c("br")
                          ])
                        }
                      )
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsMergeListMgrDto.shelf",
                  label: _vm.$i18n.t("common.state"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.goodsMergeListMgrDto.shelf == true
                          ? _c("span", { staticStyle: { color: "#46a8a0" } }, [
                              _vm._v(_vm._s(_vm.$i18n.t("common.Listed")))
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$i18n.t("common.Notlisted")))
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Addtime"),
                  prop: "createTime",
                  "render-header": _vm.renderCreateHeader
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(scope.row.createTime)) +
                            "\n                    "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  "render-header": _vm.renderSortHeader,
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            oninput: "value=value.replace(/[^0-9]/g,'')",
                            placeholder: _vm.$i18n.t(
                              "common.Pleaseentercontent"
                            )
                          },
                          on: {
                            blur: function($event) {
                              return _vm.updateActivitySort(scope.row)
                            }
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.updateActivitySort(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: _vm.$i18n.t("common.Enableornot"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          on: {
                            change: function($event) {
                              return _vm.updateStatus(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.status,
                            callback: function($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.operation"),
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.del(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.remove")))]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.searchData.page,
                  "page-size": _vm.searchData.size,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.pageData.total
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("goods-dialog", {
        ref: "goodsDialog",
        attrs: { typeName: _vm.typeName, "close-on-click-modal": false }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }