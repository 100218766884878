var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "flex-start" } },
    [
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "avatar-uploader",
          class: { disabled: _vm.uploadDisabled },
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "show-file-list": true,
            "auto-upload": false,
            limit: 1,
            "list-type": "picture-card",
            "on-change": _vm.handleChange,
            data: _vm.fileDataObj,
            "on-error": _vm.uploadError,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.fileList,
            accept: ".jpg, .jpeg, .png, .gif , .JPG, .JPEG, .PNG, .GIF"
          }
        },
        [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
      ),
      _c(
        "span",
        { staticStyle: { "margin-left": "20px", "margin-top": "70px" } },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$i18n.t("common.Suggestedsize")) +
              "：5*1024，≤5MB  "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }