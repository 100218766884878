var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c(
        "el-card",
        { staticClass: "filter-container", attrs: { shadow: "never" } },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "15px", "margin-left": "10px" } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    model: _vm.searchData,
                    size: "small",
                    "label-width": "140px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t("common.ActivityName")
                        },
                        model: {
                          value: _vm.searchData.title,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "title", $$v)
                          },
                          expression: "searchData.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "countrys",
                          placeholder: _vm.$i18n.t("common.country")
                        },
                        model: {
                          value: _vm.searchData.countryCode,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "countryCode", $$v)
                          },
                          expression: "searchData.countryCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "user_type",
                          placeholder: _vm.$t("common.ApplicableUserType")
                        },
                        model: {
                          value: _vm.searchData.userType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "userType", $$v)
                          },
                          expression: "searchData.userType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "activity_status",
                          placeholder: _vm.$i18n.t("common.Activitystatus")
                        },
                        model: {
                          value: _vm.searchData.activityStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "activityStatus", $$v)
                          },
                          expression: "searchData.activityStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "system_bool",
                          placeholder: _vm.$i18n.t("common.Enableornot")
                        },
                        model: {
                          value: _vm.searchData.status,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "status", $$v)
                          },
                          expression: "searchData.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "system_bool",
                          placeholder: _vm.$i18n.t(
                            "common.Isthehomepagedisplayed"
                          )
                        },
                        model: {
                          value: _vm.searchData.homeShow,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "homeShow", $$v)
                          },
                          expression: "searchData.homeShow"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleResetSearch()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$i18n.t("common.Reset")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "15px"
                          },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.loadlist()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n\n                                   " +
                              _vm._s(_vm.$i18n.t("common.QuerySearch")) +
                              "\n\n\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0px 10px 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function($event) {
                  return _vm.add()
                }
              }
            },
            [_vm._v("     " + _vm._s(_vm.$i18n.t("common.Add1")))]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            height: "calc(100% - 180px)",
            data: _vm.pageData.list,
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "activityId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$i18n.t("common.ActivityName"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "imgUrl",
              label: _vm.$i18n.t("common.picture"),
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("listImage", { attrs: { imgUrl: scope.row.imgUrl } })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "description",
              label: _vm.$i18n.t("common.explain"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(scope.row.description) }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "activityGoods.length",
              label: _vm.$i18n.t("common.Numberofproducts"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brandCode",
              label: _vm.$t("common.ApplicableUserType"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.userType == "ALL"
                      ? _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$dict.getDictValue("user_type", "NEW") +
                                  " , " +
                                  _vm.$dict.getDictValue("user_type", "OLD")
                              ) +
                              "\n                    "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$dict.getDictValue(
                                  "user_type",
                                  scope.row.userType
                                )
                              )
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "countrys" },
                      model: {
                        value: scope.row.countryCode,
                        callback: function($$v) {
                          _vm.$set(scope.row, "countryCode", $$v)
                        },
                        expression: "scope.row.countryCode"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$i18n.t("common.Creationtime"),
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "beginTime",
              label: _vm.$i18n.t("common.starttime"),
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTime",
              label: _vm.$i18n.t("common.Endtime"),
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "theme",
              label: _vm.$i18n.t("common.state"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      staticClass: "filter-item",
                      attrs: { clearable: "", codeType: "activity_status" },
                      model: {
                        value: scope.row.activityStatus,
                        callback: function($$v) {
                          _vm.$set(scope.row, "activityStatus", $$v)
                        },
                        expression: "scope.row.activityStatus"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$i18n.t("common.Enableornot"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { disabled: true, "active-color": "#13ce66" },
                      model: {
                        value: row.status,
                        callback: function($$v) {
                          _vm.$set(row, "status", $$v)
                        },
                        expression: "row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$i18n.t("common.Whethertodisplayonthehomepage"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { disabled: true, "active-color": "#13ce66" },
                      model: {
                        value: row.homeShow,
                        callback: function($$v) {
                          _vm.$set(row, "homeShow", $$v)
                        },
                        expression: "row.homeShow"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.updateUserName))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row, "dialogFormVisible")
                          }
                        }
                      },
                      [_vm._v("     " + _vm._s(_vm.$i18n.t("common.edit")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.edit(
                              scope.row,
                              "dialogFormTableVisible2"
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(_vm.$i18n.t("common.commodity")) +
                            "\n                    "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.addGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v("  " + _vm._s(_vm.$i18n.t("common.Addproduct")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.openSEO(scope.row)
                          }
                        }
                      },
                      [_vm._v("SEO")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$i18n.t("common.delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("add-update-dialog", {
                attrs: { formItem: _vm.formItem, isEdit: _vm.isEdit },
                on: { closeDialog: _vm.closeWindow }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogFormTableVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormTableVisible = $event
            }
          }
        },
        [
          _c("FormTableDialog", {
            scopedSlots: _vm._u([
              {
                key: "tableSlot",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticStyle: { margin: "20px 0px 20px 20px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.add()
                              }
                            }
                          },
                          [_vm._v("   " + _vm._s(_vm.$i18n.t("common.Add1")))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        ref: "pagedata",
                        staticClass: "tableGoods",
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.tableData, border: "" },
                        on: { "selection-change": _vm.selectionChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "id",
                            label: _vm.$i18n.t("common.ProductID1"),
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "code",
                            label: _vm.$i18n.t("common.barcode"),
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "imgs",
                            label: _vm.$i18n.t("common.picture"),
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "category",
                            label: _vm.$i18n.t("common.Category"),
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "country",
                            label: _vm.$i18n.t("common.SalesCountry"),
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "activityStatus",
                            label: _vm.$i18n.t("common.state"),
                            align: "center",
                            "show-overflow-tooltip": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("dataText", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      clearable: "",
                                      codeType: "activity_status",
                                      placeholder: _vm.$i18n.t("common.brand")
                                    },
                                    model: {
                                      value: scope.row.activityStatus,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "activityStatus",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.activityStatus"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "updateTime",
                            "render-header": _vm.renderRuHeader,
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "updateTime",
                              "render-header": _vm.renderSortHeader,
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$i18n.t(
                                  "common.Pleaseentercontent"
                                )
                              },
                              model: {
                                value: _vm.input,
                                callback: function($$v) {
                                  _vm.input = $$v
                                },
                                expression: "input"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "status",
                              label: _vm.$i18n.t("common.Enableornot"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          },
                          [
                            _c("el-switch", {
                              model: {
                                value: _vm.enabled,
                                callback: function($$v) {
                                  _vm.enabled = $$v
                                },
                                expression: "enabled"
                              }
                            })
                          ],
                          1
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$i18n.t("common.operation"),
                            align: "center",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function($event) {
                                          delete scope.row
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.delete"))
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogSEOVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSEOVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("dataTabs", {
                staticClass: "filter-item",
                attrs: {
                  codeType: "countrys",
                  maxDic: [_vm.formItem.countryCode]
                },
                model: {
                  value: _vm.formItem.countryCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "countryCode", $$v)
                  },
                  expression: "formItem.countryCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form",
            { ref: "variantFrom", attrs: { model: _vm.seoFrom } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.PageTitle"),
                    prop: "title",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      placeholder: _vm.$i18n.t("common.Pleaseentercontent"),
                      maxlength: "500",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.seoFrom.title,
                      callback: function($$v) {
                        _vm.$set(_vm.seoFrom, "title", $$v)
                      },
                      expression: "seoFrom.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Pagekeywords"),
                    prop: "keyword",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      placeholder: _vm.$i18n.t("common.Pleaseentercontent"),
                      maxlength: "500",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.seoFrom.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.seoFrom, "keyword", $$v)
                      },
                      expression: "seoFrom.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.PageDescription"),
                    prop: "description",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      placeholder: _vm.$i18n.t("common.Pleaseentercontent"),
                      maxlength: "1000",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.seoFrom.description,
                      callback: function($$v) {
                        _vm.$set(_vm.seoFrom, "description", $$v)
                      },
                      expression: "seoFrom.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: {
                    click: function($event) {
                      return _vm.closeWindow(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("variantFrom")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialogFormTableVisible2
        ? _c("goods", {
            ref: "goods",
            attrs: {
              activityId: _vm.formItem.activityId,
              visible: _vm.dialogFormTableVisible2,
              countryCode: _vm.countryCode,
              brandCode: _vm.brandCode,
              "close-on-click-modal": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogFormTableVisible2 = $event
              },
              close: function($event) {
                return _vm.closeWindow(true)
              }
            }
          })
        : _vm._e(),
      _vm.goodsDialogVisible
        ? _c("goods-dialog", {
            ref: "goodsDialog",
            attrs: { "close-on-click-modal": false }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }