var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-descriptions",
        { attrs: { column: 6 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.Displaypromotionalprices") } },
            [
              _vm._v(
                _vm._s(
                  _vm.formInfo.flagShowPromotionPrice
                    ? _vm.$t("common.Yes")
                    : _vm.$t("common.No")
                )
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.PromotionPrice") } },
            [
              _vm._v(
                _vm._s(_vm.getPromotionGoodsPrice(_vm.formInfo, _vm.prices))
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.ActivityStatus") } },
            [
              _c("span", { style: { color: _vm.formInfo.activeStatusColor } }, [
                _vm._v(_vm._s(_vm.formInfo.activeStatus))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "el-descriptions",
        { attrs: { column: 6 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.SoldOutOrNot") } },
            [
              _vm._v(
                _vm._s(
                  _vm.formInfo.soldOut
                    ? _vm.$t("common.Yes")
                    : _vm.$t("common.No")
                )
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.RemainingTotal") } },
            [
              _vm._v(
                _vm._s(
                  _vm.actualPromotionProportion(_vm.formInfo, _vm.goodsSpecials)
                )
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.StartTime") } },
            [_vm._v(_vm._s(_vm._f("formatDate")(_vm.formInfo.beginTime)))]
          )
        ],
        1
      ),
      _c(
        "el-descriptions",
        { attrs: { column: 6 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.Isitaflashkill") } },
            [
              _vm._v(
                _vm._s(
                  _vm.formInfo.seckill
                    ? _vm.$t("common.Yes")
                    : _vm.$t("common.No")
                )
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.TotalRemainingTotal") } },
            [
              _vm._v(
                _vm._s(
                  _vm.totalPromotionProportion(_vm.formInfo, _vm.goodsSpecials)
                )
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("common.EndTime") } },
            [_vm._v(_vm._s(_vm._f("formatDate")(_vm.formInfo.endTime)))]
          )
        ],
        1
      ),
      _vm.formInfo.seckill
        ? _c(
            "el-descriptions",
            { attrs: { column: 6 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$t("common.Secondkillsorting") } },
                [_vm._v(_vm._s(_vm.formInfo.sort))]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }