var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      codeType: "position",
                      placeholder: _vm.$i18n.t("common.Recommendedlocation")
                    },
                    model: {
                      value: _vm.searchData.position,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "position", $$v)
                      },
                      expression: "searchData.position"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      codeType: "recommend_module",
                      placeholder: _vm.$i18n.t("common.Recommendationmodule")
                    },
                    model: {
                      value: _vm.searchData.recommendModule,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "recommendModule", $$v)
                      },
                      expression: "searchData.recommendModule"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      multiple: true,
                      clearable: "",
                      codeType: "countrys",
                      placeholder: _vm.$i18n.t("common.country")
                    },
                    model: {
                      value: _vm.searchData.countryCode,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "countryCode", $$v)
                      },
                      expression: "searchData.countryCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Enableornot"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchData.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "status", $$v)
                        },
                        expression: "searchData.status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.yes"),
                          value: "true"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.no"),
                          value: "false"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.searchClick }
                    },
                    [
                      _vm._v(
                        "\n                               " +
                          _vm._s(_vm.$i18n.t("common.query")) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                               " +
                          _vm._s(_vm.$i18n.t("common.Reset")) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "20px 0px 20px 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function($event) {
                  return _vm.add(null)
                }
              }
            },
            [_vm._v("  " + _vm._s(_vm.$i18n.t("common.Add1")))]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.pageData.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "homeModuleId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "position",
              label: _vm.$i18n.t("common.Recommendedlocation"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "position" },
                      model: {
                        value: scope.row.position,
                        callback: function($$v) {
                          _vm.$set(scope.row, "position", $$v)
                        },
                        expression: "scope.row.position"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recommendModule",
              label: _vm.$i18n.t("common.Recommendationmodule"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "recommend_module" },
                      model: {
                        value: scope.row.recommendModule,
                        callback: function($$v) {
                          _vm.$set(scope.row, "recommendModule", $$v)
                        },
                        expression: "scope.row.recommendModule"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "countrys" },
                      model: {
                        value: scope.row.countryCode,
                        callback: function($$v) {
                          _vm.$set(scope.row, "countryCode", $$v)
                        },
                        expression: "scope.row.countryCode"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recommendCount",
              label: _vm.$i18n.t("common.Numberofproducts"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$i18n.t("common.Addtime"),
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              formatter: _vm.formatDate,
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Enableornot"),
              prop: "status",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { disabled: true, "active-color": "#13ce66" },
                      model: {
                        value: row.status,
                        callback: function($$v) {
                          _vm.$set(row, "status", $$v)
                        },
                        expression: "row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "200",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row, "dialogFormVisible")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.chooseGood(
                              scope.row,
                              "dialogFormTableVisible"
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("common.commodity")) +
                            "\n                    "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.addGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.Addproduct")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("varoant-dialog", {
                attrs: { formItem: _vm.formItem, isEdit: _vm.isEdit },
                on: { closeDialog: _vm.closeWindow }
              })
            ],
            1
          )
        : _vm._e(),
      _c("goods", {
        ref: "goods",
        attrs: { "close-on-click-modal": false },
        on: {
          closed: _vm.loadlist,
          close: function($event) {
            return _vm.closeWindow(true)
          }
        }
      }),
      _vm.goodsDialogVisible
        ? _c("goods-dialog", {
            ref: "goodsDialog",
            attrs: {
              typeName: _vm.typeName,
              "show-close": true,
              "close-on-click-modal": false
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }