<template>
    <div>
        <filter-container
            :arg="arg"
            :placeholder="placeholder"
            :dictionary="dictionary"
            :defaultListQuery="defaultListQuery"
            @handleSearchList="handleSearchList"
            @handleResetSearch="handleResetSearch"
        ></filter-container>
        <div style="margin: 20px 0px 20px 20px">
            <el-button @click="add" type="success" size="small">    {{ $i18n.t("common.Add1") }}</el-button>
        </div>
    <el-table ref="pagedata" :data="pageData.list" border style="width: 100%">
      <el-table-column prop="activityId" label="ID" align="center" show-overflow-tooltip></el-table-column>

        <!--<el-table-column prop="brandCode1" label="品牌" align="center" show-overflow-tooltip></el-table-column>-->
        <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="beginTime"  :label='$i18n.t("common.starttime")' align="center" show-overflow-tooltip>

        <template slot-scope="{ row }">
          <span>{{ new Date(row.beginTime).toLocaleString().split(" ")[0] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="endTime"  :label='$i18n.t("common.Endtime")'align="center" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span>{{ new Date(row.endTime).toLocaleString().split(" ")[0]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="publishStatus" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span v-if="row.publishStatus == 0" style="color: red">{{$i18n.t("common.begininaminute") }}</span>
          <span v-if="row.publishStatus == 1" style="color: green">{{ $i18n.t("common.Inprogress") }}</span>
          <span v-if="row.publishStatus == 2" style="color: gray">{{ $i18n.t("common.Ended")}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="activityGoodsNum" :label='$i18n.t("common.Firstfreeshippingitem")' align="center" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.activityGoods.length }}
        </template>
      </el-table-column>
      <el-table-column prop="status" :label='$i18n.t("common.Enableornot")' align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="theme" :label='$i18n.t("common.Creationtime")' align="center" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span>{{ new Date(row.createTime).toLocaleString()}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="theme" :label='$i18n.t("common.Updatetime")' align="center" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span>{{ row.updateTime == null ? null : new Date(row.updateTime).toLocaleString()}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="updateUserName"  :label='$i18n.t("common.Operator")' align="center" show-overflow-tooltip></el-table-column>
      <el-table-column :label='$i18n.t("common.operation")'  align="center" fixed="right" width="150px">

        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">{{$i18n.t("common.edit")}}</el-button>
          <el-button type="text" size="small" @click="deleteActivity(scope.row)">{{$i18n.t("common.delete")}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page="listQuery.page"
          :page-size="listQuery.limit"
          layout="total, prev, pager, next, jumper"
          :total="pageData.total"
      >
      </el-pagination>
    </div>
    <el-dialog

        :title='isEdit ? $i18n.t("common.edit") : $i18n.t("common.Add1")'

        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
        v-if="dialogFormVisible"
    >
      <add-update-dialog
          ref="addorupdatechild"
          :typeName="typeName"
          @handleSearchList2="handleSearchList2"
          @handleSearchList3="handleSearchList3"
          @closeDialog3="closeDialog3"
          @handleResetSearch2="handleResetSearch2"
          :dictionary="dictionary"
          :form="formdata"
      />
    </el-dialog>
  </div>
</template>
<script>
import {showSelectOfSelectable} from "@/utils/treeTable";
import moment from 'moment';
import AddUpdateDialog from './components/AddUpdateDialog.vue';
import FilterContainer from '@/components/common/FilterContainer.vue';
import i18n from '@/i18n/i18n.js';
import dictionaryUtil from '@/utils/dictionaryUtil';
const defaultDictionary = {
  // 国家
  country: dictionaryUtil.getDict('countrys'),
  //位置
  brandCode: dictionaryUtil.getDict('brand'),
  state: [
    {
      id: '1',
      name: i18n.t("common.Inprogress"),
      value: '1'
    },
    {
      id: '2',
      name: i18n.t("common.begininaminute"),
      value: '2'
    },
    {
      id: '3',
      name: i18n.t("common.Ended"),
      value: '3'
    }
  ],
  status: [
    {
      id: '1',
      name: i18n.t("common.yes"),
      value: true
    },
    {
      id: '2',
      name: i18n.t("common.no"),
      value: false
    }
  ],
  //是否促销
  promotion: [
    {
      id: '1',
      name: i18n.t("common.yes"),
      value: true
    },
    {
      id: '2',
      name: i18n.t("common.no"),
      value: false
    }
  ],
  //使用状态
  isUsed: [
    {
      id: '1',
      name: i18n.t("common.Listed"),
      value: true
    }
  ]
};
export default {
  components: {AddUpdateDialog, FilterContainer,moment},
  name: 'varoantAdmin',
  data() {
    return {
      formdata: {},
      typeName: '促销',
      dictionary: Object.assign({}, defaultDictionary),
      //查询项
      listQuery: {
        title: '',
        brandCode: '',
        countryCode:[],
        goodId: '',
        status: '',
        endTime: '',
        beginTime: '',
        page: 1,
        limit: 10,
        activityStatus:'',
      },
      defaultListQuery: {
        title: '',
        brandCode: '',
        countryCode:[],
        goodId: '',
        status: '',
        endTime: '',
        beginTime: '',
        page: 1,
        limit: 10
      },
      //组件标签是否显示
      arg: {
        // brand: true,
        country: true,
        state: true,
        enable: true,
      },
      placeholder: {
        state: this.$i18n.t("common.state"),
        country: this.$i18n.t("common.country"),
      },
      //编辑or新增
      isEdit: false,
      //表单数据
      formItem: {},
      platformList: [],
      pageData: {},
      dialogFormVisible: false,
      dialogFormTableVisible2: false,
      title: '',
      ossHost: process.env.VUE_APP_OSS_Head,
      //字典下拉框
      brandDicts: [],
      countrysDicts: [],
      platformDicts: [],
      webSiteUrl: process.env.VUE_APP_WEBSITE
    };
  },

  mounted() {
    this.handleSearchList();
  },
  methods: {
    //搜索列表
    handleSearchList(listQuery) {
      let type = 'yyyy-MM-dd hh:mm:ss';
      let beginTime = new Date(new Date(new Date().toLocaleDateString()).getTime());
      let endTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
      let nowTime = Date.parse(new Date());

      if (listQuery) {
        let status = '';
        if (listQuery.enable === true) {
          status = 'true';
        } else if (listQuery.enable === false) {
          status = 'false';
        }
        this.listQuery = {
          title: listQuery.title,
          brandCode: listQuery.brand,
          goodId: '',
          status: status,
          countryCodes: listQuery.country,
          endTime: '',
          beginTime: '',
          page: this.listQuery.page,
          limit: this.listQuery.limit,
          isUsed: listQuery.isUsed,
          promotion: listQuery.promotion
        };
        // if (listQuery.state == '1') {
        //   this.listQuery.beginTime = this.$options.filters['formatDate'](nowTime, type).toString();
        //   this.listQuery.endTime = this.$options.filters['formatDate'](nowTime, type).toString();
        // } else if (listQuery.state == '2') {
        //   this.listQuery.beginTime = this.$options.filters['formatDate'](nowTime, type).toString();
        // } else if (listQuery.state == '3') {
        //   this.listQuery.endTime = this.$options.filters['formatDate'](nowTime, type).toString();
        // }
        if (listQuery.state == '1') {
            // this.listQuery.beginTime = this.$options.filters['formatDate'](nowTime, type).toString();
            // this.listQuery.endTime = this.$options.filters['formatDate'](nowTime, type).toString();
            this.listQuery.activityStatus = "UnderWay";
        } else if (listQuery.state == '2') {
            // this.listQuery.beginTime = this.$options.filters['formatDate'](nowTime, type).toString();
            this.listQuery.activityStatus = "NotStarted"
        } else if (listQuery.state == '3') {
            // this.listQuery.endTime = this.$options.filters['formatDate'](nowTime, type).toString();
              this.listQuery.activityStatus = "Complete"
      }
      }

      this.$store.dispatch('getActivityFreeFreightPage', this.listQuery).then((res) => {
        this.pageData = res;
        this.pageData.list.forEach((item, index) => {
          //优惠券类型
          if (item.brandCode == 'Nobleza') {
            this.pageData.list[index].brandCode1 = 'NOBLEZA';
          } else if (item.brandCode == 'Unico') {
            this.pageData.list[index].brandCode1 = 'UNICO';
          } else if (item.brandCode == 'Topject') {
            this.pageData.list[index].brandCode1 = 'TOPJECT';
          } else if (item.brandCode == 'AigoStar') {
            this.pageData.list[index].brandCode1 = 'AIGOSTAR';
          }
          //上架
          if (item.status == false) {
            this.pageData.list[index].status2 =  this.$i18n.t("common.Notlisted");
          } else if (item.status == true) {
            this.pageData.list[index].status2 =  this.$i18n.t("common.Listed");
          }
          console.log("======1111",item.updateTime)
          this.pageData.list[index].createTime==null?"":moment(item.createTime).add('8','hour').format('YYYY-MM-DD HH:mm:ss');
          this.pageData.list[index].updateTime==null?"":moment(item.updateTime).add('8','hour').format('YYYY-MM-DD HH:mm:ss');
          console.log("======",this.pageData.list[index].updateTime)
        });
        console.log('this.listQuery', this.pageData.list);
      });
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, this.defaultListQuery);
      this.handleSearchList();
    },
    handleSearchList3(listQuery){
      this.dialogFormVisible = false;
      this.$store.dispatch('getActivityFreeFreightPage', this.listQuery).then((res) => {
        this.pageData = res;
        this.pageData.list.forEach((item, index) => {
          //优惠券类型
          if (item.brandCode == 'Nobleza') {
            this.pageData.list[index].brandCode1 = 'NOBLEZA';
          } else if (item.brandCode == 'Unico') {
            this.pageData.list[index].brandCode1 = 'UNICO';
          } else if (item.brandCode == 'Topject') {
            this.pageData.list[index].brandCode1 = 'TOPJECT';
          } else if (item.brandCode == 'AigoStar') {
            this.pageData.list[index].brandCode1 = 'AIGOSTAR';
          }
          //上架
          if (item.status == false) {
            this.pageData.list[index].status2 =  this.$i18n.t("common.Notlisted");
          } else if (item.status == true) {
            this.pageData.list[index].status2 = this.$i18n.t("common.Listed");
          }
          console.log("======1111",item.updateTime)
          this.pageData.list[index].createTime==null?"":moment(item.createTime).add('8','hour').format('YYYY-MM-DD HH:mm:ss');
          this.pageData.list[index].updateTime==null?"":moment(item.updateTime).add('8','hour').format('YYYY-MM-DD HH:mm:ss');
          console.log("======",this.pageData.list[index].updateTime)
        });
        console.log('this.listQuery', this.pageData.list);
      });
    },
    closeDialog3(listQuery){
      this.dialogFormVisible = false;
      console.log("1111111111111111111")
      // this.handleSearchList(listQuery);
    },
    handleSearchList2(listQuery) {
      let curSates={
                page: this.listQuery.page,
                size: this.listQuery.size,
      }
      // this.dialogFormVisible = false;
      console.log("==============",listQuery);
      if(listQuery.promotion!=undefined){
          var promotion=listQuery.promotion==true?"true":"false";
      }
      if(listQuery.isUsed!=undefined){
          var effectShelf=listQuery.isUsed==true?"true":"";
      }
      
      console.log('promotion',promotion)
      this.listQuery = {
                idFuzzy: listQuery.title,
                updateUserId: '', //操作人
                inPromotion : promotion, //是否促销
                effectShelf: effectShelf, //是否上架
                page: listQuery.page?listQuery.page:1,
                size: 10
        };
        console.log(listQuery)
      this.$store.dispatch('postGoodsSearch', this.listQuery).then((res) => {
                this.pageData2 = res;
                this.$refs.addorupdatechild.getPageData2(this.pageData2)
                console.log('this.pageData2.list', this.pageData2.list);
                if (this.$refs.addorupdatechild && this.$refs.addorupdatechild.$refs.tableData) {
                    showSelectOfSelectable(this.$refs.addorupdatechild.$refs.tableData, this.pageData2.list)

                }
      });
      
    },
    handleResetSearch2() {
      this.listQuery = Object.assign({}, this.defaultListQuery);
      this.handleSearchList2();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.handleSearchList();
    },
    //打开新增
    add() {
      this.dialogFormVisible = true;
      this.isEdit = false;
      setTimeout(() => {
        this.$refs['addorupdatechild'].form = {status:true}
        this.$refs['addorupdatechild'].isEdit = false;
        this.$refs['addorupdatechild'].selectGoods = [];
      });
    },
    //打开编辑
    edit(row) {
      this.$store.dispatch('getActivityFreeFreightById', {id: row.activityId}).then((res) => {
        this.dialogFormVisible = true;
        this.isEdit = true;
        setTimeout(() => {
          this.$refs['addorupdatechild'].isEdit = true;
          this.$refs['addorupdatechild'].form = res;
          this.$refs['addorupdatechild'].initData();
        });
      });
    },
    deleteActivity(row) {
      this.$store.dispatch('delActivityFreightPage', {id: row.activityId}).then((res) => {
        this.handleSearchList();
        this.listQuery.page = 1;
      });
    },

  
  }
};
</script>
