<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                v-model="query.idFuzzy"
                :placeholder="`SKU/EAN/` + $i18n.t('common.coding')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
                oninput="value=value.replace(/[%]/g,'')"
                clearable
            ></el-input>
            <!-- 
            <dataSelect
                style="width: 150px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder="$i18n.t('common.country')"
            ></dataSelect> -->

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">
                {{ $i18n.t('common.search') }}</el-button
            >

            <el-button class="filter-item" style="margin-left: 10px" type="success" icon="el-icon-edit" @click="handleClearQuery()">
                {{ $i18n.t('common.Reset') }}
            </el-button>
        </div>

        <el-table
            v-loading="listLoading"
            :data="giftList"
            border
            stripe
            ref="multipleTable"
            style="width: 100%; margin-top: 10px"
            @select="onSelect"
            @select-all="onSelectAll"
            :row-key="getRowKey"
        >
            <el-table-column type="selection" width="50" :selectable="isSelect" :reserve-selection="true" />
            <el-table-column :label="$i18n.t('common.GiftID')" prop="giftId" align="center" width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.giftId }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.Productname1')" prop="title" align="center" width="300">
                <template slot-scope="{ row }">
                    <span>{{ row.title }}</span>
                </template>
            </el-table-column>

            <el-table-column label="EAN" prop="giftId" align="center" width="200px">
                <template slot-scope="{ row }">
                    <span>SKU:{{ row.sku }}</span>
                    <div>EAN:{{ row.barCode }}</div>
                    <div>{{ $i18n.t('common.coding') }}:{{ row.goodsCode }}</div>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.country')" prop="countryCodeName" align="center" width="100">
                <template slot-scope="{ row }">
                    <span>{{ $dict.getDictValue('countrys', row.countryCode) }}<br /></span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.CurrentInventory')" prop="ecomRemainQty" align="center" width="100">
                <template slot-scope="{ row }">
                    <span>{{ row.ecomRemainQty }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="enableStatus" :label="$i18n.t('common.Enableornot')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.enableStatus" active-color="#13ce66" inactive-color="#ff4949" disabled> </el-switch>
                </template>
            </el-table-column>
        </el-table>
        <pagination
            v-show="total > 0"
            :total="total"
            :pageNo.sync="query.pageNo"
            :pageSize.sync="query.pageSize"
            @pagination="doQueryList"
        />
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeForm()"> {{ $i18n.t('common.cancel') }} </el-button>
            <el-button type="primary" @click="handleSubmit()"> {{ $i18n.t('common.confirm') }} </el-button>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import dataText from '@/components/common/DataText.vue';
import scene from '@/utils/scene';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary = {
    // 国家
    country: dictionaryUtil.getDict('countrys')
};

export default {
    name: 'GiftDialog',
    components: {
        Pagination,
        dataText,
        DataSelect
    },
    data() {
        return {
            giftList: [],
            total: 0,
            listLoading: true,
            query: {
                current: 1,
                limit: 20,

                idFuzzy: null,
                countryCode: null
            },
            dialogTitle: '',
            text: '',
            countrys: [],
            selections: new Map(),
            alreadySelection: [],
            dialogFormVisible: false,
            form: {
                enableStatus: false,
                countryCode: null,
                goodsId: '',
                relationGoodsId: '',
                giftId: ''
            },
            dictionary: Object.assign({}, defaultDictionary),
            showSelectGoods: true,
            countryDisabled: false,
            tipsList: [],
            dialogtipsVisible: false,
            rules: {
                countryCode: [{ required: true, message: this.$i18n.t('common.Pleaseselectacountry'), trigger: 'blur' }]
            }
        };
    },
    watch: {
        deep: true
    },
    computed: {
        scene() {
            return scene;
        }
    },
    methods: {
        /**
         * 父组件传值触发搜索
         */
        handleQueryByCountryCode(row, alreadySelection) {
            this.query.countryCode = row.countryCode;
            //只显示启用的
            this.query.status = true;
            console.log(alreadySelection);
            //回显选择框置灰
            if(row.activityStatus == 1){
                this.alreadySelection = alreadySelection;
            }else{
                alreadySelection.forEach(x => {
                    this.$refs.multipleTable.toggleRowSelection(x, true);
                    this.selections.set(x.giftId.toString(),x)
                })
                
            }
            console.log(this.selections);
            this.handleQuery();
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getGiftPage', this.query)
                .then((res) => {
                    this.giftList = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },

        handleClearQuery() {
            let params = {
                current: 1,
                limit: 10,

                idFuzzy: null,
            };
            this.query.current = 1;
            this.query.limit = 10;
            this.query.idFuzzy = null;
            this.handleQuery();
        },
        getRowKey(row) {
            return row.giftId;
        },
        onSelect(selection, row) {
            if (this.selections.has(row.giftId)) {
                this.selections.delete(row.giftId);
            } else {
                this.selections.set(row.giftId, row);
            }
            // if (this.selections[row.giftId]) {
            //     delete this.selections[row.giftId];
            // } else {
            //     this.selections[row.giftId] = row;
            // }
            console.log(this.selections);
        },
        // 全选情况
        onSelectAll(selection) {
            // 全选
            if (selection.length) {
                selection.forEach((row) => {
                    this.selections.set(row.giftId, row);
                });
            } else {
                // 取消全选
                this.giftList.forEach((row) => {
                    this.selections.delete(row.giftId);
                });
            }
        },
        //显示按钮置灰
        isSelect(row, index) {
            if (this.alreadySelection == null || this.alreadySelection.length == 0) {
                return true;
            }
            if (
                this.alreadySelection.some((x) => {
                    return x.giftId == row.giftId;
                })
            ) {
                return false;
            } else {
                return true;
            }
        },
        handleSubmit() {
            var result = [];
            if (this.alreadySelection != null && this.alreadySelection.length > 0) {
                result = result.concat(this.alreadySelection);
            }
            for(let item of this.selections.values()){
                item.activityNum = 1;
                item.quantityGiven = 0;
                result.push(item);
            }
            this.$emit('getGiftList',result);
        },
        closeForm(){
            this.$emit('closeGiftForm');
        }
    }
};
</script>
<style scoped>
.box {
    width: 600px;
    display: flex;
}
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.filter-item {
    margin-right: 10px;
    margin-top: 10px;
}
</style>
