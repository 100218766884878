var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: "SKU/EAN/" + _vm.$i18n.t("common.coding"),
              "controls-position": "right",
              oninput: "value=value.replace(/[%]/g,'')",
              clearable: ""
            },
            model: {
              value: _vm.query.idFuzzy,
              callback: function($$v) {
                _vm.$set(_vm.query, "idFuzzy", $$v)
              },
              expression: "query.idFuzzy"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleQuery }
            },
            [_vm._v("\n            " + _vm._s(_vm.$i18n.t("common.search")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: {
                click: function($event) {
                  return _vm.handleClearQuery()
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.Reset")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: {
            data: _vm.giftList,
            border: "",
            stripe: "",
            "row-key": _vm.getRowKey
          },
          on: { select: _vm.onSelect, "select-all": _vm.onSelectAll }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "50",
              selectable: _vm.isSelect,
              "reserve-selection": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.GiftID"),
              prop: "giftId",
              align: "center",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.giftId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Productname1"),
              prop: "title",
              align: "center",
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.title))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "EAN",
              prop: "giftId",
              align: "center",
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v("SKU:" + _vm._s(row.sku))]),
                    _c("div", [_vm._v("EAN:" + _vm._s(row.barCode))]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("common.coding")) +
                          ":" +
                          _vm._s(row.goodsCode)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.country"),
              prop: "countryCodeName",
              align: "center",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$dict.getDictValue("countrys", row.countryCode)
                        )
                      ),
                      _c("br")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.CurrentInventory"),
              prop: "ecomRemainQty",
              align: "center",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.ecomRemainQty))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "enableStatus",
              label: _vm.$i18n.t("common.Enableornot"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        disabled: ""
                      },
                      model: {
                        value: scope.row.enableStatus,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enableStatus", $$v)
                        },
                        expression: "scope.row.enableStatus"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          pageNo: _vm.query.pageNo,
          pageSize: _vm.query.pageSize
        },
        on: {
          "update:pageNo": function($event) {
            return _vm.$set(_vm.query, "pageNo", $event)
          },
          "update:page-no": function($event) {
            return _vm.$set(_vm.query, "pageNo", $event)
          },
          "update:pageSize": function($event) {
            return _vm.$set(_vm.query, "pageSize", $event)
          },
          "update:page-size": function($event) {
            return _vm.$set(_vm.query, "pageSize", $event)
          },
          pagination: _vm.doQueryList
        }
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }