<template>
    <el-dialog :title="title" width="800px" :visible.sync="formVisible" :close-on-click-modal='false'>
        <el-form ref="dataForm" :rules="formRules" :model="form" size="small" label-width="100px">
            <el-form-item  :label='$i18n.t("common.name")' prop="name">
                <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item  :label='$i18n.t("common.country")' prop="countryCode">
                <dataSelect
                    style="width: 200px"
                    class="filter-item"
                    v-model="form.countryCode"
                    :codeType="'countrys'"
                    :placeholder='$i18n.t("common.Pleaseselectacountry")'
                ></dataSelect>
            </el-form-item>
            <el-form-item label="URL" prop="sourceUrl">
                <el-input v-model="form.sourceUrl" maxlength='500' show-word-limit/>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.source")' prop="sourceInfo">
                <el-col :span="12">
                    <el-input v-model="form.sourceInfo" />
                </el-col>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Remarks")' prop="remark">
                <el-input type="textarea" maxlength="1000"
                          show-word-limit v-model="form.remark" oninput="if(value.length>1000)value=value.slice(0,1000)"/>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false"> {{ $i18n.t("common.cancel") }} </el-button>
            <el-button type="primary" @click="doCreate()">  {{ $i18n.t("common.confirm") }} </el-button>
        </div>
    </el-dialog>
</template>

<script>
import DataSelect from '@/components/common/DataSelect.vue';
import { time } from 'echarts';
function initFormBean() {
    const formBean = {
        name: null,
        countryCode: 'ES',
        fixedUrl: null,
        sourceInfo: null,
        remark: ""
    };
    return formBean;
}

export default {
    components: {
        DataSelect
    },
    name: 'SourceUrlAdd',
    data() {
        return {
            form: {},
            title: this.$i18n.t("common.Add1")+' URL',
            formVisible: false,
            formRules: {
                name: [
                    { required: true, message: this.$i18n.t("common.Pleaseenteraname") , trigger: 'blur' },
                    { max: 20, message: this.$i18n.t("common.Thelengthcannotexceed20characters"), trigger: 'blur' }
                ],
                countryCode: [
                    { required: true, message: this.$i18n.t("common.Pleaseselectacountry"), trigger: 'blur' }
                ],
                sourceUrl: [
                    { required: true, message: this.$i18n.t("common.PleaseEnterTheURL"), trigger: 'change' }
                ],
                sourceInfo: [
                    { required: true, message: this.$i18n.t("common.Pleaseenterthesource"), trigger: 'change' },

                    { max: 100, message: this.$i18n.t("common.Thelengthcannotexceed50characters"), trigger: 'change' },

                  //  { pattern: /^[a-zA-Z0-9]+$/, message: this.$i18n.t("common.PleaseEnterEnglishOrNumbers"), trigger: 'change'}
                ],
                remark: [
                    { max: 1000, message: this.$i18n.t("common.LengthCannotExceed1000Characters"), trigger: 'change' }
                    
                ]
            }
        };
    },
    methods: {
        /**
         * 重置表单
         */
        resetForm() {
            this.form = initFormBean();
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.resetForm();
            this.title = this.$i18n.t("common.Add1") + ' URL'
            this.formVisible = true;
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate();
            });
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.resetForm();
            this.form = JSON.parse(JSON.stringify(row));
            this.title = this.$i18n.t("common.edit") + ' URL'
            this.formVisible = true;
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate();
            });
        },
        /**
         * 执行新建操作
         */
        doCreate() {
            this.$refs['dataForm'].validate().then(() => {
                if (this.form.sourceUrlId == null) {
                    this.$store.dispatch('createSourceUrl', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success(this.$i18n.t("common.Operationsuccessful"));
                    });
                } else {
                    this.$store.dispatch('updateSourceUrl', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success(this.$i18n.t("common.Operationsuccessful"));
                    });
                }
            });
        },
    },
};
</script>
