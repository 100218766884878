<template>
	<div>
    <el-descriptions :column='6'>
        <el-descriptions-item :label="$t('common.Displaypromotionalprices')">{{ formInfo.flagShowPromotionPrice ? $t('common.Yes'): $t('common.No')}}</el-descriptions-item>
        <el-descriptions-item :label="$t('common.PromotionPrice')">{{ getPromotionGoodsPrice(formInfo, prices) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('common.ActivityStatus')"><span :style="{ color: formInfo.activeStatusColor }" >{{ formInfo.activeStatus }}</span></el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column='6'>
        <el-descriptions-item :label="$t('common.SoldOutOrNot')">{{ formInfo.soldOut ? $t('common.Yes'): $t('common.No') }}</el-descriptions-item>
        <el-descriptions-item :label="$t('common.RemainingTotal')">{{ actualPromotionProportion(formInfo, goodsSpecials) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('common.StartTime')">{{ formInfo.beginTime  | formatDate }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column='6'>
        <el-descriptions-item :label="$t('common.Isitaflashkill')">{{ formInfo.seckill ? $t('common.Yes'): $t('common.No') }}</el-descriptions-item>
        <el-descriptions-item :label="$t('common.TotalRemainingTotal')">{{ totalPromotionProportion(formInfo, goodsSpecials) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('common.EndTime')">{{ formInfo.endTime  | formatDate }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column='6' v-if="formInfo.seckill">
        <el-descriptions-item :label="$t('common.Secondkillsorting')">{{ formInfo.sort }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
    import {formatDate} from '@/utils/date';
    import { getPromotionGoodsPrice, actualPromotionProportion, totalPromotionProportion} from "@/utils/promotion";

    export default {
        props: {
            formInfo: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            prices: {
                type: Array,
                default: () => {
                    return []
                }
            },
            goodsSpecials: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
          return {

          }
        },
        filters:{
            formatDate(time,type='yyyy-MM-dd hh:mm:ss'){
                if(time==null||time===''){
                    return 'N/A';
                }
                let date = new Date(time);
                return formatDate(date,type)
            },
        },
        methods:{
            //获取促销价格
            getPromotionGoodsPrice,
            //实际促销比例
            actualPromotionProportion,
            //总促销比例
            totalPromotionProportion,
        },
    }
</script>

<style scoped>
/deep/ .el-descriptions-row:hover {
	background-color: #fff;
}
</style>
