<template>
    <el-tabs v-model="activeName" type="card" @tab-click="handleTabsClick">
        <el-tab-pane :label='$i18n.t("common.shoppingcart")' name="cart">
            <UserActivityTable ref="cart-table"></UserActivityTable>
        </el-tab-pane>
        <el-tab-pane :label='$i18n.t("common.OfflineShoppingCart")' name="offlineCart">
            <UserActivityTable ref="offline-cart-table"></UserActivityTable>
        </el-tab-pane>
    </el-tabs>
</template>

<script>

import UserActivityTable from "./components/UserActivityTable.vue";

export default {
    name: 'UserActivity',
    components: {
        UserActivityTable
    },
    data() {
        return {
            activeName: 'cart',
        };
    },
    created() {

    },
    methods: {
        handleTabsClick(tab, event) {
            if ('cart' == tab.name) {
                this.$refs["cart-table"].query.stateType = 0;
                this.$refs["cart-table"].handleQuery();
            } else {
                this.$refs["offline-cart-table"].query.stateType = 1;
                this.$refs["offline-cart-table"].handleQuery();
            }
        }
    }
};
</script>

<style scoped>

</style>
