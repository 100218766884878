<template>
    <div style="height: 100%;min-height:350px;">
	 
		<div class="filter-container" shadow="never">
            <el-form :inline="true">

                <el-form-item>
					<el-select v-model="searchData.countryCode"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder='$i18n.t("common.country")'>
						<el-option
							v-for="item in dictionary.country"
							:key="item.id"
							:label="item.name"
							:value="item.code">
						</el-option>      
					</el-select>
                </el-form-item>

 
                <el-form-item>
					<el-select v-model="searchData.status"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder='$i18n.t("common.Enableornot")'>
                        <el-option :label='$i18n.t("common.yes")' value="true"></el-option>
                        <el-option :label='$i18n.t("common.no")' value="false"></el-option>
					</el-select>
                </el-form-item>
     
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick"
                               style="margin-left: 10px">
                               {{ $i18n.t("common.query") }}
                    </el-button>
 
                </el-form-item>
            </el-form>
        </div>
		
	
		<div style="margin: 0px 0px 10px 20px;">
			<el-button @click="add()" type="success" size="small"> {{ $i18n.t("common.Add") }}</el-button>
		</div>

 
        <el-table ref="pagedata" height="calc(100% - 150px)" :data="pageData.list" border v-loading="loading"  style="width: 100%">

            <el-table-column prop="homePageCopyWriterId" label="ID" align="center" show-overflow-tooltip> </el-table-column>

            <el-table-column prop="countryCode"  :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-for="(item, index) in scope.row.homePageCopyWriterCountryDtos" :key="index">
                        <span> {{ $dict.getDictValue('countrys',  item.countryCode)  }}</span>
                    </div>   
                </template>
            </el-table-column>

            <el-table-column prop="name" :label=' $i18n.t("common.name")' align="center" show-overflow-tooltip></el-table-column>
			
            <el-table-column prop="startTime"  :label='$i18n.t("common.starttime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column>
			<el-table-column prop="endTime"  :label='$i18n.t("common.Endtime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column> 



            <el-table-column prop="status" :label='$i18n.t("common.Enableornot")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status"  active-color="#13ce66"  disabled></el-switch>
                </template>
            </el-table-column>
 

  
			<el-table-column prop="updateByName"  :label='$i18n.t("common.Operator")' align="center" show-overflow-tooltip></el-table-column>
 
			<el-table-column :label='$i18n.t("common.operation")'  align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small">{{$i18n.t("common.edit")}}</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small">{{$i18n.t("common.delete")}}</el-button>
                </template>
            </el-table-column>

 
        </el-table>

        <div class="block">
        	<el-pagination @current-change="handleCurrentChange" :current-page="searchData.page"  
				:page-sizes="pageSizes"   @size-change="handleSizeChange"
        		:page-size="searchData.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageData.total">
        	</el-pagination>
        </div>
		 
        <el-dialog :title="title" :visible.sync="dialogFormVisible" v-if="dialogFormVisible"
                   :close-on-click-modal="false">
            <add-update-dialog  @closeDialog="closeDialog" :formItem="formItem" :isEdit="isEdit" :dictionary="dictionary" :countrylang="countrylang" />
        </el-dialog>


    

		 
    </div>
</template>
<script>
 

import addUpdateDialog from './components/AddUpdateDialog.vue';
 
import moment from 'moment';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary={
    // 国家
    country:dictionaryUtil.getDict('countrys'),
    lang:dictionaryUtil.getDict('lang'),
    jumpType:dictionaryUtil.getDict('homePageCopyWriter_jump_type'),
    
};
export default {
	components: { moment,addUpdateDialog },
    name: 'homePageCopyWriter_module',
    data() {
        return {	 
			loading:false,	 
			dictionary:Object.assign({}, defaultDictionary),	
            countrylang:[],
            // 表单	 
            title: '',
            dialogFormVisible: false,
            formItem: {
                name:'',
                startTime: null,
                endTime:null,
                jumpType: '1',
                status: true,
                sort: 0,
                time: null,
                homePageCopyWriterCountrys: [],
                homePageCopyWriterLangs: []
            },
            defaultFormItem: {
                name:'',
                startTime: null,
                endTime:null,
                jumpType: '1',
                status: true,
                sort: 0,
                time: null,
                homePageCopyWriterCountrys: [],
                homePageCopyWriterLangs: []
            },
			 //查询项
			searchData: { 
                status: null,
                countryCode: null,
                page: 1,
                limit: 10
            },
            defaultSearchData: { 
                status: null,
                countryCode: null,
                page: 1,
                limit: 10
            },
			pageSizes: [10, 20, 30, 50],
			pageData: {}
        };
    }, 
    created() {
        this.initDic();
    },
    mounted() {
        this.searchClick()  
    },
    methods: {
        initDic(){
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                // console.log('initDic',res)     
                if (res != null) {
                    this.countrylang = res;
                }
            });
        },
        // 查询
		searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        // 点击页数查询
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
         // 点击条数查询
		handleSizeChange(val) {
        	// console.log(`每页 ${val} 条`);
            this.searchData.limit = val;
            this.loadlist();
      	},
        // 查询
		loadlist() {
        
            let temp = JSON.stringify(this.searchData, (key, value) => {
                if (value !== null && value !== '' && JSON.stringify(value) !== '{}') {
                    return value;
                }
            });
            let searchParam = JSON.parse(temp);
            
			this.loading=true
            var _this = this;
            this.$store.dispatch('getHomePageCopyWriterPage', searchParam).then(res => {
                _this.pageData = res;
            }).finally(() => {
				this.loading = false;
			});
        },
        // 删除
        del(row) {
            var _this = this;
            this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteHomePageCopyWriter', {homePageCopyWriterId: row.homePageCopyWriterId}).then(res => { 
                    let totalPage = Math.ceil((this.searchData.total - 1) / this.searchData.limit)
                    let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                    this.searchData.page = currentPage < 1 ? 1 : currentPage
                    this.$message.success(this.$i18n.t("common.Successfullydeleted"));
                    this.loadlist();
                });
            }).catch(() => {
            });
        },	
        // 新增
		add() {

            this.title = this.$i18n.t("common.Add1")
            this.formItem = JSON.parse(JSON.stringify(this.defaultFormItem))

            // 因为 美国 跟英国 不同地址 把 语言换成国家
            let homePageCopyWriterLangs = [];
            this.dictionary.country.forEach(element => {
                let item = {
                    code: element.code,
                    name: element.name,
                    // lang:element.code,
                    countryCode:element.code,
                    title: '',
                    jumpUrl: '',
                    imgUrl: ''
                }
                homePageCopyWriterLangs.push(item)
            });
            this.formItem.homePageCopyWriterLangs = homePageCopyWriterLangs;

            this.isEdit = false;
            this.dialogFormVisible = true;
        },
         // 编辑
        edit(row) {
            this.title = this.$i18n.t("common.edit")
            let type='yyyy-MM-dd hh:mm:ss'
            let beginTime=this.$options.filters['formatDate'](row.startTime,type)
            let endTime=this.$options.filters['formatDate'](row.endTime,type)
            row.time = [];
            row.time.push(beginTime);
            row.time.push(endTime);
			this.formItem=JSON.parse(JSON.stringify(row))

            this.formItem.homePageCopyWriterCountrys = [];

            this.formItem.homePageCopyWriterCountryDtos.forEach(element => {
            
                this.formItem.homePageCopyWriterCountrys.push(element.countryCode)
            });


            // 因为 美国 跟英国 不同地址 把 语言换成国家
            let homePageCopyWriterLangDtosMap =  new Map();
            this.formItem.homePageCopyWriterLangDtos.forEach((item)=>{
                homePageCopyWriterLangDtosMap.set(item.countryCode,item)
            })


            let homePageCopyWriterLangs = [];
            this.dictionary.country.forEach(element => {
                let item = homePageCopyWriterLangDtosMap.get(element.code);

                if(item){
                    // 存在
                    item.code = element.code;
                    item.name = element.name;
                    homePageCopyWriterLangs.push(item)
                }else{
                    let item = {
                        code: element.code,
                        name: element.name,
                        // lang:element.code,
                        countryCode:element.code,
                        title: '',
                        jumpUrl: '',
                        imgUrl: ''
                    }
                    homePageCopyWriterLangs.push(item)
                }
            });
            this.formItem.homePageCopyWriterLangs = homePageCopyWriterLangs;

            
            this.isEdit = true;
            this.dialogFormVisible = true;
        },
        // 关闭 新增，编辑页面
        closeDialog(status,isEdit) {
            this.dialogFormVisible = false; 
            if(status){
                if(isEdit){
                    this.loadlist();
                }else{
                    this.searchClick();
                }   
            }
      	},

		/**
        * 时间戳转码
        */
	    formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

 
</style>

