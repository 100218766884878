var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.name"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: { placeholder: _vm.$t("common.name") },
                model: {
                  value: _vm.formListQuery.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "title", $$v)
                  },
                  expression: "formListQuery.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.position"),
                prop: "bannerPosition",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("dataSelect", {
                attrs: { codeType: "banner_position", clearable: "" },
                on: { change: _vm.changeBannerPosition },
                model: {
                  value: _vm.formListQuery.bannerPosition,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "bannerPosition", $$v)
                  },
                  expression: "formListQuery.bannerPosition"
                }
              })
            ],
            1
          ),
          _vm.formListQuery.bannerPosition == "HomePage"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("common.ApplicableUserType"),
                    prop: "bannerUserTypeList",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formListQuery.bannerUserTypeList,
                        callback: function($$v) {
                          _vm.$set(_vm.formListQuery, "bannerUserTypeList", $$v)
                        },
                        expression: "formListQuery.bannerUserTypeList"
                      }
                    },
                    _vm._l(_vm.dictionary.userType, function(item, index) {
                      return _c(
                        "el-checkbox",
                        { key: item.code, attrs: { label: item.code } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.type"),
                prop: "bannerType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeCannerType },
                  model: {
                    value: _vm.formListQuery.bannerType,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "bannerType", $$v)
                    },
                    expression: "formListQuery.bannerType"
                  }
                },
                _vm._l(_vm.dictionary.type, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.value } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.name) +
                          "\n                    "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "bannerCountriesList",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.SelectAll")) + "\n\t\t\t\t")]
              ),
              _c("div", { staticStyle: { margin: "15px 0" } }),
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCheckedCitiesChange },
                  model: {
                    value: _vm.formListQuery.bannerCountriesList,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "bannerCountriesList", $$v)
                    },
                    expression: "formListQuery.bannerCountriesList"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.name) +
                          "\n                    "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Displaytime"),
                prop: "time",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                key: _vm.itemKey,
                attrs: {
                  clearable: "",
                  type: "datetimerange",
                  "range-separator": _vm.$i18n.t("common.to"),
                  "start-placeholder": _vm.$i18n.t("common.Startdate"),
                  "end-placeholder": _vm.$i18n.t("common.Enddate")
                },
                on: { input: _vm.clearValue },
                model: {
                  value: _vm.formListQuery.time,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "time", $$v)
                  },
                  expression: "formListQuery.time"
                }
              })
            ],
            1
          ),
          _vm.formListQuery.bannerPosition != "SubPopNotification"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.JumpType"),
                    prop: "jump",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeCannerType },
                      model: {
                        value: _vm.formListQuery.bannerJumpType,
                        callback: function($$v) {
                          _vm.$set(_vm.formListQuery, "bannerJumpType", $$v)
                        },
                        expression: "formListQuery.bannerJumpType"
                      }
                    },
                    _vm._l(_vm.bannerJumpTypeList, function(item, index) {
                      return _c(
                        "el-radio",
                        { key: item.code, attrs: { label: item.code } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.formListQuery.bannerPosition == "SubPopNotification"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.PopupDelayTime"),
                    prop: "popDelayTime",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input-number", {
                    staticClass: "input-width",
                    staticStyle: { width: "200px" },
                    attrs: { min: 0, max: 3600 },
                    model: {
                      value: _vm.formListQuery.popDelayTime,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "popDelayTime", $$v)
                      },
                      expression: "formListQuery.popDelayTime"
                    }
                  }),
                  _vm._v(" 秒\n            ")
                ],
                1
              )
            : _vm._e(),
          _vm.formListQuery.bannerJumpType === "Goods"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.ProductID1"),
                    prop: "associationId",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: {
                      oninput: "value=value.replace(/[^0-9]/g,'')",
                      maxlength: "20",
                      placeholder: _vm.$i18n.t("common.ProductID1")
                    },
                    model: {
                      value: _vm.formListQuery.associationId,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "associationId", $$v)
                      },
                      expression: "formListQuery.associationId"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formListQuery.bannerJumpType === "Activity"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("common.ActivityID"),
                    prop: "associationId",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: {
                      oninput: "value=value.replace(/[^0-9]/g,'')",
                      maxlength: "20",
                      placeholder: _vm.$t("common.ActivityID")
                    },
                    model: {
                      value: _vm.formListQuery.associationId,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "associationId", $$v)
                      },
                      expression: "formListQuery.associationId"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formListQuery.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "status", $$v)
                  },
                  expression: "formListQuery.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.sort"),
                prop: "sort",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  oninput: "value=value.replace(/[^0-9]/g,'')",
                  placeholder: _vm.$i18n.t("common.Topwithlargenumbers")
                },
                on: { blur: _vm.salaryChange },
                model: {
                  value: _vm.formListQuery.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "sort", $$v)
                  },
                  expression: "formListQuery.sort"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.formListQuery.bannerFiles, function(item, index) {
          return _c(
            "el-tab-pane",
            {
              key: item.countryCode,
              attrs: { label: item.name, name: item.countryCode }
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.formListQuery.bannerType === "Video" ||
                          _vm.formListQuery.bannerPosition ===
                            "SubPopNotification"
                            ? "uploadTitleChoice"
                            : "uploadTitle"
                        ]
                      },
                      [
                        _vm._v(
                          "\n                           " +
                            _vm._s(_vm.$i18n.t("common.picture")) +
                            "\n                        "
                        )
                      ]
                    ),
                    _c("image-upload", {
                      attrs: {
                        formListQuery: _vm.formListQuery,
                        index: index,
                        countryCode: _vm.countryCode
                      },
                      on: { handleFormData: _vm.handleFormData }
                    })
                  ],
                  1
                ),
                _vm.formListQuery.bannerPosition != "HomeRecommended" &&
                _vm.formListQuery.bannerType == "Video"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start"
                        }
                      },
                      [
                        _c("div", { staticClass: "uploadTitle" }, [
                          _vm._v(
                            "\n                           " +
                              _vm._s(_vm.$i18n.t("common.video")) +
                              "\n                        "
                          )
                        ]),
                        _c("video-upload", {
                          attrs: {
                            formListQuery: _vm.formListQuery,
                            index: index,
                            countryCode: _vm.countryCode
                          },
                          on: { handleFormData: _vm.handleFormData }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formListQuery.bannerJumpType === "Link",
                        expression: 'formListQuery.bannerJumpType==="Link"'
                      }
                    ],
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start",
                      "margin-top": "20px"
                    }
                  },
                  [
                    _c("div", { staticClass: "uploadTitle" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$i18n.t("common.JumpLink")) +
                          "\n                        "
                      )
                    ]),
                    _c("el-input", {
                      staticClass: "input-width",
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Enterjumplink")
                      },
                      model: {
                        value: item.jumpUrl,
                        callback: function($$v) {
                          _vm.$set(item, "jumpUrl", $$v)
                        },
                        expression: "item.jumpUrl"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "el-form",
                { attrs: { model: _vm.formListQuery, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "20%", display: "none" } },
                    [
                      _c(
                        "div",
                        { staticClass: "dialog-footer" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit1("variantFrom")
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$i18n.t("common.confirm")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }