var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: true, model: _vm.searchData } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      oninput: "value=value.replace(/[^0-9]/g,'')",
                      clearable: "",
                      maxlength: "25",
                      placeholder: _vm.$i18n.t("common.Ordernumber")
                    },
                    model: {
                      value: _vm.searchData.orderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "orderNo", $$v)
                      },
                      expression: "searchData.orderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      oninput: "value=value.replace(/[^0-9]/g,'')",
                      clearable: "",
                      placeholder: _vm.$i18n.t("common.UserID")
                    },
                    model: {
                      value: _vm.searchData.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "userId", $$v)
                      },
                      expression: "searchData.userId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      multiple: false,
                      clearable: "",
                      codeType: "post_sale_type",
                      placeholder: _vm.$i18n.t("common.type")
                    },
                    model: {
                      value: _vm.searchData.postSaleType,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "postSaleType", $$v)
                      },
                      expression: "searchData.postSaleType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      multiple: true,
                      codeType: "countrys",
                      placeholder: _vm.$i18n.t("common.country")
                    },
                    model: {
                      value: _vm.searchData.countryCodes,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "countryCodes", $$v)
                      },
                      expression: "searchData.countryCodes"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t("common.Pleaseselectasource")
                      },
                      model: {
                        value: _vm.searchData.orderSource,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "orderSource", $$v)
                        },
                        expression: "searchData.orderSource"
                      }
                    },
                    _vm._l(_vm.distributionlist, function(item) {
                      return _c("el-option", {
                        key: item.distributionId,
                        attrs: { label: item.title, value: item.title }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      multiple: false,
                      clearable: "",
                      codeType: "order_status",
                      placeholder: _vm.$i18n.t("common.OrderStatus")
                    },
                    model: {
                      value: _vm.searchData.orderStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "orderStatus", $$v)
                      },
                      expression: "searchData.orderStatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      multiple: false,
                      clearable: "",
                      codeType: "audit_status",
                      placeholder: _vm.$i18n.t("common.Auditstatus")
                    },
                    model: {
                      value: _vm.searchData.auditStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "auditStatus", $$v)
                      },
                      expression: "searchData.auditStatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Processingstatus"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchData.finish,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "finish", $$v)
                        },
                        expression: "searchData.finish"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Processingstatus"),
                          value: "true"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Inprogress"),
                          value: "false"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        width: "auto",
                        placeholder: _vm.$i18n.t(
                          "common.Pleasechoosewhethertocontinueapplying"
                        ),
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchData.disableOrder,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "disableOrder", $$v)
                        },
                        expression: "searchData.disableOrder"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.yes"),
                          value: "false"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.no"),
                          value: "true"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      type: "datetimerange",
                      "range-separator": _vm.$i18n.t("common.to"),
                      "start-placeholder": _vm.$i18n.t(
                        "common.Applicationtime"
                      ),
                      "end-placeholder": _vm.$i18n.t("common.Applicationtime")
                    },
                    model: {
                      value: _vm.valuePicker,
                      callback: function($$v) {
                        _vm.valuePicker = $$v
                      },
                      expression: "valuePicker"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.searchClick }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$i18n.t("common.query")) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$i18n.t("common.Reset")) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "20px 0px 20px 20px" } }),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            border: "",
            height: "calc(100% - 170px)"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "postSaleType",
              label: _vm.$i18n.t("common.Aftersalestype"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "post_sale_type" },
                      model: {
                        value: scope.row.postSaleType,
                        callback: function($$v) {
                          _vm.$set(scope.row, "postSaleType", $$v)
                        },
                        expression: "scope.row.postSaleType"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderFullInfoDto.orderNo",
              label: _vm.$i18n.t("common.Ordernumber"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderFullInfoDto != null
                      ? _c("dataText", {
                          attrs: { codeType: "countrys" },
                          model: {
                            value: scope.row.orderFullInfoDto.countryCode,
                            callback: function($$v) {
                              _vm.$set(
                                scope.row.orderFullInfoDto,
                                "countryCode",
                                $$v
                              )
                            },
                            expression: "scope.row.orderFullInfoDto.countryCode"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderFullInfoDto.userId",
              label: _vm.$i18n.t("common.UserID"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderFullInfoDto",
              label: _vm.$i18n.t("common.Productquantity"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderFullInfoDto != null
                      ? _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                scope.row.orderFullInfoDto &&
                                  scope.row.orderFullInfoDto.orderItemList
                                  ? scope.row.orderFullInfoDto.orderItemList
                                      .length
                                  : 0
                              )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderFullInfoDto.source",
              label: _vm.$i18n.t("common.source"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderFullInfoDto.totalAmt",
              label: _vm.$i18n.t("common.money"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderFullInfoDto != null
                      ? _c(
                          "dataText",
                          {
                            attrs: { codeType: "currency_code" },
                            model: {
                              value: scope.row.orderFullInfoDto.currencyCode,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.orderFullInfoDto,
                                  "currencyCode",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.orderFullInfoDto.currencyCode"
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(scope.row.orderFullInfoDto.totalAmt) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderFullInfoDto.orderStatus",
              label: _vm.$i18n.t("common.OrderStatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderFullInfoDto != null
                      ? _c("dataText", {
                          attrs: { codeType: "order_status" },
                          model: {
                            value: scope.row.orderFullInfoDto.orderStatus,
                            callback: function($$v) {
                              _vm.$set(
                                scope.row.orderFullInfoDto,
                                "orderStatus",
                                $$v
                              )
                            },
                            expression: "scope.row.orderFullInfoDto.orderStatus"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "description",
              label: _vm.$i18n.t("common.explain"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "imgUrls",
              label: _vm.$i18n.t("common.Numberofexplanatoryimages"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(row.imgUrls ? row.imgUrls.length : 0) +
                        "\n                "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "imgUrls",
              label: _vm.$i18n.t("common.Illustrativeimage"),
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.imgUrls && row.imgUrls.length > 0
                      ? _c("listImage", {
                          attrs: {
                            imgUrl: row.imgUrls[0],
                            imgUrls: row.imgUrls
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$i18n.t("common.Applicationtime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "logisticsCode",
              label: _vm.$i18n.t("common.Returnlogisticstrackingcode"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "auditStatus",
              label: _vm.$i18n.t("common.Auditstatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("dataText", {
                      attrs: { codeType: "audit_status" },
                      model: {
                        value: row.auditStatus,
                        callback: function($$v) {
                          _vm.$set(row, "auditStatus", $$v)
                        },
                        expression: "row.auditStatus"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "finish",
              label: _vm.$i18n.t("common.Processingstatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          row.finish
                            ? _vm.$i18n.t("common.Completed")
                            : _vm.$i18n.t("common.Inprogress")
                        ) +
                        "\n                "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.CanIcontinuetoapply"),
              prop: "disableOrder",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          row.disableOrder
                            ? _vm.$i18n.t("common.no")
                            : _vm.$i18n.t("common.yes")
                        ) +
                        "\n                "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.auditStatus === "NOT_APPROVED"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                _vm.edit(
                                  scope.row,
                                  _vm.$i18n.t("common.Auditing")
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.operation")) +
                                "\n                    "
                            )
                          ]
                        )
                      : scope.row.finish
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                _vm.edit(
                                  scope.row,
                                  _vm.$i18n.t("common.details")
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$i18n.t("common.details")) + " "
                            )
                          ]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                _vm.edit(
                                  scope.row,
                                  _vm.$i18n.t("common.handle")
                                )
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.handle")))]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                width: "70%",
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("varoant-dialog", {
                attrs: { formItem: _vm.formItem, isEdit: _vm.isEdit },
                on: { closeDialog: _vm.closeWindow }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }