<template>
	<div style="height: 100%;min-height:350px;">
		<filter-container :arg="arg" :defaultListQuery="defaultListQuery" :placeholder="placeholder" :dictionary="dictionary" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch'></filter-container>
		<div style="margin: 10px 0px 10px 20px;">

			<el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)">{{ $i18n.t("common.Add1")  }}</el-button>

		</div>
		<el-table ref="pagedata" height="calc(100% - 215px)" :data="pageData.list" style="width: 100%" border :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
			<el-table-column prop="bannerId" :label='$i18n.t("common.number")' align="center" show-overflow-tooltip></el-table-column>	
			<el-table-column prop="bannerPosition" :label='$i18n.t("common.position")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
<!--					<span v-if="scope.row.bannerPosition=='HomeRecommended'">首页推荐位置</span>-->
<!--					<span v-else-if="scope.row.bannerPosition=='HomePage'">首页banner</span>-->
          <dataText class='filter-item' v-model='scope.row.bannerPosition' clearable :codeType="'banner_position'"
          ></dataText>
				</template>
			</el-table-column>

			<el-table-column prop="bannerType1" :label='$i18n.t("common.type") '  align="center" show-overflow-tooltip></el-table-column>

			
			<el-table-column prop="bannerUserTypes" :label='$t("common.ApplicableUserType")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
			 
					<span v-for="item in scope.row.bannerUserTypes" :key="item.bannerUserTypeId">
						{{ $dict.getDictValue('user_type', item.userType) }}<br/>
                    </span>
				</template>  	 
			</el-table-column>

			<!--
			<el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip></el-table-column>
			-->
			<el-table-column prop="bannerCountries" :label='$t("common.country")' align="center" show-overflow-tooltip>

				<template slot-scope="scope">
					<span v-for="item in scope.row.bannerCountries" :key="item.bannerCountryId">
                    <dataText v-model='item.countryCode' :codeType="'countrys'"></dataText></span>
				</template>
			</el-table-column>
			<el-table-column prop="title"  :label='$i18n.t("common.name")'  align="center" show-overflow-tooltip></el-table-column>		
			<el-table-column prop="bannerFiles" :label='$i18n.t("common.Pictures_Videos")' align="center" width="130px">
				<template slot-scope="scope">
<!--					<el-image style="width: 100px; height: 100px" v-if="scope.row.imgUrl1 && scope.row.imgUrl1.imgUrl"-->
<!--						:src="scope.row.imgUrl1.imgUrl" fit="fill"></el-image>-->
					<listImage v-if="scope.row.imgUrl1" :imgUrl="scope.row.imgUrl1.imgUrl"></listImage>
					<video class='float-l' style="width: 100px; height: 100px" v-else-if="scope.row.videoUrl1 && scope.row.videoUrl1.videoUrl"
					 :src='scope.row.videoUrl1.videoUrl'
						controls='controls'>
						{{ $t("common.YourBrowserDoesNotSupportVideoPlayback") }}
					</video>	
				</template>
			</el-table-column>
		 <el-table-column prop="jump" :label='$i18n.t("common.JumpType")'  align="center" show-overflow-tooltip>
				<template slot-scope="scope">

                    <dataText v-model='scope.row.bannerJumpType' :codeType="'banner_jump_type'"></dataText>
				</template>
			</el-table-column>
			<el-table-column prop="targetUrl" :label='$i18n.t("common.JumpAddress")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.jumpUrl1 && scope.row.jumpUrl1.jumpUrl">{{scope.row.jumpUrl1.jumpUrl}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="status1" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
          <dataText class='filter-item' v-model='scope.row.status1' :color='scope.row.statusColor' clearable :codeType="'activity_status'"
                    ></dataText
          >
				</template>
			</el-table-column>
		
			<el-table-column prop="title" :label='$i18n.t("common.Enableornot")' align="center">
				<template slot-scope="scope">
					<el-switch
					  v-model="scope.row.status"
					  active-color="#13ce66"
					  disabled>
					</el-switch>
				</template>			
			</el-table-column>
			<el-table-column prop="beginTime" :label='$i18n.t("common.starttime")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.beginTime | formatDate}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="endTime" :label='$i18n.t("common.Endtime")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.endTime | formatDate}}</span>
				</template>
			</el-table-column>
			
            <el-table-column prop="sort" :render-header="renderSortHeader" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-input v-model="scope.row.sort" oninput="value=value.replace(/[^0-9.]/g,'')" @keyup.enter.native="putSortBanner(scope.row)" @blur="putSortBanner(scope.row)" :placeholder='$i18n.t("common.Pleaseentercontent")'></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="opByName"  :label='$i18n.t("common.Operator")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="opTime" :label='$i18n.t("common.Updatetime")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.opTime | formatDate}}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
				<template slot-scope="scope">
					<el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update',module_code)">{{ $i18n.t("common.edit") }}</el-button>
					<el-button @click="handleDelete(scope.row)" type="text" size="small" v-if="$common.hasPermission('Delete',module_code)">{{ $i18n.t("common.delete") }}</el-button>
				</template>
			</el-table-column> 
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
				:page-size="listQuery.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
			</el-pagination>
		</div>
		<el-dialog :title="isEdit?$t('common.EditBanner'):$t('common.AddBanner')" :visible.sync="dialogFormVisible" :before-close="closeDialog"  :close-on-click-modal="false">
			<div v-if="dialogFormVisible">
				<varoant-dialog :isEdit='isEdit' :formListQuery='formListQuery' :bannerId='bannerId' :dictionary='dictionary' @closeDialog='closeDialog' @handleSearchList="handleSearchList"/>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {enumeration} from '@/utils/common2';
	import {formatDate} from '@/utils/date';
	import config from '@/utils/config';
	import FilterContainer from '@/components/common/FilterContainer.vue';
	import WangEditer from "@/components/common/WangEditer/index.vue"; 
	import VaroantDialog from './components/VaroantDialog.vue';
    import dataText from "@/components/common/DataText";
    import i18n from '../../i18n/i18n.js'
    import moment from 'moment';
	import listImage from '@/components/common/ListImage.vue';
    import dictionaryUtil from '@/utils/dictionaryUtil';
	const defaultFormListQuery={
		"title": '',
		"targetUrl": '',	
		"beginTime": '',
		"endTime": "",
		"sort": 0,		
		"brandCode": '',
		"bannerJumpType": "Link",
		"time":[],
		"status": true,
		"bannerType": "Picture",
		"bannerPosition": 'HomePage',
		"goodsClassId": '',
		"bannerCountries": [],
		"bannerCountriesList": [],
		"bannerFiles": [],
		"bannerUserTypes": [],
		"bannerUserTypeList": []
	};
	const defaultDictionary={
		//类型
		type:[{
			id:'1',
			value:'Picture',
			name:i18n.t("common.picture")
		},{
			id:'2',
			value:'Video',
			name:i18n.t("common.video")
		},{
            id:'3',
            value:'Notification',
			name:i18n.t("common.popupnotification")
        }],
		// 国家
		country:dictionaryUtil.getDict('countrys'),
		bannerPosition:dictionaryUtil.getDict('banner_position'),
		brandCode:dictionaryUtil.getDict('brand'),
		languageList:dictionaryUtil.getDict('countrys'),
        bannerJumpTypeList:dictionaryUtil.getDict('banner_jump_type'),
		jump:dictionaryUtil.getDict('banner_jump_type').map((item,index)=>{
				item.id=index
				item.value=item.code
				return item
		}),
		state:[{
			id:'1',
			name:i18n.t("common.Inprogress"),
			value:'1'
		},{
			id:'2',
			name:i18n.t("common.begininaminute"),
			value:'2'
		},{
			id:'3',
			name:i18n.t("common.Ended"),
			value:'3'
		}],
		userType:dictionaryUtil.getDict('user_type'),
		status:[{
			id:'1',
			name:i18n.t("common.yes"),
			value:true
		},{
			id:'2',
			name:i18n.t("common.no"),
			value:false
		}]
	};
	export default {
		components: {
			FilterContainer,
			VaroantDialog,
			WangEditer,
      		dataText,
			moment,
			listImage
		},
		name: 'variant',
		data() {
			return {
				module_code:config.module_code.bannerAdmin,
				statusColor:'',
				listQuery:{
				  "beginTime": '',
				  "endTime": "",
				//   "brandCode": '',
				  "jump": "",
				  "status": "",
				  "countryCodes":[],
				  "bannerType": "",
				  "bannerPosition": '',
				  "goodsClassId": '',
				  "userType": '',
				  "page": 1,
				  "limit": 10,
					"desc":true,
				},
				defaultListQuery:{
				  "beginTime": '',
				  "endTime": "",
				//   "brandCode": '',
				  "jump": "",
				  "status": "",
				  "countryCodes":[],
				  "bannerType": "",
				  "bannerPosition": '',
				  "goodsClassId": '',
				  "userType": '',
				  "page": 1,
				  "limit": 10,
				  "desc":true,
				},
				bannerId:'',
				formListQuery:Object.assign({}, defaultFormListQuery),
				//组件标签是否显示
				arg:{
					name:true,
					// brand:true,
					jumpType:true,
					type:true,
					userType:true,
					country:true,
					position:true,
					state:true,
					enable:true
				},
				placeholder:{
					date:{
						start:this.$i18n.t("common.Updatetime"),
						end:this.$i18n.t("common.Updatetime"),
					},
					state:this.$i18n.t("common.state"),
					name:this.$i18n.t("common.name"),
				},
				//编辑or新增
				isEdit: false,
				pageData: {
					list:[]
				},
				ossSignature: {
					host: ''
				},
				dialogFormVisible:false,
				fullscreenLoading: false,
				dictionary:Object.assign({}, defaultDictionary),
				goodsHomeRecomandRequest: {
					beginTime: '',
					brandCode: '',
					countryCode: '',
					enabled: false,
					endTime: '',
					goodsId: '',
					imageUrl: '',
					modularCode: '',
					platformCode: '',
					sort: '',
					goodsName: '',
					goodsBarcode: ''
				}, //查询项
				searchData: {
					title: '',
					enabled: null,
					brand: null,
					country: null,
					platform: null,
					classSetId: null,
					page: 1,
					limit: 10,
				},
				goodsBarcode: '',
				ossHost: process.env.VUE_APP_OSS_Head,
				brandDicts: [],
			};
		},
		mounted() {
			this.handleSearchList();
		}, 
		filters:{
		  formatDate(time,type='yyyy-MM-dd hh:mm:ss'){
		    if(time==null||time===''){
		      return 'N/A';
		    }
		    let date = new Date(time);
		    return formatDate(date,type)
		  },
		  enumeration(val){
			  return enumeration(val)
		  }
		},
		methods: {
			//搜索列表
			handleSearchList(listQuery){
				let type='yyyy-MM-dd hh:mm:ss'
				let beginTime=moment().unix()*1000;
				let endTime=moment().unix()*1000;
				let nowTime= moment(this.formListQuery.time[0]).unix()*1000;
                let nowTime2=new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
				if(listQuery){
					this.listQuery.page=1
					console.log('listQuery',listQuery)
					let jump=''
					let status=''
					if(listQuery.jumpType===true){
						jump="true"
					}else if(listQuery.jumpType===false){
						jump="false"
					}
					if(listQuery.enable===true){
						status="true"
					}else if(listQuery.enable===false){
						status="false"
					}
					this.listQuery={
						"beginTime": '',
						"endTime": "",
						// "brandCode": listQuery.brand,
						"bannerJumpType": listQuery.jumpType,
						"status": status,
						"countryCodes":listQuery.country,
						"bannerType": listQuery.type,
						"bannerPosition": listQuery.position,
						"title": listQuery.title,
						"userType": listQuery.userType,
						"page": this.listQuery.page,
						"limit": this.listQuery.limit,
						"desc":true,
					}				
					if(listQuery.state=='1'){
						 this.listQuery.beginTime=moment(beginTime).unix() * 1000;
						 this.listQuery.endTime=moment(endTime).unix() * 1000;
					}else if(listQuery.state=='2'){
						this.listQuery.beginTime=moment(endTime).unix() * 1000;
						this.listQuery.endTime=null;
					}else if(listQuery.state=='3'){
						this.listQuery.beginTime=null;
						this.listQuery.endTime=moment(beginTime).unix() * 1000;
					}
				}				
				this.$store
					.dispatch('getBanner', this.listQuery)
					.then(res => {
						if(res && res.list && res.list.length>0){					
							res.list.forEach((item,index)=>{
								//状态	
								item.imgUrl1=item.bannerFiles.find((item)=>{
									if(item.imgUrl){
										return item.imgUrl
									}			
								})
								item.videoUrl1=item.bannerFiles.find((item)=>{
									if(item.videoUrl){
										return item.videoUrl
									}
								})
								item.jumpUrl1=item.bannerFiles.find((item)=>{
									if(item.jumpUrl){
										return item.jumpUrl
									}
								})
								if(item.beginTime>nowTime){
									item.status1='NotStarted'
									item.statusColor='red'
								}else if(item.endTime<nowTime){
									item.status1='Complete'
									item.statusColor='black'
								}else if(item.endTime>=nowTime && item.beginTime<=nowTime){
									item.status1='UnderWay'
									item.statusColor='green'
								}
								//位置
								if(item.bannerPosition==='HOMEPAGE'){
									item.position=this.$i18n.t("common.Homebanner")
								}else if(item.bannerPosition==='HOMERECOMMENDED'){
									item.position=this.$i18n.t("common.HomepageRecommendationPosition")						
								}
								//bannerType
								if(item.bannerType==='Picture'){
									item.bannerType1=this.$i18n.t("common.picture")
								}else if(item.bannerType==='Video'){
									item.bannerType1=this.$i18n.t("common.video")				
								}else if(item.bannerType==='Notification'){
									item.bannerType1=this.$i18n.t("common.popupnotification")
								}
							})

						this.pageData=res

						}else{
							this.pageData=[]
						}
					});
			},
			handleResetSearch(){
				this.listQuery = Object.assign({}, this.defaultListQuery);
				this.handleSearchList()
			},	
			//自定义label
			renderSortHeader () {
				let sort = this.$i18n.t("common.sort")	
			      return (
			        <div>
			             <span class='sort-span' on-click={()=>this.handleSort()} on-blur={()=>this.handleSort()}>sort <span class='el-icon-sort'></span></span>	
			        </div>
			      )
			},
			//排序
			handleSort(){
				this.listQuery.desc=!this.listQuery.desc
				setTimeout(()=>{
					this.handleSearchList();
				})			
			},
			//打开新增
			add(){
				this.isEdit=false
				this.dialogFormVisible=true
			},
			//打开编辑
			edit(row){
				console.log('erow',row)
				this.isEdit=true
				this.bannerId=row.bannerId
				// this.formListQuery= Object.assign({},row)	  
				this.formListQuery= JSON.parse(JSON.stringify(row));
				this.formListQuery.time=[]
				if(this.formListQuery.beginTime && this.formListQuery.endTime){
					let type='yyyy-MM-dd hh:mm:ss'
					let beginTime=this.$options.filters['formatDate'](this.formListQuery.beginTime,type)
					let endTime=this.$options.filters['formatDate'](this.formListQuery.endTime,type)
					this.formListQuery.time.push(beginTime)
					this.formListQuery.time.push(endTime)
					console.log('this.formListQuery.time',this.formListQuery.time)
				}
				let arr=[]
				if(row.bannerCountries && row.bannerCountries.length>0){
					row.bannerCountries.forEach((item)=>{
						arr.push(item.countryCode)
					})		
				}
				this.$set(this.formListQuery,"bannerCountriesList",arr)

				let arrUserType=[]
				if(row.bannerUserTypes && row.bannerUserTypes.length>0){
					row.bannerUserTypes.forEach((item)=>{
						arrUserType.push(item.userType)
					})		
				}
				this.$set(this.formListQuery,"bannerUserTypeList",arrUserType)
				// console.log('formListQuery',this.formListQuery)
				this.dialogFormVisible=true
			},
			//删除
			handleDelete(row){
				this.$confirm( this.$i18n.t("common.doyouwanttodelete2")+'?', this.$i18n.t("common.prompt"), {
				//   confirmButtonText: '确定',
				//   cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					this.$store
					  .dispatch("deleteBanner", {
						  id:row.bannerId,
					  })
					  .then((res) => {
					    if (res != null) {
							let totalPage = Math.ceil((this.pageData.total - 1) / this.listQuery.limit)
							let currentPage =this.listQuery.page > totalPage ? totalPage : this.listQuery.page
							this.listQuery.page = currentPage < 1 ? 1 : currentPage
							this.handleSearchList();
					    	this.$message({
					    	  type: 'success',
					    	  message: this.$i18n.t("common.doyouwanttodelete2")
					    	});
					    }
					  });
				});
			},
			//关闭窗口
			closeDialog(){
				this.dialogFormVisible=false
				this.formListQuery=Object.assign({}, defaultFormListQuery)
				this.dictionary=Object.assign({}, defaultDictionary)
			},
			putSortBanner(row){
				const str = '^(\\d+)\\.(\\d{0}).*$'
				const reg = new RegExp(str)
				row.sort=row.sort.replace(/[^\d.]/g, "")
				row.sort=row.sort.replace(reg, '$1');
				this.$store
				  .dispatch("putSortBanner", {
					  id:row.bannerId,
					  sort:row.sort?row.sort:0
				  })
				  .then((res) => {
					  if(res){
						  this.handleSearchList()
					  }				    
				  });		
			},
			handleCurrentChange(val) {
				this.listQuery.page = val;
				this.handleSearchList();
			},
			deletegoodsHomeRecomand(row) {
				this.$confirm(this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
						// confirmButtonText: '确定',
						// cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$store.dispatch('deleteGoodsHomeRecomand', {
							recomandId: row.recomandId
						}).then(res => {
							this.handleSearchList();
						});
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: this.$i18n.t("common.Cancelleddeletion")
						});
					});
			}
		},
		updated() {}
	};

</script>
<style scoped>
	.sort-span{
	    color:#000000;
	}
</style>
