// 转整数失败则返回默认值
export function toIntOrDefault(input, defaultValue = 0) {
    const number = parseInt(input);
    return isNaN(number) ? defaultValue : number;
}

// 转浮点数失败则返回默认值
export function toFloatOrDefault(input, defaultValue = 0) {
    const number = parseFloat(input);
    return isNaN(number) ? defaultValue : number;
}