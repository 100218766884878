<template>
    <div style="height: 100%;min-height:350px;">
	 
		<div class="filter-container" shadow="never">
            <el-form :inline="true">
                <el-form-item>
					<el-input v-model="searchData.idFuzzy" placeholder="SKU/EAN" style="width: 180px;"
                            class="filter-item" clearable ></el-input>
                </el-form-item>
                <el-form-item>
					<el-input v-model="searchData.palletName" :placeholder='$i18n.t("common.Combinationname")' style="width: 180px;"
                            class="filter-item" clearable   ></el-input>
                </el-form-item>
                <el-form-item>
					<el-select v-model="searchData.countryCode"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder='$i18n.t("common.country")'>
						<el-option
							v-for="item in dictionary.country"
							:key="item.id"
							:label="item.name"
							:value="item.code">
						</el-option>      
					</el-select>
                </el-form-item>
                
                
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick"
                               style="margin-left: 10px">
                               {{ $i18n.t("common.query") }}
                    </el-button>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery"
                               style="margin-left: 10px" size="small">
                               {{ $i18n.t("common.Reset") }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
		
	
		<div style="margin: 0px 0px 10px 20px;">

			<el-button @click="add()" type="success" size="small"> {{ $i18n.t("common.Add1") }}</el-button>

		</div>

 
        <el-table ref="pagedata" height="calc(100% - 150px)" :data="pageData.list" border v-loading="loading"  style="width: 100%">

            <el-table-column prop="palletId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="palletName" :label=' $i18n.t("common.Combinationname")' align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="countryCode"  :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $dict.getDictValue('countrys',  scope.row.countryCode)  }}
                </template>
            </el-table-column>
			<el-table-column prop="palletStatus1" :label='$i18n.t("common.state")'  align="center" show-overflow-tooltip>
                
            </el-table-column>

			<el-table-column prop="endTime" :label='$i18n.t("common.Remainingquantity_combinedquantity")'  align="center" show-overflow-tooltip>
			<template slot-scope="scope">
				{{  scope.row.palletQuantity-scope.row.salesCount }}/{{ scope.row.palletQuantity }} 
			</template>
			</el-table-column>


 
			<el-table-column prop="createTime"  :label='$i18n.t("common.Creationtime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column>
			<el-table-column prop="startTime"  :label='$i18n.t("common.starttime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column>
			<el-table-column prop="endTime"  :label='$i18n.t("common.Endtime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column> 
			
			<el-table-column prop="createName"  :label='$i18n.t("common.Creator")' align="center" show-overflow-tooltip></el-table-column>
 
			<el-table-column :label='$i18n.t("common.operation")'  align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small">{{$i18n.t("common.edit")}}</el-button>
                    <el-button @click="editTranslate(scope.row)" type="text" size="small">{{$i18n.t("common.Multilingualconfiguration")}}</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small">{{$i18n.t("common.delete")}}</el-button>
                </template>
            </el-table-column>

 
        </el-table>
        <div class="block">
        	<el-pagination @current-change="handleCurrentChange" :current-page="searchData.page"  
				:page-sizes="[10, 20, 30, 50]"   @size-change="handleSizeChange"
        		:page-size="searchData.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageData.total">
        	</el-pagination>
        </div>
		 
        <el-dialog :title="title" :visible.sync="dialogFormVisible" v-if="dialogFormVisible"
                   :close-on-click-modal="false">
            <add-update-dialog  @closeDialog="closeDialog" :formItem="formItem" :isEdit="isEdit" :dictionary="dictionary" />
        </el-dialog>


        <el-dialog :title='$i18n.t("common.Multilingualconfiguration")' :visible.sync="dialogFormTranslateVisible" v-if="dialogFormTranslateVisible"
                   :close-on-click-modal="false">
            <pallet-translate-dialog  @closeDialogTranslate="closeDialogTranslate" :formItem="formItem" :dictionary="dictionary" />
        </el-dialog>

		 
    </div>
</template>
<script>
 

import addUpdateDialog from './components/AddUpdateDialog.vue';
import PalletTranslateDialog from './components/PalletTranslateDialog.vue';
import moment from 'moment';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary={
    // 国家
    country:dictionaryUtil.getDict('countrys'),
};
export default {
	components: { moment, addUpdateDialog, PalletTranslateDialog },
    name: 'pallet_module',
    data() {
        return {	 
			loading:false,	 
			dictionary:Object.assign({}, defaultDictionary),		 
            title: '',
            dialogFormVisible: false,
            dialogFormTranslateVisible: false,
            formItem: {},
			 //查询项
			searchData: {
                idFuzzy: '',
                palletName: '',
                countryCode: '',
                page: 1,
                limit: 10
            },
			pageSizes: [10, 20, 30, 50],
            // 组合列表
			pageData: {}
        };
    }, 
    mounted() {
        this.searchClick()  
    },
    methods: {
        // 查询
		searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        // 点击页数查询
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
         // 点击条数查询
		handleSizeChange(val) {
        	console.log(`每页 ${val} 条`);
            this.searchData.limit = val;
            this.loadlist();
      	},
        // 重置
        cleanQuery() {
            this.searchData.idFuzzy = '';
            this.searchData.palletName = '';
            this.searchData.countryCode = '';
            this.searchData.page = 1;
            this.searchData.limit = 10;
            this.loadlist();
        },
        // 组合查询
		loadlist() {
			this.loading=true
            var _this = this;
            this.$store.dispatch('getPalletPage', this.searchData).then(res => {
                _this.pageData = res;
                // console.log(_this.pageData)
                _this.pageData.list.forEach(element => {
                    if(element.palletStatus == 'NotStarted'){
                        element.palletStatus1 = this.$i18n.t("common.begininaminute"); 
                    }else if(element.palletStatus=='UnderWay'){
                        element.palletStatus1 =  this.$i18n.t("common.Inprogress"); 
                    }else if(element.palletStatus=='Complete'){
                        element.palletStatus1 = this.$i18n.t("common.Ended"); 
                    }
                });
            }).finally(() => {
				this.loading = false;
			});
        },
		add() {

            this.title = this.$i18n.t("common.Add1")

            this.formItem = {
                countryCode: this.dictionary.country[0].code,
                isUseCoupon:false,            
            };
            this.isEdit = false;
            this.dialogFormVisible = true;
        },
        edit(row) {
            this.title = this.$i18n.t("common.edit")
            let type='yyyy-MM-dd hh:mm:ss'
            let beginTime=this.$options.filters['formatDate'](row.startTime,type)
            let endTime=this.$options.filters['formatDate'](row.endTime,type)
            row.time = [];
            row.time.push(beginTime);
            row.time.push(endTime);
            // 商品 列表
            console.log("edit",row)
			this.formItem=JSON.parse(JSON.stringify(row))
            this.isEdit = true;
            this.dialogFormVisible = true;
        },
        closeDialog(status,isEdit) {
            this.dialogFormVisible = false; 
            if(status){
                if(isEdit){
                    this.loadlist();
                }else{
                    this.searchClick();
                }   
            }
      	},
		del(row) {
            var _this = this;
            this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deletePallet', {palletId: row.palletId}).then(res => { 
                    let totalPage = Math.ceil((this.searchData.total - 1) / this.searchData.limit)
                    let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                    this.searchData.page = currentPage < 1 ? 1 : currentPage
                    this.$message.success(this.$i18n.t("common.Successfullydeleted"));
                    this.loadlist();
                });
            }).catch(() => {
            });
        },	
        editTranslate(row) {
            this.formItem=JSON.parse(JSON.stringify(row))
            this.dialogFormTranslateVisible = true;
        },
        closeDialogTranslate(status) {
            this.dialogFormTranslateVisible = false; 
      	},
		 /**
         * 时间戳转码
         */
		 formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

 
</style>

