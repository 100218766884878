<template>
    <div style="height: 100%;min-height:350px;">
		<filter-container :arg="arg" :placeholder="placeholder" :dictionary="dictionary" :defaultListQuery="defaultListQuery" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch'></filter-container>
		<div style="margin: 10px 0px 10px 20px;">
			<el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)">  {{ $i18n.t("common.Add1") }}</el-button>
		</div>
        <el-table
            ref="pagedata"
            :data="pageData.list"
			v-loading="loading"
			height="calc(100% - 170px)"
            border
            style="width: 100%"         
        >
            <el-table-column prop="distributionId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="title" :label='typeName+ $i18n.t("common.name")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID1")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="agbsActivityTitle" :label='$i18n.t("common.barcode")'  align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div>{{scope.row.goodsMergeListMgrDto.barCode}}</div>
				</template>
			</el-table-column>
            <el-table-column prop="bannerFiles" :label='$i18n.t("common.picture")' align="center" width="130px">
            	<template slot-scope="scope">
<!--            		<el-image style="width: 100px; height: 100px"-->
<!--            			:src="ossHost + scope.row.goodsMergeListMgrDto.imageUrl" fit="fill"></el-image>-->
					<listImage :imgUrl="scope.row.goodsMergeListMgrDto.imageUrl"></listImage>
            	</template>
            </el-table-column>
            <el-table-column prop="goodsMergeListMgrDto.title"  :label='$i18n.t("common.name")' align="center" show-overflow-tooltip>
            </el-table-column>
			<el-table-column prop="treeList" :label='$i18n.t("common.Category")' align="center" height="55" style="width:100px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;" show-overflow-tooltip>
				<template slot-scope="scope">
					<div  v-for="(item,index) in scope.row.goodsMergeListMgrDto.treeList" :key="index">
						<span  v-for="(item2,index2) in item.parents" :key="index2">
							<span>{{item2.title}}></span>								
						</span>
						<span>{{item.title}}</span>					
					</div>
				</template>
			</el-table-column> 
            <!-- <el-table-column prop="goodsMergeListMgrDto" label="所属类目" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div v-if="scope.row.goodsMergeListMgrDto.treeList && scope.row.goodsMergeListMgrDto.treeList.length>0">{{scope.row.goodsMergeListMgrDto.treeList[0].title}}</div>
				</template>
			</el-table-column> -->
            <el-table-column prop="countryCode" :label='$i18n.t("common.DistributionCountry")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>
						{{ $dict.getDictValue('countrys', scope.row.countryCode) }}<br/>
					</span>
				</template>
			</el-table-column>

            <el-table-column prop="goodsMergeListMgrDto.packageQuantity" :label='$t("common.PackingQuantity")'  align="center" show-overflow-tooltip></el-table-column>
         
            <!-- <el-table-column prop="goodsMergeListMgrDto.brandCode" label="品牌" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div v-if="scope.row.goodsMergeListMgrDto.treeList && scope.row.goodsMergeListMgrDto.treeList.length>0">{{scope.row.goodsMergeListMgrDto.treeList[0].brandCode}}</div>
				</template>
			</el-table-column> -->
            <el-table-column prop="status1" :label='$i18n.t("common.Activitystatus")' align="center" show-overflow-tooltip>

            	<template slot-scope="scope">
            		<span :style="{color:scope.row.statusColor}">{{scope.row.status1}}</span>
            	</template>
            </el-table-column>
            <el-table-column prop="startTime"  :label='$i18n.t("common.starttime")' align="center" show-overflow-tooltip>
            	<template slot-scope="scope">
            		<span v-if="scope.row.startTime">
            			{{ scope.row.startTime | formatDate}}<br/>
            		</span>
            	</template>
            </el-table-column>
            <el-table-column prop="endTime"  :label='$i18n.t("common.Endtime")' align="center" show-overflow-tooltip>
            	<template slot-scope="scope">
            		<span v-if="scope.row.endTime">
            			{{ scope.row.endTime | formatDate}}<br/>
            		</span>
            	</template>
            </el-table-column>
			<el-table-column prop="prices" :label='$i18n.t("common.Lowestprice")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsMergeListMgrDto.prices" :key="index">
                        <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                            Number(s.lowestPrice).toFixed(2)
                        }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="theme" :label='$i18n.t("common.fixedprice")'  align="center" show-overflow-tooltip>
            	<template slot-scope="scope" >
            		<div v-if="scope.row.goodsMergeListMgrDto && scope.row.goodsMergeListMgrDto.prices.length>0">
            			<span v-for="(s,index) in scope.row.goodsMergeListMgrDto.prices" :key="index"> {{s.countryCode}}:<span v-if="s.resultPrice">{{ Number(s.resultPrice).toFixed(2) }}</span> <br /></span>	
            		</div>					
            	</template>
            </el-table-column>		
            <el-table-column prop="discount" :label='$i18n.t("common.discount")' align="center" show-overflow-tooltip>
            	<template slot-scope="scope">
            			<span v-if="scope.row.promotionType=='Discount'">-{{ scope.row.discount }}%<br/></span>
            			<span v-else-if="scope.row.promotionType=='Price'">-€{{(scope.row.goodsPrice-scope.row.discount).toFixed(2) }}<br/></span>
            	</template>
            </el-table-column>
            <el-table-column prop="endTime" :label='$i18n.t("common.Distributionprice1")' align="center" show-overflow-tooltip>
				<template slot-scope="scope" >
						<span v-if="scope.row.discountPrice">{{scope.row.countryCode}}:{{ scope.row.discountPrice.toFixed(2) }}</span>					
				</template>
			</el-table-column>
            <el-table-column prop="freeDelivery" :label='$i18n.t("common.Freeshippingornot")' align="center" show-overflow-tooltip>
            	<template slot-scope="scope">
            			<span v-if="scope.row.isFreeFreight==1">{{ $i18n.t("common.yes") }}<br/></span>
            			<span v-else-if="scope.row.isFreeFreight==0">{{ $i18n.t("common.no") }}<br/></span>
            	</template>
            </el-table-column>
            <el-table-column prop="endTime" :label='$t("common.RemainingTotal")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.quantity-scope.row.soldQuantity}}/{{scope.row.quantity}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="lockQuantity" :label='$i18n.t("common.DistributionQuantityLock")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.lockQuantity}}</span>
				</template>
			</el-table-column>
            <el-table-column prop="status" :label='$i18n.t("common.Enableornot")'  align="center" show-overflow-tooltip>
            	<template slot-scope="scope">
            		<el-switch v-model="scope.row.status"
					 @change="updateStatus(scope.row)"
					 :active-value="1"
					 :inactive-value="0"
					  active-color="#13ce66">
            		</el-switch>
            	</template>			
            </el-table-column>
			<el-table-column prop="createName" :label='$i18n.t("common.Creator")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right" width="200px">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update',module_code)">{{ $i18n.t("common.edit") }}</el-button>
					<el-button @click="copyUrl(scope.row)" type="text" size="small">{{ $t("common.CopyLink") }}</el-button>
					<el-button @click="toUrl(scope.row)" type="text" size="small" v-if="$common.hasPermission('View',module_code)">{{ $i18n.t("common.check") }}</el-button>
                    <el-button @click="deletePromotion(scope.row)" type="text" size="small" v-if="$common.hasPermission('Delete',module_code)">{{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
        	<el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
        		:page-size="listQuery.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
        	</el-pagination>
        </div>
        <el-dialog :title='isEdit?this.$i18n.t("common.edit"):this.$i18n.t("common.Add1")'  :visible.sync="dialogFormVisible" @close="closeDialog" :close-on-click-modal="false">
			<div v-if="dialogFormVisible">
				<sale-dialog ref="dialog"  :priceSetting="priceSetting" :isEdit="isEdit" :preSaleCountryFlag="preSaleCountryFlag" :typeName="typeName" @closeDialog='closeDialog' @handleSearchList='handleSearchList' :dictionary="dictionary" :formListQuery="formListQuery"/>
			</div>
         </el-dialog>
    </div>
</template>
<script>
	import {formatDate} from '@/utils/date';
	import SaleDialog from '@/components/common/SaleDialog.vue';
	import FilterContainer from '@/components/common/FilterContainer.vue';
	import Config from '@/utils/config';
	import i18n from '@/i18n/i18n.js';
	import listImage from '@/components/common/ListImage.vue';
    import dictionaryUtil from '@/utils/dictionaryUtil';
    import Scene from '@/utils/scene';
	const defaultDictionary={
			// 国家
			country:dictionaryUtil.getDict('countrys'),
			//位置
			brandCode:dictionaryUtil.getDict('brand'),
			state:[{
				id:'1',
				name: i18n.t("common.Inprogress") ,
				value:'UnderWay'
			},{
				id:'2',
				name:i18n.t("common.begininaminute") ,
				value:'NotStarted'
			},{
				id:'3',
			    name: i18n.t("common.Ended"),
				value:'Complete'
			}],
			status:[{
				id:'1',
				name: i18n.t("common.yes"),
				value:true
			},{
				id:'2',
				name: i18n.t("common.no"),
				value:false
			}],
			//是否免运费
			freeDelivery:[{
				id:'1',
				name: i18n.t("common.yes"),
				value:true
			},{
				id:'2',
				name: i18n.t("common.no"),
				value:false
			}],
			//是否分销
			promotion:[{
				id:'1',
				name: i18n.t("common.Inpromotion"),
				value:true
			},{
				id:'2',
				name: i18n.t("common.whole"),
				value:false
			}],
			//使用状态
			isUsed:[{
				id:'1',
				name: i18n.t("common.Notlisted"),
				value:false
			},{
				id:'2',
				name: i18n.t("common.Listed"),
				value:true
			}]
		};
export default {
    components: { SaleDialog,FilterContainer,listImage },
    name: 'varoantAdmin',
    data() {
        return {
			module_code:Config.module_code.distribution,
			ipArr:[],
			loading:false,
			typeName:i18n.t("common.Distribution"),			
			dictionary:Object.assign({}, defaultDictionary),
			defaultDictionary:Object.assign({}, defaultDictionary),
            //查询项
            listQuery:{
              "title": "",
              "brandCode": "",
              "goodId": "",
              "status": "",
              "endTime":"",
              "beginTime":"",
              "page": 1,
              "limit": 10
            },
			defaultListQuery:{
			  "title": "",
			  "brandCode": "",
			  "goodId": "",
			  "status": "",
			  "endTime":"",
			  "beginTime":"",
			  "page": 1,
			  "limit": 10
			},
			formListQuery:{
			  "title": "",
			  "brandCode": "",
			  "goodsId": "",
			  "status": 1,
			  "type":"1",
			  'pageDataSelectList':[],
			  "promotionRuleCountrys":[]
			},
			defaultFormListQuery:{
			  "title": "",
			  "brandCode": "",
			  "goodsId": "",
			  "status": 1,
			  "type":"1",
			  'pageDataSelectList':[],
			  "promotionRuleCountrys":[]
			},
			//组件标签是否显示
			arg:{
				name:true,
				brand:false,
				country:true,
				state:true
			},
			placeholder:{
				name:i18n.t("common.DistributionNameProductIDBarcode"),
				country:this.$i18n.t("common.DistributionCountry")
			},
			//编辑or新增
			isEdit: false,
            //表单数据
            formItem: {},
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
			preSaleCountryFlag: false,
            priceSetting: []
        };
    },
	filters:{
	  formatDate(time,type='yyyy-MM-dd hh:mm:ss'){
	    if(time==null||time===''){
	      return 'N/A';
	    }
	    let date = new Date(time);
	    return formatDate(date,type)
	  },
	},
    mounted() {
        this.handleSearchList()
    },
    methods: {
		//搜索列表
		handleSearchList(listQuery){
			this.loading=true
			let type='yyyy-MM-dd hh:mm:ss'
			let beginTime=new Date().getTime()
			let endTime=new Date().getTime() +24 * 60 * 60 * 1000 -1
			let nowTime=Date.now()
			let nowTime2=Date.now() + 24 * 60 * 60 * 1000 - 1
			if(listQuery){
				this.listQuery.page=1
				let status=''
				if(listQuery.enable===true){
					status="1"
				}else if(listQuery.enable===false){
					status="0"
				}
				this.listQuery={
					"title": listQuery.title,
					"brandCode": listQuery.brand,
					"goodId": "",
					"status": status,
					'activityStatus':listQuery.state,
					"countryCode":listQuery.country,
					"endTime":"",
					"beginTime":"",
					"page": this.listQuery.page,
					"limit": this.listQuery.limit,
					'nowTime':nowTime
				}
			}				
					
			this.$store		
				.dispatch('getDistribution', this.listQuery)
				.then(res => {					
					this.pageData=res
					this.pageData.list.forEach((item,index)=>{

							// if(item.quantity-item.soldQuantity<=0){
							// 	item.status1='已结束'
							// 	item.statusColor='black'
							// }else{

							//产品连涵韬提新要求不按库存判断状态，只按时间	

								if(item.startTime>nowTime){
									item.status1=this.$i18n.t("common.begininaminute")
									item.statusColor='red'
								}else if(item.endTime<nowTime){
									item.status1=this.$i18n.t("common.Ended")
									item.statusColor='black'
								}else if(item.endTime>nowTime && item.startTime<=nowTime){
									item.status1=this.$i18n.t("common.Inprogress")
									item.statusColor='green'
								}
							// }										
					})
					this.loading=false						
				});
		},
		handleResetSearch(){
			this.listQuery = Object.assign({}, this.defaultListQuery);
			this.handleSearchList()
		},
		copyUrl(row){
            const url = this.getUrl(row)
			let copyInput = document.createElement("input");
			//val是要复制的内容
			copyInput.value=url
			document.body.appendChild(copyInput);
			copyInput.select();      
			let copyed = document.execCommand("copy");
			if (copyed) {
			  document.body.removeChild(copyInput);
			  this.$message.success(this.$i18n.t("common.Successfullycopied"));
			}        
		},
		toUrl(row){
            const url = this.getUrl(row);
            window.open(url)
		},
        getUrl(row){
            if(Scene.isAigoStar){
                this.ipArr=Config.copyIP
            }else if(Scene.isNobleza){
                this.ipArr=Config.copyNIP
            }else if(Scene.isTaylorSwoden){
                this.ipArr=Config.copyTSIP
            }
            let url=''
            let countryCode=row.countryCode.toLowerCase()

            // 前端反馈 分销 针对英国 将国家转换
            if(countryCode == 'gb'){
                countryCode ='en-GB'
            }

            let distributionId=row.distributionId
            let goodsId=row.goodsId
            let code = encodeURI(distributionId)
            let base64=btoa(code)
            if(process.env.NODE_ENV=='prod'){
                if(Scene.isTaylorSwoden && (row.countryCode=='CN' || row.countryCode=='PL' || row.countryCode=='FR' || row.countryCode=='DE' || row.countryCode=='US' || row.countryCode=='UK' || row.countryCode=='PT')){
                    return
                }else if(!Scene.isTaylorSwoden && (row.countryCode=='CN' || row.countryCode=='PT')){
                    return
                }
                let ipVal=this.ipArr.filter((item)=>{
                    if(item.code==row.countryCode){
                        return item
                    }
                })
                url=`https://www.${ipVal[0].val}/commodity/detail?id=${goodsId}&distributionId=${base64}&country=${countryCode}`
            }else if (process.env.NODE_ENV=='pre'){

                let ipaddress='b2c.aigostar.vip'
                let port='5443'
                url=`https://${ipaddress}:${port}/commodity/detail?id=${goodsId}&distributionId=${base64}&country=${countryCode}`

            }else {
                if(Scene.isTaylorSwoden && (row.countryCode=='CN' || row.countryCode=='PL' || row.countryCode=='FR' || row.countryCode=='DE' || row.countryCode=='US' || row.countryCode=='UK' || row.countryCode=='PT')){
                    return
                }
                let ipaddress=''
                let port=''
                if(Scene.isAigoStar){
                    ipaddress='apptest.aigostar'
                    port='8341'
                }else if(Scene.isNobleza){
                    ipaddress='apptestnobleza.aigostar'
                    port='8345'
                }else if(Scene.isTaylorSwoden){
                    ipaddress='apptest.aigostar'
                    port='8102'
                }
                url=`http://${ipaddress}.com:${port}/commodity/detail?id=${goodsId}&distributionId=${base64}&country=${countryCode}`
            }
            return url;
        },
		updateStatus(row){
			console.log(row)
			let status=""
			row.status=='1'?status="1":status="0"
			this.$store.dispatch('putDisEnable', {
				status,
				id:row.distributionId,
			}).then(res => {
				this.handleSearchList()
			})
		},
		
		handleCurrentChange(val) {
			this.listQuery.page = val;
			this.handleSearchList();
		},
		deletePromotion(row){
			// 二次确认删除
			this.$confirm( this.$i18n.t("common.confirmdeletion"),  this.$i18n.t("common.prompt"), {
			    type: 'warning'
			})
			    .then(() => {
			        this.$store
			          .dispatch("deleteDistribution", {
			        	  id:row.distributionId
			          }).then(res => {
						let totalPage = Math.ceil((this.pageData.total - 1) / this.listQuery.limit)
						let currentPage =this.listQuery.page > totalPage ? totalPage : this.listQuery.page
						this.listQuery.page = currentPage < 1 ? 1 : currentPage
						this.handleSearchList();  
			        	this.$message({
			        		message:   this.$i18n.t("common.Successfullydeleted"),
			        		type: 'success',
			        		duration: 1000
			        	});
			        })	
			    })
			    .catch(() => {});		
		},
		//打开新增
		add(){
			this.isEdit=false
			this.dialogFormVisible=true
		},
		//打开编辑
		edit(rows){
			let row=JSON.parse(JSON.stringify(rows))
			this.isEdit=true
						
			row.promotionValue=row.discount
			row.promotionCount=row.quantity
			row.isFreeFreight==1?row.freeDelivery=true:row.freeDelivery=false	
			if(row.startTime && row.endTime){				
				row.time=[]			
				let type='yyyy-MM-dd hh:mm:ss'
				let beginTime=this.$options.filters['formatDate'](row.startTime,type)
				let endTime=this.$options.filters['formatDate'](row.endTime,type)
				row.time.push(beginTime)
				row.time.push(endTime)
			}
			this.formListQuery= Object.assign({},row)
			this.formListQuery.promotionRuleCountrys=[]
			setTimeout(()=>{
				this.formListQuery.promotionRuleCountrys.push(row)		
				this.dialogFormVisible=true
			})
			
			
		},
		//关闭窗口
		closeDialog(){
			this.dialogFormVisible=false
			this.formListQuery=Object.assign({}, this.defaultFormListQuery)
		}
    },
    watch:{
        // 打开新增编辑对话框，请求接口
        dialogFormVisible:{
            handler(newValue, oldValue){
                if(newValue){
                    this.$store.dispatch('listPriceSettings').then((res) => {
                        this.priceSetting = res
                    });
                }
            }
        }
    }
};
</script>

