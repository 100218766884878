var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(_vm._s(_vm.$i18n.t("common.Basicinformationoftheorder")))
      ]),
      _c(
        "el-descriptions",
        { attrs: { title: "", column: 3 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Ordernumber") } },
            [_vm._v(_vm._s(_vm.orderInfo.orderNo))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Creationtime") } },
            [_vm._v(_vm._s(_vm._f("formatDate")(_vm.orderInfo.createTime)))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.OrderStatus") } },
            [
              _c("dataText", {
                staticClass: "filter-item",
                attrs: { clearable: "", codeType: "order_status" },
                model: {
                  value: _vm.orderInfo.orderStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.orderInfo, "orderStatus", $$v)
                  },
                  expression: "orderInfo.orderStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.UserID") } },
            [_vm._v(_vm._s(_vm.orderInfo.userId))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Useremail") } },
            [_vm._v(_vm._s(_vm.orderInfo.email))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.country") } },
            [
              _c("dataText", {
                staticClass: "filter-item",
                attrs: {
                  clearable: "",
                  codeType: "countrys",
                  placeholder: _vm.$i18n.t("common.country")
                },
                model: {
                  value: _vm.orderInfo.countryCode,
                  callback: function($$v) {
                    _vm.$set(_vm.orderInfo, "countryCode", $$v)
                  },
                  expression: "orderInfo.countryCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.activity") } },
            [_vm._v(_vm._s(_vm.orderInfo.source))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Returnsandexchanges") } },
            [_vm._v(_vm._s(_vm.returnGoodsNum))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.consultingservice") } },
            [_vm._v(_vm._s(_vm.consultationNum))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Documentremarks") } },
            [_vm._v(_vm._s(_vm.orderInfo.remarkTag))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.combination") } },
            [
              _vm._v(
                _vm._s(
                  _vm.orderInfo.palletDescription ||
                    _vm.$i18n.t("common.nothing")
                )
              )
            ]
          ),
          !_vm.scene.isAigoSmartApp
            ? _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.platform") } },
                [_vm._v(_vm._s(_vm.orderInfo.platform))]
              )
            : _vm._e(),
          !_vm.scene.isAigoSmartApp
            ? _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.source") } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.userInfo &&
                        _vm.sourceTextMsg(
                          _vm.userInfo.sourceUrlName,
                          _vm.userInfo.sourceUrl
                        )
                    )
                  )
                ]
              )
            : _vm._e(),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.OrderSource") } },
            [_vm._v(_vm._s(_vm.orderInfo.orderSourceUrlName))]
          )
        ],
        1
      ),
      _vm.orderAddress
        ? _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.Shippingaddress")))
          ])
        : _vm._e(),
      _vm.orderAddress
        ? _c(
            "el-descriptions",
            { attrs: { title: "", border: "" } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.name") } },
                [
                  _vm._v(
                    _vm._s(_vm.orderAddress.userName) +
                      " " +
                      _vm._s(_vm.orderAddress.userSurname) +
                      "\n            "
                  )
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Mobilephonenumber") } },
                [_vm._v(_vm._s(_vm.orderAddress.linkPhone))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.country") } },
                [
                  _c("dataText", {
                    staticClass: "filter-item",
                    attrs: { clearable: "", codeType: "countrys" },
                    model: {
                      value: _vm.orderAddress.countryCode,
                      callback: function($$v) {
                        _vm.$set(_vm.orderAddress, "countryCode", $$v)
                      },
                      expression: "orderAddress.countryCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.province") } },
                [_vm._v(_vm._s(_vm.orderAddress.province))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.city") } },
                [_vm._v(_vm._s(_vm.orderAddress.city))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.PostalCode") } },
                [_vm._v(_vm._s(_vm.orderAddress.postalCode))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Detailedaddress") } },
                [_vm._v(_vm._s(_vm.orderAddress.addressContent))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Housenumber") } },
                [_vm._v(_vm._s(_vm.orderAddress.houseNum))]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t(
                      "common.RecipientCompanyAddressAdditionalContent"
                    )
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.fieldTextMerge(
                        _vm.orderAddress.recipientCompany,
                        _vm.orderAddress.addressAdditionalContent
                      )
                    )
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(_vm._s(_vm.$i18n.t("common.Invoiceaddress")))
      ]),
      _vm.orderInvoice
        ? _c(
            "el-descriptions",
            { attrs: { title: "", border: "" } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.TaxID") } },
                [_vm._v(_vm._s(_vm.orderInvoice.taxNumber))]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: { label: _vm.$i18n.t("common.InvoiceStatus"), span: 2 }
                },
                [
                  _c("dataText", {
                    staticClass: "filter-item",
                    attrs: { clearable: "", codeType: "order_invoice" },
                    model: {
                      value: _vm.orderInvoice.invoiceStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.orderInvoice, "invoiceStatus", $$v)
                      },
                      expression: "orderInvoice.invoiceStatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.name") } },
                [
                  _vm._v(
                    _vm._s(_vm.orderInvoice.firstName) +
                      " " +
                      _vm._s(_vm.orderInvoice.lastName) +
                      "\n            "
                  )
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Mobilephonenumber") } },
                [_vm._v(_vm._s(_vm.orderInvoice.phoneNumber))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.country") } },
                [
                  _c("dataText", {
                    staticClass: "filter-item",
                    attrs: { clearable: "", codeType: "countrys" },
                    model: {
                      value: _vm.orderInvoice.countryCode,
                      callback: function($$v) {
                        _vm.$set(_vm.orderInvoice, "countryCode", $$v)
                      },
                      expression: "orderInvoice.countryCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.province") } },
                [_vm._v(_vm._s(_vm.orderInvoice.province))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.city") } },
                [_vm._v(_vm._s(_vm.orderInvoice.city))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.PostalCode") } },
                [_vm._v(_vm._s(_vm.orderInvoice.postalCode))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Detailedaddress") } },
                [_vm._v(">" + _vm._s(_vm.orderInvoice.addressContent))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Housenumber") } },
                [_vm._v(_vm._s(_vm.orderInvoice.houseNum))]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t(
                      "common.RecipientCompanyAddressAdditionalContent"
                    )
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.fieldTextMerge(
                        _vm.orderInvoice.recipientCompany,
                        _vm.orderInvoice.addressAdditionalContent
                      )
                    )
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "el-descriptions",
            { attrs: { title: "", border: "" } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.name") } },
                [
                  _vm._v(
                    _vm._s(_vm.orderAddress.userName) +
                      " " +
                      _vm._s(_vm.orderAddress.userSurname) +
                      "\n            "
                  )
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Mobilephonenumber") } },
                [_vm._v(_vm._s(_vm.orderAddress.linkPhone))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.country") } },
                [
                  _c("dataText", {
                    staticClass: "filter-item",
                    attrs: { clearable: "", codeType: "countrys" },
                    model: {
                      value: _vm.orderAddress.countryCode,
                      callback: function($$v) {
                        _vm.$set(_vm.orderAddress, "countryCode", $$v)
                      },
                      expression: "orderAddress.countryCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.province") } },
                [_vm._v(_vm._s(_vm.orderAddress.province))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.city") } },
                [_vm._v(_vm._s(_vm.orderAddress.city))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.PostalCode") } },
                [_vm._v(_vm._s(_vm.orderAddress.postalCode))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Detailedaddress") } },
                [_vm._v(_vm._s(_vm.orderAddress.addressContent))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Housenumber") } },
                [_vm._v(_vm._s(_vm.orderAddress.houseNum))]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t(
                      "common.RecipientCompanyAddressAdditionalContent"
                    )
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.fieldTextMerge(
                        _vm.orderAddress.recipientCompany,
                        _vm.orderAddress.addressAdditionalContent
                      )
                    )
                  )
                ]
              )
            ],
            1
          ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(_vm._s(_vm.$i18n.t("common.Paymentinformation")))
      ]),
      _c(
        "el-descriptions",
        { attrs: { title: "" } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Paymentstatus") } },
            [
              _c("dataText", {
                staticClass: "filter-item",
                attrs: { clearable: "", codeType: "payment_status" },
                model: {
                  value: _vm.orderInfo.paymentStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.orderInfo, "paymentStatus", $$v)
                  },
                  expression: " orderInfo.paymentStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Paymentmethod") } },
            [_vm._v(_vm._s(_vm.payMethodName))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Paymenttime") } },
            [_vm._v(_vm._s(_vm._f("formatDate")(_vm.orderInfo.paymentTime)))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.coupon") } },
            [
              _c(
                "dataText",
                {
                  attrs: { codeType: "currency_code" },
                  model: {
                    value: _vm.currencyCode,
                    callback: function($$v) {
                      _vm.currencyCode = $$v
                    },
                    expression: "currencyCode"
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.orderInfo.couponDiscountAmount) +
                      "\n                "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Patrioticcoin") } },
            [
              _c(
                "dataText",
                {
                  attrs: { codeType: "currency_code" },
                  model: {
                    value: _vm.currencyCode,
                    callback: function($$v) {
                      _vm.currencyCode = $$v
                    },
                    expression: "currencyCode"
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.orderInfo.balancedDiscountAmt) +
                      "\n                "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.freight") } },
            [
              _c(
                "dataText",
                {
                  attrs: { codeType: "currency_code" },
                  model: {
                    value: _vm.currencyCode,
                    callback: function($$v) {
                      _vm.currencyCode = $$v
                    },
                    expression: "currencyCode"
                  }
                },
                [_vm._v(_vm._s(_vm.orderInfo.freightFee))]
              )
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.CouponName") } },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.couponName) + "\n            "
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Couponcode") } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.couponCodes) +
                  "\n            "
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Paymentamount") } },
            [
              _c(
                "dataText",
                {
                  attrs: { codeType: "currency_code" },
                  model: {
                    value: _vm.currencyCode,
                    callback: function($$v) {
                      _vm.currencyCode = $$v
                    },
                    expression: "currencyCode"
                  }
                },
                [_vm._v(_vm._s(_vm.orderInfo.paymentAmt))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(_vm._s(_vm.$i18n.t("common.LogisticsInformation")))
      ]),
      _c(
        "el-descriptions",
        { attrs: { title: "", column: 3 } },
        [
          _vm.orderLogistics != null
            ? _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Deliverytime") } },
                [
                  _vm._v(
                    _vm._s(_vm._f("formatDate")(_vm.orderInfo.deliverTime)) +
                      "\n            "
                  )
                ]
              )
            : _c("el-descriptions-item", {
                attrs: { label: _vm.$i18n.t("common.Deliverytime") }
              }),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.logisticscompany") } },
            [
              _vm._v(
                _vm._s(
                  _vm.orderLogistics != null
                    ? _vm.orderLogistics.logisticsCompanyName
                    : ""
                ) + "\n            "
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Trackingnumber") } },
            [
              _vm._v(
                _vm._s(
                  _vm.orderLogistics != null
                    ? _vm.orderLogistics.logisticsNo
                    : ""
                ) + "\n            "
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Whethertomanuallyship") } },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.isManualDeliver(_vm.orderLogistics)) +
                  "\n\n            "
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Manualshippingoperator") } },
            [
              _vm._v(
                _vm._s(
                  _vm.orderLogistics != null
                    ? _vm.orderLogistics.manualDeliverUserName
                    : ""
                ) + "\n            "
              )
            ]
          ),
          _vm.orderLogistics != null
            ? _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Manualshippingoperationtime")
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatDate")(_vm.orderLogistics.manualDeliverTime)
                    ) + "\n            "
                  )
                ]
              )
            : _c("el-descriptions-item", {
                attrs: {
                  label: _vm.$i18n.t("common.Manualshippingoperationtime")
                }
              }),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$i18n.t("common.Shippingremarks") } },
            [
              _vm._v(
                _vm._s(
                  _vm.orderLogistics != null ? _vm.orderLogistics.remark : ""
                )
              )
            ]
          )
        ],
        1
      ),
      _vm.orderInfo.transferStatus != null
        ? _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.Bankremittance")))
          ])
        : _vm._e(),
      _vm.orderInfo.transferStatus != null
        ? _c(
            "el-descriptions",
            { attrs: { title: "", column: 3 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Remittancestatus") } },
                [
                  _c("dataText", {
                    staticClass: "filter-item",
                    attrs: { clearable: "", codeType: "transfer_status" },
                    model: {
                      value: _vm.orderInfo.transferStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.orderInfo, "transferStatus", $$v)
                      },
                      expression: " orderInfo.transferStatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Remittanceconfirmationoperator")
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.orderInfo.transferConfirmUserName) +
                      "\n            "
                  )
                ]
              ),
              _vm.orderLogistics != null
                ? _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t(
                          "common.Remittanceconfirmationoperationtime"
                        )
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.orderInfo.transferConfirmTime
                          )
                        ) + "\n            "
                      )
                    ]
                  )
                : _c("el-descriptions-item", {
                    attrs: {
                      label: _vm.$i18n.t("common.Manualshippingoperationtime")
                    }
                  }),
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Remarks") } },
                [_vm._v(_vm._s(_vm.orderInfo.confirmRemark))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.orderInfo.cancelReason
        ? _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.CancelRemark")))
          ])
        : _vm._e(),
      _vm.orderInfo.cancelReason
        ? _c("span", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.orderInfo.cancelReason) +
                "\n        "
            )
          ])
        : _vm._e(),
      _vm.orderInfo.orderType == "PRE_SALE_ORDER"
        ? _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.Preorderinformation")))
          ])
        : _vm._e(),
      _vm.orderInfo.orderType == "PRE_SALE_ORDER"
        ? _c(
            "el-descriptions",
            { attrs: { title: "", column: 3 } },
            [
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Converttoformaldocument")
                  }
                },
                [
                  _vm.orderInfo.formal == true
                    ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                    : _vm._e(),
                  _vm.orderInfo.formal == false
                    ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                    : _vm._e()
                ]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Positiveconversionsinglemode")
                  }
                },
                [
                  _vm.orderInfo.trunFormalType == "1"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$i18n.t("common.automatic")))
                      ])
                    : _vm._e(),
                  _vm.orderInfo.trunFormalType == "2"
                    ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.Manual")))])
                    : _vm._e()
                ]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Positiveconversionsingletime")
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("formatDate")(_vm.orderInfo.trunFormalTime))
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.orderInfo.orderType == "PRE_SALE_ORDER" &&
      _vm.orderInfo.preSalePayType == "DEPOSIT_AND_FINAL_PAYMENT"
        ? _c(
            "el-table",
            {
              ref: "pagedata",
              staticStyle: { width: "100%" },
              attrs: {
                "row-key": "orderNo",
                data: _vm.orderInfo.preSaleOrderList,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "preSalePayType",
                  label: _vm.$i18n.t("common.type"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.preSalePayType
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$dict.getPreSalePayType(
                                      row.preSalePayType
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2152938452
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderNo",
                  label: _vm.$i18n.t("common.Subordernumber"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalAmt",
                  label: _vm.$i18n.t("common.money"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "dataText",
                            {
                              attrs: { codeType: "currency_code" },
                              model: {
                                value: _vm.formItem.currencyCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.formItem, "currencyCode", $$v)
                                },
                                expression: "formItem.currencyCode"
                              }
                            },
                            [_vm._v(_vm._s(scope.row.totalAmt) + " ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3808175653
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderStatus",
                  label: _vm.$i18n.t("common.OrderStatus"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("dataText", {
                            attrs: { codeType: "order_status" },
                            model: {
                              value: scope.row.orderStatus,
                              callback: function($$v) {
                                _vm.$set(scope.row, "orderStatus", $$v)
                              },
                              expression: "scope.row.orderStatus"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  315408150
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "paymentTime",
                  label: _vm.$i18n.t("common.Paymenttime"),
                  align: "center",
                  formatter: _vm.formatDate,
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderNextCancelTime",
                  label: _vm.$i18n.t("common.Paymentdeadline"),
                  align: "center",
                  formatter: _vm.formatDate,
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payMethodName",
                  label: _vm.$i18n.t("common.Paymentmethod"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.payTypeDesc))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3649712039
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "couponDiscountAmount",
                  label: _vm.$i18n.t("common.coupon"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "dataText",
                            {
                              attrs: { codeType: "currency_code" },
                              model: {
                                value: _vm.formItem.currencyCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.formItem, "currencyCode", $$v)
                                },
                                expression: "formItem.currencyCode"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(scope.row.couponDiscountAmount) + " "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  242457094
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "balancedDiscountAmt",
                  label: _vm.$i18n.t("common.Patrioticcoin"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "dataText",
                            {
                              attrs: { codeType: "currency_code" },
                              model: {
                                value: _vm.formItem.currencyCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.formItem, "currencyCode", $$v)
                                },
                                expression: "formItem.currencyCode"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(scope.row.balancedDiscountAmt) + " "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2368000120
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.orderInfo.orderType == "PRE_SALE_ORDER"
        ? _c(
            "el-descriptions",
            { attrs: { title: "", column: 3 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: _vm.$i18n.t("common.Remarks") } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.orderInfo.orderRemark) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.orderInfo.orderType == "PRE_SALE_ORDER"
        ? _c(
            "el-button",
            {
              attrs: { type: "text", size: "small" },
              on: { click: _vm.openRemark }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.Addremarks")))]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.giftActivityGoodsList.length > 0,
              expression: "giftActivityGoodsList.length > 0 "
            }
          ]
        },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.GiftInformation")))
          ]),
          _c(
            "el-table",
            { attrs: { data: _vm.giftActivityGoodsList, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Serialnumber"),
                  align: "center",
                  prop: "orderItemId"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.$index + 1))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.GiveawayEventID"),
                  align: "center",
                  prop: "giftActivityId"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.GiveawayEvent"),
                  align: "center",
                  prop: "giftActivityName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.name"),
                  align: "center",
                  prop: "goodsTitle"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.ProductID1"),
                  align: "center",
                  prop: "goodsId"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "SKU", align: "center", prop: "skuCode" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.barcode"),
                  align: "center",
                  prop: "barCode"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Pricing_unitprice"),
                  align: "center",
                  prop: "price"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "dataText",
                          {
                            attrs: { codeType: "currency_code" },
                            model: {
                              value: _vm.currencyCode,
                              callback: function($$v) {
                                _vm.currencyCode = $$v
                              },
                              expression: "currencyCode"
                            }
                          },
                          [_vm._v(_vm._s(scope.row.price))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Sellingprice_unitprice"),
                  align: "center",
                  prop: "salePrice"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "dataText",
                          {
                            attrs: { codeType: "currency_code" },
                            model: {
                              value: _vm.currencyCode,
                              callback: function($$v) {
                                _vm.currencyCode = $$v
                              },
                              expression: "currencyCode"
                            }
                          },
                          [_vm._v(_vm._s(scope.row.salePrice))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.PackingQuantity"),
                  align: "center",
                  prop: "packageSize"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.quantity"),
                  align: "center",
                  prop: "buyQty"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Totalprice"),
                  align: "center",
                  prop: "salePrice"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "dataText",
                          {
                            attrs: { codeType: "currency_code" },
                            model: {
                              value: _vm.currencyCode,
                              callback: function($$v) {
                                _vm.currencyCode = $$v
                              },
                              expression: "currencyCode"
                            }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  (scope.row.salePrice *
                                    1000 *
                                    scope.row.buyQty) /
                                    1000
                                ) +
                                "\n                        "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(_vm._s(_vm.$i18n.t("common.ProductInformation")))
      ]),
      _c(
        "el-table",
        { attrs: { data: _vm.orderItemList, border: "" } },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Serialnumber"),
              align: "center",
              prop: "orderItemId"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.$index + 1))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.name"),
              align: "center",
              prop: "goodsTitle"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ProductID1"),
              align: "center",
              prop: "goodsId"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "SKU", align: "center", prop: "skuCode" }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.barcode"),
              align: "center",
              prop: "barCode"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.VariantInformation"),
              align: "center",
              prop: "goodsVariantVal"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.getgoodsVariantVal(scope.row.goodsVariantVal)
                        ) +
                        "\n                "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Pricing_unitprice"),
              align: "center",
              prop: "price"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "dataText",
                      {
                        attrs: { codeType: "currency_code" },
                        model: {
                          value: _vm.currencyCode,
                          callback: function($$v) {
                            _vm.currencyCode = $$v
                          },
                          expression: "currencyCode"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.price))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Sellingprice_unitprice"),
              align: "center",
              prop: "salePrice"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "dataText",
                      {
                        attrs: { codeType: "currency_code" },
                        model: {
                          value: _vm.currencyCode,
                          callback: function($$v) {
                            _vm.currencyCode = $$v
                          },
                          expression: "currencyCode"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.salePrice))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.PackingQuantity"),
              align: "center",
              prop: "packageSize"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.quantity"),
              align: "center",
              prop: "buyQty"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Totalprice"),
              align: "center",
              prop: "salePrice"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "dataText",
                      {
                        attrs: { codeType: "currency_code" },
                        model: {
                          value: _vm.currencyCode,
                          callback: function($$v) {
                            _vm.currencyCode = $$v
                          },
                          expression: "currencyCode"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              (scope.row.salePrice * 1000 * scope.row.buyQty) /
                                1000
                            ) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "flex-end" } },
        [
          _c(
            "el-form",
            {
              staticStyle: { "margin-right": "10px", "margin-top": "10px" },
              attrs: {
                "label-position": "right",
                "label-width": "120px",
                inline: true
              }
            },
            [
              _vm.orderInfo.orderType != "PRE_SALE_ORDER" ||
              _vm.orderInfo.preSalePayType == "FULL_PAYMENT"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.MerchandiseSubtotal")
                      }
                    },
                    [
                      _c(
                        "dataText",
                        {
                          attrs: { codeType: "currency_code" },
                          model: {
                            value: _vm.currencyCode,
                            callback: function($$v) {
                              _vm.currencyCode = $$v
                            },
                            expression: "currencyCode"
                          }
                        },
                        [_vm._v(_vm._s(_vm.orderInfo.productAmt))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.orderInfo.orderType == "PRE_SALE_ORDER" ||
                _vm.orderInfo.preSalePayType != "FULL_PAYMENT") &&
              _vm.orderPreSaleInfo &&
              _vm.orderPreSaleInfo.orderDepositAmt
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$i18n.t("common.DepositAmount") } },
                    [
                      _c(
                        "dataText",
                        {
                          attrs: { codeType: "currency_code" },
                          model: {
                            value: _vm.currencyCode,
                            callback: function($$v) {
                              _vm.currencyCode = $$v
                            },
                            expression: "currencyCode"
                          }
                        },
                        [_vm._v(_vm._s(_vm.orderPreSaleInfo.orderDepositAmt))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.orderInfo.orderType == "PRE_SALE_ORDER" ||
                _vm.orderInfo.preSalePayType != "FULL_PAYMENT") &&
              _vm.orderPreSaleInfo &&
              _vm.orderPreSaleInfo.showOrderFinalPaymentAmt &&
              _vm.orderPreSaleInfo.orderFinalPaymentAmt
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$i18n.t("common.FinalPaymentAmount") }
                    },
                    [
                      _c(
                        "dataText",
                        {
                          attrs: { codeType: "currency_code" },
                          model: {
                            value: _vm.currencyCode,
                            callback: function($$v) {
                              _vm.currencyCode = $$v
                            },
                            expression: "currencyCode"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.orderPreSaleInfo.orderFinalPaymentAmt)
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.freight") } },
                [
                  _c(
                    "dataText",
                    {
                      attrs: { codeType: "currency_code" },
                      model: {
                        value: _vm.currencyCode,
                        callback: function($$v) {
                          _vm.currencyCode = $$v
                        },
                        expression: "currencyCode"
                      }
                    },
                    [_vm._v(_vm._s(_vm.orderInfo.freightFee))]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$i18n.t("common.FullReductionDiscount") }
                },
                [
                  _c("span", [_vm._v("-  ")]),
                  _c(
                    "dataText",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: { codeType: "currency_code" },
                      model: {
                        value: _vm.currencyCode,
                        callback: function($$v) {
                          _vm.currencyCode = $$v
                        },
                        expression: "currencyCode"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.orderInfo.fullDiscountReduction) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.coupon") } },
                [
                  _c("span", [_vm._v("-  ")]),
                  _c(
                    "dataText",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: { codeType: "currency_code" },
                      model: {
                        value: _vm.currencyCode,
                        callback: function($$v) {
                          _vm.currencyCode = $$v
                        },
                        expression: "currencyCode"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.orderInfo.couponDiscountAmount) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.Patrioticcoin") } },
                [
                  _c("span", [_vm._v("-  ")]),
                  _c(
                    "dataText",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: { codeType: "currency_code" },
                      model: {
                        value: _vm.currencyCode,
                        callback: function($$v) {
                          _vm.currencyCode = $$v
                        },
                        expression: "currencyCode"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.orderInfo.balancedDiscountAmt) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.total") } },
                [
                  _c(
                    "dataText",
                    {
                      attrs: { codeType: "currency_code" },
                      model: {
                        value: _vm.currencyCode,
                        callback: function($$v) {
                          _vm.currencyCode = $$v
                        },
                        expression: "currencyCode"
                      }
                    },
                    [_vm._v(_vm._s(_vm.orderInfo.totalAmt))]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.Rebate") } },
                [
                  _c(
                    "dataText",
                    {
                      attrs: { codeType: "currency_code" },
                      model: {
                        value: _vm.currencyCode,
                        callback: function($$v) {
                          _vm.currencyCode = $$v
                        },
                        expression: "currencyCode"
                      }
                    },
                    [_vm._v(_vm._s(_vm.orderInfo.rebateAmt))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm.orderInfo.orderType != "PRE_SALE_ORDER" &&
          _vm.orderInfo.orderStatus !== "Canceled" &&
          _vm.orderInfo.orderStatus !== "UnPaid"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.channelOrder()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("common.CancelOrder")) + "\n            "
                  )
                ]
              )
            : _vm._e(),
          _vm.orderInfo.orderType == "PRE_SALE_ORDER" &&
          _vm.orderInfo.preSalePayType == "DEPOSIT_AND_FINAL_PAYMENT" &&
          (_vm.orderInfo.preSaleOrderStatus == "DEPOSIT_PAID" ||
            _vm.orderInfo.preSaleOrderStatus == "FINAL_PAYMENT_UN_PAY" ||
            _vm.orderInfo.preSaleOrderStatus == "FINAL_PAYMENT_PAID")
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.channelOrder()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("common.CancelOrder")) + "\n            "
                  )
                ]
              )
            : _vm.orderInfo.orderType == "PRE_SALE_ORDER" &&
              _vm.orderInfo.preSalePayType == "FULL_PAYMENT" &&
              _vm.orderInfo.orderStatus !== "Canceled" &&
              _vm.orderInfo.orderStatus !== "UnPaid"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.channelOrder()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("common.CancelOrder")) + "\n            "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.close")))]
          )
        ],
        1
      ),
      _vm.channelFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Areyousuretocancelthisorder"),
                visible: _vm.channelFormVisible,
                "append-to-body": true,
                width: "30%",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.channelFormVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "1000",
                          type: "textarea",
                          placeholder: _vm.$i18n.t("common.reason")
                        },
                        model: {
                          value: _vm.cancelReason,
                          callback: function($$v) {
                            _vm.cancelReason = $$v
                          },
                          expression: "cancelReason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      on: {
                        click: function($event) {
                          _vm.channelFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit("variantFrom")
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("common.confirm")) +
                          "\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.remarkFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Addremarks"),
                visible: _vm.remarkFormVisible,
                "append-to-body": true,
                width: "30%",
                "before-close": _vm.closeRemarkDialog,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.remarkFormVisible = $event
                }
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: _vm.$i18n.t("common.Pleaseenteracomment"),
                  maxlength: "500",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.orderRemark,
                  callback: function($$v) {
                    _vm.orderRemark = $$v
                  },
                  expression: "orderRemark"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      on: { click: _vm.closeRemarkDialog }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      attrs: {
                        type: "primary",
                        loading: _vm.orderRemarkLoading
                      },
                      on: { click: _vm.putOrderRemark }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$i18n.t("common.confirm")) +
                          "\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }