<template>
    <div>
       
        <el-form :inline="true">
            <el-form-item  >
                <el-select v-model="listQuery.shelfCountryCode" disabled  class="filter-item" style="width: 100px;  " :placeholder='$i18n.t("common.country")' clearable>
                    <el-option v-for="item in dictionary.country"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code">
                    </el-option>      
                </el-select>		 
            </el-form-item>


            <el-form-item>
                <el-select v-model="listQuery.effectShelf" :placeholder='$i18n.t("common.Isitontheshelf")'   clearable  class="filter-item" style="width: 100px; " disabled >
                    <el-option :label='$i18n.t("common.Listed")' value="true"></el-option>
                    <el-option :label='$i18n.t("common.Notlisted")' value="false"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-input v-model="listQuery.idFuzzy" :placeholder='$i18n.t("common.SKU_EAN_Encoding")' clearable></el-input>
            </el-form-item>

            <el-form-item >
                <el-cascader :placeholder='$i18n.t("common.Pleaseselectacategory")'  v-model="listQuery.classifyId" :options="dictionary.classifyTree"
                    :props="{checkStrictly: true,value:'goodsClassifyId',label:'title',children:'children'}"  
                    :show-all-levels="false" clearable>
                </el-cascader>
            </el-form-item>

            <el-form-item >
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleCurrentChange(1)"
                            style="margin-left: 10px"> {{ $i18n.t("common.query") }}</el-button>
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px"
                        size="small">     {{ $i18n.t("common.Reset") }}
                </el-button>
            </el-form-item>
        </el-form>  
                        

        <h3 style="color:red;margin:10px 0px;"> {{ $i18n.t("common.Selectupto5") }}</h3>
        <el-table ref="tableData" :data="pageData.list" style="width: 100%" height="500" border row-key="goodsId" default-expand-all

            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
            :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
            id="goodsTable"
            @select="selectOfSelectable"
            @select-all="selectAllOfSelectable"
            @selection-change="handleSelectionChange">
            <el-table-column align="center" :reserve-selection="true" type="selection" width="55" :selectable="row => row.checkboxStatus">
            </el-table-column>
            <!-- 用于显示子表格张开按钮 -->
            <el-table-column prop="quantityType" label="" width="50px" align="center">
            </el-table-column>
            <el-table-column prop="goodsId"  :label='$i18n.t("common.ProductID")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>SKU:{{ scope.row.sku }}</span><br />
                    <span>EAN:{{ scope.row.barCode }}</span><br />
                    <span>{{ $i18n.t("common.code") }}:{{ scope.row.goodsCode }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image style="height: 100px" v-if="scope.row.imageUrl"
                        :src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackingQuantity")' align="center" show-overflow-tooltip></el-table-column>

            <el-table-column prop="ecomRemainQty" :label='$i18n.t("common.inventory")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        <span v-if="s.countryCode && s.countryCode == listQuery.shelfCountryCode">
                            {{ s.countryCode }}:{{ s.ecomRemainQty }}<br />
                        </span>     
                    </span>
                </template>
            </el-table-column>


            <el-table-column prop="title"  :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        <span v-if="s.shelf">{{
                            $dict.getDictValue('countrys', s.countryCode)
                        }}<br /></span>
                    </span>
                </template>
            </el-table-column>

<!--            <el-table-column prop="endTime" :label='$i18n.t("common.Promotionprice1")' align="center" show-overflow-tooltip>

                <template slot-scope="scope" v-if="scope.row.prices.length > 0">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.promotionPrice">{{
                            s.promotionPrice.toFixed(2)
                        }}</span><br />
                    </span>
                </template>
            </el-table-column>-->
            <el-table-column prop="prices" :label='$i18n.t("common.Promotionprice1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                                <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                    <span style="font-weight:bold;text-align: left;display: block;">{{$i18n.t("common.NewUserPromotion")}}<br/></span>
                                    <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                    </span>
                                </span>
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                    <span style="font-weight:bold;text-align: left;display: block;">{{$i18n.t("common.RegularUserPromotion")}}<br/></span>
                                    <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                    </span>
                                    </span>
                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.resultPrice">{{
                            s.resultPrice.toFixed(2)
                        }}</span><br />
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="brand" :label='$i18n.t("common.price1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-show="s.salePriceEbp || s.salePriceEbp>=0">{{ s.countryCode }}:{{
                            s.salePriceEbp.toFixed(2)
                        }}<br /></span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="brand" :label='$i18n.t("common.Fixedprice1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-if="s.resultPrice">{{ s.countryCode }}:{{
                            Number(s.fixedPrice).toFixed(2)
                        }}<br /></span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }}</span>
                    <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                </template>
            </el-table-column>
        </el-table>
                
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
                :page-size="listQuery.size" layout="total, prev, pager, next, jumper" :total="pageData.total">
            </el-pagination>                       
        </div>
        <span slot="footer" class="dialog-footer">           
            <span>{{ $i18n.t("common.Selected") }}:<span style="color: #008000;font-size: 16px;font-weight: 800;">{{pageDataSelectList.length}}</span></span>       
            <el-button style="width: 20%;margin-left: 50px;" type="primary" @click="handleSelected"> {{ $i18n.t("common.confirm") }}</el-button>
        </span>     
    </div>
</template>
<script>

import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from "@/utils/treeTable";
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";

export default {
    components: {
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        tableList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            listQueryData: {
                shelfCountryCode: '',
                classifyId: "",
                idFuzzy: '',
                preSaleCountryFlag: 'false',
                // brandCode: '',
                // updateUserId: '', //操作人
                // inPromotion: '', //是否促销
                effectShelf: 'true', //是否上架
                // beginCreateTime: '',
                // endCreateTime: '',
                // beginUpdateTime: '',
                // endUpdateTime: '',
                page: 1,
                size: 10
            },
            defaultListQueryData: {
                shelfCountryCode: '',
                classifyId: "",
                idFuzzy: '',
                preSaleCountryFlag: 'false',
                // brandCode: '',
                // updateUserId: '', //操作人
                // inPromotion: '', //是否促销
                effectShelf: 'true', //是否上架
                // beginCreateTime: '',
                // endCreateTime: '',
                // beginUpdateTime: '',
                // endUpdateTime: '',
                page: 1,
                size: 10
            },
            listQuery: {
                shelfCountryCode: '',
                preSaleCountryFlag: 'false',
                effectShelf: 'true',
                idFuzzy: '',
                classifyId:[],
                page: 1,
                size: 10        
            },
            pageData: {
                list: []
            },
            //确认后的被选中的商品
            pageDataSelectList: [],
            flag: false,
            ossHost: process.env.VUE_APP_OSS_Head,
            // loading: false,
            defaultListQuery: {
                shelfCountryCode: '',
                preSaleCountryFlag: 'false',
                effectShelf: 'true',
                idFuzzy: '',
                classifyId:[],
                page: 1,
                size: 10 
            }, 
        };
    },
    mounted() {
       //初始化类目
        this.getGoodsClassifyTree();
    },
    methods: {
        selectAllOfSelectable(selection) {
            selectAllOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        selectOfSelectable(selection) {
            selectOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        //获取商品树类目
        getGoodsClassifyTree() {
            this.$store.dispatch('getGoodsClassifyTree', {}).then((res) => {
                this.$set(this.dictionary, 'classifyTree', res);
            });
        },
        // 
        handleCurrentChange(val) {
            this.listQuery.page = val;
            this.loadlist();
        },
        // 重置
        cleanQuery() {
            let shelfCountryCode = this.listQuery.shelfCountryCode;
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.listQuery.shelfCountryCode = shelfCountryCode; 
            console.log('cleanQuery',this.listQuery)
            this.loadlist();
        },
         // 商品查询
        loadlist(){
            this.listQueryData = Object.assign({}, this.defaultListQueryData); 
            console.log('loadlist',this.listQueryData) 
            this.listQueryData.effectShelf = this.listQuery.effectShelf; 
            this.listQueryData.shelfCountryCode = this.listQuery.shelfCountryCode; 
            this.listQueryData.page = this.listQuery.page; 
            this.listQueryData.size = this.listQuery.size;  

            let idFuzzy = this.listQuery.idFuzzy;
            if (idFuzzy) {  
                this.listQueryData.idFuzzy = this.listQuery.idFuzzy; 
            }

            let val = this.listQuery.classifyId;
            if (val) {  
                this.listQueryData.classifyId = val[val.length - 1];
            }
            
            let temp = JSON.stringify(this.listQueryData, (key, value) => {
                if (value !== null && value !== '') {
                    return value;
                }
            });
            this.listQueryData = JSON.parse(temp);
            this.$store.dispatch('postGoodsSearch', this.listQueryData).then((res) => {
                // console.log(res)
                this.pageData = res;

                showSelectOfSelectable(this.$refs.tableData, this.pageData.list)
            });
        },
        // 父组件选择商品触发
        handleSearchList(val) {
            this.pageDataSelectList=[];
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.listQuery.shelfCountryCode = val; 
    
            this.loadlist();
            // 已选中打勾
            this.flag = true;
            if (this.tableList.length > 0) {
                this.tableList.forEach(item => {
                    this.$refs.tableData.toggleRowSelection(item)
                });
                this.pageDataSelectList = this.tableList
            }
            this.flag = false;
        },
        //选中商品事件
        handleSelectionChange(val) {
            // console.log(val);
            if (this.flag) return;
            let temp = {}; //存放id
            let result = []; //新数组           
            if (val.length > 5) {
                // 截取5位后,不需要的数据
                let tempArr = val.slice(5);
                tempArr.forEach((item) => {
                    this.$refs.tableData.toggleRowSelection(item, false);
                });
                // 截取前5位
                val = val.slice(0, 5);
            }
            val.map((item, index) => {
                if (!temp[item.goodsId]) {
                    result.push(item);
                    temp[item.goodsId] = true;
                }
            })
            
            this.pageDataSelectList = result
        },
        
        //选择商品
        handleSelected() {
            if (!this.pageDataSelectList || this.pageDataSelectList.length <= 0) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseselectaproductfirst") ,
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            if (this.pageDataSelectList.length > 5) {
                this.pageDataSelectList = this.pageDataSelectList.slice(0, 5)
            }
            this.$emit('handleSelected',JSON.parse(JSON.stringify(this.pageDataSelectList)));
        },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped>
 
 

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: 30px; */
}
  
  
 
 
</style>
