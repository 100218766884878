<template>
    <div>
        <el-table :data="tableData" class="form_table" border fit highlight-current-row>
            <el-table-column prop="sort" :label="$t('common.DragToSort')" align="center" width="80px">
                <i class="el-icon-sort drag-btn"></i>
            </el-table-column>
            <el-table-column prop="imageUrl" :label="$t('common.Productimage1')" width="150" align="center">
                <template slot-scope="scope">
                    <el-image
                        style="height: 100px; width: 100px"
                        v-if="scope.row.imageUrl != ''"
                        :src="ossHost + scope.row.imageUrl"
                        fit="fill"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="barCode" label="EAN" align="center"> </el-table-column>
            <el-table-column prop="title" :label="$t('common.Productname1')" align="center"> </el-table-column>
            <el-table-column :label="$i18n.t('common.DefaultSelectionOfUsers')" prop="userDefaultSelection" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.userDefaultSelection" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelCustomDialog()"> {{ $i18n.t('common.cancel') }} </el-button>
            <el-button type="primary" @click="confirmCustomDialog()"> {{ $i18n.t('common.confirm') }} </el-button>
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    name: 'giftSort',
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            tableData: []
        };
    },
    created() {},
    methods: {
        // 行拖拽
        rowDrop() {
            this.$nextTick(() => {
                this.sortable1 = Sortable.create(document.querySelector('.form_table .el-table__body-wrapper tbody'), {
                    handle: '.drag-btn',
                    onEnd: ({ newIndex, oldIndex }) => {
                        this.tableData.splice(newIndex, 0, this.tableData.splice(oldIndex, 1)[0]);
                        var newArray = this.tableData.slice(0);
                        this.tableData = [];
                        this.$nextTick(function () {
                            this.tableData = newArray;
                        });
                    }
                });
            });
        },
        confirmCustomDialog() {
            var i = 0;
            this.tableData.forEach((x) => {
                x.sort = i;
                i++;
            });
            console.log(this.tableData);
            this.$store.dispatch('updateGiftSort', this.tableData).then((res) => {
                this.$message({
                    message: this.$i18n.t('common.Successfullysaved'),
                    type: 'success'
                });
                this.$emit('closeGiftSort');
            });
        },
        cancelCustomDialog() {
            this.$emit('closeGiftSort');
            this.tableData = [];
        },
        openCustomDialog(tableData) {
            this.tableData = tableData;
            this.rowDrop();
        }
    }
};
</script>

<style>
.btns {
    text-align: center;
    margin-top: 20px;
}
</style>
