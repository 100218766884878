<template>
  <div>
    <div class="filter-container" shadow="never">
      <el-form :inline="true">
      
        <el-form-item>
					<el-input v-model="searchData.title" :placeholder='$i18n.t("common.name")' style="width: 180px;" class="filter-item" clearable ></el-input>
        </el-form-item>

        <el-form-item>
					<el-select v-model="searchData.countryCode"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder='$i18n.t("common.country")'>
						<el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code"></el-option>      
					</el-select>
        </el-form-item>
        
        <el-form-item>
					<el-select v-model="searchData.activityStatus"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder='$i18n.t("common.state")'>
						<el-option v-for="item in dictionary.activityStatus" :key="item.id" :label="item.name" :value="item.code"></el-option>      
					</el-select>
        </el-form-item>

        <el-form-item>
					<el-select v-model="searchData.status"   clearable class="filter-item" style="width: 180px; margin-left: 10px"   :placeholder='$i18n.t("common.Enableornot")' >
						<el-option v-for="item in dictionary.status" :key="item.id" :label="item.name" :value="item.value"></el-option>      
					</el-select>
        </el-form-item>
        
        
        <el-form-item>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleSearchList" style="margin-left: 10px">
              {{ $i18n.t("common.query") }}
            </el-button>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleResetSearch" style="margin-left: 10px" size="small">
              {{ $i18n.t("common.Reset") }}
            </el-button>
        </el-form-item>
      </el-form>
    </div>     
        
    <div style="margin: 20px 0px 20px 20px;">

			<el-button @click="add()" type="success" size="small">{{ $i18n.t("common.Add1") }}</el-button>

		</div>

    <el-table ref="pagedata" :data="pageData.list" border v-loading="loading"  style="width: 100%">

      <el-table-column prop="fullDiscountId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="title"  :label='$i18n.t("common.name")' align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="countryCode"  :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
          <template slot-scope="scope">
              {{ $dict.getDictValue('countrys',  scope.row.countryCode)  }}
          </template>
      </el-table-column>
      <el-table-column prop="startTime" :label='$i18n.t("common.starttime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column>
			<el-table-column prop="endTime" :label='$i18n.t("common.Endtime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column> 
      <el-table-column prop="activityStatus" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
          <template slot-scope="{ row }">
              <span v-if="row.activityStatus == 'NotStarted'" style="color: black">{{ $i18n.t("common.begininaminute")}}</span>
              <span v-if="row.activityStatus == 'UnderWay'" style="color: green">{{ $i18n.t("common.Inprogress") }}</span>
              <span v-if="row.activityStatus == 'Complete'" style="color: gray">{{ $i18n.t("common.Ended") }}</span>
          </template>       
      </el-table-column>
      <el-table-column prop="createTime" :label=' $i18n.t("common.Creationtime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createName" :label=' $i18n.t("common.Creator")' align="center" show-overflow-tooltip></el-table-column>

      <el-table-column prop="status" :label='$i18n.t("common.Enableornot")'  align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status1"  active-color="#13ce66"  disabled></el-switch>
        </template>
      </el-table-column>
      
      <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
          <template slot-scope="scope">
              <el-button @click="edit(scope.row)" type="text" size="small">{{ $i18n.t("common.edit") }}</el-button>
              <el-button @click="del(scope.row)" type="text" size="small">{{ $i18n.t("common.delete") }}</el-button>
          </template>
      </el-table-column>


    </el-table>



    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="searchData.page"  
        :page-sizes="pageSizes"   @size-change="handleSizeChange"
        :page-size="searchData.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageData.total">
      </el-pagination>
    </div>




    <el-dialog :title='isEdit ? $i18n.t("common.edit")  : $i18n.t("common.Add1") '  :visible.sync="dialogFormVisible" v-if="dialogFormVisible" :close-on-click-modal="false"   >

      <add-update-dialog :isEdit="isEdit"  :dictionary="dictionary" :formdata="formdata" @closeDialog="closeDialog"/>
    </el-dialog>


    
  </div>
</template>
<script>

import AddUpdateDialog from './components/AddUpdateDialog.vue';
import i18n from '@/i18n/i18n.js';
import moment from 'moment';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary={
    // 国家
    country:dictionaryUtil.getDict('countrys'),
    // 满减活动 状态
    activityStatus: [
        {
          id: '1',
          name:i18n.t("common.begininaminute") ,
          code: 'NotStarted'
        },
        {
          id: '2',
          name: i18n.t("common.Inprogress") ,
          code: 'UnderWay'
        },
        {
          id: '3',
          name: i18n.t("common.Ended"),
          code: 'Complete'
        }
    ],
    // 满减活动是否启用
    status: [
      {
        id: '1',
        name: i18n.t("common.yes"),
        value: 1
      },
      {
        id: '2',
        name: i18n.t("common.no"),
        value: 0
      }
    ],
    // 商品上架状态
    state:[{
      id:'1',
      name:i18n.t("common.Listed"),
      value:true
    },{
      id:'2',
      name:i18n.t("common.Notlisted"),
      value:false
    }],
    // 商品是否促销
    promotion:[{
      id:'1',
      name: i18n.t("common.yes"),
      value:true
    },{
      id:'2',
      name: i18n.t("common.no"),
      value:false
    }],

};
export default {
  components: { AddUpdateDialog, moment },
  name: 'fulldiscount_module',
  data() {
    return {
      dictionary:Object.assign({}, defaultDictionary),
      dialogFormVisible: false,
      isEdit: false,
      formdata: {},
      //查询项
      searchData: {
        title: '',
        countryCode:'',
        activityStatus: '',
        status: '',
        page: 1,
        limit: 10
      },
      defaultSearchData: {
        title: '',
        countryCode:'',
        activityStatus: '',
        status: '',
        page: 1,
        limit: 10
      },
      loading: false,
      pageSizes: [10, 20, 30, 50],
      pageData: {}
     
    };
  },

  mounted() {
    this.getGoodsClassifyTree(); 
    this.handleSearchList();
  },
  methods: {
    // 类目
    getGoodsClassifyTree(){
      this.$store.dispatch('getGoodsClassifyTree', {}).then(res => {
          this.$set(this.dictionary,"classifyTree",res)				
          // console.log('this.pageData',defaultDictionary,this.dictionary)
      });
    },
    //搜索列表
    handleSearchList() {
      this.searchData.page = this.defaultSearchData.page
      this.searchData.limit = this.defaultSearchData.limit 
      this.loadlist();
    },
    //重置
    handleResetSearch() {
      this.searchData = Object.assign({}, this.defaultSearchData);
      this.handleSearchList();
    },
    // 点击页数查询
    handleCurrentChange(val) {
      this.searchData.page = val;
      this.loadlist();
    },
    // 点击条数查询
		handleSizeChange(val) {
      this.searchData.limit = val;
      this.loadlist();
    },
    loadlist(){
      this.loading=true
      let temp = JSON.stringify(this.searchData, (key, value) => {
        if (value !== null && value !== '') {
          return value;
        }
      });
      let curSates = JSON.parse(temp);
      this.$store.dispatch('getFullDiscountPage', curSates).then(res => {

          res.list.forEach((item)=>{
              if (item.status == '0') {
                item.status1=false
              } else if(item.status == '1') {
                item.status1=true					
              }
          });
          this.pageData = res  
           
      }).finally(() => {
				this.loading = false;
			});
    },
    // 满减活动 新增
    add(){    
      this.isEdit = false;
      this.dialogFormVisible = true;
    },
    // 满减活动 修改
    edit(row){
      this.isEdit = true;
      this.formdata = row;
      this.dialogFormVisible = true;
    },
    // 满减活动 删除
    del(row){
      var _this = this;
      this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
          // confirmButtonText: '确定',
          // cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          this.$store.dispatch('deleteFullDiscount', {fullDiscountId: row.fullDiscountId}).then(res => { 
              let totalPage = Math.ceil((this.searchData.total - 1) / this.searchData.limit)
              let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
              this.searchData.page = currentPage < 1 ? 1 : currentPage
              this.$message.success(  this.$i18n.t("common.Successfullydeleted"));
              this.loadlist();
          });
      }).catch(() => {
      });
    },
    // 满减活动 弹框关闭
    closeDialog(status,isEdit){
      this.dialogFormVisible = false; 
      if(status){
          if(isEdit){
              this.loadlist();
          } else {
              this.handleSearchList();
          }   
      }
      this.getGoodsClassifyTree();
    },
    /**
    * 时间戳转码
    */
    formatDate(row, column) {
        // 获取单元格数据
        let data = row[column.property];
        if (!data) {
            return '';
        } else {
            return moment(data).format('YYYY-MM-DD HH:mm:ss');
        }
    }
        
  }
 
};

</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}


</style>