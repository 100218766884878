<template>
    <el-radio-group v-bind='$attrs' v-on='$listeners'>
        <el-radio v-for='cc in brandDicts' :key='cc.code' :label='cc.code' :value='cc.code'>{{cc.name}}</el-radio>
    </el-radio-group>
</template>

<script>
export default {
    props: {
        codeType: {
            type: String
        }
    },
    data() {
        return {
            //字典下拉框
            brandDicts: []
        };
    },
    computed: {
        selectedValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    created() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            let _this = this;
            this.$store.dispatch('getDicItems', { tag: this.codeType }).then(res => {
                if (res != null) {
                    _this.brandDicts = res;
                }
            });
        }
    }
};
</script>

<style></style>
