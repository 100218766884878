<template>
    <div>

        <span style="font-weight: bold;">{{ $i18n.t('common.GlobalConfiguration') }}</span>
        <el-form
                ref="dataForm"
                label-position="left"
                size="small"
                label-width="110px"
                style="margin-left: 50px"
        >

            <el-tabs v-model='activeName'>
                <el-tab-pane v-for='(item,index) in langsArr' :key='index' :label='item.langName' :name='item.code'>
                    <div>
                        <el-form-item :label='$i18n.t("common.PromotionalPpicture")' prop="imgUrl">

                            <imgUpload ref="imageUpload" :imgMessage="item.imageUrl" :imglimit="1" :imgSize="1" :countryCode="item.lang" :imgUrlList="item.imgUrlList"  @event="changeUrl"></imgUpload>

                        </el-form-item>
                    </div>
                </el-tab-pane>
            </el-tabs>

        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{ $i18n.t('common.cancel') }}</el-button>
            <el-button type="primary" @click="saveGiftActivityPublicityChart">{{ $i18n.t('common.confirm1') }}</el-button>
        </div>

    </div>
</template>


<script>

import imgUpload from './imgUpload';
import dictionaryUtil from '@/utils/dictionaryUtil';


const defaultDictionary = {
    // 国家
    countrys: dictionaryUtil.getDict('countrys')
};


export default {
    components: { imgUpload },
    name: 'giftActivityPublicityChart',
    data() {
        return {

            dictionary: Object.assign({}, defaultDictionary),
            langsArr: [],
            activeName: '0'
        }
    },
    created() {

        this.initLangsArr();

    },
    methods: {

        /**
         * 重置多语言
         */
        initLangsArr() {

            this.$store
                .dispatch('listGiftActivityPublicityChar')
                .then((res) => {

                    this.langsArr = [];
                    for (let item of this.dictionary.countrys) {
                        let param = {
                            lang: item.code,
                            langName: item.name,
                            imageUrl: null,
                            imgUrlList: []
                        };
                        this.langsArr.push(param);
                    }

                    this.langsArr.forEach(x => {

                        res.forEach(x2 => {

                            if (x.lang === x2.countryCode && (x2.imageUrl !== 'undefined' && x2.imageUrl != null && x2.imageUrl !== '')) {
                                x.imageUrl = x2.imageUrl;
                                x.imgUrlList = [x2.imageUrl];
                            }
                        })
                    })
                })
                .finally(() => {

                });
        },
        saveGiftActivityPublicityChart() {

            var params = [];

            this.langsArr.forEach(x => {

                params.push({

                    imageUrl: x.imageUrl,
                    countryCode: x.lang
                })
            })

            this.$store.dispatch('saveGiftActivityPublicityChart', params).then(() => {
                this.$message({
                    message: this.$i18n.t("common.Successfullysaved"),
                    type: 'success'
                });
                this.closeDialog();
            });

        },
        closeDialog() {

            this.$emit('closeGiftActivityPublicityChart');
        },
        changeUrl(data) {

            this.langsArr.forEach(x => {

                if (x.lang === data.countryCode) {

                    if (data.imgUrlList.length > 0) {
                        x.imageUrl = data.imgUrlList[0];
                    }else {
                        x.imageUrl = '';
                    }
                }
            });
        }

    }

};



</script>

<style>

    .dialog-footer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>