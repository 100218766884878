<template>
    <div style="height: 100%;min-height:350px;" class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <div style="padding: auto">
                    <el-form-item>
                        <el-input v-model="query.name" clearable :placeholder='$i18n.t("common.name")'  style="width: 200px" class="filter-item" @keyup.enter.native="handleQuery" />
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                            style="width: 180px;"
                            class="filter-item"
                            v-model="query.countryCode"
                            clearable
                            :codeType="'countrys'"
                            :placeholder='$i18n.t("common.country")'
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>

                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">   {{ $i18n.t("common.query") }} </el-button>

                        <el-button
                            class="filter-item"
                            icon="el-icon-search"
                            type="primary"
                            @click="cleanQuery"
                            style="margin-left: 10px"
                            size="small "
                        >
                        {{ $i18n.t("common.Reset") }}
                        </el-button>
                    </el-form-item>
                    <div style="margin-bottom:10px">

                        <el-button class="filter-item" type="success" @click="handleCreate" style="margin-top: 0px;">   {{ $i18n.t("common.Add1") }}</el-button>

                    </div>
                </div>
            </el-form>

        </div>

        <el-table height="calc(100% - 160px)" v-loading="listLoading" :data="list" @selection-change="selectionChange" border stripe style="width: 100%">
            <el-table-column  :label='$i18n.t("common.number") ' prop="sourceUrlId" width="180px" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.sourceUrlId }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.name") ' prop="name" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column  :label='$i18n.t("common.country")' prop="countryCode" align="center">
                <template slot-scope="scope">
                    <div>{{$dict.getDictValue('countrys',scope.row.countryCode)}}</div>
                </template>
            </el-table-column>

            <el-table-column :label='$i18n.t("common.URLClickToCopyAddress")' prop="fixedUrl" align="center" width="350px">
                <template slot-scope="{ row }">
                    <span @click="copyUrl(row.sourceUrl)">{{ row.sourceUrl }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Remarks")' prop="remark" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.remark }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Creationtime")' prop="createTime" align="center" width="140px">
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Creator")' prop="createByName" align="center" width="150px">
                <template slot-scope="{ row }">
                    <span>{{ row.createByName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" width="160">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">{{ $i18n.t("common.edit") }}</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">{{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>

        <!-- 新建编辑表单 -->
        <addOrUpdate ref="sourceUrlAdd" @created="doQueryList({ pageNo: 1 })" />

    </div>
</template>

<script>
import addOrUpdate from './components/addUpdateDialog.vue';
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';

export default {
    name: 'SourceUrl',
    components: {
        Pagination,
        DataSelect,
        addOrUpdate
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                size: 10,
                name: null,
                countryCode: null,
            },
            selectItems: [],
        };
    },
    created() {
        this.doQueryList({ pageNo: 1 });
    },
    methods: {
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getSourceUrlPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$confirm(this. $i18n.t("common.Areyousuretocontinuedeletingdata"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteSourceUrl', row.sourceUrlId).then((res) => {
                     this.$message({
                            type: 'success',
                            message:   this.$i18n.t("common.Successfullydeleted")
                        });
                    this.doQueryList({ pageNo: 1 });
                });
            });
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            this.query=[];
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.$refs.sourceUrlAdd.handleCreate();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.$store.dispatch('getSourceUrl', row.sourceUrlId).then((res) => {
                this.$refs.sourceUrlAdd.handleUpdate(res);
            });
            
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        copyUrl(url) {
            let copyInput = document.createElement("input");
            //val是要复制的内容
            copyInput.value=url
            document.body.appendChild(copyInput);
            copyInput.select();
            let copyed = document.execCommand("copy");
            if (copyed) {
                document.body.removeChild(copyInput);
                this.$message.success( this.$i18n.t("common.Successfullycopied"));
            }
        }
    }
};
</script>

<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>
