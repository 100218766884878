<template> 
	<div>
		<div class="flex-between">
			<div style="display: flex;justify-content: flex-start;align-items: center;">
				<el-input v-model="title" :placeholder="$i18n.t('common.Categoryname1')"></el-input>
				<el-button type="primary" @click='handleSearchList2' size="small" style="margin-left: 20px;">{{ $i18n.t('common.query') }}</el-button>
			</div>
			<div>
				<el-button type="success" size="small" style="margin-left: 20px;" @click='addRelation'>{{ $i18n.t('common.addassociations') }}</el-button>
			</div>
		</div>
		<div class="header-container">
			<span>{{ $i18n.t('common.Maincategory') }}:{{rowInfo.title}}</span>
		</div>
		<el-table ref="pagedata" :data="pageData.list" border style="width: 100%;margin-top: 20px;">
			<el-table-column prop="goodClassifyId" :label="$i18n.t('common.ProductCatalogID')" align="center" show-overflow-tooltip> </el-table-column>
			<el-table-column prop="title" :label="$i18n.t('common.RelatedCategoryName')" align="center" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="createTime" :label="$i18n.t('common.Addtime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.createTime | formatDate}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$i18n.t('common.operation')" align="center">
				<template slot-scope="scope">
					<el-button @click="handelete(scope.row)" type="text" size="small">{{ $i18n.t('common.remove') }}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handleCurrentChange" :current-page="listQuery2.page"
			:page-size="listQuery2.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
		</el-pagination>
		<el-dialog :title="$i18n.t('common.AddAssociatedCategory')" :visible.sync="addRelationVisible" width="35%"  append-to-body>
			<div v-if="addRelationVisible">

				<el-form ref="form"  label-width="120px">
					<el-form-item :label="$i18n.t('common.Selectmaincategory')">

						<el-tree ref="tree"
							:data="treeData"
							check-strictly 
							show-checkbox node-key="module_id" 
							:props="defaultProps"
						>
						</el-tree>
					</el-form-item>
				</el-form>				
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 20%;" @click="addRelationVisible = false">{{ $i18n.t('common.cancel') }}</el-button>
				<el-button style="width: 20%;" type="primary" @click="postCheckedKeys">{{ $i18n.t('common.confirm1') }}</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	import FilterContainer from '@/components/common/FilterContainer.vue';
	export default {
		components: {
			FilterContainer
		},
		props: {
			rowInfo:{
				type: Object,
				default: () => {
					return {}
				}
			},
			treeData:{
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				pageData:{},
				formLabelWidth: '120px',
				form: {},
				addRelationVisible: false,
				activeIndex: null,
				title:"",
				//组件标签是否显示
				arg: {
					code: true,
					country: true,
					status: true
				},
				listQuery2:{
					"relatedClassifyId": '',
					"title": "",
					"brandCode": "",
					"page": 1,
					"limit": 10
				},
				defaultListQuery:{
				  "relatedClassifyId": "",
				  "title": "",
				  "brandCode": "",
				  "page": 1,
				  "limit": 10
				},
				defaultProps:{
					label:"title",
					id:"goodsClassifyId",
					children:"children",
					/*disabled:function(data,node){
					if(data.parentId==0){
							return true
					    }else{
					        return false            
					    }        
					} */
				},
				nameRules: {
					name: [{
						required: true,
						message: this.$i18n.t('common.Pleaseentertheproducttitle'),
						trigger: 'blur'
					}]
				},
				//选中数量
				rules: {
					brand: [{
						required: true,
						message: this.$i18n.t('common.Pleaseselectabrand'),
						trigger: 'change'
					}],
					category: [{
						required: true,
						message: this.$i18n.t('common.Pleaseselectacategory'),
						trigger: 'change'
					}],
				},
			};
		},
		methods: {
			//关联
			addRelation() {
				this.addRelationVisible = true
                this.resetDisabled(this.treeData)
                this.$forceUpdate()
			},
            // 关联类目树设置禁用 1、第一级禁用 2、自己禁用
            resetDisabled(nodes){
                if(nodes && nodes.length > 0){
                    nodes.forEach(item => {
                        // 保留之前的逻辑
                        let disabled = false
                        if(item.parentId==0){
                            disabled = true
                        }

                        if(this.rowInfo.goodClassifyId == item.goodsClassifyId){
                            disabled = true
                        }

                        this.$set(item, 'disabled', disabled)
                            this.resetDisabled(item.children)
                        })
                }
            },
			//搜索列表
			handleSearchList2(){			
				if(this.rowInfo){				
					const listQuery={
						"title":"",
						"relatedClassifyId":this.rowInfo.goodsClassifyRelatedPrivatelyId,
						"page": this.listQuery2.page,
						"limit": this.listQuery2.limit
					}
					listQuery.title=this.title
					this.$store
						.dispatch('getCategoryAssociation',listQuery)
						.then(res => {					
							this.pageData=res							
						});				
				}				
				
			},
			handleCurrentChange(val) {
				this.listQuery2.page = val;
				this.handleSearchList2();
			},
			closeDialog(){
				this.$emit('closeDialog')
			},
			//新增主类目
			postCheckedKeys(){
				if(this.$refs.tree.getCheckedNodes()){
					if(this.$refs.tree.getCheckedNodes().length<=0){
						this.$message({
						  message: this.$i18n.t('common.Pleaseselectacategoryfirst1'),
						  type: 'warning'
						});
						return	
					}					
				}				
				let goodClassify=this.$refs.tree.getCheckedNodes()
				console.log('goodClassify',goodClassify)
				console.log('this.rowInfo',this.rowInfo)

                for (let i = 0; i < goodClassify.length; i++) {
                    if(this.rowInfo.goodClassifyId === goodClassify[i].goodsClassifyId){
                        this.$message({
                            message: this.$i18n.t("common.CantRelateToYourself"),
                            type: 'warning'
                        });
                        return
                    }
                }

				let data=[]
				data=goodClassify.map((item)=>{
					return {
						goodClassifyId:item.goodsClassifyId,
						relatedClassifyId:this.rowInfo.goodsClassifyRelatedPrivatelyId
					}
				})
				this.$store.dispatch('postBatch', data).then(res => {
					if(res==""){
						this.$message({
							message: this.$i18n.t('common.Successfullyadded'),
							type: 'success'
						});
					}
					this.addRelationVisible=false
					this.handleSearchList2()
				}).catch((err) => {
						this.$message({
							type: 'info',
							message: err.message
						});
				});					
			},
			handelete(row){
				this.$confirm(this.$i18n.t('common.confirmdeletion')+`?`, this.$i18n.t('common.prompt'), {
						confirmButtonText: this.$i18n.t('common.confirm1'),
						cancelButtonText: this.$i18n.t('common.cancel'),
						type: 'warning'
					})
					.then(() => {
					this.$store.dispatch('deletePrivately', {
						id:row.goodsClassifyRelatedPrivatelyId,
					}).then(res => {
						this.handleSearchList2()
					})	
				})	
			},
		},
		mounted(){
			this.handleSearchList2()
		}
	};
</script>
<style scoped>
	.header-container {
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		line-height: normal;
		color: #626262;
		padding: 10px 0px;
	}

	.input-width {
		width: 30%;
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}

	.prpa {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.prpa-sub {
		border: 1px solid #000000;
		width: 80%;
	}

	.content1 {
		width: auto;
		height: 100%;
		overflow-y: scroll;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.content-sub-left {
		cursor: pointer;
		position: relative;
		background-color: #ececec;
		width: 40%;
	}

	.content-sub-right {
		width: 60%;
	}

	.content-sub-item {
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		padding: 10px 0px 10px 10px;
	}

	.active::before {
		content: '';
		position: absolute;
		border-left: 4px solid #55aaff;
		height: 30px;
		left: 0px;
	}

	.active {
		font-weight: 900;
		font-style: normal;
		font-size: 20px;
		background-color: #46a8a0;
		color: white;
	}

	.content2 {
		width: auto;
		height: 100%;
	}

	.content2-sub {
		font-size: 15px;
		color: #464646;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.custom-tree-node {
	    flex: 1;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    font-size: 14px;
	    padding-right: 8px;
	  }
</style>