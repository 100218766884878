<template>
    <div>
        <el-form :model="formItem" :rules="rules" ref="variantFrom">

<!--            <el-form-item label="品牌" prop="brandCode" :label-width="formLabelWidth">

                <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="formItem.brandCode"
                        clearable
                        :codeType="'brand'"
                        :placeholder='$i18n.t("common.brand")'
                        @change="treeChange"
                ></dataSelect>

            </el-form-item>-->
            <el-form-item :label='$i18n.t("common.country")' prop="countryCode" :label-width="formLabelWidth">

                <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="formItem.countryCode"
                        clearable
                        :codeType="'countrys'"
                        @change="treeChange"
                        :placeholder='$i18n.t("common.country")'
                ></dataSelect>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Recommendedlocation")' prop="position" :label-width="formLabelWidth">
                <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="formItem.position"
                        clearable
                        :codeType="'position'"
                        :placeholder='$i18n.t("common.Recommendedlocation")'
                ></dataSelect>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Recommendationmodule")' prop="recommendModule" v-if="formItem.position == 'HomePage'"
                          :label-width="formLabelWidth">
                <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="formItem.recommendModule"
                        clearable
                        :codeType="'recommend_module'"
                        :placeholder='$i18n.t("common.Recommendationmodule")'
                ></dataSelect>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Pleaseselectacategory")' prop="category" :label-width="formLabelWidth"
                          v-if="formItem.position == 'Classify'">
                <el-tree :props="defaultProps" :data="treeData" node-key="goodsClassifyId" ref="treeForm"
                         @node-click="handleBucketClick" accordion
                         :expand-on-click-node="false">
          <span class="show-hide custom-tree-node" slot-scope="{ node, data }">
            <span>
                   <el-radio v-model="formItem.goodClassId" :label="data.goodsClassifyId"><br></el-radio>
            {{ node.label }}
            </span>
          </span>
                </el-tree>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Enableornot")' prop="status" :label-width="formLabelWidth">
                <el-switch v-model="formItem.status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%;" @click="closeDialog(false)"> {{ $i18n.t("common.cancel") }}</el-button>
            <el-button style="width: 20%;" type="primary" @click="onSubmit('variantFrom')"> {{ $i18n.t("common.confirm") }}</el-button>
        </div>
        <!-- <el-dialog title="选择变体参数" :visible.sync="paraVisible" width="35%" append-to-body>
            <div class="flex-center">
                <el-tree :props="defaultProps" show-checkbox @check-change="handleCheckChange"> </el-tree>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button style="width: 20%;" @click="centerDialogVisible = false">取 消</el-button>
                <el-button style="width: 20%;" type="primary" @click="centerDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>
<script>
import FilterContainer from '@/components/common/FilterContainer.vue';
import dataSelect from '@/components/common/DataSelect.vue';

export default {
    components: {
        FilterContainer,
        dataSelect
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            formLabelWidth: '120px',
            activeIndex: null,
            treeData: [],
            rules: {
                brandCode: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectabrand"),
                        trigger: 'change'
                    }
                ],
                countryCode: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectacountry"),
                        trigger: 'change'
                    }
                ],
                position: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselecttherecommendedlocation"),
                        trigger: 'change'
                    }
                ], recommendModule: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselecttherecommendedmodule"),
                        trigger: 'change'
                    }
                ]
            },
            checkedPrpa: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            count: 1
        };
    },
    created() {
        this.initTree(this.$tenantBrand.getBrand(), this.formItem.countryCode);
    },
    methods: {
        initTree(brandCode, countryCode) {
            this.$store.dispatch('getClassTree', {
                status: 1,
                // brandCode: brandCode,
                countryCode: countryCode
            }).then(res => {
                this.treeData = res;
            });
        },
        treeChange() {
            this.formItem.goodClassId = null;
            this.initTree(this.$tenantBrand.getBrand(), this.formItem.countryCode);
        },
        closeDialog(status) {
            if (this.$refs['variantFrom'] !== undefined) {
                console.log(1111)
                this.$refs['variantFrom'].resetFields();
            }
            this.$emit('closeDialog', status);
        },
        append(data) {
            const newChild = {
                id: id++,
                label: 'testtest',
                children: []
            };
            if (!data.children) {
                this.$set(data, 'children', []);
            }
            data.children.push(newChild);
        },

        remove(node, data) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1);
        },
        handleBucketClick(row) {
            this.formItem.goodClassId = row.goodsClassifyId
        },
        //提交
        onSubmit(formName) {
            if (!this.formItem.goodClassId && this.formItem.position === 'Classify') {
                this.$message.error(this.$i18n.t("common.Pleaseselectacategory"));
                return;
            }
            if(this.formItem.position === 'Classify'){
                this.formItem.recommendModule = null;
            }
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),   this.$i18n.t("common.prompt"), {
                        // confirmButtonText: '确定',
                        // cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (this.isEdit) {
                            this.$store.dispatch('updateRecommendModule', this.formItem).then(response => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullymodified"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true);
                            });
                        } else {
                            this.$store.dispatch('addRecommendModule', this.formItem).then(response => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullysubmitted"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true);
                            });
                        }
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
