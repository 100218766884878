var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _vm.classList && _vm.classList.length > 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Class"),
                    prop: "classifyId",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Pleaseselectacategory")
                      },
                      model: {
                        value: _vm.formListQuery.classifyId,
                        callback: function($$v) {
                          _vm.$set(_vm.formListQuery, "classifyId", $$v)
                        },
                        expression: "formListQuery.classifyId"
                      }
                    },
                    _vm._l(_vm.classList, function(item) {
                      return _c("el-option", {
                        key: item.goodsClassifyId,
                        attrs: {
                          label: item.title,
                          value: item.goodsClassifyId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "flex",
                "justify-content": "center",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                    width: "80%"
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$i18n.t("common.Selectparameters")))
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.Selected")))])
                ]
              )
            ]
          ),
          _c("div", { staticClass: "prpa" }, [
            _c(
              "div",
              { staticClass: "prpa-sub" },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { margin: "0px" },
                    attrs: {
                      gutter: 20,
                      type: "flex",
                      justify: "space-between"
                    }
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "content1-box",
                        staticStyle: { padding: "0px" },
                        attrs: { span: 16 }
                      },
                      [
                        _c("div", { staticClass: "content1" }, [
                          _c(
                            "div",
                            { staticClass: "content-sub-left" },
                            _vm._l(_vm.options, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "content-sub-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.getActiveIndex(item, index)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        active:
                                          _vm.activeIndex == index
                                            ? true
                                            : false
                                      }
                                    },
                                    [
                                      _vm._v(_vm._s(item.title)),
                                      item.parameterItems &&
                                      item.parameterItems.length > 0
                                        ? _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  item.parameterItems.length
                                                ) +
                                                ")"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm.optionsItemList.length > 0
                            ? _c(
                                "div",
                                { staticClass: "content-sub-right" },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: { change: _vm.changeCheckList },
                                      model: {
                                        value: _vm.checkedPrpa,
                                        callback: function($$v) {
                                          _vm.checkedPrpa = $$v
                                        },
                                        expression: "checkedPrpa"
                                      }
                                    },
                                    _vm._l(_vm.optionsItemList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.parameterItemId,
                                          staticStyle: {
                                            padding: "10px 0px 10px 10px"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: { label: item },
                                              on: {
                                                change: function($event) {
                                                  return _vm.handleChecked(
                                                    item,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.title) +
                                                  " " +
                                                  _vm._s(item.source)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                ],
                                1
                              )
                            : _vm.optionsItemList.length <= 0
                            ? _c(
                                "div",
                                { staticClass: "content-sub-right" },
                                [
                                  _c("el-empty", {
                                    attrs: { "image-size": 200 }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "content2-box",
                        staticStyle: { padding: "0px 10px 0px 10px" },
                        attrs: { span: 8 }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "content2" },
                          _vm._l(_vm.checkedPrpa2, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: item.parameterItemId,
                                staticClass: "content2-sub"
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "80%",
                                      "font-size": "14px",
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    },
                                    attrs: { type: "primary" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.title) +
                                        " " +
                                        _vm._s(item.source)
                                    )
                                  ]
                                ),
                                _c("el-button", {
                                  attrs: {
                                    type: "info",
                                    icon: "el-icon-close"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }