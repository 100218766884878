<template>
    <div>
        <el-form :model="formListQuery" :rules="rules" ref="variantFrom">
            <el-form-item v-if="classList && classList.length>0" :label="$i18n.t('common.Class')" prop="classifyId" :label-width="formLabelWidth">
                <el-select v-model="formListQuery.classifyId" :placeholder="$i18n.t('common.Pleaseselectacategory')">
                    <el-option v-for="item in classList" :key="item.goodsClassifyId" :label="item.title" :value="item.goodsClassifyId">
                    </el-option>
                </el-select>
            </el-form-item>
            <div style="width: 100%;display: flex;justify-content: center;margin-bottom:20px;">
                <div style="display: flex;justify-content: space-between;align-items: center;width: 80%;">
                    <span>{{ $i18n.t('common.Selectparameters') }}</span>
                    <span>{{ $i18n.t('common.Selected') }}</span>
                </div>         
            </div>
            <div class="prpa">           
                <div class="prpa-sub">
                    <el-row :gutter="20" type="flex" justify="space-between" style="margin: 0px;">
                        <el-col :span="16" class="content1-box" style="padding: 0px;">
                            <div class="content1">
                                <div class="content-sub-left">
                                    <div
                                        class="content-sub-item"
                                        @click="getActiveIndex(item, index)"
                                        v-for="(item, index) in options"
                                        :key="index"
                                    >
                                        <span :class="{ active: activeIndex == index ? true : false }">{{ item.title }}<span v-if="item.parameterItems && item.parameterItems.length>0">({{item.parameterItems.length}})</span></span>
                                    </div>
                                </div>
                                <div class="content-sub-right" v-if="optionsItemList.length > 0">
                                    <el-checkbox-group v-model="checkedPrpa" @change="changeCheckList">
                                        <div
                                            style="padding: 10px 0px 10px 10px;"
                                            v-for="(item, index) in optionsItemList"
                                            :key="item.parameterItemId"
                                        >
                                            <el-checkbox :label="item" @change="handleChecked(item, index)">{{ item.title }} {{item.source}}</el-checkbox>
                                        </div>
                                    </el-checkbox-group>
                                </div>
                                <div class="content-sub-right" v-else-if="optionsItemList.length <= 0">
                                    <el-empty :image-size="200"></el-empty>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8" class="content2-box" style="padding: 0px 10px 0px 10px;">
                            <div class="content2">
                                <div class="content2-sub" v-for="(item, index) in checkedPrpa2" :key="item.parameterItemId">
                                    <el-button style="width: 80%;font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" type="primary"
                                        >{{ item.title }} {{item.source}}</el-button>
                                    <el-button type="info" icon="el-icon-close"  @click="remove(item)"></el-button>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%;" @click="closeDialog">{{ $i18n.t("common.cancel") }}</el-button>
            <el-button style="width: 20%;" type="primary" @click="onSubmit('variantFrom')">{{ $i18n.t("common.confirm") }}</el-button>
        </div>
        <!-- <el-dialog title="选择变体参数" :visible.sync="paraVisible" width="35%" append-to-body>
			<div class="flex-center">
				<el-form :model="titleList" ref="titleFrom" :rules="rules" label-width="100px">
					<el-form-item v-for="(item,index) in titleList" :key="item.id" :label="item.name" prop="name">
						<el-input v-model="item.value" placeholder="请输入商品标题" autocomplete="off"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 20%;" @click="centerDialogVisible = false">取 消</el-button>
				<el-button style="width: 20%;" type="primary" @click="centerDialogVisible = false">确 定</el-button>
			</span>
		</el-dialog> -->
    </div>
</template>
<script>
import FilterContainer from '@/components/common/FilterContainer.vue';
export default {
    components: {
        FilterContainer
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        dictionary: {
            //字典
            type: Object,
            default: () => {
                return {};
            }
        },
        formListQuery: {
            type: Object,
            default: () => {
                return {};
            }
        },
        checkedPrpa2: {
            type: Array,
            default: () => {
                return [];
            }
        },
        classList: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            formLabelWidth: '120px',
            form: {},
            activeIndex: null,
            //组件标签是否显示
            arg: {
                code: true,
                country: true,
                status: true
            },
            paraVisible: true,
            //查询项
            searchData: {
                searchStr: '',
                saleCountry: [],
                platformCode: [],
                enabled: null,
                arrayDate: [],
                page: 1,
                limit: 10
            },
            //选中数量
            rules: {
                /*brandCode: [
                    {
                        required: true,
                        message: '请选择品牌',
                        trigger: 'change'
                    }
                ],*/
                classifyId: [
                    {
                        required: true,
                        message: this.$i18n.t('common.Pleaseselectacategory'),
                        trigger: 'change'
                    }
                ]
            },
            options: [],
            optionsItemList: [],
            checkedPrpa: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            count: 1
        };
    },
    mounted() {
        this.getVarAdGroupleft();
        //this.changeBrandCode();
        if (this.isEdit == true) {
            this.checkedPrpa2.forEach(item => {
                this.checkedPrpa.push(item);
            });
        }
    },
    methods: {
        changeCheckList(e) {
            this.$forceUpdate();
        },
        //根据品牌找类目
        changeBrandCode() {
            this.$store
                .dispatch('postVarAdSearchClassify', {
                    //brandCode: this.formListQuery.brandCode,
                    level:1
                })
                .then(res => {
                    //this.classList = res;
                    this.$emit("classList", res)
                });
        },
        //参数组左
        getVarAdGroupleft() {
            this.$store.dispatch('getVarAdGroupleft', {}).then(res => {
                this.options = res;
            });
        },
        //上移
        upMove(index, row) {
            if (index <= 0) {
                this.$message.error(this.$i18n.t('common.CannotContinueToMoveUp'));
            } else {
                const upData = this.pageData.list[index - 1];
                this.pageData.list.splice(index - 1, 1);
                this.pageData.list.splice(index, 0, upData);
            }
        },
        //下移
        upDown(index, scope) {
            console.log('1', scope);
            if (index === this.pageData.list.length - 1) {
                this.$message.error(this.$i18n.t('common.CannotContinueToMoveDown'));
            } else {
                const downData = this.pageData.list[index + 1];
                this.pageData.list.splice(index + 1, 1);
                this.pageData.list.splice(index, 0, downData);
            }
        },
        //选中列表
        handleChecked(val, index) {
			if(this.checkedPrpa2.length<=0){
				this.checkedPrpa2=JSON.parse(JSON.stringify(this.checkedPrpa))
			}else{
				let falg=true
				this.checkedPrpa2.forEach((item,index)=>{
					if(item.parameterItemId==val.parameterItemId){
						this.checkedPrpa2.splice(index,1)
						falg=false
					}	
				})
				if(falg){
					this.checkedPrpa2.push(val)
				}
			}
        },
        handleCheckChange(data, checked, indeterminate) {
            console.log(data, checked, indeterminate);
        },
        getActiveIndex(item, index) {
            this.activeIndex = index;
            this.$store
                .dispatch('getVarAdGroupitem', {
                    parameterGroupId: item.parameterGroupId
                })
                .then(res => {
					let arr=[]
                    this.optionsItemList = res;
					res.forEach((item,index)=>{
						this.checkedPrpa2.forEach((item2,index2)=>{
							if(item.parameterItemId==item2.parameterItemId){
								arr.push(item)
							}
						})
					})
					
					this.checkedPrpa=arr;
                });
        },
        remove(row) {
            this.checkedPrpa.forEach((item,index)=>{
					if(item.parameterItemId==row.parameterItemId){
						this.checkedPrpa.splice(index,1)
					}	
			})
            this.checkedPrpa2.forEach((item,index)=>{
					if(item.parameterItemId==row.parameterItemId){
						this.checkedPrpa2.splice(index,1)
					}	
			})
        },
        closeDialog() {
            this.$emit('addcloseDialog');
        },
        handleSearchList() {
            this.$emit('handleSearchList');
        },
        //提交
        onSubmit(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {
                        if(this.checkedPrpa2.length<=0){
                            this.$message.error(this.$i18n.t('common.Pleaseselectparameters'));
                            return
                        }
                        if (this.isEdit) {
                            this.formListQuery.parameterReqList = this.checkedPrpa2.map(item => {
                                return {
                                    classifyParameterId: item.parameterGroupId,
                                    parameterId: item.parameterItemId,
                                    parameterName: item.title
                                };
                            });
                            this.$store.dispatch('postVarAdput', this.formListQuery).then(res => {
                                this.$message({
                                    message: this.$i18n.t('common.Successfullysubmitted'),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog();
                                this.handleSearchList();
                            });
                        } else {
                            this.formListQuery.parameterReqList = this.checkedPrpa2.map(item => {
                                return {
                                    classifyParameterId: item.parameterGroupId,
                                    parameterId: item.parameterItemId,
                                    parameterName: item.title
                                };
                            });
                            this.$store.dispatch('postVarAdput', this.formListQuery).then(res => {
                                this.$message({
                                    message: this.$i18n.t('common.Successfullysubmitted'),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog();
                                this.handleSearchList();
                            });
                        }
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t('common.Verificationfailed'),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
	width: 100%;
}

.prpa-sub {
    border: 1px solid rgba(234, 234, 234, 1);
    width: 80%;    
}
.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;   
    display: flex;   
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    height: 100%;
    background-color: #ececec;
    padding: 0px 0px 0px 10px;
    width: 40%;
}

.content-sub-right {
    width: 60%;
    height: 100%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    background-color: #55aaff;
    color: white;
}
.content2 {
    width: 100%;
}

.content2-sub {
    font-size: 15px;
    line-height: 15px;
    margin: 10px 0px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>