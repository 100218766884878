<template>
    
    <div>
        <el-form :model="formItem" :rules="rules" ref="variantFrom">

            <el-form-item :label="$i18n.t('common.Enableornot')"  :label-width="formLabelWidth">
                <el-switch v-model="formItem.status"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
            </el-form-item>


            <el-form-item :label="$i18n.t('common.country')"   prop="countryCode" :label-width="formLabelWidth">
                <el-select v-model="formItem.countryCode" :placeholder="$i18n.t('common.Pleaseselectacountry')"  @change="changeCountryCode">
                    <el-option v-for="(item, index) in dictionary.country" :key="index" :label="item.name" :value="item.code"> </el-option>
                </el-select>
            </el-form-item>

            <el-form-item :label="$i18n.t('common.RebateDimension')"  prop="rebateDimension" :label-width="formLabelWidth">
                <el-select v-model="formItem.rebateDimension" :placeholder="$i18n.t('common.PleaseSelectARebateDimension')"  @change="changeRebateDimension">
                    <el-option v-for="(item, index) in dictionary.rebateDimension" :key="index" :label="item.name" :value="item.code"> </el-option>
                </el-select>
            </el-form-item>
       
            <el-form-item :label="$i18n.t('common.Activitytime')"   prop="time" :label-width="formLabelWidth">
                <el-date-picker
                    v-model="formItem.time"
                    clearable
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    :range-separator="$i18n.t('common.to')"  
                    :start-placeholder="$i18n.t('common.Startdate')"
                    :end-placeholder="$i18n.t('common.Enddate')"
 
                >
                </el-date-picker>
            </el-form-item>


            <el-form-item :label="$i18n.t('common.Rebateratio')" prop="shoppingRebateId" :label-width="formLabelWidth">
                <el-select v-model="formItem.shoppingRebateId" :placeholder="$i18n.t('common.PleaseSelectARebateRatio')"  >
                    <el-option v-for="(item, index) in dictionaryRates" :key="index" :label="item.rates+'%'" :value="item.shoppingRebateId"> </el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="formItem.rebateDimension == 'country'" :label="$i18n.t('common.RebateObject')"   prop="rebateObjectCountry" :label-width="formLabelWidth" class="must">
                <el-select v-model="formItem.rebateObjectCountry" :placeholder="$i18n.t('common.PleaseSelectARebateObject')" >
                    <el-option v-for="(item, index) in dictionaryRebateObjectCountry" :key="index" :label="item.name" :value="item.code"> </el-option>
                </el-select>
            </el-form-item>  


            <el-form-item v-if="formItem.rebateDimension == 'classify'"  :label="$i18n.t('common.RebateObject')"   :label-width="formLabelWidth" class="must">
                <el-tree ref="tree" :props="classifyTreeProps" :data="dictionaryClassifyTree" node-key="goodsClassifyId" show-checkbox
                :default-checked-keys="checkedKeys"  :check-on-click-node="true" :check-strictly="true"
                @check="treeCheck" >
				</el-tree>
            </el-form-item>  
  
          
            <el-form-item v-if="formItem.rebateDimension == 'goods'" :label="$i18n.t('common.RebateObject')"   :label-width="formLabelWidth" class="must">
                <template>
                    <el-button type="success" @click="changeGoods">{{ $i18n.t("common.Selectproduct") }}  </el-button>                                    
                </template>
            </el-form-item>  

            <el-table v-if="formItem.rebateDimension == 'goods'" :data="tableList" ref="tableList" style="width: 100%" border >
                
                <el-table-column prop="goodsId"  :label="$i18n.t('common.ProductID')"   align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="goodsCode" :label="$i18n.t('common.SKU_EAN_Encoding')"   align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>SKU:{{ scope.row.sku }}</span
                        ><br/>
                        <span>EAN:{{ scope.row.barCode }}</span
                        ><br/>
                        <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="imageUrl" :label="$i18n.t('common.Productimage1')"  align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image
                                style="height: 100px"
                                v-if="scope.row.imageUrl"
                                :src="ossHost + scope.row.imageUrl"
                                fit="fill"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="title" :label="$i18n.t('common.ProductName')"  align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="goodsSpecials" :label="$i18n.t('common.Countrieslisted')"  align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsSpecials" :key="s.countryCode">
                            <span v-if="s.shelf==true"> {{ $dict.getDictValue('countrys', s.countryCode) }}<br/> </span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="prices" :label="$i18n.t('common.inventory')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                            {{ s.countryCode }}:{{ s.ecomRemainQty }}<br/>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="packageQuantity" :label="$i18n.t('common.PackingQuantity')"  align="center"
                                    show-overflow-tooltip></el-table-column>
                <el-table-column prop="prices" :label="$i18n.t('common.Fixedprice1')"  align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            <span v-if="s.fixedPrice">{{ s.countryCode }}:{{
                                Number(s.fixedPrice).toFixed(2)
                                }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="prices" :label="$i18n.t('common.Lowestprice')"  align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                                Number(s.lowestPrice).toFixed(2)
                                }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="brand" :label="$i18n.t('common.fixedprice')"   align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            <span v-if="s.resultPrice">{{ s.countryCode }}:{{
                                s.resultPrice.toFixed(2)
                                }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="brand" :label="$i18n.t('common.price1')"  align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            <span v-show="s.salePriceEbp || s.salePriceEbp>=0">{{
                                s.countryCode
                                }}:{{
                                s.salePriceEbp.toFixed(2)
                                }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="shelf" :label="$i18n.t('common.state')"  align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }} </span>
                        <span v-else>{{ $i18n.t("common.Notlisted") }} </span>
                    </template>
                </el-table-column>


            </el-table>


           
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%" @click="closeDialog(false)">{{ $i18n.t("common.cancel") }} </el-button>
            <el-button style="width: 20%" type="primary" :loading="loading" @click="onSubmit('variantFrom')">  {{ $i18n.t("common.confirm1") }} </el-button>
        </div>



        <el-dialog :visible.sync="goodsDialogVisible" v-if="goodsDialogVisible"  append-to-body @close="closeGoodsDialog"   >
            <goods-dialog  ref="goodsDialog" :dictionary="dictionary" :tableList="tableList"  @handleGoodsSelected="handleGoodsSelected"  />
        </el-dialog>

    </div>
</template>
<script>
import cache from '@/utils/cache';
import goodsDialog from './GoodsDialog.vue';
import i18n from '@/i18n/i18n';

export default {
    components: {
        goodsDialog
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
		formItem:{
            type: Object,
            default:()=>{
				return {
					shoppingRebateId: null,
                    status: 1, //0禁用 1启用
                    countryCode: '',
                    rebateDimension: null,
                    time: [],
                    startTime: null,
                    endTime: null,
                    rebateObjectCountry: null,
                    rebateObjectClassifyId: null,
                    rebateObjectGoodsId: null
				}
			}
        },
    },
    data() {
        return {
            formLabelWidth: '200px',
            ossHost: process.env.VUE_APP_OSS_Head,
            //选中数量
            rules: {
                countryCode: [
                    {
                        required: true,
                        message: i18n.t('common.Pleaseselectacountry') ,
                        trigger: 'change'
                    }
                ],
                rebateDimension: [
                    {
                        required: true,
                        message: i18n.t('common.PleaseSelectARebateDimension') ,
                        trigger: 'change'
                    }
                ],
                shoppingRebateId: [
                    {
                        required: true,
                        message: i18n.t('common.PleaseEnterTheRebateRatio') ,
                        trigger: 'blur'
                    }
                ],
                time: [
                    { 
                        type: 'array', required: true, message: i18n.t('common.Pleaseselectadaterange') , trigger: 'change',
                        fields: {
                            0: { type: 'string', required: true, message: i18n.t('common.Pleaseselectastartdate')  },
                            1: { type: 'string', required: true, message: i18n.t('common.Pleaseselecttheenddate')  },
                        }
                    }
                ] 
                
            },
            classifyTreeProps: {
				label: function (data, node) {
					return data.title
				},
				id: function (data, node) {
					return data.goodsClassifyId
				},
                children: 'children',
			},
            // 类目tree 默认勾选的节点的 key 的数组
            checkedKeys: [],
            goodsDialogVisible: false,
            // 选中的商品
            tableList:[],

            loading: false,
            dictionaryRebateObjectCountry: [],
            dictionaryRates: [],
            dictionaryClassifyTree: [],
            ratesMap: {},
            countryMap: {}
        };
    },
    mounted() {      
        // this.getGoodsClassifyTree();
    
        this.setCountryMap(); 
        this.init()
	},
    methods: {
        async init(){ 


            // 类目
            this.dictionaryClassifyTree= [],
            await this.$store.dispatch('getGoodsClassifyTree', {}).then(res => {         
                this.dictionaryClassifyTree = this.handleTreeData(JSON.parse(JSON.stringify(res)));	
            });

            this.checkedKeys = [];
            // 返利对象 回显
            if( this.formItem.rebateDimension == 'classify' && this.isEdit == true){
                this.checkedKeys.push(this.formItem.rebateObjectClassifyId);
            }
            


            this.tableList =[]; 
            this.dictionaryRebateObjectCountry = [];
            this.dictionaryRates = [];     
            this.ratesMap = {};
            await this.$store.dispatch("getListByCountryCode", {countryCode: this.formItem.countryCode}).then((res) => {
                this.ratesMap = res
			}); 
           
            if (this.isEdit == true) {
                // 返利国家下拉选项值
                this.dictionaryRebateObjectCountry = [];
                this.dictionaryRebateObjectCountry.push(this.countryMap[this.formItem.countryCode]);
                if(this.formItem.rebateDimension =='country'){
                    this.formItem.rebateObjectCountry =this.countryMap[this.formItem.countryCode].code;
                }
                // 根据 国家 显示 返利比例 
                this.dictionaryRates = []
                // console.log('this.formItem.countryCode',this.ratesMap[this.formItem.countryCode])
                if( this.ratesMap[this.formItem.countryCode]){
                    this.dictionaryRates = this.ratesMap[this.formItem.countryCode]
                }
                
                if( this.formItem.rebateDimension == 'goods' ){
                    this.tableList =[];
                    // 查询商品信息 
                    const data = [].concat(this.formItem.rebateObjectGoodsId);
                    this.$store.dispatch('postPromotionId', data).then((res) => {
                        this.tableList = res;
                    });               
                }               
            } 
        },
        setCountryMap(){
            this.countryMap = {};
            this.dictionary.country.map(item=>{
                this.countryMap[item.code] = item;
            })
        },
        // 请选择国家
        changeCountryCode(val) {
            // 返利国家下拉选项值
            this.dictionaryRebateObjectCountry=[];
            this.dictionaryRebateObjectCountry.push(this.countryMap[val]);
            if(this.formItem.rebateDimension =='country'){
                this.formItem.rebateObjectCountry =this.countryMap[val].code;
            }
            // 根据 国家 显示 返利比例 
            this.dictionaryRates = []
            this.formItem.shoppingRebateId = null;
            if( this.ratesMap[this.formItem.countryCode]){
                this.dictionaryRates = this.ratesMap[this.formItem.countryCode]
            }  
        },
         // 请选择返利维度
        changeRebateDimension(val){   
            // 返利维度 变化 返利对象清空
            this.formItem.rebateObjectCountry = null;
            this.formItem.rebateObjectClassifyId = null;
            this.formItem.rebateObjectGoodsId = null;

            if(this.formItem.rebateDimension =='country'){
                this.formItem.rebateObjectCountry = this.formItem.countryCode;
            }
            if(this.tableList.length > 0){
                this.formItem.rebateObjectGoodsId = this.tableList[0].goodsId;
            } 
            if( this.checkedKeys.length > 0){
                this.formItem.rebateObjectClassifyId = this.checkedKeys[0];
            } 
        },

       
   
        // 取类目三级
        handleTreeData(data ) {        
            data.forEach(item => {
                item.children.forEach(i => {
                    i.children.forEach((_item, index, arr) => {
                        arr[index].children = [];
                    });
                });
            });
            return data;
        },
        treeCheck(data,list){    
            if(list.checkedKeys.length>0){
                this.checkedKeys = [data.goodsClassifyId];
                this.$refs.tree.setCheckedKeys([data.goodsClassifyId]); 
                this.formItem.rebateObjectClassifyId = data.goodsClassifyId
            } else {
                this.checkedKeys = [];
                this.$refs.tree.setCheckedKeys([]);
                this.formItem.rebateObjectClassifyId = '';
            }
        },

        //选择商品
        changeGoods() {
            this.goodsDialogVisible = true;
        },
        // 商品子组件点击确定回调
        handleGoodsSelected(val){  
            this.tableList = val;
            if(this.tableList.length > 0){
                this.formItem.rebateObjectGoodsId = this.tableList[0].goodsId;
            }else{
                this.formItem.rebateObjectGoodsId = '';
            }  
            this.goodsDialogVisible = false
        },
        // 关闭商品列表页面
        closeGoodsDialog() {
            this.goodsDialogVisible = false
        },


        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        },
        onSubmit(formName) {

            if( this.formItem.rebateDimension == 'country' && (this.formItem.rebateObjectCountry == '' || this.formItem.rebateObjectCountry == null) ){
                this.$message({
                    message: this.$i18n.t("common.PleaseSelectARebateObject") ,
                    type: 'warning',
                    duration: 1000
                });
                return;
            }
            if( this.formItem.rebateDimension == 'classify' && (this.formItem.rebateObjectClassifyId == '' || this.formItem.rebateObjectClassifyId == null) ){
                this.$message({
                    message: this.$i18n.t("common.PleaseSelectARebateObject") ,
                    type: 'warning',
                    duration: 1000
                });
                return;
            }          
            if( this.formItem.rebateDimension == 'goods' && (this.formItem.rebateObjectGoodsId == '' || this.formItem.rebateObjectGoodsId ==null) ){              
                this.$message({
                    message: this.$i18n.t("common.PleaseSelectARebateObject") ,
                    type: 'warning',
                    duration: 1000
                });
                return;
            }

            if( this.formItem.rebateDimension == 'country'){
                this.formItem.rebateObjectClassifyId = null;
                this.formItem.rebateObjectGoodsId = null;
            } 
            if( this.formItem.rebateDimension == 'classify' ){
                this.formItem.rebateObjectCountry = null;
                this.formItem.rebateObjectGoodsId = null;
            } 
            if( this.formItem.rebateDimension == 'goods' ){
                this.formItem.rebateObjectCountry = null;
                this.formItem.rebateObjectClassifyId = null;
            } 

            this.$refs[formName].validate((valid) => {
                if (valid) {
          
                    this.formItem.startTime = new Date(this.formItem.time[0]).getTime();
                    this.formItem.endTime = new Date(this.formItem.time[1]).getTime();
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata") , this.$i18n.t("common.prompt") , {
                        confirmButtonText: this.$i18n.t("common.confirm1") ,
                        cancelButtonText: this.$i18n.t("common.cancel") ,
                        type: 'warning',
                    }).then(() => {
                        this.loading=true                        
                        if (this.isEdit) {
                          
                            this.$store.dispatch('putRebateGoodsPool', this.formItem).then((res) => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullymodified") ,
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });
                        } else {
                            this.$store.dispatch('addRebateGoodsPool', this.formItem).then((res) => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullysubmitted") ,
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });
                        }
                    }).catch(() => {
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t("common.Verificationfailed") ,
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        }
    }

};
</script>
<style scoped>
 

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
 
</style>
<style>
	.must .el-form-item__label:before{
		content: '*';
		color: #F56C6C;
		margin-right: 4px;
	}
</style>