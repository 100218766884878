<template xmlns="http://www.w3.org/1999/html">
    <div>
        <el-divider content-position='left'>{{ $i18n.t("common.Basicinformationoftheorder") }}</el-divider>
        <el-descriptions title='' :column='3'>

            <!--<el-descriptions-item :label='$i18n.t("common.brand")':span='3'>

                <dataText class='filter-item' v-model='orderInfo.brandCode' clearable :codeType="'brand'"
                          :placeholder='$i18n.t("common.brand")'></dataText
                >


            </el-descriptions-item>-->
            <el-descriptions-item :label='$i18n.t("common.Ordernumber")'>{{ orderInfo.orderNo }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Creationtime")'>{{ orderInfo.createTime |formatDate }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.OrderStatus")'>

                <dataText class='filter-item' v-model='orderInfo.orderStatus' clearable :codeType="'order_status'"
                ></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.UserID")'>{{ orderInfo.userId }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Useremail")'>{{ orderInfo.email }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.country")'>
                <dataText class='filter-item' v-model='orderInfo.countryCode' clearable :codeType="'countrys'"
                          :placeholder='$i18n.t("common.country")'></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.activity")'>{{ orderInfo.source }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Returnsandexchanges")'>{{ returnGoodsNum }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.consultingservice")'>{{ consultationNum }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Documentremarks")'>{{ orderInfo.remarkTag }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.combination")'>{{ orderInfo.palletDescription ||  $i18n.t("common.nothing")  }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.platform")' v-if="!scene.isAigoSmartApp">{{ orderInfo.platform }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.source")'  v-if="!scene.isAigoSmartApp">{{userInfo && sourceTextMsg(userInfo.sourceUrlName,userInfo.sourceUrl)}}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.OrderSource")' >{{orderInfo.orderSourceUrlName}}</el-descriptions-item>
        </el-descriptions>
        <el-divider v-if='orderAddress' content-position='left'>{{  $i18n.t("common.Shippingaddress")  }}</el-divider>
        <el-descriptions v-if='orderAddress' title='' border>
            <el-descriptions-item :label=' $i18n.t("common.name")  '>{{ orderAddress.userName }} {{
                    orderAddress.userSurname
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Mobilephonenumber") '>{{ orderAddress.linkPhone }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.country") '>
                <dataText class='filter-item' v-model='orderAddress.countryCode' clearable
                          :codeType="'countrys'"></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.province") '>{{ orderAddress.province }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.city") '>{{ orderAddress.city }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.PostalCode") '>{{ orderAddress.postalCode }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Detailedaddress") '>{{ orderAddress.addressContent }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Housenumber") '>{{ orderAddress.houseNum }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.RecipientCompanyAddressAdditionalContent") '>{{fieldTextMerge(orderAddress.recipientCompany, orderAddress.addressAdditionalContent)}}</el-descriptions-item>
        </el-descriptions>
        <el-divider content-position='left'>{{ $i18n.t("common.Invoiceaddress")  }}</el-divider>
        <el-descriptions v-if='orderInvoice' title='' border>
            <el-descriptions-item :label='$i18n.t("common.TaxID") '>{{ orderInvoice.taxNumber }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.InvoiceStatus") ' :span='2'>
                <dataText class='filter-item' v-model='orderInvoice.invoiceStatus' clearable
                          :codeType="'order_invoice'"></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.name") '>{{ orderInvoice.firstName }} {{ orderInvoice.lastName }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Mobilephonenumber") '>{{ orderInvoice.phoneNumber }}</el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.country") '>
                <dataText class='filter-item' v-model='orderInvoice.countryCode' clearable
                          :codeType="'countrys'"></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.province") '>{{ orderInvoice.province }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.city") '>{{ orderInvoice.city }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.PostalCode") '>{{ orderInvoice.postalCode }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Detailedaddress") '>>{{ orderInvoice.addressContent }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Housenumber") '>{{ orderInvoice.houseNum }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.RecipientCompanyAddressAdditionalContent") '>{{fieldTextMerge(orderInvoice.recipientCompany, orderInvoice.addressAdditionalContent)}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions v-else title='' border>
            <el-descriptions-item :label='$i18n.t("common.name") '>{{ orderAddress.userName }} {{
                    orderAddress.userSurname
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Mobilephonenumber") '>{{ orderAddress.linkPhone }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.country")'>
                <dataText class='filter-item' v-model='orderAddress.countryCode' clearable
                          :codeType="'countrys'"></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.province") '>{{ orderAddress.province }}</el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.city") '>{{ orderAddress.city }}</el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.PostalCode") '>{{ orderAddress.postalCode }}</el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.Detailedaddress") '>{{ orderAddress.addressContent }}</el-descriptions-item>
            <el-descriptions-item   :label='$i18n.t("common.Housenumber") '>{{ orderAddress.houseNum }}</el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.RecipientCompanyAddressAdditionalContent") '>{{fieldTextMerge(orderAddress.recipientCompany, orderAddress.addressAdditionalContent)}}</el-descriptions-item>
        </el-descriptions>
        <el-divider content-position='left'>{{ $i18n.t("common.Paymentinformation") }}</el-divider>
        <el-descriptions title=''>
            <el-descriptions-item :label='$i18n.t("common.Paymentstatus")'>
                <dataText class='filter-item' v-model=' orderInfo.paymentStatus' clearable
                          :codeType="'payment_status'"></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.Paymentmethod") '>{{ payMethodName }}</el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.Paymenttime") '>{{ orderInfo.paymentTime | formatDate }}</el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.coupon") '>
                <dataText v-model='currencyCode' :codeType="'currency_code'">{{
                        orderInfo.couponDiscountAmount
                    }}
                </dataText>
            </el-descriptions-item>
            <el-descriptions-item  :label='$i18n.t("common.Patrioticcoin") '>
                <dataText v-model='currencyCode' :codeType="'currency_code'">{{
                        orderInfo.balancedDiscountAmt
                    }}
                </dataText>
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.freight") '>
                <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderInfo.freightFee }}</dataText>
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.CouponName") '>
                {{ couponName }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Couponcode") '>
                {{ couponCodes }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Paymentamount") '>
                <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderInfo.paymentAmt }}</dataText>
            </el-descriptions-item>
        </el-descriptions>
        <el-divider content-position='left'>{{ $i18n.t("common.LogisticsInformation") }}</el-divider>
        <el-descriptions title='' :column='3'>
            <el-descriptions-item :label='$i18n.t("common.Deliverytime") ' v-if=" orderLogistics!=null ">{{
                    orderInfo.deliverTime | formatDate
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Deliverytime") ' v-else>
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.logisticscompany") '>{{
                    orderLogistics != null ? orderLogistics.logisticsCompanyName : ''
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Trackingnumber") '>{{
                    orderLogistics != null ? orderLogistics.logisticsNo : ''
                }}
            </el-descriptions-item>


            <el-descriptions-item :label='$i18n.t("common.Whethertomanuallyship") '>
                    {{ isManualDeliver(orderLogistics) }}

            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Manualshippingoperator") '>{{
                    orderLogistics != null ?orderLogistics.manualDeliverUserName: ''
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Manualshippingoperationtime") ' v-if=" orderLogistics!=null ">{{
                    orderLogistics.manualDeliverTime | formatDate
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Manualshippingoperationtime") '  v-else>
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Shippingremarks") '>{{ orderLogistics != null ?orderLogistics.remark:'' }}</el-descriptions-item>
        </el-descriptions>
        <el-divider content-position='left' v-if="(orderInfo.transferStatus!=null)">{{ $i18n.t("common.Bankremittance") }}</el-divider>
        <el-descriptions title='' :column='3' v-if="(orderInfo.transferStatus!=null)">
            <el-descriptions-item :label='$i18n.t("common.Remittancestatus") '>
                <dataText class='filter-item' v-model=' orderInfo.transferStatus' clearable
                          :codeType="'transfer_status'"></dataText
                >
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Remittanceconfirmationoperator") '>{{
                    orderInfo.transferConfirmUserName
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Remittanceconfirmationoperationtime") ' v-if=" orderLogistics!=null ">{{
                    orderInfo.transferConfirmTime | formatDate
                }}
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Manualshippingoperationtime") ' v-else>
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Remarks")'>{{ orderInfo.confirmRemark }}</el-descriptions-item>
        </el-descriptions>
        <el-divider content-position='left' v-if='orderInfo.cancelReason'>{{ $i18n.t("common.CancelRemark") }}</el-divider>
        <span v-if='orderInfo.cancelReason'>
            {{
                orderInfo.cancelReason
            }}
        </span>
        <el-divider content-position='left' v-if="orderInfo.orderType=='PRE_SALE_ORDER'">{{ $i18n.t("common.Preorderinformation") }}</el-divider>
        <el-descriptions title='' :column='3' v-if="orderInfo.orderType=='PRE_SALE_ORDER'">
            <el-descriptions-item :label='$i18n.t("common.Converttoformaldocument")'>
                <span v-if="orderInfo.formal == true">{{ $i18n.t("common.yes") }}</span>
                <span v-if="orderInfo.formal == false">{{ $i18n.t("common.no") }}</span>
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Positiveconversionsinglemode")'>
                <span v-if="orderInfo.trunFormalType == '1'">{{$i18n.t("common.automatic")  }}</span>
                <span v-if="orderInfo.trunFormalType == '2'">{{$i18n.t("common.Manual")  }}</span>             
            </el-descriptions-item>
            <el-descriptions-item :label='$i18n.t("common.Positiveconversionsingletime")'>{{ orderInfo.trunFormalTime |formatDate }}</el-descriptions-item>
        </el-descriptions>
        <el-table ref="pagedata" v-if="orderInfo.orderType=='PRE_SALE_ORDER' && orderInfo.preSalePayType=='DEPOSIT_AND_FINAL_PAYMENT'" row-key="orderNo" :data="orderInfo.preSaleOrderList" border style="width: 100%">
            <el-table-column prop="preSalePayType" :label='$i18n.t("common.type")' width="120px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.preSalePayType">{{ $dict.getPreSalePayType(row.preSalePayType) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="orderNo" :label='$i18n.t("common.Subordernumber")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="totalAmt" :label='$i18n.t("common.money")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="formItem.currencyCode" :codeType="'currency_code'">{{ scope.row.totalAmt }} </dataText>
                </template>
            </el-table-column>
            <el-table-column prop="orderStatus" :label='$i18n.t("common.OrderStatus")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.orderStatus" :codeType="'order_status'"></dataText>
                </template>
            </el-table-column>
            <el-table-column
                prop="paymentTime"
                :label='$i18n.t("common.Paymenttime") '  
                align="center"
                :formatter="formatDate"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="orderNextCancelTime"
                :label='$i18n.t("common.Paymentdeadline") '  
                align="center"
                :formatter="formatDate"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="payMethodName"   :label='$i18n.t("common.Paymentmethod") ' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.payTypeDesc }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="couponDiscountAmount"  :label='$i18n.t("common.coupon") ' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="formItem.currencyCode" :codeType="'currency_code'">{{ scope.row.couponDiscountAmount }} </dataText>
                </template>
            </el-table-column>
            <el-table-column prop="balancedDiscountAmt"  :label='$i18n.t("common.Patrioticcoin") ' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="formItem.currencyCode" :codeType="'currency_code'">{{ scope.row.balancedDiscountAmt }} </dataText>
                </template>
            </el-table-column>
        </el-table>
        <el-descriptions title='' v-if="orderInfo.orderType=='PRE_SALE_ORDER'" :column='3'>
            <el-descriptions-item     :label='$i18n.t("common.Remarks")' >
                {{ orderInfo.orderRemark }}
            </el-descriptions-item>
        </el-descriptions>
        <el-button @click="openRemark" type="text" size="small" v-if="orderInfo.orderType=='PRE_SALE_ORDER'">{{ $i18n.t("common.Addremarks") }}</el-button>
        <!--  赠品信息      -->
        <div v-show='giftActivityGoodsList.length > 0 '>
            <el-divider content-position='left'>{{ $i18n.t("common.GiftInformation") }}</el-divider>
            <el-table :data='giftActivityGoodsList'  border>
                <el-table-column :label='$i18n.t("common.Serialnumber")' align='center' prop='orderItemId'>
                    <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.GiveawayEventID") ' align='center' prop='giftActivityId'></el-table-column>
                <el-table-column :label='$i18n.t("common.GiveawayEvent") ' align='center' prop='giftActivityName'></el-table-column>
                <el-table-column :label='$i18n.t("common.name") ' align='center' prop='goodsTitle'></el-table-column>
                <el-table-column :label='$i18n.t("common.ProductID1") ' align='center' prop='goodsId'></el-table-column>
                <el-table-column label='SKU' align='center' prop='skuCode'></el-table-column>

                <el-table-column :label='$i18n.t("common.barcode") ' align='center' prop='barCode'></el-table-column>
                <el-table-column :label='$i18n.t("common.Pricing_unitprice") '  align='center' prop='price'>
                    <template v-slot="scope">
                        <dataText v-model='currencyCode' :codeType="'currency_code'">{{ scope.row.price }}</dataText>
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.Sellingprice_unitprice") '  align='center' prop='salePrice'>
                    <template v-slot="scope">
                        <dataText v-model='currencyCode' :codeType="'currency_code'">{{ scope.row.salePrice }}</dataText>
                    </template>
                </el-table-column>

                <el-table-column :label='$i18n.t("common.PackingQuantity") ' align='center' prop='packageSize'></el-table-column>
                <el-table-column :label='$i18n.t("common.quantity") '  align='center' prop='buyQty'></el-table-column>
                <el-table-column  :label='$i18n.t("common.Totalprice") '  align='center' prop='salePrice'>

                    <template v-slot="scope">
                        <dataText v-model='currencyCode' :codeType="'currency_code'">
                            {{ scope.row.salePrice * 1000 * scope.row.buyQty / 1000 }}
                        </dataText>
                    </template>

                </el-table-column>
            </el-table>
        </div>

        <el-divider content-position='left'>{{ $i18n.t("common.ProductInformation") }}</el-divider>
        <el-table :data='orderItemList' border>
            <el-table-column :label='$i18n.t("common.Serialnumber")' align='center' prop='orderItemId'>
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>

<el-table-column :label='$i18n.t("common.name") ' align='center' prop='goodsTitle'></el-table-column>
            <el-table-column :label='$i18n.t("common.ProductID1") ' align='center' prop='goodsId'></el-table-column>
            <el-table-column label='SKU' align='center' prop='skuCode'></el-table-column>

            <el-table-column :label='$i18n.t("common.barcode") ' align='center' prop='barCode'></el-table-column>
            <!--<el-table-column label='品牌' align='center' prop='payType'>
                <dataText class='filter-item' v-model='orderInfo.brandCode' clearable :codeType="'brand'"
                ></dataText
                >
            </el-table-column>-->
            <el-table-column :label='$i18n.t("common.VariantInformation") ' align='center' prop='goodsVariantVal'>

                <template v-slot="scope">
                    {{ getgoodsVariantVal(scope.row.goodsVariantVal) }}
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Pricing_unitprice") '  align='center' prop='price'>
                <template v-slot="scope">
                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ scope.row.price }}</dataText>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Sellingprice_unitprice") '  align='center' prop='salePrice'>
                <template v-slot="scope">
                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ scope.row.salePrice }}</dataText>
                </template>
            </el-table-column>

            <el-table-column :label='$i18n.t("common.PackingQuantity") ' align='center' prop='packageSize'></el-table-column>
            <el-table-column :label='$i18n.t("common.quantity") '  align='center' prop='buyQty'></el-table-column>
            <el-table-column  :label='$i18n.t("common.Totalprice") '  align='center' prop='salePrice'>

                <template v-slot="scope">
                    <dataText v-model='currencyCode' :codeType="'currency_code'">
                        {{ scope.row.salePrice * 1000 * scope.row.buyQty / 1000 }}
                    </dataText>
                </template>

            </el-table-column>
        </el-table>
        <div style='display: flex;justify-content: flex-end'>

            <el-form :label-position="'right'" label-width='120px' style='margin-right:10px;margin-top:10px' :inline="true" >
                <el-form-item :label='$i18n.t("common.MerchandiseSubtotal") ' v-if="orderInfo.orderType!='PRE_SALE_ORDER' || orderInfo.preSalePayType=='FULL_PAYMENT'">

                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderInfo.productAmt }}</dataText>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.DepositAmount")' v-if="(orderInfo.orderType=='PRE_SALE_ORDER' || orderInfo.preSalePayType!='FULL_PAYMENT') && orderPreSaleInfo && orderPreSaleInfo.orderDepositAmt">
                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderPreSaleInfo.orderDepositAmt }}</dataText>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.FinalPaymentAmount")' v-if="(orderInfo.orderType=='PRE_SALE_ORDER' || orderInfo.preSalePayType!='FULL_PAYMENT') && orderPreSaleInfo && orderPreSaleInfo.showOrderFinalPaymentAmt && orderPreSaleInfo.orderFinalPaymentAmt">
                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderPreSaleInfo.orderFinalPaymentAmt }}</dataText>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.freight")'>
                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderInfo.freightFee }}</dataText>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.FullReductionDiscount") '>
                    <span>-  </span><dataText style="display:inline-block" v-model='currencyCode' :codeType="'currency_code'">{{
                            orderInfo.fullDiscountReduction
                        }}
                    </dataText>
                </el-form-item>
                <el-form-item   :label='$i18n.t("common.coupon") '>
                    <span>-  </span><dataText style="display:inline-block" v-model='currencyCode' :codeType="'currency_code'">{{
                            orderInfo.couponDiscountAmount
                        }}
                    </dataText>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Patrioticcoin") '>
                    <span>-  </span><dataText style="display:inline-block" v-model='currencyCode' :codeType="'currency_code'">{{
                            orderInfo.balancedDiscountAmt
                        }}
                    </dataText>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.total") '>
                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderInfo.totalAmt }}</dataText>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Rebate") '>
                    <dataText v-model='currencyCode' :codeType="'currency_code'">{{ orderInfo.rebateAmt }}</dataText>
                </el-form-item>
            </el-form>
        </div>

        <div slot='footer' class='dialog-footer'>
            <!-- 正式单 -->
            <el-button style='width: 20%;' type='primary'
                       v-if="orderInfo.orderType!='PRE_SALE_ORDER' && orderInfo.orderStatus !== 'Canceled' && orderInfo.orderStatus !== 'UnPaid'"
                       @click="channelOrder()">{{ $i18n.t("common.CancelOrder")  }}
            </el-button>
            <!-- 预售单 -->
            <!-- 预售单定金 + 尾款 -->
            <el-button style='width: 20%;' type='primary'
                       v-if="orderInfo.orderType=='PRE_SALE_ORDER' && orderInfo.preSalePayType=='DEPOSIT_AND_FINAL_PAYMENT' && (orderInfo.preSaleOrderStatus=='DEPOSIT_PAID' || orderInfo.preSaleOrderStatus=='FINAL_PAYMENT_UN_PAY' || orderInfo.preSaleOrderStatus=='FINAL_PAYMENT_PAID')"
                       @click="channelOrder()">{{ $i18n.t("common.CancelOrder")  }}
            </el-button>
            <!-- 预售单全款 -->
            <el-button style='width: 20%;' type='primary'
                       v-else-if="orderInfo.orderType=='PRE_SALE_ORDER' && orderInfo.preSalePayType=='FULL_PAYMENT' && orderInfo.orderStatus !== 'Canceled' && orderInfo.orderStatus !== 'UnPaid'"
                       @click="channelOrder()">{{ $i18n.t("common.CancelOrder")  }}
            </el-button>
            <el-button style='width: 20%;' @click='closeDialog(false)'>{{ $i18n.t("common.close")  }}</el-button>
        </div>
        <el-dialog :title='$i18n.t("common.Areyousuretocancelthisorder")' v-if='channelFormVisible' :visible.sync='channelFormVisible'
                   :append-to-body="true" width="30%"
                   :close-on-click-modal='false'>
            <el-form>
                <el-form-item>
                    <el-input maxlength="1000" type="textarea" v-model="cancelReason"
                              :placeholder='$i18n.t("common.reason")'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class='dialog-footer'>
                <el-button style='width: 20%;' @click='channelFormVisible = false'>{{ $i18n.t("common.cancel") }}</el-button>
                <el-button style='width: 20%;' type='primary' @click="onSubmit('variantFrom')">{{ $i18n.t("common.confirm") }}
                </el-button>
            </div>
        </el-dialog>
        <el-dialog :title='$i18n.t("common.Addremarks")' v-if='remarkFormVisible' :visible.sync='remarkFormVisible'
                   :append-to-body="true" width="30%"
                   :before-close="closeRemarkDialog"
                   :close-on-click-modal='false'>
            <el-input
                type="textarea"
                :placeholder='$i18n.t("common.Pleaseenteracomment")'
                v-model="orderRemark"
                maxlength="500"
                show-word-limit
            >
            </el-input>
            <div slot='footer' class='dialog-footer'>
                <el-button style='width: 20%;' @click='closeRemarkDialog'> {{ $i18n.t("common.cancel") }}</el-button>
                <el-button style='width: 20%;' type='primary' :loading='orderRemarkLoading' @click="putOrderRemark"> {{ $i18n.t("common.confirm") }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import FilterContainer from '@/components/common/FilterContainer.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import moment from 'moment';
import scene from '../../../utils/scene';
export default {
    components: {
        FilterContainer,
        dataSelect,
        dataText
    },
    props: {
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        },
    },
    data() {
        return {
            activeIndex: null,
            orderRemark:'',
            orderRemarkLoading:false,
            treeData: {},
            //查询项
            //选中数量
            rules: {
                /*brandCode: [
                    {
                        required: true,
                        message: this. $i18n.t("common.Pleaseselectabrand") ,
                        trigger: 'change'
                    }
                ],*/
                countryCode: [
                    {
                        required: true,
                        message:  this. $i18n.t("common.Pleaseselectacountry") ,
                        trigger: 'change'
                    }
                ],
                category: [
                    {
                        required: true,
                        message:  this. $i18n.t("common.Pleaseselectacategory") ,
                        trigger: 'change'
                    }
                ]
            },
            checkedPrpa: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            count: 1,
            orderItemList: [],
            giftActivityGoodsList: [],
            orderAddress: {},
            orderInfo: {},
            orderInvoice: {},
            orderLogistics: {},
            orderPreSaleInfo:{},
            currencyCode: '',
            returnGoodsNum: 0,
            consultationNum: 0,
            payMethodName: '',
            userInfo: {},           
            remarkFormVisible: false,
            channelFormVisible: false,
            cancelReason: "",
            couponName: "",
            couponCodes: "",
        };
    },
    computed:{
      scene() {
        return scene
      },
        isManualDeliver(){
            return (orderLogistics)=>{
                if(orderLogistics == null){
                    return '';
                }
                if(orderLogistics.isManualDeliver == true){
                    return this. $i18n.t("common.yes") ;
                }
                return this. $i18n.t("common.no");
            }
        }
    },
    created() {
        this.orderAddress = this.formItem.orderAddress;
        this.orderInfo = this.formItem.orderInfo;
        this.orderPreSaleInfo = this.formItem.orderPreSaleInfo;
        this.orderItemList = this.orderInfo.orderItemList;
        this.giftActivityGoodsList = this.formItem.giftActivityGoodsList;
        this.orderInvoice = this.formItem.orderInvoice;
        this.orderLogistics = this.formItem.orderLogistics;
        this.currencyCode = this.formItem.currencyCode;
        this.returnGoodsNum = this.formItem.returnGoodsNum;
        this.consultationNum = this.formItem.consultationNum;
        this.payMethodName = this.formItem.payMethodName;
        this.userInfo = this.formItem.userInfo;
        this.couponName = this.formItem.couponName;
        this.couponCodes = this.formItem.couponCodes;
    },
    methods: {
        /**
         * 时间戳转码
         */
         formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        getgoodsVariantVal(goodsVariantValStr) {
            if (!goodsVariantValStr) {
                return '';
            }
            var goodsVariantVal = JSON.parse(goodsVariantValStr);
            let resutl = [];
            for (const goodsVariantValElement of goodsVariantVal.list) {
                for (const goodsVariantValElementElement of goodsVariantValElement.items) {
                    if (goodsVariantValElementElement.checked) {
                        resutl.push(goodsVariantValElementElement.itemValue);
                    }

                }
            }
            return resutl.join('、');
        },
        closeDialog(status) {
            if (this.$refs['variantFrom'] !== undefined) {
                this.$refs['variantFrom'].resetFields();
            }
            this.$emit('closeDialog', status);
        },
        openRemark(){
            this.orderRemark=this.formItem.orderInfo.orderRemark
            this.remarkFormVisible=true;
        },
        //添加备注
        putOrderRemark(){
            this.orderRemarkLoading=true    
            this.$store.dispatch('putOrderRemark', {
                    orderId: this.orderInfo.orderId,
                    orderRemark: this.orderRemark
                }).then((res) => {
                    if(res){
                        this.$message.success( this. $i18n.t("common.Successfullyadded1") );
                        this.formItem.orderInfo.orderRemark=this.orderRemark
                        this.closeRemarkDialog();
                    }else{
                        this.$message.success( this. $i18n.t("common.Addfailed") );
                    }
                    this.orderRemarkLoading=false      
                }).catch((err)=>{
                    this.orderRemarkLoading=false
                });
        },
        closeRemarkDialog(){
            this.remarkFormVisible=false;
            this.orderRemark=''
        },
        remove(node, data) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1);
        },
        handleCheckChange(data, checked, indeterminate) {
        },
        getActiveIndex(index) {
            this.activeIndex = index;
        },
        channelOrder() {
            this.channelFormVisible = true;
        },
        //提交
        onSubmit(formName) {
            let that = this;
            this.$confirm(this. $i18n.t("common.Whethertosubmitdata") ,this. $i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('cancelOrder', {
                    id: this.orderInfo.orderId,
                    orderId: this.orderInfo.orderId,
                    cancelReason: this.cancelReason
                }).then((res) => {
                    this.$message.success(this. $i18n.t("common.Canceledsuccessfully") );
                    that.closeDialog(true);
                });
            });
        },
        fieldTextMerge(company, address) {
            const separate = "，";
            if (company && address) {
                return company + separate + address;
            } else if (company && !address) {
                return company + separate;
            } else if (!company && address) {
                return separate + address;
            } else {
                return '';
            }
        },
        sourceTextMsg(sourceUrlName,sourceUrl){
            if ('直接访问' == sourceUrlName) {
                sourceUrlName = this.$t('common.DirectVisit');
            } else if ('其他' == sourceUrlName) {
                sourceUrlName = this.$t('common.other');
            }
          const separate = "、";
          if (sourceUrlName && sourceUrl) {
              return sourceUrlName + separate + sourceUrl;
          } else if(sourceUrlName && !sourceUrl){
              return sourceUrlName;
          } else if(!sourceUrlName && sourceUrl){
              return sourceUrl;
          } else{
              return '';
          }
        }
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
