var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticClass: "form_table",
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "sort",
                label: _vm.$t("common.DragToSort"),
                align: "center",
                width: "80px"
              }
            },
            [_c("i", { staticClass: "el-icon-sort drag-btn" })]
          ),
          _c("el-table-column", {
            attrs: {
              prop: "imageUrl",
              label: _vm.$t("common.Productimage1"),
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.imageUrl != ""
                      ? _c("el-image", {
                          staticStyle: { height: "100px", width: "100px" },
                          attrs: {
                            src: _vm.ossHost + scope.row.imageUrl,
                            fit: "fill"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "barCode", label: "EAN", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$t("common.Productname1"),
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.DefaultSelectionOfUsers"),
              prop: "userDefaultSelection",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: {
                        oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')"
                      },
                      model: {
                        value: scope.row.userDefaultSelection,
                        callback: function($$v) {
                          _vm.$set(scope.row, "userDefaultSelection", $$v)
                        },
                        expression: "scope.row.userDefaultSelection"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.cancelCustomDialog()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.confirmCustomDialog()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }