var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    _vm._g(_vm._b({}, "el-radio-group", _vm.$attrs, false), _vm.$listeners),
    _vm._l(_vm.brandDicts, function(cc) {
      return _c(
        "el-radio",
        { key: cc.code, attrs: { label: cc.code, value: cc.code } },
        [_vm._v(_vm._s(cc.name))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }