<template>
    <div>
        <el-dialog :title='$i18n.t("common.Selectproduct")' v-bind="$attrs" v-on="$listeners" width="80%" :visible.sync="visible"
                   append-to-body>
            <div class="filter-container">
                <el-form :inline="true">
                    <el-form-item>
                        <el-input v-model="searchData.idFuzzy" :placeholder='$i18n.t("common.SKU_EAN_Encoding")'
                                  style="width: 180px; margin-left: 10px" clearable></el-input>
                    </el-form-item>
                    <!--                    <el-form-item>-->
                    <!--                        <el-select-->
                    <!--                                v-model="searchData.goodStatus"-->
                    <!--                                placeholder="状态"-->
                    <!--                                clearable-->
                    <!--                                style="width: 180px; margin-left: 10px"-->
                    <!--                                class="filter-item"-->
                    <!--                        >-->
                    <!--                            <el-option label="已上架" value="true"></el-option>-->
                    <!--                            <el-option label="未上架" value="false"></el-option>-->
                    <!--                        </el-select>-->
                    <!--                    </el-form-item>-->
                    <el-form-item>
                        <el-select
                                v-model="searchData.status"
                                :placeholder='$i18n.t("common.Enableornot")'
                                clearable
                                style="width: 180px; margin-left: 10px"
                                class="filter-item"
                        >
                            <el-option :label= '$i18n.t("common.yes")' value="true"></el-option>
                            <el-option :label= '$i18n.t("common.no")' value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick"
                                   style="margin-left: 10px">
                                   {{ $i18n.t("common.query") }}
                        </el-button>
                        <el-button
                                class="filter-item"
                                icon="el-icon-search"
                                type="primary"
                                @click="cleanQuery"
                                style="margin-left: 10px"
                                size="small"
                        >
                        {{ $i18n.t("common.Reset") }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="flex-between" style='margin: 0px 0px 10px 30px'>
                <div style="display: flex;justify-content: flex-start;align-items: center;">
                    <el-button @click="add()" type="success" > {{ $i18n.t("common.Add1") }}</el-button>
                </div>
                <div>
                    <el-button type='primary' plain @click="delAll()" :disabled='getDisabled()' >{{ $i18n.t("common.BatchRemoval") }}（{{selectNum}}）</el-button>
                </div>

            </div>

            <el-table ref="pagedata" :data="pageData.list" border style="width: 100%"
                      @selection-change="handleSelectionChange" row-key='goodsHomeRecommendId'>
                <el-table-column
                    type="selection"
                    width="55" :reserve-selection="true"></el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.goodsId" :label="$i18n.t('common.ProductID')" align="center"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column
                        prop="goodsMergeListMgrDto.goodsCode"
                        :label="$i18n.t('common.SKU_EAN_Encoding')"
                        align="center"
                        show-overflow-tooltip
                >
                    <template v-slot="scope">
                        <span>SKU:{{ scope.row.goodsMergeListMgrDto.sku }}</span><br/>
                        <span>EAN:{{ scope.row.goodsMergeListMgrDto.barCode }}</span><br/>
                        <span>   {{ $i18n.t("common.coding") }}:{{ scope.row.goodsMergeListMgrDto.goodsCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.imageUrl" :label=' $i18n.t("common.picture")' align="center"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image
                                style="height: 100px"
                                v-if="scope.row.goodsMergeListMgrDto.imageUrl"
                                :src="ossHost + scope.row.goodsMergeListMgrDto.imageUrl"
                                fit="fill"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.treeList" :label=' $i18n.t("common.Category")' align="center" height="55"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-for="(item,index) in scope.row.goodsMergeListMgrDto.treeList" :key="index">
						<span v-for="(item2,index2) in item.parents" :key="index2">
							<span>{{ item2.title }}></span>
						</span>
                            <span>{{ item.title }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.title" :label=' $i18n.t("common.ProductName")'  align="center"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.goodsSpecials" :label=' $i18n.t("common.Countrieslisted")'  align="center"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsMergeListMgrDto.goodsSpecials" :key="index"
                              v-if="s.shelf ">
                            {{ $dict.getDictValue('countrys', s.countryCode) }}<br/>
                        </span>
                    </template>
                </el-table-column>

<!--                <el-table-column prop="goodsMergeListMgrDto.brandCode" label="品牌" align="center"-->
<!--                                 show-overflow-tooltip>-->
<!--                    <template slot-scope="scope">-->
<!--                        <span v-if="scope.row.goodsMergeListMgrDto.brandCode">{{-->
<!--                                scope.row.goodsMergeListMgrDto.brandCode-->
<!--                            }}</span>-->
<!--                        <span v-else-if="scope.row.goodsMergeListMgrDto && scope.row.goodsMergeListMgrDto.treeList && scope.row.goodsMergeListMgrDto.treeList.length > 0">{{-->
<!--                                scope.row.goodsMergeListMgrDto.treeList[0].brandCode-->
<!--                            }}</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->

                <!--
                <el-table-column prop="goodsMergeListMgrDto.prices" label="定价" align="center" show-overflow-tooltip>

                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsMergeListMgrDto.prices" :key="index">
                            {{ s.countryCode }}:{{ s.resultPrice.toFixed(2) }}<br/>
                        </span>
                    </template>
                </el-table-column>

                -->
                <el-table-column prop="goodsMergeListMgrDto.goodsSpecials" :label='$i18n.t("common.inventory")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsMergeListMgrDto.goodsSpecials" :key="index" 
                            v-if="s.shelf && s.ecomRemainQty">
                            {{ $dict.getDictValue('countrys', s.countryCode) }}:{{ s.ecomRemainQty }}<br/>    
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="goodsMergeListMgrDto.shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>

                    <template slot-scope="scope">
                        <span v-if="scope.row.goodsMergeListMgrDto.shelf === true" style="color: #46a8a0;">{{ $i18n.t("common.Listed") }}</span>
                        <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                    </template>
                </el-table-column>
                <el-table-column  :label='$i18n.t("common.Addtime")' prop="createTime" :render-header="renderCreateHeader">
                    <template slot-scope="scope">{{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="sort" :render-header="renderSortHeader" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.sort" oninput="value=value.replace(/[^0-9]/g,'')"
                                  @keyup.enter.native="putSortBanner(scope.row)" @blur="putSortBanner(scope.row)" :placeholder='$i18n.t("common.Pleaseentercontent")'></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="status" :label='$i18n.t("common.Enableornot")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="updateStatus(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="del(scope.row)" type="text" size="small">{{ $i18n.t("common.remove") }}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="searchData.page"
                        :page-size="searchData.size"
                        layout="total, prev, pager, next, jumper"
                        :total="pageData.total"
                ></el-pagination>
            </div>
        </el-dialog>
        <goods-dialog ref="goodsDialog" :typeName="typeName" :show-close="true" :close-on-click-modal="false"/>
    </div>
</template>
<script>
import VaroantDialog from './VaroantDialog.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import GoodsDialog from '@/components/common/GoodsDialog.vue';
import modal_mixin from '@/components/common/modal_mixin.js';
import moment from 'moment';
import Special from "@/views/special";

export default {
    components: {Special, VaroantDialog, FormTableDialog, dataSelect, moment, dataText, GoodsDialog},
    mixins: [modal_mixin],
    name: 'recommend_module',
    data() {
        return {
            typeName: this.$i18n.t("common.Homepagerecommendation"),
            //查询项
            searchData: {
                idFuzzy: '',
                goodStatus: '',
                brandCode: '',
                status: 'true',
                recommendModule: '',
                page: 1,
                limit: 10,
                desc: '',
            },
            isEdit: false,
            //表单数据
            formItem: {},
            platformList: [],
            desc: true,
            createTimeDesc: true,
            pageData: {},
            dialogFormVisible: false,
            dialogFormTableVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
            homeModuleId: '',
            countryCode: '',
            brandCode: '',
            selectNum: 0,
            //确认后的被选中的商品
            pageDataSelectList: [],
        };
    },

    methods: {
        //自定义label
        renderSortHeader() {
            let sort = this.$i18n.t("common.sort")
            return (
                    <div>
                        <span class='sort-span' on-click={() => this.handleSort()} on-blur={() => this.handleSort()}>sort <span
                                class='el-icon-sort'></span></span>
                    </div>
            )
        },
        //自定义label
        renderCreateHeader() {
            let Addtime = this.$i18n.t("common.Addtime")
            return (
                    <div>
                        <span class='sort-span' on-click={() => this.handleCreateTimeSort()}>Addtime <span
                                class='el-icon-sort'></span></span>
                    </div>
            )
        },
        updateStatus(row) {
            this.$store
                    .dispatch("updateRecommendStatus", {
                        id: row.goodsHomeRecommendId,
                        status: row.status ? row.status : false
                    })
                    .then((res) => {
                        if (res) {
                            this.loadlist()
                        }
                    });
        },
        handleCreateTimeSort() {
            this.createTimeDesc = !this.createTimeDesc;
            this.searchData.desc = `createTime ${this.createTimeDesc ? 'desc' : 'asc'}, sort ${this.desc ? 'desc' : 'asc'}`
            setTimeout(() => {
                this.loadlist();
            })
        },
        //排序
        handleSort() {
            this.desc = !this.desc
            this.searchData.desc = `sort ${this.desc ? 'desc' : 'asc'}, createTime ${this.createTimeDesc ? 'desc' : 'asc'}`
            setTimeout(() => {
                this.loadlist();
            })
        },
        handleInit(homeModuleId, countryCode, brandCode) {
            this.homeModuleId = homeModuleId;
            this.countryCode = countryCode;
            this.brandCode = brandCode;
            this.cleanQuery();
        },
        searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        loadlist(clearSelection = true) {
            var _this = this;
            this.$store.dispatch('getRecommendGoodsPage', {homeModuleId: this.homeModuleId, ...this.searchData}).then(res => {
                _this.pageData = res;
            });
            if (clearSelection && this.$refs.pagedata) {
                this.$refs.pagedata.clearSelection()
            }
        },
        async add() {
            if (this.$refs.goodsDialog) {
                const goodsList = await this.$refs.goodsDialog.open(this.homeModuleId, true, null, this.countryCode, this.$tenantBrand.getBrand());
                if (goodsList && goodsList.length > 0) {
                    this.$store.dispatch('addRecommendGoods', {
                        homeModuleId: this.homeModuleId,
                        goodsIds: goodsList.map(x => x.goodsId)
                    }).then(res => {
                        this.$message.success( this.$i18n.t("common.Successfullyadded2"));
                        this.loadlist();
                    });
                }
            }
        },
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist(false);
        },
        cleanQuery() {
            this.searchData.idFuzzy = '';
            this.searchData.countryCode = [];
            this.searchData.brandCode = '';
            this.searchData.status = 'true';
            this.searchData.recommendModule = '';
            this.searchData.goodStatus = '';
            this.searchClick();
        },
        putSortBanner(row) {
            const str = '^(\\d+)\\.(\\d{0}).*$'
            const reg = new RegExp(str)
            row.sort = row.sort.replace(/[^\d.]/g, "")
            row.sort = row.sort.replace(reg, '$1');
            this.$store
                    .dispatch("updateRecommendSort", {
                        id: row.goodsHomeRecommendId,
                        sort: row.sort ? row.sort : 0
                    })
                    .then((res) => {
                        if (res) {
                            this.loadlist()
                        }
                    });
        },
        del(row) {
            this.$confirm(this.$i18n.t("common.Areyousuretoremovethisproduct"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                    .then(() => {
                        this.$store.dispatch('delRecommendGoods', {
                            homeModuleId: this.homeModuleId,
                            id: row.goodsHomeRecommendId
                        }).then(res => {
                            let totalPage = Math.ceil((this.pageData.total - 1) / this.searchData.limit)
                            let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                            this.searchData.page = currentPage < 1 ? 1 : currentPage;
                            console.log(this.pageData.total, totalPage, currentPage, this.searchData, 121232)
                            this.$message.success(this.$i18n.t("common.Successfullyremoved"));
                            this.loadlist();
                        });
                    })
                    .catch(() => {
                    });
        },
        handleSelectionChange(selection){
            this.pageDataSelectList = selection
            if(this.pageDataSelectList){
                this.selectNum = this.pageDataSelectList.length
            }else{
                this.selectNum = 0
            }
        },
        delAll() {
            let goodsHomeRecommendIds = this.pageDataSelectList.map(value => value.goodsHomeRecommendId);
            if (!goodsHomeRecommendIds || goodsHomeRecommendIds.length === 0) {
                this.$message({
                    type: 'warning',
                    message: this.$i18n.t("common.Pleaseselectaproduct")
                });
                return;
            }
            this.$confirm(this.$i18n.t("common.ConfirmBatchDeletionOfSelectedProducts"), this.$i18n.t("common.Prompt"), {
                confirmButtonText: this.$i18n.t("common.Confirm"),
                cancelButtonText: this.$i18n.t("common.Cancel"),
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('batchDelRecommendGoods', {
                        homeModuleId: this.homeModuleId,
                        goodsHomeRecommendIds: goodsHomeRecommendIds
                    }).then(res => {
                        this.pageDataSelectList = [];
                        let totalPage = Math.ceil((this.pageData.total - goodsHomeRecommendIds.length) / this.searchData.limit);
                        let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page;
                        this.searchData.page = currentPage < 1 ? 1 : currentPage;
                        this.$message.success(this.$i18n.t("common.Successfullyremoved"));
                        this.loadlist();
                    });
                })
                .catch(() => {
                });
        },
        getDisabled(){
            if(this.selectNum && this.selectNum > 0){
                return false;
            }
            return true;
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.my-content {
    background: #fde2e2;
}
</style>
