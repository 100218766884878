var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("div", { staticClass: "table-list" }, [
          _c(
            "div",
            { staticClass: "table-div" },
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v(_vm._s(_vm.$i18n.t("common.Basicinformationoftheorder")))
              ]),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.Ordernumber") } },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.openOrderDetailDialog(
                                _vm.orderInfo.orderId
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.orderInfo.orderNo))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.country") } },
                    [
                      _c("dataText", {
                        staticClass: "filter-item",
                        attrs: {
                          clearable: "",
                          codeType: "countrys",
                          placeholder: _vm.$i18n.t("common.country")
                        },
                        model: {
                          value: _vm.orderInfo.countryCode,
                          callback: function($$v) {
                            _vm.$set(_vm.orderInfo, "countryCode", $$v)
                          },
                          expression: "orderInfo.countryCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.UserID") } },
                    [_vm._v(_vm._s(_vm.orderInfo.userId))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Numberofproductmodels")
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.orderInfo && _vm.orderInfo.orderItemList
                              ? _vm.orderInfo.orderItemList.length
                              : 0
                          ) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.source") } },
                    [_vm._v(_vm._s(_vm.orderInfo.source))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.money") } },
                    [
                      _c(
                        "dataText",
                        {
                          attrs: { codeType: "currency_code" },
                          model: {
                            value: _vm.orderInfo.currencyCode,
                            callback: function($$v) {
                              _vm.$set(_vm.orderInfo, "currencyCode", $$v)
                            },
                            expression: "orderInfo.currencyCode"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.orderInfo.totalAmt) +
                              "\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.OrderStatus") } },
                    [
                      _c("dataText", {
                        staticClass: "filter-item",
                        attrs: { clearable: "", codeType: "order_status" },
                        model: {
                          value: _vm.orderInfo.orderStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.orderInfo, "orderStatus", $$v)
                          },
                          expression: "orderInfo.orderStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.Creationtime") } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(_vm.orderInfo.createTime)) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.Paymentmethod") } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.postSaleOrder.payMethodName) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.Paymenttime") } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(_vm.orderInfo.paymentTime)
                        ) + "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.Shipmentstatus") } },
                    [
                      _c("dataText", {
                        staticClass: "filter-item",
                        attrs: { clearable: "", codeType: "delivery_status" },
                        model: {
                          value: _vm.orderInfo.deliveryStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.orderInfo, "deliveryStatus", $$v)
                          },
                          expression: "orderInfo.deliveryStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.Deliverytime") } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(_vm.orderInfo.deliverTime)
                        ) + "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-div" },
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v(_vm._s(_vm.$i18n.t("common.ApplicationDetails")))
              ]),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.type") } },
                    [
                      _c("dataText", {
                        staticClass: "filter-item",
                        attrs: { clearable: "", codeType: "post_sale_type" },
                        model: {
                          value: _vm.postSaleOrder.postSaleType,
                          callback: function($$v) {
                            _vm.$set(_vm.postSaleOrder, "postSaleType", $$v)
                          },
                          expression: "postSaleOrder.postSaleType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$i18n.t("common.Applicationtime") } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(_vm.postSaleOrder.createTime)
                        ) + "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Returnlogisticstrackingcode")
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.postSaleOrder.logisticsCode) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.postSaleChangeGoods && _vm.postSaleChangeGoods.length > 0
            ? _c(
                "div",
                { staticClass: "table-div" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$i18n.t("common.Exchangeitem")) +
                      ":\n                "
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.postSaleChangeGoods, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Serialnumber"),
                          align: "center",
                          prop: "orderItemId"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [_vm._v(_vm._s(scope.$index + 1))]
                              }
                            }
                          ],
                          null,
                          false,
                          811300409
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.name"),
                          align: "center",
                          prop: "goodsTitle"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.ProductID1"),
                          align: "center",
                          prop: "goodsId"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.barcode"),
                          align: "center",
                          prop: "barCode"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.VariantInformation"),
                          align: "center",
                          prop: "goodsVariantVal"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Pricing_unitprice"),
                          align: "center",
                          prop: "price"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "dataText",
                                    {
                                      attrs: { codeType: "currency_code" },
                                      model: {
                                        value: _vm.orderInfo.currencyCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.orderInfo,
                                            "currencyCode",
                                            $$v
                                          )
                                        },
                                        expression: "orderInfo.currencyCode"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.price) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2164891130
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Sellingprice_unitprice"),
                          align: "center",
                          prop: "salePrice"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "dataText",
                                    {
                                      attrs: { codeType: "currency_code" },
                                      model: {
                                        value: _vm.orderInfo.currencyCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.orderInfo,
                                            "currencyCode",
                                            $$v
                                          )
                                        },
                                        expression: "orderInfo.currencyCode"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.salePrice) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4198816417
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.quantity"),
                          align: "center",
                          prop: "buyQty"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Totalprice"),
                          align: "center",
                          prop: "payType"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "dataText",
                                    {
                                      attrs: { codeType: "currency_code" },
                                      model: {
                                        value: _vm.orderInfo.currencyCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.orderInfo,
                                            "currencyCode",
                                            $$v
                                          )
                                        },
                                        expression: "orderInfo.currencyCode"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            scope.row.salePrice *
                                              scope.row.buyQty
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3939269625
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.postSaleReturnGoods && _vm.postSaleReturnGoods.length > 0
            ? _c(
                "div",
                { staticClass: "table-div" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$i18n.t("common.Returnedgoods")) +
                      ":\n                "
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.postSaleReturnGoods, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Serialnumber"),
                          align: "center",
                          prop: "orderItemId"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [_vm._v(_vm._s(scope.$index + 1))]
                              }
                            }
                          ],
                          null,
                          false,
                          811300409
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.name"),
                          align: "center",
                          prop: "goodsTitle"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.ProductID1"),
                          align: "center",
                          prop: "goodsId"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.barcode"),
                          align: "center",
                          prop: "barCode"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.VariantInformation"),
                          align: "center",
                          prop: "goodsVariantVal"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Pricing_unitprice"),
                          align: "center",
                          prop: "price"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "dataText",
                                    {
                                      attrs: { codeType: "currency_code" },
                                      model: {
                                        value: _vm.orderInfo.currencyCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.orderInfo,
                                            "currencyCode",
                                            $$v
                                          )
                                        },
                                        expression: "orderInfo.currencyCode"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.price) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2164891130
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Sellingprice_unitprice"),
                          align: "center",
                          prop: "salePrice"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "dataText",
                                    {
                                      attrs: { codeType: "currency_code" },
                                      model: {
                                        value: _vm.orderInfo.currencyCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.orderInfo,
                                            "currencyCode",
                                            $$v
                                          )
                                        },
                                        expression: "orderInfo.currencyCode"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.salePrice) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4198816417
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.quantity"),
                          align: "center",
                          prop: "buyQty"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Totalprice"),
                          align: "center",
                          prop: "payType"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "dataText",
                                    {
                                      attrs: { codeType: "currency_code" },
                                      model: {
                                        value: _vm.orderInfo.currencyCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.orderInfo,
                                            "currencyCode",
                                            $$v
                                          )
                                        },
                                        expression: "orderInfo.currencyCode"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            scope.row.salePrice *
                                              scope.row.buyQty
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3939269625
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.formItem.description
            ? _c("div", { staticClass: "table-div" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$i18n.t("common.ApplicationDescription")) +
                    ": " +
                    _vm._s(_vm.formItem.description) +
                    "\n            "
                )
              ])
            : _vm._e(),
          _vm.postSaleRecord && _vm.postSaleRecord.length > 0
            ? _c(
                "div",
                { staticClass: "table-div" },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("   " + _vm._s(_vm.$i18n.t("common.Auditing")))
                  ]),
                  _c(
                    "el-descriptions",
                    { attrs: { title: "", column: 3, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.result") } },
                        [
                          _c("dataText", {
                            staticClass: "filter-item",
                            attrs: { codeType: "audit_status" },
                            model: {
                              value: _vm.checkInfo.auditStatus,
                              callback: function($$v) {
                                _vm.$set(_vm.checkInfo, "auditStatus", $$v)
                              },
                              expression: "checkInfo.auditStatus"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.Audittime") } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(_vm.checkInfo.createTime)
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.Reviewedby") } },
                        [_vm._v(_vm._s(_vm.checkInfo.createUserName))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.reason") } },
                        [_vm._v(_vm._s(_vm.checkInfo.description))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.formItem.imgUrls
            ? _c(
                "div",
                { staticClass: "table-div" },
                _vm._l(_vm.formItem.imgUrls, function(item, index) {
                  return _c("el-image", {
                    key: index,
                    staticStyle: {
                      width: "100px",
                      height: "100px",
                      margin: "0 10px 10px 0"
                    },
                    attrs: {
                      src: _vm.ossHost + item,
                      "preview-src-list": _vm.formItem.fullImgUrls
                    }
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "table-div" },
            [
              !_vm.postSaleOrder.finish
                ? _c(
                    "el-form",
                    {
                      ref: "variantFrom",
                      attrs: { rules: _vm.rules, model: _vm.formBody }
                    },
                    [
                      _vm.postSaleOrder.auditStatus === "NOT_APPROVED"
                        ? _c(
                            "el-form-item",
                            {
                              key: "auditStatus",
                              attrs: {
                                label: _vm.$i18n.t("common.result"),
                                prop: "auditStatus",
                                "label-width": _vm.formLabelWidth
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formBody.auditStatus,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formBody, "auditStatus", $$v)
                                    },
                                    expression: "formBody.auditStatus"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "ADOPT" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.adopt"))
                                      )
                                    ]
                                  ),
                                  _c("el-radio", { attrs: { label: "FAIL" } }, [
                                    _vm._v(_vm._s(_vm.$i18n.t("common.Failed")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            {
                              key: "finish",
                              attrs: {
                                label: _vm.$i18n.t("common.result"),
                                prop: "finish",
                                "label-width": _vm.formLabelWidth
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formBody.finish,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formBody, "finish", $$v)
                                    },
                                    expression: "formBody.finish"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v(
                                      _vm._s(_vm.$i18n.t("common.Inprogress"))
                                    )
                                  ]),
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v(
                                      _vm._s(_vm.$i18n.t("common.Completed"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                      _c(
                        "el-form-item",
                        {
                          key: "description",
                          attrs: {
                            label: _vm.$i18n.t("common.reason"),
                            prop: "description",
                            "label-width": _vm.formLabelWidth
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.formBody.description,
                              callback: function($$v) {
                                _vm.$set(_vm.formBody, "description", $$v)
                              },
                              expression: "formBody.description"
                            }
                          }),
                          _vm._v(
                            "\n                        *" +
                              _vm._s(
                                _vm.$i18n.t(
                                  "common.Thereplycontentwillbedisplayedtotheuser"
                                )
                              ) +
                              "\n                    "
                          )
                        ],
                        1
                      ),
                      _vm.formBody.auditStatus === "FAIL"
                        ? _c(
                            "el-form-item",
                            {
                              key: "disableOrder",
                              attrs: {
                                label: _vm.$i18n.t(
                                  "common.Pleasechoosewhethertocontinueapplying"
                                ),
                                prop: "disableOrder",
                                "label-width": _vm.formLabelWidth
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formBody.disableOrder,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formBody,
                                        "disableOrder",
                                        $$v
                                      )
                                    },
                                    expression: "formBody.disableOrder"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "false" } },
                                    [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))]
                                  ),
                                  _c("el-radio", { attrs: { label: "true" } }, [
                                    _vm._v(_vm._s(_vm.$i18n.t("common.no")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          !_vm.postSaleOrder.finish
            ? _c(
                "div",
                { staticClass: "table-div flex-button" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog(false)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit("variantFrom")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.Submit")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.postSaleRecord && _vm.postSaleRecord.length > 0
            ? _c(
                "div",
                { staticClass: "table-div" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.postSaleRecord, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Serialnumber"),
                          align: "center",
                          prop: "postSaleRecordId"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.result"),
                          align: "center",
                          prop: "price"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.recordType === "Audit"
                                    ? _c("dataText", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          clearable: "",
                                          codeType: "audit_status"
                                        },
                                        model: {
                                          value: scope.row.auditStatus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "auditStatus",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.auditStatus"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.finish
                                                ? _vm.$i18n.t("common.complete")
                                                : _vm.$i18n.t("common.result")
                                            )
                                        )
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          524131465
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Replycontent"),
                          align: "center",
                          prop: "description"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.time"),
                          align: "center",
                          prop: "createTime"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          scope.row.createTime
                                        )
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          921844889
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Operator"),
                          align: "center",
                          prop: "createUserName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c("div", {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer"
      }),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.OrderDetails"),
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("OrderDetailDialog", {
                attrs: { formItem: _vm.orderFormItem },
                on: { closeDialog: _vm.closeWindow }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }