<template>
    <div>
       
        <el-form :inline="true">
 
            <el-form-item>
                <el-input v-model="listQueryData.idFuzzy" :placeholder="$i18n.t('common.SKU_EAN_Encoding')" clearable></el-input>
            </el-form-item>

            <el-form-item>
                <el-select v-model="listQueryData.effectShelf" :placeholder="$i18n.t('common.state')"  clearable  class="filter-item" style="width: 100px; "   >
                    <el-option :label="$i18n.t('common.Listed')"   value="true"></el-option>
                    <el-option :label="$i18n.t('common.Notlisted')"  value="false"></el-option>
                </el-select>
            </el-form-item>
 

            <el-form-item >
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleCurrentChange(1)"
                            style="margin-left: 10px">{{ $i18n.t("common.query") }}  </el-button>
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px"
                        size="small">{{ $i18n.t("common.Reset") }}  
                </el-button>

            </el-form-item>

        </el-form>  
                        
       
        <el-table ref="tableData" :data="pageData.list" style="width: 100%" height="500" border row-key="goodsId" default-expand-all
            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
            :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
            id="goodsTable"
            >
 
            <el-table-column width="55" align="center" type=''>
                <template slot-scope="scope">
                    <div class="seRadio">
                        <el-radio v-model="selectionId" :label="scope.row.goodsId" :disabled="!scope.row.checkboxStatus" @change="selectionChange(scope.row)"
                        >{{ '' }}
                        </el-radio>
                    </div>
                </template>
            </el-table-column>

            
            <!-- 用于显示子表格张开按钮 -->
            <el-table-column prop="quantityType" label="" width="50px" align="center">
            </el-table-column>
            
            
            <el-table-column prop="goodsId" :label="$i18n.t('common.ProductID')"  align="center"
                                show-overflow-tooltip></el-table-column>
            <el-table-column prop="goodsCode" :label="$i18n.t('common.SKU_EAN_Encoding')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>SKU:{{ scope.row.sku }}</span
                    ><br/>
                    <span>EAN:{{ scope.row.barCode }}</span
                    ><br/>
                    <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                </template>
            </el-table-column>



            <el-table-column prop="imageUrl" :label="$i18n.t('common.Productimage1')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image
                            style="height: 100px"
                            v-if="scope.row.imageUrl"
                            :src="ossHost + scope.row.imageUrl"
                            fit="fill"
                    ></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="packageQuantity" :label="$i18n.t('common.PackingQuantity')"  align="center"
                                show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" :label="$i18n.t('common.ProductName')" align="center"
                                show-overflow-tooltip></el-table-column>
            <el-table-column prop="goodsSpecials" :label="$i18n.t('common.Countrieslisted')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        <span v-if="s.shelf">{{
                            $dict.getDictValue('countrys', s.countryCode)
                            }}<br/></span>
                    </span>
                </template>
            </el-table-column>
<!--            <el-table-column prop="endTime" label="促销价" align="center" show-overflow-tooltip>
                <template slot-scope="scope" v-if="scope.row.prices.length > 0">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            {{ $dict.getDictValue('countrys', s.countryCode) }}:<span
                                v-if="s.promotionPrice">{{
                            s.promotionPrice.toFixed(2)
                            }}{{ $dict.getDictCurrencyValue(s.countryCode) }}</span
                        ><br/>
                        </span>
                </template>
            </el-table-column>-->
            <el-table-column prop="prices" :label="$i18n.t('common.Promotionprice1')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                                <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                    <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.NewUserPromotion") }} <br/></span>
                                    <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                    </span>
                                </span>
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                    <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.RegularUserPromotion") }}<br/></span>
                                    <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                    </span>
                                    </span>
                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="prices" :label="$i18n.t('common.fixedprice')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.resultPrice">{{
                        s.resultPrice.toFixed(2)
                        }}{{ $dict.getDictCurrencyValue(s.countryCode) }}</span
                    ><br/>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="prices" :label="$i18n.t('common.Fixedprice1')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-if="s.fixedPrice">{{ s.countryCode }}:{{
                            Number(s.fixedPrice).toFixed(2)
                            }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="prices" :label="$i18n.t('common.Lowestprice')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                            Number(s.lowestPrice).toFixed(2)
                            }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="shelf" :label="$i18n.t('common.state')"   align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }}</span>
                    <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                </template>
            </el-table-column>
 

        </el-table>
                
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="listQueryData.page"
                :page-size="listQueryData.size" layout="total, prev, pager, next, jumper" :total="pageData.total">
            </el-pagination>                       
        </div>

        <span slot="footer" class="dialog-footer">           
            <span>{{ $i18n.t("common.Selected") }}:<span style="color: #008000;font-size: 16px;font-weight: 800;">{{pageDataSelectList.length}}</span></span>       
            <el-button style="width: 20%;margin-left: 50px;" type="primary" @click="handleGoodsSelected">{{ $i18n.t("common.Confirm") }}</el-button>
        </span>   

    </div>
</template>
<script>
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
 

export default {
    components: {
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        tableList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            listQueryData: {               
                idFuzzy: '',
                preSaleCountryFlag: 'false',            
                effectShelf: null, //是否上架
                page: 1,
                size: 10
            },
            defaultListQueryData: {
                idFuzzy: '',
                preSaleCountryFlag: 'false',
                effectShelf: null, //是否上架
                page: 1,
                size: 10
            },
            pageData: {
                list: []
            },
            //确认后的被选中的商品
            pageDataSelectList: [],
            selectionId: '',
            //被选中的商品
            selectedRow: {},
            flag: false,
            ossHost: process.env.VUE_APP_OSS_Head,
  
        };
    },
    mounted() {
        // 执行查询
        this.cleanQuery();
        if(this.tableList.length > 0){
            this.selectionId = this.tableList[0].goodsId;
            this.pageDataSelectList = JSON.parse(JSON.stringify(this.tableList))   
        }
    },
    methods: {
        
 
        // 查询
        handleCurrentChange(val) {
            this.listQueryData.page = val;
            this.loadlist();
        },
        // 重置
        cleanQuery() {
            this.listQueryData = Object.assign({}, this.defaultListQueryData); 
            this.loadlist();
        },
         // 商品查询
        loadlist(){
            let temp = JSON.stringify(this.listQueryData, (key, value) => {
                if (value !== null && value !== '') {
                    return value;
                }
            });
            let data = JSON.parse(temp);
            this.$store.dispatch('postGoodsSearch', data).then((res) => {
                this.pageData = res;
                 
            });
        },
 
        //选中商品事件
        selectionChange(row) {
            this.selectedRow = Object.assign({}, row);     
            this.pageDataSelectList = [];
            this.pageDataSelectList.push(this.selectedRow);
        },
        
        //选择商品
        handleGoodsSelected() {
            if (!this.selectedRow) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseselectaproductfirst") ,
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            this.$emit('handleGoodsSelected',JSON.parse(JSON.stringify(this.pageDataSelectList)));
        },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped>
 
 

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: 30px; */
}
  
  
 
 
</style>
