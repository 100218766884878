var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { "margin-left": "50px" },
          attrs: {
            "label-position": "left",
            size: "small",
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.langsArr, function(item, index) {
              return _c(
                "el-tab-pane",
                {
                  key: index,
                  attrs: { label: item.langName, name: item.code }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.ActivityMode"),
                        prop: "activityType"
                      }
                    },
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          codeType: "gift_activity_type",
                          placeholder: _vm.$i18n.t("common.ActivityType")
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: item.activityType,
                          callback: function($$v) {
                            _vm.$set(item, "activityType", $$v)
                          },
                          expression: "item.activityType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.ActivitySorting"),
                            prop: "sort"
                          }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              class: item.lang,
                              attrs: {
                                data: item.tableData,
                                border: "",
                                fit: "",
                                "highlight-current-row": ""
                              }
                            },
                            [
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    prop: "sort",
                                    label: _vm.$i18n.t("common.DragToSort"),
                                    align: "center"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: " el-icon-sort drag-btn"
                                  })
                                ]
                              ),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "giftActivityName",
                                  label: _vm.$i18n.t("common.ActivityName"),
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "pageName",
                                  label: _vm.$i18n.t(
                                    "common.LandingPageActivityName"
                                  ),
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "giftActivityId",
                                  label: _vm.$i18n.t("common.ActivityCode"),
                                  align: "center"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.cancel")))
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.saveGiftActivitySort }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }