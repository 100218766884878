<template>
	<div>
		<el-upload v-if="formListQuery.bannerFiles[index].videoUrl==''" class='avatar-uploader' :action='ossInfo.ossSignature.host'
			:show-file-list='false' :auto-upload='false' list-type='picture-card' ref='uploadVideo'
			:on-progress='uploadVideoProcess' :on-change='handleChange' :data='fileDataObj' accept='.mp4'>
			<i v-if='videoUploadPercent==0' class='el-icon-plus avatar-uploader-icon'></i>
			<el-progress v-else-if='videoUploadPercent>0' type='circle' :percentage='(videoUploadPercent)'
				style='margin-top:30px;width: 178px;height: 178px;'></el-progress>
		</el-upload>
		<video class='float-l' style='width: 178px;height: 178px;' v-else :src='formListQuery.bannerFiles[index].videoUrl'
			controls='controls'>
			您的浏览器不支持视频播放
		</video>
		<div style='margin-left: 20px;display: flex;justify-content: flex-start;align-items: flex-end;'>
			<span v-if="formListQuery.bannerFiles[index].videoUrl==''">支持mp4格式，≤500MB</span>
			<el-button v-else type='primary' @click='backVideo' plain>重新上传</el-button>
		</div>
	</div>
</template>

<script>
	import X2JS from 'x2js'; //解析xml
	export default {
	    data() {
	        return {
				host:process.env.VUE_APP_OSS_Head
	        }
	    },
		props:{
			formListQuery: {
				type: Object,
				default: () => {
					return {};
				}
			},
			index:{
				type: Number,
				default: 0
			},
			countryCode:{
				type: String,
				default: 'CN'
			}
		},
		data(){
			return{
				fileDataObj:{},
				videoUrl:'',
				ossVideoUrl:'',
				videoFlag: 'false',
				videoUploadPercent: 0,
				ossInfo: {
					ossHost: process.env.VUE_APP_OSS_Head,				
					ossSignature: {
						host: ''
					},
					activityTranslates: [],
					langs: [],
					seoIndex: '0',
					newFiles: []
				}
			}
		},
		mounted() {
			this.getOssSignature();
		},
	    methods: {
			//获取OSS签名
			getOssSignature() {
				this.$store
					.dispatch('getSignature', {
						dir: 'b2c'
					})
					.then((res) => {
						this.ossInfo.ossSignature = res;
						this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
					});
			},
			// uploadSuccess(file){
			// 	console.log('file++',file)
			// 	let testFile = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
			// 	const extension = testFile === 'mp4';
			// 	const isLt1M=file.size>1024 * 1024 * 500
			// 	if (!extension) {
			// 	        this.$message.error('上传视频只支持mp4');
			// 	        this.$set(this.formListQuery.bannerFiles[this.index],'videoUrl','')
			// 	        return false;
			// 	}
			// 	if (isLt1M) {
			// 		this.$message.error('上传视频大小不能超过 500MB!');
			// 		this.$set(this.formListQuery.bannerFiles[this.index],'videoUrl','')
			// 		return false;
			// 	}
			// 	return 	(extension) && isLt1M		
			// },
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.videoUploadPercent = parseInt(file.percentage.toFixed(0));
			},
			//关闭视频
			backVideo() {
				this.videoUploadPercent = 0;
				this.ossVideoUrl = '';
				this.$set(this.formListQuery.bannerFiles[this.index],'videoUrl','')
			},
			handleChange(file, fileLis){
				const fileType = ['video/mp4']
				if (fileType.indexOf(file.raw.type) == -1) {
					this.$message.error('上传视频只支持mp4');
					return;
				}
				if (file.size>1024 * 1024 * 500) {
					this.$message.error('上传视频大小不能超过 500MB!');
					return;
				}
				let dataObj = {
					name: file.name,
					key: this.ossInfo.ossSignature.dir +
						'/' +
						new Date().getFullYear() +
						'/' +
						this.randomString() +
						this.getSuffix(file.name),
					policy: this.ossInfo.ossSignature['policy'],
					OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
					success_action_status: '201',
					callback: '',
					signature: this.ossInfo.ossSignature['signature']
					// file: file.file,
				};
				file.type = 'add';
				this.fileDataObj = dataObj;
				setTimeout(() => {
					this.$refs.uploadVideo.submit();
					let x2js = new X2JS();
					console.log('file.response',file.response)
					let jsonObj = x2js.xml2js(file.response);
					console.log(jsonObj);
					let ossUrl = jsonObj.PostResponse.Location;
					this.ossVideoUrl = ossUrl;
					this.formListQuery.bannerFiles.forEach((item,index)=>{
						if(item.countryCode==this.countryCode){
							// this.$set(this.formListQuery.bannerFiles[index],'imgUrl','')
							this.$set(this.formListQuery.bannerFiles[index],'videoUrl',this.ossVideoUrl)
							this.$set(this.formListQuery.bannerFiles[index],'countryCode',this.countryCode)
						}
					})
					if (file.status === 'success') {
						this.videoUploadPercent = 0;
					}
				this.$emit('handleFormData',this.formListQuery)	
				}, 500);
			}
	        // handleChange(file, fileLis) {
	        // 	let dataObj = {
	        // 		name: file.name,
	        // 		key: this.ossInfo.ossSignature.dir +
	        // 			'/' +
	        // 			new Date().getFullYear() +
	        // 			'/' +
	        // 			this.randomString() +
	        // 			this.getSuffix(file.name),
	        // 		policy: this.ossInfo.ossSignature['policy'],
	        // 		OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
	        // 		success_action_status: '201',
	        // 		callback: '',
	        // 		signature: this.ossInfo.ossSignature['signature']
	        // 		// file: file.file,
	        // 	};
	        // 	file.type = 'add';
	        // 	this.fileDataObj = dataObj;
	        // 	setTimeout(() => {
	        // 		this.$refs.uploadVideo.submit();
	        // 		let x2js = new X2JS();
	        // 		console.log('file.response',file.response)
	        // 		let jsonObj = x2js.xml2js(file.response);
	        // 		console.log(jsonObj);
	        // 		let ossUrl = jsonObj.PostResponse.Location;
	        // 		this.ossVideoUrl = ossUrl;
	        // 		this.formListQuery.bannerFiles.forEach((item,index)=>{
	        // 			if(item.countryCode==this.countryCode){
	        // 				this.$set(this.formListQuery.bannerFiles[index],'imgUrl','')
	        // 				this.$set(this.formListQuery.bannerFiles[index],'videoUrl',this.ossVideoUrl)
	        // 				this.$set(this.formListQuery.bannerFiles[index],'countryCode',this.countryCode)
	        // 			}
	        // 		})
	        // 		this.$emit('handleFormData',this.formListQuery)
	        // 	}, 500);
	        // },
	    }
	}
</script>

<style scoped>
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		height: 178px;
		display: block;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 178px !important;
		height: 178px !important;
	}
	
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	
	/deep/ .avatar-uploader .el-upload--picture-card {
		width: 178px !important;
		height: 178px !important;
	}
	
	/deep/ .avatar-uploader .el-progress--circle {
		width: 176px !important;
		height: 176px !important;
		margin-top: 0px !important;
	}
	
	/deep/ .avatar-uploader .el-progress {
		width: 176px !important;
		height: 176px !important;
		margin-top: 0px !important;
	}
	
	/deep/ .avatar-uploader .el-progress-circle {
		width: 176px !important;
		height: 176px !important;
		margin-top: 0px !important;
	}
	
	.avatar {
		height: 178px;
		display: block;
	}
	
	.uploadTitle {
		width: 120px;
		text-align: end;
		padding-right: 12px;
		box-sizing: border-box;
	}
	
	.uploadTitle:before {
		content: '*';
		color: #F56C6C;
		margin-right: 4px;
	}
</style>