<template>
    <div>
        <el-form :model="addSetting" size="medium" label-width="130px">
            <el-form-item :required="true" :label="$i18n.t('common.LandingPageDisplay')" prop="pageStatus">
                <el-switch v-model="addSetting.pageStatus" active-color="#13ce669"></el-switch>
            </el-form-item>
            <el-form-item :label="$i18n.t('common.LandingPageActivityName')" prop="pageName">
                <el-input v-model="addSetting.pageName" style="width: 100%" maxlength="50" :placeholder="$i18n.t('common.LandingPageActivityName')"></el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('common.ActivityDescription')">
                <wangEditer ref="wangEditer"  style="margin-top: 10px" />
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelCustomDialog()"> {{ $i18n.t('common.cancel') }} </el-button>
            <el-button type="primary" @click="confirmCustomDialog()"> {{ $i18n.t('common.confirm') }} </el-button>
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';
import wangEditer from '@/components/common/WangEditer/index';

export default {
    name: 'giftSort',
    components:{
        wangEditer
    },
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            addSetting: {
                giftActivityId:null,
                pageStatus:false,
                pageName:"",
                pageContent:""
            }
        };
    },
    created() {},
    methods: {
        confirmCustomDialog() {
            let result = '';
            //获取组件中的内容
            if (this.$refs['wangEditer'].getHtml() != undefined) {
                result = this.$refs['wangEditer'].getHtml().slice(0, 9999);
            }
            this.addSetting.pageContent = result;
            this.$store.dispatch('updateGiftActivityPageSetting', this.addSetting).then((res) => {
                this.$message({
                    message: this.$i18n.t('common.Successfullysaved'),
                    type: 'success'
                });
                this.$emit('closeGiftPageSetting');
            });
        },
        cancelCustomDialog() {
            this.$emit('closeGiftPageSetting');
        },
        init(row){
            this.addSetting.giftActivityId = row.giftActivityId;
            this.addSetting.pageStatus = row.pageStatus;
            this.addSetting.pageName = row.pageName;
            this.addSetting.pageContent = row.pageContent;
            setTimeout(() => {
                this.$refs['wangEditer'].initData(this.addSetting.pageContent);
            }, 100);
        }
    }
};
</script>

<style>
.btns {
    text-align: center;
    margin-top: 20px;
}
</style>
