var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      attrs: { type: "card" },
      on: { "tab-click": _vm.handleTabsClick },
      model: {
        value: _vm.activeName,
        callback: function($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName"
      }
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: _vm.$i18n.t("common.shoppingcart"), name: "cart" } },
        [_c("UserActivityTable", { ref: "cart-table" })],
        1
      ),
      _c(
        "el-tab-pane",
        {
          attrs: {
            label: _vm.$i18n.t("common.OfflineShoppingCart"),
            name: "offlineCart"
          }
        },
        [_c("UserActivityTable", { ref: "offline-cart-table" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }