<template>
    <div style="display: flex; justify-content: flex-start">
        <el-upload
            class="avatar-uploader"
            :class="{disabled: uploadDisabled}"
            :action="ossInfo.ossSignature.host"
            :show-file-list="true"
            :auto-upload="false"
            :limit="1"
            list-type="picture-card"
            ref="uploadImg"
            :on-change="handleChange"
            :data="fileDataObj"
            :on-error="uploadError"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept=".jpg, .jpeg, .png, .gif , .JPG, .JPEG, .PNG, .GIF"
        >
    
            <i class="el-icon-plus avatar-uploader-icon"></i>
                   
        </el-upload>
 
        <span style='margin-left: 20px;margin-top: 70px;'> {{$i18n.t("common.Suggestedsize")}}：5*1024，≤5MB  </span>
    </div>
</template>

<script>
import X2JS from 'x2js'; //解析xml
export default {
    props: {
        img: String,
        imgIndex: Number
    },
    data() {
        return {
            fileDataObj: {},
            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: { host: '' },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            fileList:[],
     
            imageUrl: this.img
        };
    },
    created() {
        this.getOssSignature();
        
    },
    mounted() {
        this.initFileList();
    },
    
    methods: {
        initFileList(){
            if(this.imageUrl){
                let item ={
                    url: this.ossHost + this.imageUrl
                }
                this.fileList.push(item)
            }
            
        },
        event() {
            this.$emit('event', this.imageUrl,this.imgIndex);
        },
        
        handleChange(file, fileList) {

            const fileType = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
            if (fileType.indexOf(file.raw.type) == -1) {
                this.$message.error( this.$i18n.t("common.Uploadingimagesonlysupports"));
                this.$refs.uploadImg.clearFiles();
                return;
            }

            const isLt = file.size / 1024 / 1024  <= 5;
            if (!isLt) {
                this.$message.error(this.$i18n.t("common.Thesizeoftheuploadedimagecannotexceed") + ' 5MB!');
                this.$refs.uploadImg.clearFiles();
                return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            this.fileDataObj = dataObj;
            console.log('dataObj', this.ossInfo.ossHost);
            let that = this;
            setTimeout(() => {
                this.$refs.uploadImg.submit();
                var x2js = new X2JS();
                var jsonObj = x2js.xml2js(file.response);
                let ossUrl = jsonObj.PostResponse.Location;
                let zi = 'com';
                if (ossUrl.indexOf(zi) !== -1) {
                    that.imageUrl = ossUrl.substr(ossUrl.lastIndexOf(zi) + zi.length);
                }   
                // this.$refs.uploadImg.clearFiles(); 
                that.event();
            }, 500);
        },
        handleRemove(file,fileList){
            this.$refs.uploadImg.clearFiles(); 
            this.imageUrl =''
            this.event();
        },
        uploadError(){
            this.$refs.uploadImg.clearFiles(); 
            this.$message.error(this.$i18n.t("common.FileUploadFailed")); 
        },
        //获取OSS签名
        getOssSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2c'
                })
                .then((res) => {
                    console.log(res);
                    this.ossInfo.ossSignature = res;
                    this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                });
        },
        /**
         * 生成随机文件名
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },

        getSuffix(filename) {
            let pos = filename.lastIndexOf('.');
            let suffix = '';
            if (pos != -1) {
                suffix = filename.substring(pos);
            }
            return suffix;
        }
    },
    computed:{
        uploadDisabled: function(){
            console.log('uploadDisabled',this.imageUrl!='')
            return this.imageUrl!=''
        }
    },
};
</script>



<style scoped>
	
    .disabled /deep/ .el-upload--picture-card{
        display: none;
    }
    
	.avatar {
		height: 100px;
		display: block;
	}


</style>
 
