var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex-between" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "flex-start",
              "align-items": "center"
            }
          },
          [
            _c("el-input", {
              attrs: { placeholder: _vm.$i18n.t("common.Categoryname1") },
              model: {
                value: _vm.title,
                callback: function($$v) {
                  _vm.title = $$v
                },
                expression: "title"
              }
            }),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "20px" },
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleSearchList2 }
              },
              [_vm._v(_vm._s(_vm.$i18n.t("common.query")))]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "20px" },
                attrs: { type: "success", size: "small" },
                on: { click: _vm.addRelation }
              },
              [_vm._v(_vm._s(_vm.$i18n.t("common.addassociations")))]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "header-container" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$i18n.t("common.Maincategory")) +
              ":" +
              _vm._s(_vm.rowInfo.title)
          )
        ])
      ]),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.pageData.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "goodClassifyId",
              label: _vm.$i18n.t("common.ProductCatalogID"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$i18n.t("common.RelatedCategoryName"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$i18n.t("common.Addtime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$i18n.t("common.operation"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handelete(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.remove")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.listQuery2.page,
          "page-size": _vm.listQuery2.limit,
          layout: "total, prev, pager, next, jumper",
          total: _vm.pageData.total
        },
        on: { "current-change": _vm.handleCurrentChange }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.AddAssociatedCategory"),
            visible: _vm.addRelationVisible,
            width: "35%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.addRelationVisible = $event
            }
          }
        },
        [
          _vm.addRelationVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    { ref: "form", attrs: { "label-width": "120px" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Selectmaincategory")
                          }
                        },
                        [
                          _c("el-tree", {
                            ref: "tree",
                            attrs: {
                              data: _vm.treeData,
                              "check-strictly": "",
                              "show-checkbox": "",
                              "node-key": "module_id",
                              props: _vm.defaultProps
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: {
                    click: function($event) {
                      _vm.addRelationVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.postCheckedKeys }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }