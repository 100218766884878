<template>
    <div style="height: 100%;min-height:350px;">
        <div class="filter-container">
            <el-form ref="form" :inline="true" :model="searchData">
                <el-form-item>
                    <!--                    <el-input-->
                    <!--                            style='width: 180px; margin-left: 10px'-->
                    <!--                            class='filter-item'-->
                    <!--                            v-model='searchData.orderNo'-->
                    <!--                            type="number"-->
                    <!--                            placeholder='订单号'-->
                    <!--                    ></el-input>-->
                    <el-input
                        style="width: 180px; margin-left: 10px"
                        v-model="searchData.orderNo"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        clearable
                        maxlength="25"
                        :placeholder='$i18n.t("common.Ordernumber")'
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="searchData.userId"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        clearable
                        :placeholder='$i18n.t("common.UserID")'
                    ></el-input>
                </el-form-item>
                <!--<el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="searchData.brandCode"
                        clearable
                        :codeType="'brand'"
                        :placeholder='$i18n.t("common.brand")'
                    ></dataSelect>
                </el-form-item>-->
                <el-form-item>
                    <dataSelect
                        :multiple="false"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="searchData.postSaleType"
                        clearable
                        :codeType="'post_sale_type'"
                        :placeholder='$i18n.t("common.type")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="searchData.countryCodes"
                        clearable
                        :multiple="true"
                        :codeType="'countrys'"
                        :placeholder='$i18n.t("common.country")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>

                    <el-select v-model="searchData.orderSource" clearable  :placeholder='$i18n.t("common.Pleaseselectasource")'>

                        <el-option v-for="item in distributionlist" :key="item.distributionId" :label="item.title" :value="item.title">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        :multiple="false"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="searchData.orderStatus"
                        clearable
                        :codeType="'order_status'"
                        :placeholder='$i18n.t("common.OrderStatus")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        :multiple="false"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="searchData.auditStatus"
                        clearable
                        :codeType="'audit_status'"
                        :placeholder='$i18n.t("common.Auditstatus")'
                    ></dataSelect>
                </el-form-item>

                <el-form-item >
                    <el-select
                        v-model="searchData.finish"
                        :placeholder='$i18n.t("common.Processingstatus")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.Processingstatus")' value="true"></el-option>
                        <el-option :label='$i18n.t("common.Inprogress")' value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item >
                    <el-select
                    width="auto"
                        v-model="searchData.disableOrder"
                        :placeholder='$i18n.t("common.Pleasechoosewhethertocontinueapplying")'
                        clearable
                     
                       
                    >
                        <el-option :label='$i18n.t("common.yes")' value="false"></el-option>
                        <el-option :label='$i18n.t("common.no")'  value="true"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="valuePicker"
                        type="datetimerange"
                   
                        :range-separator='$i18n.t("common.to")'
                            :start-placeholder='$i18n.t("common.Applicationtime")'
                            :end-placeholder='$i18n.t("common.Applicationtime")'
                        style="margin-left: 10px"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick" style="margin-left: 10px">
                        {{ $i18n.t("common.query") }}
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanQuery"
                        style="margin-left: 10px"
                        size="small"
                    >
                    {{ $i18n.t("common.Reset") }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="margin: 20px 0px 20px 20px"></div>
        <el-table ref="pagedata" :data="pageData.list" border style="width: 100%" height="calc(100% - 170px)">


            <!-- <el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip>
                 <template slot-scope="scope">
                     <dataText
                         v-if="scope.row.orderFullInfoDto != null"
                         v-model="scope.row.orderFullInfoDto.brandCode"
                         :codeType="'brand'"
                     ></dataText>
                 </template>
             </el-table-column>-->
             <el-table-column prop="postSaleType" :label='$i18n.t("common.Aftersalestype")'  align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <dataText v-model="scope.row.postSaleType" :codeType="'post_sale_type'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="orderFullInfoDto.orderNo" :label='$i18n.t("common.Ordernumber")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText
                        v-if="scope.row.orderFullInfoDto != null"
                        v-model="scope.row.orderFullInfoDto.countryCode"
                        :codeType="'countrys'"
                    ></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="orderFullInfoDto.userId" :label='$i18n.t("common.UserID")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="orderFullInfoDto" :label='$i18n.t("common.Productquantity")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.orderFullInfoDto != null">
                        {{
                            scope.row.orderFullInfoDto && scope.row.orderFullInfoDto.orderItemList
                                ? scope.row.orderFullInfoDto.orderItemList.length
                                : 0
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column prop="orderFullInfoDto.source" :label='$i18n.t("common.source")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="orderFullInfoDto.totalAmt" :label='$i18n.t("common.money")' align="center" show-overflow-tooltip>
                <template v-slot="scope">
                    <dataText  v-if="scope.row.orderFullInfoDto != null" v-model="scope.row.orderFullInfoDto.currencyCode" :codeType="'currency_code'">
                        {{ scope.row.orderFullInfoDto.totalAmt }}
                    </dataText>
                </template>
            </el-table-column>
            <el-table-column prop="orderFullInfoDto.orderStatus" :label='$i18n.t("common.OrderStatus")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText  v-if="scope.row.orderFullInfoDto != null" v-model="scope.row.orderFullInfoDto.orderStatus" :codeType="'order_status'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="description" :label='$i18n.t("common.explain")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="imgUrls" :label='$i18n.t("common.Numberofexplanatoryimages")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    {{ row.imgUrls ? row.imgUrls.length : 0 }}
                </template>
            </el-table-column>
            <el-table-column prop="imgUrls" :label='$i18n.t("common.Illustrativeimage")' align="center">
                <template slot-scope="{ row }">
<!--                    <el-image-->
<!--                        v-if="row.imgUrls && row.imgUrls.length > 0"-->
<!--                        style="width: 100px; height: 100px; margin: 0 10px 10px 0"-->
<!--                        :src="ossHost + row.imgUrls[0]"-->
<!--                        :preview-src-list="getFullUrl(row)"-->
<!--                    ></el-image>-->
                    <listImage v-if="row.imgUrls && row.imgUrls.length > 0" :imgUrl="row.imgUrls[0]" :imgUrls="row.imgUrls"></listImage>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" :label='$i18n.t("common.Applicationtime")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="logisticsCode" :label='$i18n.t("common.Returnlogisticstrackingcode")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="auditStatus" :label='$i18n.t("common.Auditstatus")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <dataText v-model="row.auditStatus" :codeType="'audit_status'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="finish" :label='$i18n.t("common.Processingstatus")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    {{ row.finish ?  $i18n.t("common.Completed") :$i18n.t("common.Inprogress")  }}
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.CanIcontinuetoapply")' prop="disableOrder" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    {{ row.disableOrder ? $i18n.t("common.no") : $i18n.t("common.yes") }}
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.auditStatus === 'NOT_APPROVED'" @click="edit(scope.row, $i18n.t('common.Auditing'))" type="text" size="small"
                        >{{  $i18n.t("common.operation")  }}
                    </el-button>
                    <el-button v-else-if="scope.row.finish" @click="edit(scope.row, $i18n.t('common.details'))" type="text" size="small"> {{  $i18n.t("common.details")  }} </el-button>
                    <el-button v-else @click="edit(scope.row, $i18n.t('common.handle') )" type="text" size="small">{{  $i18n.t("common.handle")  }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="searchData.page"
                :page-size="searchData.size"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
        </div>
        <el-dialog :title="title" width="70%" v-if="dialogFormVisible" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <varoant-dialog @closeDialog="closeWindow" :formItem="formItem" :isEdit="isEdit" />
        </el-dialog>
    </div>
</template>
<script>
import VaroantDialog from './components/VaroantDialog.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import listImage from '@/components/common/ListImage.vue';
import moment from 'moment';

import Scene from '@/utils/scene';

export default {
    components: { VaroantDialog, FormTableDialog, dataSelect, moment, dataText , listImage},
    name: 'recommend_module',
    data() {
        return {
            //查询项
            searchData: {
                orderNo: '',
                orderCode: null,
                countryCodes: [],
                brandCode: null,
                postSaleType: null,
                auditStatus: null,
                finish: null,
                disableOrder: null,
                beginTime: null,
                endTime: null,
                orderStatus: null,
                orderSource: null,
                userId: null,
                page: 1,
                limit: 10
            },
            defaultSearchData: {
                orderNo: '',
                orderCode: null,
                countryCodes: [],
                brandCode: null,
                postSaleType: null,
                auditStatus: null,
                finish: null,
                disableOrder: null,
                beginTime: null,
                endTime: null,
                orderStatus: null,
                userId: null,
                page: 1,
                limit: 10
            },
            //时间选择器
            valuePicker: [],
            //组件标签是否显示
            arg: {
                name: true,
                brand: true,
                category: true
            },
            //组件标签是否显示
            argFrom: {
                code: true,
                put: true
            },
            isEdit: false,
            //表单数据
            formItem: {},
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
            dialogFormTableVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
            distributionlist: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$i18n.t("common.today"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text:this.$i18n.t("common.Lastweek"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.Thismonth"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.Lastmonth1"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.thisyear"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    watch: {
        $route(to, from) {
            // 在mounted函数执行的方法，放到该处
            if (to.path === '/b2c/postSale/index') {
                this.routerHandler();
            }
        }
    },

    created() {
        this.ossHostHandler();
        this.getDictItems();
        this.getDistributionlist();
        this.routerHandler();
    },
    methods: {

        ossHostHandler(){
            if(Scene.isAigoStar){
                this.ossHost = process.env.VUE_APP_OSS_Head_SDP
                // console.log('ossHostHandler',this.ossHost,process.env.VUE_APP_OSS_Head_SDP)
            }
           
        },

        /**
         * 处理路由跳转参数赋值
         */
        routerHandler() {
            var orderNo = this.$route.params.postOrderNo;
            this.searchData = Object.assign({}, this.defaultSearchData);
            if (orderNo) {
                this.searchData.orderNo = orderNo;
            }
            var userId = this.$route.params.userId;
            if (userId) {
                this.searchData.userId = userId;
            }
            this.loadlist();
        },
        getDistributionlist() {
            this.$store.dispatch('getDistributionlist', null).then((res) => {
                this.distributionlist = res;
            });
        },

        getFullUrl(row) {
            if (row.imgUrls && row.imgUrls.length > 0) {
                return row.imgUrls.map((value) => {
                    return this.ossHost + value;
                });
            }
            return [];
        },
        searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        loadlist() {
            var _this = this;
            if (this.valuePicker != null && this.valuePicker.length > 0) {
                this.searchData.beginTime = moment(this.valuePicker[0]).unix() * 1000;
                this.searchData.endTime = moment(this.valuePicker[1]).unix() * 1000;
            } else {
                this.searchData.beginTime = null;
                this.searchData.endTime = null;
            }
            this.$store.dispatch('getPostSalePage', this.searchData).then((res) => {
                _this.pageData = res;
                setTimeout(()=>{
                    _this.$refs.pagedata.doLayout()
                },500)
            });
        },
        add() {
            this.isEdit = false;
            this.title = this.$i18n.t("common.Add1");
            this.formItem = {
                goodClassId: '',
                countryCode: '',
                status: null,
                position: '',
                brandCode: null,
                recommendModule: ''
            };

            this.dialogFormVisible = true;
        },
        edit(row, title) {
            this.title = title;
            this.isEdit = true;
            this.formItem = Object.assign({}, row);
            this.dialogFormVisible = true;
        },
        preview(row) {
            var url = `${this.webSiteUrl}promotionTheme?id=${row.activityId}`;
            window.open(url, '_blank');
        },
        del(row) {
            var _this = this;
            this.$confirm(this.$i18n.t("common.confirmdeletion")+"?", this.$i18n.t("common.prompt"), {
                confirmButtonText:this.$i18n.t("common.confirm1"),
                cancelButtonText: this.$i18n.t("common.cancel"),
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('delActivity', { id: row.activityId }).then((res) => {
                        if (res) {
                            this.$message.success(this.$i18n.t("common.Successfullydeleted"));
                            this.loadlist();
                        }
                    });
                })
                .catch(() => {});
        },
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
        formInit() {
            var _this = this;
            if (this.$refs.formItem !== undefined) {
                this.$refs.formItem.initFormData(this.formItem);
                this.$refs.formItem.initDictData({ countrys: _this.countrysDicts, platforms: _this.platformDicts });
            } else {
                setTimeout(() => {
                    this.$refs.formItem.initFormData(this.formItem);
                    this.$refs.formItem.initDictData({ countrys: _this.countrysDicts, platforms: _this.platformDicts });
                }, 500);
            }
        },
        closeWindow(status) {
            this.dialogFormVisible = false;
            if (status) {
                this.loadlist();
            }
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'platform' }).then((res) => {
                if (res != null) _this.platformDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
        },
        cleanQuery() {
            this.searchData.orderCode = '';
            this.searchData.countryCodes = [];
            this.searchData.brandCode = null;
            this.searchData.orderNo = null;
            this.searchData.userId = null;
            this.searchData.sourceId = null;
            this.searchData.postSaleType = null;
            this.searchData.auditStatus = null;
            this.searchData.finish = null;
            this.searchData.disableOrder = null;
            this.searchData.endTime = null;
            this.searchData.beginTime = null;
            this.searchData.orderStatus = null;
            this.searchData.orderSource = null;
            this.valuePicker = [];
            this.searchClick();
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.my-content {
    background: #fde2e2;
}


.el-form .el-select{
  width: 100%;
}

</style>
