var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c("filter-container", {
        attrs: {
          arg: _vm.arg,
          placeholder: _vm.placeholder,
          dictionary: _vm.dictionary,
          defaultListQuery: _vm.defaultListQuery
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch
        }
      }),
      _c(
        "div",
        { staticStyle: { margin: "10px 0px 10px 20px" } },
        [
          _vm.$common.hasPermission("Create", _vm.module_code)
            ? _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: { click: _vm.add }
                },
                [_vm._v("  " + _vm._s(_vm.$i18n.t("common.Add1")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            height: "calc(100% - 170px)",
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "distributionId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.typeName + _vm.$i18n.t("common.name"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsId",
              label: _vm.$i18n.t("common.ProductID1"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agbsActivityTitle",
              label: _vm.$i18n.t("common.barcode"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.goodsMergeListMgrDto.barCode))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bannerFiles",
              label: _vm.$i18n.t("common.picture"),
              align: "center",
              width: "130px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("listImage", {
                      attrs: { imgUrl: scope.row.goodsMergeListMgrDto.imageUrl }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsMergeListMgrDto.title",
              label: _vm.$i18n.t("common.name"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            staticStyle: {
              width: "100px",
              overflow: "hidden",
              "white-space": "nowrap",
              "text-overflow": "ellipsis",
              "-o-text-overflow": "ellipsis"
            },
            attrs: {
              prop: "treeList",
              label: _vm.$i18n.t("common.Category"),
              align: "center",
              height: "55",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(
                    scope.row.goodsMergeListMgrDto.treeList,
                    function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _vm._l(item.parents, function(item2, index2) {
                            return _c("span", { key: index2 }, [
                              _c("span", [_vm._v(_vm._s(item2.title) + ">")])
                            ])
                          }),
                          _c("span", [_vm._v(_vm._s(item.title))])
                        ],
                        2
                      )
                    }
                  )
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.DistributionCountry"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.$dict.getDictValue(
                              "countrys",
                              scope.row.countryCode
                            )
                          )
                      ),
                      _c("br")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsMergeListMgrDto.packageQuantity",
              label: _vm.$t("common.PackingQuantity"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status1",
              label: _vm.$i18n.t("common.Activitystatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { style: { color: scope.row.statusColor } }, [
                      _vm._v(_vm._s(scope.row.status1))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "startTime",
              label: _vm.$i18n.t("common.starttime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.startTime
                      ? _c("span", [
                          _vm._v(
                            "\n            \t\t\t" +
                              _vm._s(_vm._f("formatDate")(scope.row.startTime))
                          ),
                          _c("br")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTime",
              label: _vm.$i18n.t("common.Endtime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.endTime
                      ? _c("span", [
                          _vm._v(
                            "\n            \t\t\t" +
                              _vm._s(_vm._f("formatDate")(scope.row.endTime))
                          ),
                          _c("br")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "prices",
              label: _vm.$i18n.t("common.Lowestprice"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.goodsMergeListMgrDto.prices, function(
                    s,
                    index
                  ) {
                    return _c("span", { key: index }, [
                      s.lowestPrice
                        ? _c("span", [
                            _vm._v(
                              _vm._s(s.countryCode) +
                                ":" +
                                _vm._s(Number(s.lowestPrice).toFixed(2)) +
                                _vm._s(
                                  _vm.$dict.getDictCurrencyValue(s.countryCode)
                                )
                            ),
                            _c("br")
                          ])
                        : _vm._e()
                    ])
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "theme",
              label: _vm.$i18n.t("common.fixedprice"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.goodsMergeListMgrDto &&
                    scope.row.goodsMergeListMgrDto.prices.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            scope.row.goodsMergeListMgrDto.prices,
                            function(s, index) {
                              return _c("span", { key: index }, [
                                _vm._v(" " + _vm._s(s.countryCode) + ":"),
                                s.resultPrice
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(Number(s.resultPrice).toFixed(2))
                                      )
                                    ])
                                  : _vm._e(),
                                _c("br")
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "discount",
              label: _vm.$i18n.t("common.discount"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.promotionType == "Discount"
                      ? _c("span", [
                          _vm._v("-" + _vm._s(scope.row.discount) + "%"),
                          _c("br")
                        ])
                      : scope.row.promotionType == "Price"
                      ? _c("span", [
                          _vm._v(
                            "-€" +
                              _vm._s(
                                (
                                  scope.row.goodsPrice - scope.row.discount
                                ).toFixed(2)
                              )
                          ),
                          _c("br")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTime",
              label: _vm.$i18n.t("common.Distributionprice1"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.discountPrice
                      ? _c("span", [
                          _vm._v(
                            _vm._s(scope.row.countryCode) +
                              ":" +
                              _vm._s(scope.row.discountPrice.toFixed(2))
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "freeDelivery",
              label: _vm.$i18n.t("common.Freeshippingornot"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.isFreeFreight == 1
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$i18n.t("common.yes"))),
                          _c("br")
                        ])
                      : scope.row.isFreeFreight == 0
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$i18n.t("common.no"))),
                          _c("br")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTime",
              label: _vm.$t("common.RemainingTotal"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.quantity - scope.row.soldQuantity) +
                          "/" +
                          _vm._s(scope.row.quantity)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lockQuantity",
              label: _vm.$i18n.t("common.DistributionQuantityLock"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.lockQuantity))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$i18n.t("common.Enableornot"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#13ce66"
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateStatus(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createName",
              label: _vm.$i18n.t("common.Creator"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right",
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$common.hasPermission("Update", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.copyUrl(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.CopyLink")))]
                    ),
                    _vm.$common.hasPermission("View", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.toUrl(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.check")))]
                        )
                      : _vm._e(),
                    _vm.$common.hasPermission("Delete", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.deletePromotion(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isEdit
              ? this.$i18n.t("common.edit")
              : this.$i18n.t("common.Add1"),
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.closeDialog
          }
        },
        [
          _vm.dialogFormVisible
            ? _c(
                "div",
                [
                  _c("sale-dialog", {
                    ref: "dialog",
                    attrs: {
                      priceSetting: _vm.priceSetting,
                      isEdit: _vm.isEdit,
                      preSaleCountryFlag: _vm.preSaleCountryFlag,
                      typeName: _vm.typeName,
                      dictionary: _vm.dictionary,
                      formListQuery: _vm.formListQuery
                    },
                    on: {
                      closeDialog: _vm.closeDialog,
                      handleSearchList: _vm.handleSearchList
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }