var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formItem, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formItem.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "status", $$v)
                  },
                  expression: "formItem.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$i18n.t("common.Pleaseselectacountry")
                  },
                  on: { change: _vm.changeCountryCode },
                  model: {
                    value: _vm.formItem.countryCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "countryCode", $$v)
                    },
                    expression: "formItem.countryCode"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.RebateDimension"),
                prop: "rebateDimension",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$i18n.t(
                      "common.PleaseSelectARebateDimension"
                    )
                  },
                  on: { change: _vm.changeRebateDimension },
                  model: {
                    value: _vm.formItem.rebateDimension,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "rebateDimension", $$v)
                    },
                    expression: "formItem.rebateDimension"
                  }
                },
                _vm._l(_vm.dictionary.rebateDimension, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Activitytime"),
                prop: "time",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "range-separator": _vm.$i18n.t("common.to"),
                  "start-placeholder": _vm.$i18n.t("common.Startdate"),
                  "end-placeholder": _vm.$i18n.t("common.Enddate")
                },
                model: {
                  value: _vm.formItem.time,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "time", $$v)
                  },
                  expression: "formItem.time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Rebateratio"),
                prop: "shoppingRebateId",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$i18n.t("common.PleaseSelectARebateRatio")
                  },
                  model: {
                    value: _vm.formItem.shoppingRebateId,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "shoppingRebateId", $$v)
                    },
                    expression: "formItem.shoppingRebateId"
                  }
                },
                _vm._l(_vm.dictionaryRates, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.rates + "%",
                      value: item.shoppingRebateId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.formItem.rebateDimension == "country"
            ? _c(
                "el-form-item",
                {
                  staticClass: "must",
                  attrs: {
                    label: _vm.$i18n.t("common.RebateObject"),
                    prop: "rebateObjectCountry",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$i18n.t(
                          "common.PleaseSelectARebateObject"
                        )
                      },
                      model: {
                        value: _vm.formItem.rebateObjectCountry,
                        callback: function($$v) {
                          _vm.$set(_vm.formItem, "rebateObjectCountry", $$v)
                        },
                        expression: "formItem.rebateObjectCountry"
                      }
                    },
                    _vm._l(_vm.dictionaryRebateObjectCountry, function(
                      item,
                      index
                    ) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.formItem.rebateDimension == "classify"
            ? _c(
                "el-form-item",
                {
                  staticClass: "must",
                  attrs: {
                    label: _vm.$i18n.t("common.RebateObject"),
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      props: _vm.classifyTreeProps,
                      data: _vm.dictionaryClassifyTree,
                      "node-key": "goodsClassifyId",
                      "show-checkbox": "",
                      "default-checked-keys": _vm.checkedKeys,
                      "check-on-click-node": true,
                      "check-strictly": true
                    },
                    on: { check: _vm.treeCheck }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formItem.rebateDimension == "goods"
            ? _c(
                "el-form-item",
                {
                  staticClass: "must",
                  attrs: {
                    label: _vm.$i18n.t("common.RebateObject"),
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.changeGoods }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("common.Selectproduct")) + "  "
                        )
                      ]
                    )
                  ]
                ],
                2
              )
            : _vm._e(),
          _vm.formItem.rebateDimension == "goods"
            ? _c(
                "el-table",
                {
                  ref: "tableList",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableList, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsId",
                      label: _vm.$i18n.t("common.ProductID"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsCode",
                      label: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v("SKU:" + _vm._s(scope.row.sku))
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v("EAN:" + _vm._s(scope.row.barCode))
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$i18n.t("common.coding")) +
                                    ":" +
                                    _vm._s(scope.row.goodsCode)
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1138614848
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "imageUrl",
                      label: _vm.$i18n.t("common.Productimage1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.imageUrl
                                ? _c("el-image", {
                                    staticStyle: { height: "100px" },
                                    attrs: {
                                      src: _vm.ossHost + scope.row.imageUrl,
                                      fit: "fill"
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      183490098
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: _vm.$i18n.t("common.ProductName"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsSpecials",
                      label: _vm.$i18n.t("common.Countrieslisted"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.goodsSpecials, function(
                              s,
                              index
                            ) {
                              return _c("span", { key: s.countryCode }, [
                                s.shelf == true
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$dict.getDictValue(
                                              "countrys",
                                              s.countryCode
                                            )
                                          )
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e()
                              ])
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      756755268
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prices",
                      label: _vm.$i18n.t("common.inventory"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.goodsSpecials, function(
                              s,
                              index
                            ) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(s.ecomRemainQty)
                                ),
                                _c("br")
                              ])
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      768943684
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "packageQuantity",
                      label: _vm.$i18n.t("common.PackingQuantity"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prices",
                      label: _vm.$i18n.t("common.Fixedprice1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.prices, function(s, index) {
                              return _c("span", { key: index }, [
                                s.fixedPrice
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(s.countryCode) +
                                          ":" +
                                          _vm._s(
                                            Number(s.fixedPrice).toFixed(2)
                                          ) +
                                          _vm._s(
                                            _vm.$dict.getDictCurrencyValue(
                                              s.countryCode
                                            )
                                          )
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e()
                              ])
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      2945861441
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prices",
                      label: _vm.$i18n.t("common.Lowestprice"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.prices, function(s, index) {
                              return _c("span", { key: index }, [
                                s.lowestPrice
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(s.countryCode) +
                                          ":" +
                                          _vm._s(
                                            Number(s.lowestPrice).toFixed(2)
                                          ) +
                                          _vm._s(
                                            _vm.$dict.getDictCurrencyValue(
                                              s.countryCode
                                            )
                                          )
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e()
                              ])
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      3335674497
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brand",
                      label: _vm.$i18n.t("common.fixedprice"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.prices, function(s, index) {
                              return _c("span", { key: index }, [
                                s.resultPrice
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(s.countryCode) +
                                          ":" +
                                          _vm._s(s.resultPrice.toFixed(2)) +
                                          _vm._s(
                                            _vm.$dict.getDictCurrencyValue(
                                              s.countryCode
                                            )
                                          )
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e()
                              ])
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      881677955
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brand",
                      label: _vm.$i18n.t("common.price1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.prices, function(s, index) {
                              return _c("span", { key: index }, [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          s.salePriceEbp || s.salePriceEbp >= 0,
                                        expression:
                                          "s.salePriceEbp || s.salePriceEbp>=0"
                                      }
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(s.countryCode) +
                                        ":" +
                                        _vm._s(s.salePriceEbp.toFixed(2)) +
                                        _vm._s(
                                          _vm.$dict.getDictCurrencyValue(
                                            s.countryCode
                                          )
                                        )
                                    ),
                                    _c("br")
                                  ]
                                )
                              ])
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      3124635391
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf",
                      label: _vm.$i18n.t("common.state"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.shelf == true
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#46a8a0" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.Listed")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$i18n.t("common.Notlisted")) +
                                        " "
                                    )
                                  ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      527187420
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v("  " + _vm._s(_vm.$i18n.t("common.confirm1")) + " ")]
          )
        ],
        1
      ),
      _vm.goodsDialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.goodsDialogVisible, "append-to-body": "" },
              on: {
                "update:visible": function($event) {
                  _vm.goodsDialogVisible = $event
                },
                close: _vm.closeGoodsDialog
              }
            },
            [
              _c("goods-dialog", {
                ref: "goodsDialog",
                attrs: { dictionary: _vm.dictionary, tableList: _vm.tableList },
                on: { handleGoodsSelected: _vm.handleGoodsSelected }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }