<template> 
	<div>
		<div class="header-container">
			<!--<span>品牌:{{rowInfo.brandCode}}</span>-->
			<span style="margin-left: 5px;">{{ $i18n.t('common.Class') }}:{{rowInfo.classifyName}}</span>
		</div>
		<div>
			<el-input v-model="variantTitle" class="input-width" :placeholder="$i18n.t('common.name')"></el-input>
			<el-button type="primary" size="small" style="margin-left: 20px;" @click="filterVariantList">{{ $i18n.t('common.query') }}</el-button>
		</div>
		
		<el-table
		    ref="pagedata"
		    :data="pageData"
		    border
		    style="width: 100%;margin-top: 20px;"         
		>
		    <el-table-column prop="parameterItemId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
		    <el-table-column prop="title" :label="$i18n.t('common.name')" align="center" show-overflow-tooltip></el-table-column>
		    <el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.updateTime">{{scope.row.updateTime | formatDate}}</span>
				</template>
			</el-table-column>
		    <el-table-column :label="$i18n.t('common.operation')" align="center">
		        <template slot-scope="scope">
		            <el-button @click="update(scope.row)" type="text" size="small">{{ $i18n.t('common.CustomName') }}</el-button>
		        </template>
		    </el-table-column>
		</el-table>

		<!-- <div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog">取 消</el-button>
			<el-button style="width: 20%;" type="primary" @click="onSubmit('pagedata')">确 定</el-button>
		</div> -->
		<el-dialog :title="$i18n.t('common.CustomName')" :visible.sync="customVisible" width="35%" :before-close="customCloseDialog" append-to-body>
			<div v-if="customVisible">
				<div>
				<el-form ref="titleFrom" label-width="100px">
					<el-form-item :label="$i18n.t('common.name')" prop="name">
						{{rowInfo2.title}}
					</el-form-item>
					<el-form-item v-for="(item,index) in titleList" :key="index" :label="item.name" prop="name">
						<el-input v-model="item.group" :placeholder="$i18n.t('common.CustomName')" autocomplete="off"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 20%;" @click="customCloseDialog">{{ $i18n.t("common.cancel") }}</el-button>
				<el-button style="width: 20%;" type="primary" @click="onSubmit">{{ $i18n.t("common.confirm") }}</el-button>
			</span>
			</div>
			
		</el-dialog>

	</div>
</template>
<script>
	import FilterContainer from '@/components/common/FilterContainer.vue';
    import dictionaryUtil from '@/utils/dictionaryUtil';
	export default {
		components: {
			FilterContainer
		},
		props: {
			rowInfo:{
				type:Object,
				default:()=>{
					return {}
				}
			}
		},
		data() {
			return {
				formLabelWidth: '120px',
				form: {},
				customVisible:false,
				activeIndex: null,
				variantTitle:"",
				//组件标签是否显示
				arg: {
					code: true,
					country: true,
					status: true
				},
				rowInfo2:{},
				//选中数量
				rules: {
					brand: [{
						required: true,
						message: this.$i18n.t('common.Pleaseselectabrand'),
						trigger: 'change'
					}],
					category: [{
						required: true,
						message: this.$i18n.t('common.Pleaseselectacategory'),
						trigger: 'change'
					}],
				},
				titleList: dictionaryUtil.getDict("lang"),
				pageData: []
			};
		},
		methods: {
			//自定义名称
			update(row){
				this.customVisible=true
				this.rowInfo2=row
				this.getVarAdVariantName()
			},
			//得到自定义语言
			getVarAdVariantName(){
				this.$store
					.dispatch('getVarAdVariantName',{
						variantParameterId:this.rowInfo2.variantParameterId
					})
					.then(res => {
						if(res && res.length>0){
							this.titleList.forEach((item)=>{
								res.forEach((item2)=>{
									if(item.code==item2.lang){
										item.group=item2.title
									}
								})							
							})
						}else{
							this.titleList=dictionaryUtil.getDict("lang")
						}
					});
			},
			filterVariantList(){
					if(this.variantTitle){
						this.pageData=this.pageData.filter((item)=>{
							if(item.title==this.variantTitle){
								return item
							}
						})
					}else{
						this.getVarAdvariantList()
					}
			},
			//获取变体列表
			getVarAdvariantList(){
				this.$store
					.dispatch('getVarAdvariantList',{
						variantId:this.rowInfo.variantId
					})
					.then(res => {					
						this.pageData=res
					});
			},
			//提交
			onSubmit() {			
				this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
					confirmButtonText: this.$i18n.t("common.confirm"),
                    cancelButtonText: this.$i18n.t("common.cancel"),
					type: 'warning'
				}).then(() => {
					let data=[]
					console.log(this.titleList)
					data=this.titleList.map(item=>{
						return {
							variantParameterId:this.rowInfo2.variantParameterId,
							title:item.group,
							lang:item.code
						}
					})
					this.$store
						.dispatch('postVarAdVariantName',data)
						.then(res => {					
							this.$message({
								message: this.$i18n.t("common.Successfullysubmitted"),
								type: 'success',
								duration: 1000
							});
							this.customVisible=false
							this.getVarAdvariantList()
						});
					
				});					
			},
			closeDialog(){
				this.$emit('closeDialog');
			},
			customCloseDialog(){
				this.customVisible=false
			}
		},
		mounted(){
			this.getVarAdvariantList()
		}
	};
</script>
<style scoped>
	.header-container {
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		line-height: normal;
		color: #626262;
		padding: 10px 0px;
	}

	.input-width {
		width: 30%;
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}

	.prpa {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.prpa-sub {
		border: 1px solid #000000;
		width: 80%;
	}

	.content1 {
		width: auto;
		height: 100%;
		overflow-y: scroll;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.content-sub-left {
		cursor: pointer;
		position: relative;
		background-color: #ececec;
		width: 40%;
	}

	.content-sub-right {
		width: 60%;
	}

	.content-sub-item {
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		padding: 10px 0px 10px 10px;
	}

	.active::before {
		content: '';
		position: absolute;
		border-left: 4px solid #55aaff;
		height: 30px;
		left: 0px;
	}

	.active {
		font-weight: 900;
		font-style: normal;
		font-size: 20px;
		background-color: #46a8a0;
		color: white;
	}

	.content2 {
		width: auto;
		height: 100%;
	}

	.content2-sub {
		font-size: 15px;
		color: #464646;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>
