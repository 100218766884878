<template>
    <div>
        <el-form :model="formItem" :rules="rules" ref="formItem">

            <el-form-item :label='$i18n.t("common.Combinationname")' prop="palletName" :label-width="formLabelWidth">
                <el-input v-model="formItem.palletName" style="width: 350px;" :placeholder='$i18n.t("common.Combinationname")' maxlength="100" show-word-limit></el-input>
            </el-form-item>
 

            <el-form-item  :label='$i18n.t("common.country")' prop="countryCode" :label-width="formLabelWidth">
                <el-select v-model="formItem.countryCode" clearable class="filter-item" style="width: 180px; " :placeholder='$i18n.t("common.country")'>
						<el-option
							v-for="item in dictionary.country"
							:key="item.id"
							:label="item.name"
							:value="item.code">
						</el-option>      
					</el-select>
            </el-form-item>
 

            <el-form-item :label='$i18n.t("common.Combinationtime")' prop="time" :label-width="formLabelWidth">
                <el-date-picker
                    v-model="formItem.time"
                    clearable
                  
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    range-separator="-"
                    :start-placeholder='$i18n.t("common.starttime")'
                    :end-placeholder='$i18n.t("common.Endtime")'
                >
                </el-date-picker>
            </el-form-item>

            <el-form-item :label='$i18n.t("common.Combinationquantity")' prop="palletQuantity" :label-width="formLabelWidth">
                <el-input v-model="formItem.palletQuantity" style="width: 380px;" :placeholder='$i18n.t("common.Combinationquantity")'   ></el-input>
            </el-form-item>



            <el-form-item :label='$i18n.t("common.CanIusecoupons")' prop="isUseCoupon" :label-width="formLabelWidth">
                <el-radio-group v-model="formItem.isUseCoupon" >
                    <el-radio :label="false">{{ $i18n.t("common.no") }}</el-radio>
                    <el-radio :label="true">{{ $i18n.t("common.yes") }}</el-radio>
                </el-radio-group>
            </el-form-item>



            <el-form-item :label='$i18n.t("common.CombinationIcon")' prop="imgUrl" :label-width="formLabelWidth">
                
                <imgUpload ref="imageUpload" :img="formItem.imgUrl"  @event="changeUrl"></imgUpload>
  
            </el-form-item>


            <el-form-item :label='$i18n.t("common.Selectproduct")' :label-width="formLabelWidth" :required="true">

                <template>
                    <el-button type="success" @click="changeGoods">{{ $i18n.t("common.Selectproduct") }}</el-button>                                    
                </template>
            </el-form-item>
          
           
            <el-table :data="tableList" ref="tableList" style="width: 100%" border >
                <el-table-column :label='$i18n.t("common.sort")'  align="center" width="60"  show-overflow-tooltip> 
                    <template slot-scope="scope" >
                        <el-form-item>
                            <el-input v-model="scope.row.sort"  style="width: 40px;" @blur= "blurSort()" ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column label="SKU/EAN"  prop="sku" align="center" show-overflow-tooltip> 
                    <template slot-scope="scope">
                        <span>SKU:{{ scope.row.sku }}</span><br />
                        <span>EAN:{{ scope.row.barCode }}</span><br />
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.Mainimage")'  prop="imageUrl"  align="center">
                    <template slot-scope="scope">
<!--                        <el-image-->
<!--                            style="height: 100px"-->
<!--                            v-if="scope.row.imageUrl"-->
<!--                            :src="ossHost +scope.row.imageUrl"-->
<!--                            fit="fill"-->
<!--                        ></el-image>-->
                        <listImage :imgUrl="scope.row.imageUrl"></listImage>
                    </template>
                </el-table-column>
               
                <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")'    align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsSpecialsShelf" :key="s.countryCode">
                            <span v-if="index < scope.row.goodsSpecialsShelf.length-1"> {{ s.countryCode }}/
                            </span>
                            <span v-if="index == scope.row.goodsSpecialsShelf.length-1"> {{ s.countryCode }}  
                            </span>
                        </span>
                    </template>    
                </el-table-column>
                <el-table-column prop="shelf" :label='$i18n.t("common.state")' width="60" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }}</span>
                        <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                    </template>
                </el-table-column>


                <el-table-column prop="ecomRemainQty" :label='$i18n.t("common.inventory")'  align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                            <span v-if="s.countryCode && s.countryCode == formItem.countryCode">
                                {{ s.countryCode }}:{{ s.ecomRemainQty }}<br />
                            </span>     
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackingQuantity")' align="center" show-overflow-tooltip></el-table-column>

                <el-table-column prop="fixedPrice"  :label='$i18n.t("common.Fixedprice1")'    align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            <span v-if="s.countryCode == formItem.countryCode">{{ s.countryCode }}:{{
                                Number(s.fixedPrice).toFixed(2)
                            }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                        </span>
                    </template>     
                </el-table-column>

                <el-table-column prop="resultPrice" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            <span v-if="s.resultPrice&&s.countryCode==formItem.countryCode">
                                {{ s.countryCode }}:{{s.resultPrice.toFixed(2)}}{{$dict.getDictCurrencyValue(s.countryCode)}}<br />
                            </span>
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="salePriceEbp" :label='$i18n.t("common.price1")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.prices" :key="index">
                            <span v-if=" (s.salePriceEbp || s.salePriceEbp>=0) && s.countryCode==formItem.countryCode "    v-show="s.salePriceEbp || s.salePriceEbp>=0">
                                {{ s.countryCode }}:{{ s.salePriceEbp.toFixed(2) }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br />        
                           </span>
                        </span>
                    </template>
                </el-table-column>

                <el-table-column  :label='$i18n.t("common.Setcombinationdiscount")' width="100" align="center" show-overflow-tooltip>
                    <template slot-scope="scope" >
                        <el-form-item>
                            <el-input v-model="scope.row.palletValueChar" style="width: 100%;"  @change="calculatePalletValueChar(scope.$index, scope.row)"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>

                <el-table-column prop=""  :label='$i18n.t("common.Combinationprice")' align="center"   show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="color: #fa3434;">
                        {{ formItem.countryCode }}:{{ Number(scope.row.palletValueDiscount).toFixed(2) }}{{$dict.getDictCurrencyValue(formItem.countryCode)}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column :label='$i18n.t("common.operation")'  fixed="right" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">  
                        <el-button type="primary" size="mini" @click="deleteGoods(scope.$index, scope.row)" class="table-inner-button">{{ $i18n.t("common.delete") }}</el-button>
                    </template>
                </el-table-column>

            </el-table>
            
        
        </el-form>

        <div slot="footer" class="dialog-footer-Price" style="margin-top: 20px;">
           {{$i18n.t("common.Combinationprice")}}：<span style="color: #fa3434;">{{    Number(palletTotalPriceDiscount).toFixed(2)  }}</span>
        </div>
        <div slot="footer" class="dialog-footer-Price">
            <span style="text-decoration: line-through;color: #9da1a7;">{{  Number(palletTotalPrice).toFixed(2)   }}</span>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%;" @click="closeDialog(false)"> {{ $i18n.t("common.cancel") }}</el-button>
          
            <el-button style="width: 20%;" type="primary" :loading="loading" @click="onSubmit('formItem')"> {{ $i18n.t("common.confirm") }}</el-button>
        </div>
      
        
        <el-dialog :visible.sync="goodsDialogVisible" v-if="goodsDialogVisible"  append-to-body @close="closeGooodsDialog"   >
            <goods-dialog  ref="goodsDialog" :dictionary="dictionary" :tableList="tableList"  @handleSelected="handleSelected"  />
        </el-dialog>


    </div>
</template>
<script>
 
import goodsDialog from './GoodsDialog.vue';
import imgUpload from './imgUpload';
import listImage from '@/components/common/ListImage.vue';

export default {
    components: {
        goodsDialog,imgUpload,listImage
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        var validatePalletQuantity = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$i18n.t("common.Pleaseenterthecombinationquantity")));
            }  
            if (this.isEdit) {
                // 修改时数量不能＜已购买的数量，输入的值＜已购买数量时自动变为已购买数量
                if(!Number(value) || value < this.formItem.salesCount){
                    this.formItem.palletQuantity = this.formItem.salesCount;
                }
            }else{
                // 只能输入正整数（不能为0），输入其他值时默认变为1
                if(!Number(value) || value <1){
                   this.formItem.palletQuantity = 1;
                }  
            }
            callback();
        };
       
        return {
            formLabelWidth: '150px',
            ossHost: process.env.VUE_APP_OSS_Head,
            goodsDialogVisible: false,
            tableList:[],
            //组件标签是否显示
            argFrom: {
                name: true,
                category: true,
                useState: true
            },
            pageDataSelectList: [],//选中的列表数据
            placeholderFrom: {
                name: this.$i18n.t("common.SKU_EAN_Encoding"),
                receiveState: this.$i18n.t("common.state"),
            },
            loading: false,
            palletTotalPrice: 0,
            palletTotalPriceDiscount: 0,
            rules: {
                palletName: [ 
                    { required: true, message: this.$i18n.t("common.Pleaseenteracombinationname"), trigger: 'blur' },
                    { min: 1, max: 100, message: this.$i18n.t("common.Upto100wordscanbeentered"), trigger: 'blur' }  
                ],
                countryCode: [
                    { required: true, message: this.$i18n.t("common.Pleaseselectacountry"), trigger: 'change' }
                ],
                time: [
                    { 
                        type: 'array', required: true, message: this.$i18n.t("common.Pleaseselectadaterange"), trigger: 'change',
                        fields: {
                            0: { type: 'string', required: true, message: this.$i18n.t("common.Pleaseselectastartdate") },
                            1: { type: 'string', required: true, message: this.$i18n.t("common.Pleaseselecttheenddate") },
                        }
                    }
                ], 
                palletQuantity: [
                    { required: true, message: this.$i18n.t("common.Pleaseenterthecombinationquantity"), trigger: 'blur' },
                    { validator: validatePalletQuantity, trigger: 'blur' }
                ],
                isUseCoupon: [
                    { required: true, message: this.$i18n.t("common.Pleasechoosewhethertousecoupons"), trigger: 'change' }
                ]
            }
            // , 
            // checkedPrpa: [],
            // defaultProps: {
            //     children: 'children',
            //     label: 'title'
            // },
            // count: 1
        };
    },
    created() {
         
    },
    mounted() {
        this.palletTotalPrice = 0,
        this.palletTotalPriceDiscount = 0,
        this.initData();
    },
    methods: {
        initData() {
            if (this.isEdit == true) {
                this.$nextTick(() => {
                    let goodsId = [];
                    this.formItem.palletGoodss.forEach((item)=>{
                        goodsId.push(item.goodsId);
                    });
                    this.handleSearchByGoodsId(goodsId);
                });
            }
	    },
        // 商品信息查询
        handleSearchByGoodsId(goodsId) {
            const data = [].concat(goodsId);
            this.$store.dispatch('postGoodsIdPallet', data).then((res) => {      
                let data = res    
                for (var i = 0; i < data.length; i++) {
                    let element = data[i];
                    // 增加上架国家节点
                    element.goodsSpecialsShelf = element.goodsSpecials.filter((item)=>item.shelf);
                    // 设置组合字段 Sort PalletDiscountType，PalletValue 
                    this.formItem.palletGoodss.forEach((item)=>{
                        if(item.goodsId==element.goodsId){
                            element.sort = item.sort;
                            if(item.palletDiscountType == 'Discount'){
                                element.palletValueChar =  item.palletValue +'%';
                            }else if(item.palletDiscountType == 'Price'){
                                element.palletValueChar =  item.palletValue +'';
                            }
                            // 计算组合价格
                            this.calculatePalletValueChar(i,element);
                        }
                    });
                   
                }
                this.tableList = res;
                 // 计算组合总价
                this.calculatePalletTotalPrice()
            });
        },
        //选择商品
        changeGoods() {
            //加个锁防止重复触发selection-change事件
            this.goodsDialogVisible = true;
            this.$nextTick(()=>{
                // 子组件方法
                this.$refs.goodsDialog.handleSearchList(this.formItem.countryCode)
            })
        },
        // 关闭商品列表页面
        closeGooodsDialog() {
            this.goodsDialogVisible = false
        },
        // 商品子组件点击确定回调
        handleSelected(val){  
            let tableMap =  new Map();
            this.tableList.forEach((item)=>{
                tableMap.set(item.goodsId,item)
            })

            if(val){
                val.forEach(element => {
                    // 增加上架国家节点
                    element.goodsSpecialsShelf = element.goodsSpecials.filter((item)=>item.shelf);
                    // 设置组合字段 Sort PalletDiscountType，PalletValue 
                    let tableItem = tableMap.get(element.goodsId);
                    if(tableItem){
                        element.sort = tableItem.sort;
                        element.palletValueChar = tableItem.palletValueChar; 
                        element.palletValueDiscount = tableItem.palletValueDiscount; 
                    }else{
                        element.sort = 0;
                        element.palletValueDiscount = this.getPriceByCountryCode(element);
                        element.palletValueChar = ''; 
                    }  
                });  
            }

            this.tableList = val;
             // 计算组合总价
            this.calculatePalletTotalPrice();
            this.goodsDialogVisible = false
        },
        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        },
        //提交
        onSubmit(formName) {
            if(this.tableList.length <= 1 || this.tableList.length > 5){
                this.$message({
                    message: this.$i18n.t("common.Pleaseselect25items"),
                    type: 'warning',
                    duration: 1000
                });
                return;
            }  
            for(var i=0; i<this.tableList.length; i++){
                let item = this.tableList[i]
                if(!item.shelf){
                     // 状态有【未上架】的商品，提示：“请选择已上架的商品”
                    this.$message({
                        message: this.$i18n.t("common.Pleaseselectaproductthathasbeenlisted"),
                        type: 'warning',
                        duration: 1000
                    });
                    return;
                }
                let ecomRemainQty = this.getEcomRemainQtyByCountryCode(item);
                if(ecomRemainQty < this.formItem.palletQuantity ) {
                      // 已选择的任意商品的库存＜组合数量，提示：“已选择商品的库存不能小于活动数量”
                    this.$message({

                        message: this.$i18n.t("common.Inventoryofselecteditems"),

                        type: 'warning',
                        duration: 1000
                    });
                    return;
                }
             
                if( item.palletValueChar === undefined || item.palletValueChar == null || item.palletValueChar == '' ) {
                    // 组合价格为空，提示：组合价格不能为空   
                    this.$message({
                        message: this.$i18n.t("common.Combinationpricecannotbeempty"),
                        type: 'warning',
                        duration: 1000
                    });
                    return;
                }

                var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?(%)?$/;
                if (!pattern.test(item.palletValueChar)) {
                    this.$message({
                        message: this.$i18n.t("common.Caninput0andpositiveintegers"),
                        type: 'warning',
                        duration: 1000
                    });
                    return;
                }  
            }  
   
            this.$refs[formName].validate(valid => {
                // console.log('valid',valid)
                if (valid) {

                    this.formItem.startTime = new Date(this.formItem.time[0]).getTime();
                    this.formItem.endTime = new Date(this.formItem.time[1]).getTime();
                    let palletGoodss = [];
                    this.tableList.forEach((item)=>{ 
                        let palletDiscountType ='';
                        let palletValue = 0;

                        let palletValueCharStr =  '' + item.palletValueChar 
                        if (palletValueCharStr.endsWith('%')) {           
                            palletDiscountType = 'Discount';
                            palletValue = item.palletValueChar.split('%')[0];
                        } else {
                            palletDiscountType ='Price';
                            palletValue = item.palletValueChar;
                        }                          
                        let goods = {
                            'goodsId': item.goodsId,
                            'sort': item.sort,
                            'palletDiscountType': palletDiscountType,
                            'palletValue': palletValue  
                        }
                        
                        palletGoodss.push(goods);
                    })
                    this.formItem.palletGoodss = palletGoodss;
             
                   
                    this.$store.dispatch('postCheckPallet', this.formItem).then(response => {                       
                        let checkForm = Object.assign({}, response);  
                        if( checkForm.checkType == 'PreSale' && !checkForm.checkResult){
                            let skuStr  = '';
                            checkForm.skuList.forEach((item)=>{
                                skuStr = skuStr + item + '、'
                            });
                            skuStr = skuStr.substring(0,skuStr.length-1);
                            this.$message({
                                message: skuStr+this.$i18n.t("common.Presaleproductscannotbeadded"),
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }

                        this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),this.$i18n.t("common.prompt"), {
                            // confirmButtonText: '确定',
                            // cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {   
                            this.loading=true
                            
                            console.log("onsubmit",this.formItem)
                            if (this.isEdit) {
                                this.$store.dispatch('putPallet', this.formItem).then(response => {
                                    this.$message({
                                        message: this.$i18n.t("common.Successfullymodified"),
                                        type: 'success',
                                        duration: 1000
                                    });
                                    this.closeDialog(true,this.isEdit);
                                }).finally(() => {
                                    this.loading = false;
                                });
                            } else {
                                this.$store.dispatch('addPallet', this.formItem).then(response => {
                                    this.$message({
                                        message:  this.$i18n.t("common.Successfullysubmitted"),
                                        type: 'success',
                                        duration: 1000
                                    });
                                    this.closeDialog(true,this.isEdit);
                                }).finally(() => {
                                    this.loading = false;
                                });
                            }
                        }).catch(() => {});
                    
                    }) 
                                       
                } else {
                    this.$message({
                        message:  this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        },
        // 商品删除
        deleteGoods(index, row) {
            this.$confirm(this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.tableList.splice(index, 1);
                this.calculatePalletTotalPrice();
                this.$message({
                    message: this.$i18n.t("common.Successfullydeleted"),
                    type: 'success'
                });
            }).catch(() => {});
        },
        // 商品输入排序触发 列表重新排序
        blurSort(){  
            this.tableList = this.tableList.sort((itemA,itemB)=>{
                return itemB.sort-itemA.sort;
            });
        },
        // 计算组合价格
        calculatePalletValueChar(index, row){
            // -设置组合折扣：默认为空，可输入0和正整数、符号只能输入%和.，最多可输如小数点后两位
            let price  = this.getPriceByCountryCode(row);  
            // var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?(%)?$/;
            var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d*))?(%)?$/;
            if (!pattern.test(row.palletValueChar)) {
                row.palletValueDiscount = price;
                row.palletValueChar = '';
                this.$message({
                    message: this.$i18n.t("common.Caninput0andpositiveintegers"),
                    type: 'warning',
                    duration: 1000
                });
                return;
            }  
           
            let palletValueCharStr =  '' + row.palletValueChar 
            console.log(palletValueCharStr)
            if (palletValueCharStr.endsWith('%')) {
                let palletValue = row.palletValueChar.split('%')[0];
                palletValue = Number(palletValue).toFixed(2)
                row.palletValueChar = palletValue + '%'
                // ----输入的百分比：需≤100%，超过100%时，自动变为100%
                if(palletValue>100){
                    palletValue=100;
                    row.palletValueChar='100%';
                }
                // 折扣 组合价格=商品金额-商品金额*折扣  
                 row.palletValueDiscount =  price - price * palletValue /100;
            } else {
                // ----输入的金额：不能超过商品金额（商品金额按优先级读取：固定价格＞售价＞定价），超过时自动变为固定价格/售价/定价
                row.palletValueChar =  Number(row.palletValueChar).toFixed(2)
                if(price < row.palletValueChar){
                    row.palletValueChar =  price;
                }
                 // 优惠 组合价格=商品金额-优惠金额
                row.palletValueDiscount = price - row.palletValueChar;
            }
            this.calculatePalletTotalPrice();
        },
        // 计算总价
        calculatePalletTotalPrice(){
            this.palletTotalPrice = 0,
            this.palletTotalPriceDiscount = 0,
            this.tableList.forEach((item)=>{
                let price  = this.getPriceByCountryCode(item);
                this.palletTotalPrice = this.palletTotalPrice + price;  
                this.palletTotalPriceDiscount =  this.palletTotalPriceDiscount + item.palletValueDiscount;            
            });
        },
        getPriceByCountryCode(row){
            // 商品金额
            let price =  0;
            // 对应国家的价格信息
            let priceRow =  row.prices.filter((item)=> item.countryCode == this.formItem.countryCode)[0];           
            if(priceRow.fixedPrice){
                 //固定价格
                price = priceRow.fixedPrice;
            }else if(priceRow.salePriceEbp){
                //售价
                price = priceRow.salePriceEbp;
            }else if(priceRow.resultPrice){
                //定价
                price = priceRow.resultPrice;
            }
            return price;
        },
        // 获取对应国家库存
        getEcomRemainQtyByCountryCode(row){
            // console.log('getEcomRemainQtyByCountryCode',row)
            // 对应国家的库存
            let ecomRemainQtyRow =  row.goodsSpecials.filter((item)=> item.countryCode == this.formItem.countryCode)[0];           
            if( ecomRemainQtyRow === undefined  || ecomRemainQtyRow == null ){
                return 0;
            }
            if( ecomRemainQtyRow.saleDoubleEbp !== undefined  && ecomRemainQtyRow.saleDoubleEbp != null && ecomRemainQtyRow.saleDoubleEbp >= 1 ){
                return ecomRemainQtyRow.ecomRemainQty/ecomRemainQtyRow.saleDoubleEbp;
            }else{
                return ecomRemainQtyRow.ecomRemainQty;
            }
         
        },
        changeUrl(data) {
            console.log("数据", data)
            this.formItem.imgUrl = data
            console.log("数据", this.formItem.imgUrl)
        }
    
    }
};
</script>
<style scoped>
 
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
 
.dialog-footer-Price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: 30px; */
    margin-right: 100px;
}
</style>
