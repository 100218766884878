<template>
    <el-tabs v-bind="$attrs" v-on="$listeners" style="width:100%">
        <el-tab-pane v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :name="cc.code"> </el-tab-pane>
    </el-tabs>
</template>

<script>
export default {
    props: {
        codeType: {
            type: String
        },
        maxDic: {
            type: Array
        }
    },
    data() {
        return {
            //字典下拉框
            brandDicts: []
        };
    },

    created() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: this.codeType }).then(res => {
                if (res != null) {
                    if (this.maxDic != null && this.maxDic.length > 0) {
                        for (var a of res) {
                            if (this.maxDic.indexOf(a.code) >= 0) {
                                this.brandDicts.push(a);
                            }
                        }
                    } else {
                        this.brandDicts = res;
                    }
                }
            });
        }
    }
};
</script>

<style></style>
