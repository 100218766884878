var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c("filter-container", {
        attrs: {
          arg: _vm.arg,
          placeholder: _vm.placeholder,
          dictionary: _vm.dictionary,
          defaultListQuery: _vm.defaultListQuery
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch
        }
      }),
      _c(
        "div",
        { staticStyle: { margin: "10px 0px 10px 20px" } },
        [
          _vm.$common.hasPermission("Create", _vm.module_code)
            ? _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: { click: _vm.add }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("common.Add1")) + "\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "tableData",
          staticStyle: { width: "100%" },
          attrs: {
            height: "calc(100% - 175px)",
            data: _vm.pageData.list,
            border: "",
            "row-class-name": _vm.expandClassName
          },
          on: {
            "expand-change": _vm.handleExpandChange,
            "row-click": _vm.handleRowExpandDetail
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-table-expand",
                        attrs: { "label-position": "left", inline: "" }
                      },
                      [
                        _c(
                          "el-tabs",
                          { staticStyle: { "margin-left": "30px" } },
                          [
                            scope.row.newUserStatus
                              ? _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: _vm.$t("common.NewUserPromotion")
                                    }
                                  },
                                  [
                                    _c(
                                      "el-tabs",
                                      {
                                        staticStyle: { height: "220px" },
                                        attrs: {
                                          type: "border-card",
                                          "tab-position": "left"
                                        }
                                      },
                                      _vm._l(
                                        scope.row.newUserPromotionRuleCountrys,
                                        function(s, index) {
                                          return _c(
                                            "el-tab-pane",
                                            {
                                              key: index,
                                              attrs: {
                                                label: _vm.$dict.getDictValue(
                                                  "countrys",
                                                  s.countryCode
                                                )
                                              }
                                            },
                                            [
                                              _c("table-details", {
                                                attrs: {
                                                  "form-info": s,
                                                  prices:
                                                    scope.row
                                                      .goodsMergeListMgrDto
                                                      .prices,
                                                  goodsSpecials:
                                                    scope.row
                                                      .goodsMergeListMgrDto
                                                      .goodsSpecials
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.status
                              ? _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "common.RegularUserPromotion"
                                      )
                                    }
                                  },
                                  [
                                    _c(
                                      "el-tabs",
                                      {
                                        staticStyle: { height: "220px" },
                                        attrs: {
                                          type: "border-card",
                                          "tab-position": "left"
                                        }
                                      },
                                      _vm._l(
                                        scope.row.oldUserPromotionRuleCountrys,
                                        function(s, index) {
                                          return _c(
                                            "el-tab-pane",
                                            {
                                              key: index,
                                              attrs: {
                                                label: _vm.$dict.getDictValue(
                                                  "countrys",
                                                  s.countryCode
                                                )
                                              }
                                            },
                                            [
                                              _c("table-details", {
                                                attrs: {
                                                  "form-info": s,
                                                  prices:
                                                    scope.row
                                                      .goodsMergeListMgrDto
                                                      .prices,
                                                  goodsSpecials:
                                                    scope.row
                                                      .goodsMergeListMgrDto
                                                      .goodsSpecials
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "promotionId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$t("common.PromotionName"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "EAN",
              label: _vm.$t("common.barcode"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "EAN: " +
                          _vm._s(scope.row.goodsMergeListMgrDto.barCode) +
                          " "
                      ),
                      _c("br")
                    ]),
                    _c("span", [
                      _vm._v(
                        "SKU: " +
                          _vm._s(scope.row.goodsMergeListMgrDto.sku) +
                          " "
                      ),
                      _c("br")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bannerFiles",
              label: _vm.$t("common.Productimage"),
              align: "center",
              width: "130px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("listImage", {
                      attrs: { imgUrl: scope.row.goodsMergeListMgrDto.imageUrl }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsMergeListMgrDto.title",
              label: _vm.$t("common.Productname1"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "theme",
              label: _vm.$t("common.PromotionCountry"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(
                    _vm.distinctPromotionCountry(
                      scope.row.promotionRuleCountrys
                    ),
                    function(s, index) {
                      return _c("span", { key: index }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$dict.getDictValue("countrys", s.countryCode)
                            )
                        ),
                        _c("br")
                      ])
                    }
                  )
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$t("common.EnabledStatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.NewUserPromotion")) +
                          ":\n          "
                      ),
                      _c(
                        "span",
                        {
                          style: {
                            color: scope.row.newUserStatus ? "green" : "red"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.newUserStatus
                                  ? _vm.$t("common.Enable")
                                  : _vm.$t("common.Disabled")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _c("br")
                    ]),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.RegularUserPromotion")) +
                          ":\n          "
                      ),
                      _c(
                        "span",
                        {
                          style: { color: scope.row.status ? "green" : "red" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.status == 1
                                  ? _vm.$t("common.Enable")
                                  : _vm.$t("common.Disabled")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _c("br")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateName",
              label: _vm.$t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$t("common.OperatingTime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("common.Operation"),
              align: "center",
              width: "150px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$common.hasPermission("Update", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.edit")) + "\n        ")]
                        )
                      : _vm._e(),
                    _vm.$common.hasPermission("Delete", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.deletePromotion(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("common.delete")) + "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Add1"),
            visible: _vm.dialogAddVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAddVisible = $event
            },
            close: _vm.closeAddDialog
          }
        },
        [
          _vm.dialogAddVisible
            ? _c(
                "div",
                [
                  _c("pro-add", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      tableList: _vm.tableList,
                      preSaleCountryFlag: _vm.preSaleCountryFlag
                    },
                    on: {
                      handleResetSearch: _vm.handleResetSearch2,
                      setup: _vm.setAdd,
                      closeAddDialog: _vm.closeAddDialog,
                      handleSearchList: _vm.handleSearchList
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isEdit ? _vm.$t("common.edit") : _vm.$t("common.Add1"),
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.closeDialog
          }
        },
        [
          _vm.dialogFormVisible
            ? _c(
                "div",
                [
                  _c("promotion-dialog", {
                    attrs: {
                      isEdit: _vm.isEdit,
                      proAddData: _vm.proAddData,
                      priceSetting: _vm.priceSetting,
                      preSaleCountryFlag: _vm.preSaleCountryFlag,
                      dictionary: _vm.dictionary,
                      formListQuery: _vm.formListQuery
                    },
                    on: {
                      queryPro: _vm.queryPro,
                      closeDialog: _vm.closeDialog,
                      handChangeSelectList: _vm.handChangeSelectList,
                      handleSearchList: _vm.handleSearchList,
                      handleResetSearch2: _vm.handleResetSearch2
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }