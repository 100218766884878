var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "flex-start" } },
        [
          _c(
            "el-upload",
            {
              ref: "uploadImg",
              staticClass: "avatar-uploader2",
              attrs: {
                action: _vm.ossInfo.ossSignature.host,
                "list-type": "picture-card",
                "auto-upload": false,
                "show-file-list": false,
                accept: ".jpg, .jpeg, .png, .gif",
                "file-list": _vm.fileList,
                data: _vm.fileDataObj,
                limit: _vm.imglimit,
                "on-change": _vm.handleChange
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading,
                    disabled: _vm.fileList.length >= _vm.imglimit
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.SelectImage")))]
              )
            ],
            1
          ),
          _c("div", [
            _c("span", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v(
                _vm._s(
                  _vm.$i18n.t("common.RecommendedSizeX", {
                    "XXX*XXX": "1920*435"
                  })
                )
              )
            ]),
            _c("br"),
            _c("span", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v(_vm._s(_vm.$i18n.t("common.FileSize1MB")))
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "avatar-uploader" }, [
        _c(
          "ul",
          { staticClass: "el-upload-list el-upload-list--picture-card" },
          _vm._l(_vm.fileList, function(item, idx) {
            return _c(
              "li",
              {
                key: item.url + idx,
                staticClass: "el-upload-list__item is-success",
                attrs: { tabindex: "0" }
              },
              [
                _c("div", [
                  _c("img", {
                    staticClass: "el-upload-list__item-thumbnail img-center",
                    attrs: { src: item.url, alt: "" }
                  }),
                  _c("span", { staticClass: "el-upload-list__item-actions" }, [
                    _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item-preview",
                        on: {
                          click: function($event) {
                            return _vm.handlePictureCardPreview(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "el-icon-zoom-in" })]
                    ),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function($event) {
                                return _vm.handleRemove(item)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e()
                  ])
                ])
              ]
            )
          }),
          0
        )
      ]),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": true,
                width: "700px",
                visible: _vm.dialogVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [_c("img", { attrs: { width: "100%", src: _vm.dialogImageUrl } })]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }