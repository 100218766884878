<template>
    <div>
        <el-form :model="fullDiscountForm" :rules="rules" ref="fullDiscountForm">

            <el-form-item  :label='$i18n.t("common.name")'  prop="title" :label-width="formLabelWidth"  >
                <el-input v-model="fullDiscountForm.title" style="width: 400px;" :placeholder='$i18n.t("common.Pleaseenteraname")'  show-word-limit></el-input>
            </el-form-item>
 

            <el-form-item :label='$i18n.t("common.country")' prop="countryCode" :label-width="formLabelWidth"  >
                <el-select v-model="fullDiscountForm.countryCode" clearable class="filter-item" style="width: 180px; " :placeholder='$i18n.t("common.country")'>
						<el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code">
						</el-option>      
					</el-select>
            </el-form-item>

            <el-form-item  :label='$i18n.t("common.time")' prop="time" :label-width="formLabelWidth"  >
                <el-date-picker v-model="fullDiscountForm.time" clearable  type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    range-separator="-" :start-placeholder='$i18n.t("common.starttime")' :end-placeholder='$i18n.t("common.Endtime")'>
                </el-date-picker>
            </el-form-item>
 
            <el-form-item :label='handle()' :label-width="formLabelWidth" :required="true">
                <div v-for="(item, index) in fullDiscountRuleList" :key="index" style="display: flex; margin-bottom: 10px; ">
                    <span style="padding-right: 5px;">{{ $i18n.t("common.Full") }}</span>
                    <el-input v-model="item.fullPrice" :ref="'fullPrice_' +index"  style="width: 150px;" :placeholder='$i18n.t("common.EnterAmount")' show-word-limit @blur="handleFullPrice(item,index)" ></el-input>
                    <span style="padding-right: 8px;padding-left: 8px;">{{ $i18n.t("common.Reduce") }}</span>
                    <el-input v-model="item.discountPrice" :ref="'discountPrice_' +index"  style="width: 150px;" :placeholder='$i18n.t("common.EnterAmount")'  show-word-limit @blur="handleDiscountPrice(item,index)"></el-input>
                    <span  v-if="fullDiscountRuleList.length<10" ><i style="font-size:22px;" class="el-icon-circle-plus-outline" @click="addRule(item)" ></i></span> 
                    <span  v-if="fullDiscountRuleList.length>1" ><i style="font-size:20px;" class="el-icon-remove-outline"  @click="delRule(item,index)"></i></span>
                </div>
            </el-form-item>


            <el-form-item :label='$i18n.t("common.Enableornot")'  prop="status1" :label-width="formLabelWidth" :required="true">
                <el-switch v-model="fullDiscountForm.status1"  active-color="#13ce66" @change="changeStatus" ></el-switch>
            </el-form-item>



            <el-form-item :label='$i18n.t("common.Applicableclassification")'  :label-width="formLabelWidth">
				<el-tree ref="tree" :props="classifyTreeProps" :data="dictionary.classifyTree" node-key="goodsClassifyId" :default-checked-keys="checkedKeys" show-checkbox>
				</el-tree>
			</el-form-item>

           
            <el-form-item :label='$i18n.t("common.Applicableproducts")'  :label-width="formLabelWidth">
				<el-button type="success" @click="changeGoods">{{ $i18n.t("common.Addproduct") }}</el-button>
			</el-form-item>

            
            <el-form-item  :label-width="formLabelWidth">
				<el-table ref="goodsSelectList" :data="goodsSelectList" border style="width: 100%">
					<el-table-column prop="goodsId"   :label='$i18n.t("common.ProductID1")' align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="goodsCode"  :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span>SKU:{{ scope.row.sku }}</span><br />
							<span>EAN:{{ scope.row.barCode }}</span><br />
							<span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-image style="height: 100px" v-if="scope.row.imageUrl" :src="ossHost + scope.row.imageUrl"
								fit="fill"></el-image>
						</template>
					</el-table-column>
					<el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
 
                            <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                            </span>
						</template>
					</el-table-column>

<!--					<el-table-column prop="brandCode"  :label='$i18n.t("common.brand")' align="center" show-overflow-tooltip></el-table-column>-->
<!--					<el-table-column prop="endTime" :label='$i18n.t("common.Promotionprice1")'  align="center" show-overflow-tooltip>

						<template slot-scope="scope" v-if="scope.row.prices.length > 0">
							<span v-for="(s, index) in scope.row.prices" :key="index">
								{{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.distributionPrice">{{
									s.distributionPrice.toFixed(2) }}</span><br />
							</span>
						</template>
					</el-table-column>-->
					<el-table-column prop="prices" :label='$i18n.t("common.Promotionprice1")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
                                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.NewUserPromotion") }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
							<span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.RegularUserPromotion") }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
							<span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="brand"  :label='$i18n.t("common.fixedprice")'  align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-for="(s, index) in scope.row.prices" :key="index"> {{ s.resultPrice.toFixed(2) }}<br />
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.shelf == true" style="color: #46a8a0;">{{ $i18n.t("common.Listed") }}</span>
							<span v-else>{{ $i18n.t("common.Notlisted") }}</span>
						</template>
					</el-table-column>

                    <el-table-column :label='$i18n.t("common.operation")' fixed="right" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">  
                            <el-button type="primary" size="mini" @click="deleteGoods(scope.$index, scope.row)" class="table-inner-button">{{ $i18n.t("common.delete") }}</el-button>
                        </template>
                    </el-table-column>
				</el-table>
			</el-form-item>  

        </el-form>   


        <div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog(false)"> {{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" :loading="loading"  @click="onSubmit('fullDiscountForm')"> {{ $i18n.t("common.confirm") }}</el-button>
 
		</div>



        <el-dialog :visible.sync="goodsDialogVisible" v-if="goodsDialogVisible"  append-to-body  >
            <goods-dialog  ref="goodsDialog" :dictionary="dictionary" :goodsSelectList="goodsSelectList"  @handleSelected="handleSelectionGooods"  />
        </el-dialog>

    </div>
</template>
<script>
 
import goodsDialog from './GoodsDialog.vue';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
export default {
    components: {
        goodsDialog
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        formdata: {
            type: Object,
            default: () => {
                return {};
            }
        }    
    },
    data() {
        return {
            formLabelWidth: '150px',
            pricemsg: this.$i18n.t("common.OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0"),
            pricemsg2: this.$i18n.t("common.PleaseEnterTheCorrectAmount"),
            ossHost: process.env.VUE_APP_OSS_Head,
            classifyTreeProps: {
				label: function (data, node) {
					return data.title
				},
				id: function (data, node) {
					return data.parameterGroupId
				},
			},
            // 类目tree 默认勾选的节点的 key 的数组
            checkedKeys: [],
            // 规则
            fullDiscountRuleList: [
                {
                    fullDiscountRuleId: null,
                    fullDiscountId: null,
                    fullPrice: null,
                    discountPrice: null,
                    sort: null
                }    
            ],
            // 默认规则对象
            defaultFullDiscountRule:{
                fullDiscountRuleId: null,
                fullDiscountId: null,
                fullPrice: null,
                discountPrice: null,
                sort: null
            },
            // 商品列表 
            goodsDialogVisible: false,
            // 选中商品列表 
            goodsSelectList: [],
            loading: false,
            fullDiscountForm: {
                fullDiscountId:'',
                title: '',
                countryCode: null,
                time: null,
                startTime: null,
                endTime:null,
                status: '1',
                status1: true,
                fullDiscountRuleList: [],
                fullDiscountGoodsIdList: [],
                fullDiscountGoodsClassifyIdList:[]
            },
            defaultFullDiscountForm: {
                fullDiscountId:'',
                title: '',
                countryCode: null,
                time: null,
                startTime: null,
                endTime:null,
                status: '1',
                status1: true,
                fullDiscountRuleList: [],
                fullDiscountGoodsIdList: [],
                fullDiscountGoodsClassifyIdList:[]
            },
            rules: {
                title: [ 
                    { required: true, message: this.$i18n.t("common.Pleaseenteraname"), trigger: 'blur' },              
                ],
                countryCode: [
                    { required: true, message:  this.$i18n.t("common.Pleaseselectacountry"), trigger: 'change' }
                ],
                time: [
                    { 
                        type: 'array', required: true, message:  this.$i18n.t("common.Pleaseselectadaterange"), trigger: 'change',
                        fields: {
                            0: { type: 'string', required: true, message:  this.$i18n.t("common.Pleaseselectastartdate") },
                            1: { type: 'string', required: true, message: this.$i18n.t("common.Pleaseselecttheenddate") },
                        }
                    }
                ], 
                status: [
                    { required: true, message: this.$i18n.t("common.Enableornot"), trigger: 'change' }     
                ]      
            } 
        };
    },
    created() {
         
    },
    mounted() {
        this.initData();
        
    },
    methods: {
        initData(){
            console.log(this.isEdit,this.dictionary.country); 
            if(this.isEdit){
                // 编辑
                this.$store.dispatch('getFullDiscountInfo', this.formdata).then((res) => {      
                    let type='yyyy-MM-dd hh:mm:ss';
                    let beginTime=this.$options.filters['formatDate'](res.startTime,type);
                    let endTime=this.$options.filters['formatDate'](res.endTime,type);
                    res.time = [];
                    res.time.push(beginTime);
                    res.time.push(endTime);
                    res.status1='';
                    if (res.status == '0') {
                        res.status1=false;
                    } else if(res.status == '1') {
                        res.status1=true;					
                    }
                    
                    this.fullDiscountForm = Object.assign({},res);

                    if(res.fullDiscountRuleList && res.fullDiscountRuleList.length > 0){
                        this.fullDiscountRuleList = res.fullDiscountRuleList;
                    } 

                    if(res.fullDiscountGoodsClassifyList && res.fullDiscountGoodsClassifyList.length > 0){
                        this.checkedKeys = [];
                        res.fullDiscountGoodsClassifyList.forEach(element => {
                            this.checkedKeys.push(element.goodsClassifyId);
                        });  
                    }
                    if(res.fullDiscountGoodsList && res.fullDiscountGoodsList.length > 0){
                        this.searchGoodsList(res.fullDiscountGoodsList);
                    }
                });       
            } else {
                // 新增
                this.fullDiscountForm = Object.assign({},this.defaultFullDiscountForm);
                this.fullDiscountForm.countryCode = this.dictionary.country[0].code;
            }
        },
        handle(){
          return this.$i18n.t("common.Rules") + "\n" +  "(" + this.$i18n.t("common.Superimposable") + ")"
        },
        addRule(item){
            if(this.fullDiscountRuleList.length>=10){
                return;
            }
            let fullDiscountRule = Object.assign({},this.defaultFullDiscountRule);
            this.fullDiscountRuleList.push(fullDiscountRule)
            if(this.fullDiscountRuleList.length>10){
                this.fullDiscountRuleList = this.fullDiscountRuleList.slice(0, 10);
            }
        },
        delRule(item,index){
            // console.log(item,index);
            this.fullDiscountRuleList.splice(index, 1);    
        },
        //规则满金额检验
        handleFullPrice(item,index){
            console.log('handleFullPrice',item,index,this.patternPrice(item.fullPrice))
            if(!this.patternPrice(item.fullPrice)){
                item.fullPrice = null;
                this.$message({
                    message: this.pricemsg,
                    type: 'warning',
                    duration: 1000
                });
                return;
            }
            // 下一档的金额需＞上一档，否则不可提交并提示“请输入正确金额”
            if(!this.checkPreAndNextPrice(item,index,'fullPrice')){
                item.fullPrice = null;
                this.$message({
                    message: this.pricemsg2,
                    type: 'warning',
                    duration: 1000
                });
                return;
            }

            // 每满金额必须≥减金额：否则不可提交并提示“请输入正确金额”；
            if(!this.checkFullPriceAndDiscountPrice(item)){
                item.fullPrice = null;
                this.$message({
                    message: this.pricemsg2,
                    type: 'warning',
                    duration: 1000
                });
                return;
            } 
        },
        //规则减金额检验
        handleDiscountPrice(item,index){
            if(!this.patternPrice(item.discountPrice)){
                item.discountPrice = null;
                this.$message({
                    message: this.pricemsg,
                    type: 'warning',
                    duration: 1000
                });
                return;
            }
            // 下一档的金额需＞上一档，否则不可提交并提示“请输入正确金额”
            if(!this.checkPreAndNextPrice(item,index,'discountPrice')){
                item.discountPrice = null;
                this.$message({
                    message: this.pricemsg2,
                    type: 'warning',
                    duration: 1000
                });
                return;
            }

           // 每满金额必须≥减金额：否则不可提交并提示“请输入正确金额”；
           if(!this.checkFullPriceAndDiscountPrice(item)){
                item.discountPrice = null;
                this.$message({
                    message: this.pricemsg2,
                    type: 'warning',
                    duration: 1000
                });
                return;
            } 
        }, 
        patternPrice(price){
            // 只能输入正数，最多可输入小数点后两位，不能以0开头
            // var pattern = /^(([1-9]{1}\d*)(\.(\d){1,2})?)|(([0]{1}\.(\d))((\d){1})?)$/;
            var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
 
            if( !pattern.test(price) || Number(price) <= 0 ){
                return false;
            }
            return true;
        },
        checkPreAndNextPrice(item,index,type){    
            if(type == 'fullPrice'){
                let fullPrice = item.fullPrice;
                // 当前挡 ＞ 上一档，否则不可提交并提示“请输入正确金额”
                if(index > 0){
                    let preIndex = index-1;
                    let fullPricePrevious = this.fullDiscountRuleList[preIndex].fullPrice; 
                    
                    if(fullPricePrevious == null){
                        this.$refs['fullPrice_' + preIndex ][0].focus();
                        return false;
                    }               
                    if( Number(fullPrice) <= Number(fullPricePrevious)){                                        
                        return false;
                    }     
                }
                //  下一档 ＞ 当前挡，否则不可提交并提示“请输入正确金额”
                if(index + 1 < this.fullDiscountRuleList.length){
                    let fullPriceNext = this.fullDiscountRuleList[index+1].fullPrice;
                    if(fullPriceNext!=null && Number(fullPriceNext) <= Number(fullPrice)){
                        return false;
                    }    
                }
            } else if(type == 'discountPrice'){
                let discountPrice = item.discountPrice;
                // 当前挡 ＞ 上一档，否则不可提交并提示“请输入正确金额”
                if(index > 0){
                    let preIndex = index-1;
                    let discountPricePrevious = this.fullDiscountRuleList[preIndex].discountPrice;
                    if(discountPricePrevious == null){
                        this.$refs['discountPrice_' + preIndex ][0].focus();
                        return false;
                    }
                    if(Number(discountPrice) <= Number(discountPricePrevious)){      
                        return false;
                    }     
                }
                //  下一档 ＞ 当前挡，否则不可提交并提示“请输入正确金额”
                if(index + 1 < this.fullDiscountRuleList.length){
                    let discountPriceNext = this.fullDiscountRuleList[index+1].discountPrice;
                    if( discountPriceNext!=null && Number(discountPriceNext) <= Number(discountPrice)){
                        return false;
                    }    
                }
            }
 
            return true;
        },
        checkFullPriceAndDiscountPrice(item){    
            // 每满金额必须≥减金额：否则不可提交并提示“请输入正确金额”；
            let fullPrice = item.fullPrice;
            let discountPrice = item.discountPrice;
            if( discountPrice !=null &&  fullPrice !=null &&  Number(fullPrice) < Number(discountPrice)){       
                return false;
            }
            return true;
        },
        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        }, 
        checkfullDiscountRuleList(){
            for(let index = 0 ; index < this.fullDiscountRuleList.length; index++){
                let item = this.fullDiscountRuleList[index];
                if(!this.patternPrice(item.fullPrice) ||!this.patternPrice(item.discountPrice) ){
                    return false;
                }
                // 每满金额必须≥减金额：否则不可提交并提示“请输入正确金额”；
                if(!this.checkFullPriceAndDiscountPrice(item)){
                    return false;
                }
                if(index > 0) {
                    // 下一档的金额需＞上一档，否则不可提交并提示“请输入正确金额”
                    if(!this.checkPreAndNextPrice(item,index,'fullPrice')){
                        return false;
                    }
                    if(!this.checkPreAndNextPrice(item,index,'discountPrice')){
 
                        return false;
                    }
                }
            }  
            return true;
        },
        //提交
        onSubmit(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    // 规则 检验规则
                    if(!this.checkfullDiscountRuleList()){
                        this.$message({
                            message: this.pricemsg2,
                            type: 'warning',
                            duration: 1000
                        });
                        return;
                    }
                    //  适用分类 适用商品 其一必填
                    
                    let checkedKeyList = this.$refs.tree.getCheckedKeys();
                    // console.log()
                    if(checkedKeyList.length == 0 && this.goodsSelectList.length == 0){
                        this.$message({
                            message: this.$i18n.t("common.PleaseSelectApplicableCategoriesOrApplicableProducts"),
                            type: 'warning',
                            duration: 1000
                        });
                        return;
                    }

                    this.fullDiscountForm.startTime = new Date(this.fullDiscountForm.time[0]).getTime(); 
                    this.fullDiscountForm.endTime = new Date(this.fullDiscountForm.time[1]).getTime(); 


                    this.fullDiscountRuleList.forEach((item,index)=>{
                        item.sort = index+1;
                    });
                    this.fullDiscountForm.fullDiscountRuleList = this.fullDiscountRuleList;

                    this.fullDiscountForm.fullDiscountGoodsIdList =[];
                    this.goodsSelectList.forEach(x=>{
                        this.fullDiscountForm.fullDiscountGoodsIdList.push(x.goodsId);
                    });

                    this.fullDiscountForm.fullDiscountGoodsClassifyIdList = checkedKeyList;
                    

                     


                    this.$store.dispatch('postCheckFullDiscount', this.fullDiscountForm).then(response => {                       
                        let checkForm = Object.assign({}, response);  
                        if(!checkForm.checkResult){      
                            this.$message({
                                message: checkForm.msg,
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }

                        this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),  this.$i18n.t("common.prompt"), {
                            // confirmButtonText: '确定',
                            // cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {   
                            this.loading=true
                            if (this.isEdit) {
                                this.$store.dispatch('putFullDiscount', this.fullDiscountForm).then(response => {
                                    this.$message({
                                        message: this.$i18n.t("common.Successfullymodified"),
                                        type: 'success',
                                        duration: 1000
                                    });
                                    this.closeDialog(true);
                                }).finally(() => {
                                    this.loading = false;
                                });
                            } else {
                                this.$store.dispatch('addFullDiscount', this.fullDiscountForm).then(response => {
                                    this.$message({
                                        message: this.$i18n.t("common.Successfullysubmitted"),
                                        type: 'success',
                                        duration: 1000
                                    });
                                    this.closeDialog(true);
                                }).finally(() => {
                                    this.loading = false;
                                });
                            }
                        }).catch(() => {
                        });
                    
                    }) 

                } else {
                    this.$message({
                        message: this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        },
        changeStatus(val) {
			if (val == true) {
				this.fullDiscountForm.status = "1"
			} else if (val == false) {
				this.fullDiscountForm.status = "0"
			}
		},
        // 选择商品
        changeGoods() {
			this.goodsDialogVisible = true;
		},
        // 商品子组件点击确定回调
        handleSelectionGooods(val) {
            console.log(val)
			this.goodsSelectList = val;
            console.log(this.goodsSelectList)
            this.goodsDialogVisible = false; 
		},
        // 商品删除
        deleteGoods(index, row) {
            this.$confirm( this.$i18n.t("common.confirmdeletion"),  this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.goodsSelectList.splice(index, 1);
                this.$message({
                    message:   this.$i18n.t("common.Successfullydeleted"),
                    type: 'success'
                });
            }).catch(() => {});
        },
        // 回显商品列表
        searchGoodsList(fullDiscountGoodsList){
            let goodsIdList = [];
            fullDiscountGoodsList.forEach(element => {
                goodsIdList.push(element.goodsId);
            });
            // console.log('goodId', goodId)
			// const data = [].concat(goodId)
			this.$store.dispatch('postPromotionId', goodsIdList).then(res => {
                this.goodsSelectList = res
            });
        },
			isShowGoodsPromotion,
			//获取促销价格
			getPromotionGoodsPrice,
    }
};
</script>
<style scoped> 
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>
