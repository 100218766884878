<template>
    <div ref="editor" :id="editorId" @input="change"></div>
  <!-- <el-button type="primary" round  @click="setHtml">设置内容</el-button> -->
  <!-- <el-button type="primary" style="margin-top: 10px" @click="getHtml"
  >保存内容</el-button
> -->
</template>

<script>
import Vue from 'vue';
import '@wangeditor/editor/dist/css/style.css';
import wangeditor from 'wangeditor';
import * as imageConversion from 'image-conversion';
import {randomString, getSuffix, blobToFile} from '@/utils/common2';
import {Editor, Toolbar} from '@wangeditor/editor-for-vue';
import X2JS from 'x2js'; //解析xml
export default Vue.extend({
    name: 'wangEditer',
    props: {
        value: {
            type: String,
            default: ""
        },
        editorId: {
            type: String,
            default: "editor"
        },
        preData: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            editor: {},
            ossSignature: {},
            ossImgUrl: "",
            ossVUrl: ""
        };
    },
    components: {Editor, Toolbar},
    mounted() {
        this.getSignature();
        this.editor = new wangeditor(this.$refs.editor);
        this.editor.config.height = 500;
        this.editor.config.uploadImgMaxSize = 15 * 1024 * 1024; // 15M
        this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
            // resultFiles 是 input 中选中的文件列表
            // insertImgFn 是获取图片 url 后，插入到编辑器的方法
            console.info('resultFiles', resultFiles);
            for (let i = 0; i < resultFiles.length; i++) {
                //超过2M开始自动压缩  总文件不能超过15M
                let isLt2M = resultFiles[i].size / 1024 / 1024 < 2; // 判定图片大小是否小于2MB
                if (!isLt2M) {
                    imageConversion
                        .compressAccurately(resultFiles[i], {
                            size: 600,
                            accuracy: 0.95,
                            scale: 0.5
                        })
                        .then((res) => {
                            console.log(blobToFile(res, 'temp.jpg'));
                            this.uploadImg(res, insertImgFn);
                        });
                } else {
                    this.uploadImg(resultFiles[i], insertImgFn);
                }
            }
            // 上传图片，返回结果，将图片插入到编辑器中
            // insertImgFn(imgUrl)
        };

        this.editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
            // resultFiles 是 input 中选中的文件列表
            // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
            console.info(resultFiles);
            for (let i = 0; i < resultFiles.length; i++) {
                this.uploadVideo(resultFiles[i], insertVideoFn);
            }
            // 上传视频，返回结果，将视频地址插入到编辑器中
            // insertVideoFn(videoUrl)
        };
        this.editor.config.zIndex = 1;
        this.editor.create();
        this.changeEditorContent()
    },
    methods: {
        //初始化文本内容
        initData(data) {
            console.log(data)
            if (data == undefined) {
                data = '';
            }
            this.editor.txt.html(data);
        },
        onCreated(editor) {
            this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
        },
        changeEditorContent() {
            this.editor.txt.html(this.value)
            this.setPlaceholderText()
        },
        //保存
        getHtml() {
            return this.editor.txt.html();
            // context.emit("save", data.editor.txt.html());
        },
        getText() {
            return this.editor.txt.text();
            // context.emit("save", data.editor.txt.html());
        },
        refresh() {
            setTimeout(() => {
                console.info(this.preData);
                this.editor.txt.html(this.preData); // 重新设置编辑器内容
            }, 500);
        },

        //获取OSS签名
        getSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2c'
                })
                .then((res) => {
                    this.ossSignature = res;
                });
        },
        //上传图片
        uploadImg(file, insertImgFn) {
            let dataObj = {
                name: file.name,
                key: this.ossSignature.dir + '/' + randomString() + getSuffix(file.name),
                policy: this.ossSignature['policy'],
                OSSAccessKeyId: this.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossSignature['signature'],
                file: file
            };
            console.info('dataObj', dataObj);
            // console.info(data.ossSignature.host);
            this.$store
                .dispatch('postFormDataOss', {params: dataObj, url: this.ossSignature.host})
                .then((res) => {
                    //解析xml
                    var x2js = new X2JS();
                    var jsonObj = x2js.xml2js(res);
                    let ossName = jsonObj.PostResponse.Key;
                    let ossUrl = jsonObj.PostResponse.Location;
                    this.ossImgUrl = ossUrl
                    // 上传图片，返回结果，将图片插入到编辑器中
                    insertImgFn(ossUrl);
                })
                .catch((e) => {
                    console.info(e);
                });
        },
        uploadVideo(file, insertVideoFn) {
            let dataObj = {
                name: file.name,
                key: this.ossSignature.dir + '/' + randomString() + getSuffix(file.name),
                policy: this.ossSignature['policy'],
                OSSAccessKeyId: this.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossSignature['signature'],
                file: file
            };
            // console.info(dataObj);
            // console.info(data.ossSignature.host);
            this.$store.dispatch('postFormDataOss', {params: dataObj, url: this.ossSignature.host}).then((res) => {
                console.log('333', res);
                //解析xml
                var x2js = new X2JS();
                var jsonObj = x2js.xml2js(res);
                let ossName = jsonObj.PostResponse.Key;
                let ossUrl = jsonObj.PostResponse.Location;
                this.ossVUrl = ossUrl
                // 上传图片，返回结果，将视频插入到编辑器中
                insertVideoFn(ossUrl);
            });
        },
        //判断是否显示提示语
        setPlaceholderText() {
            const placeholder = document.querySelector('#' + this.editorId + ' .placeholder');
            let text = this.getText()
            // 初始化时检查编辑框内容，判断是否显示提示语
            if (!text) {
                placeholder.style.display = 'block';
            } else {
                placeholder.style.display = 'none';
            }
        },
        change(e) {
            //e.target.innerHTML
            this.setPlaceholderText()
            this.$emit('input', this.getHtml())
        }
    }
});
</script>

<style></style>
