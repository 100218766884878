<template>
  <div>
    <el-form :model="dataItem" :rules="rules" ref="variantFrom">
      <el-form-item :label='$i18n.t("common.TopicName")' prop="title" :label-width="formLabelWidth">
        <el-input v-model="dataItem.title" style="width: 350px;" :placeholder='$i18n.t("common.name")' maxlength="200" show-word-limit></el-input>
      </el-form-item>
      <el-form-item :label='$i18n.t("common.country")' prop="countryCode" :label-width="formLabelWidth">
        <dataSelect style="width: 180px;" class="filter-item" v-model="dataItem.countryCode" clearable :codeType="'countrys'" :placeholder='$i18n.t("common.country")'></dataSelect>
      </el-form-item>

      <!--            <el-form-item :label='$i18n.t("common.brand")'  prop="brandCode" :label-width="formLabelWidth">

                <dataSelect
                        style="width: 180px;"
                        class="filter-item"
                        v-model="dataItem.brandCode"
                        clearable
                        :codeType="'brand'"
                        :placeholder='$i18n.t("common.brand")'
                ></dataSelect>

            </el-form-item>-->
      <el-form-item :label=' $i18n.t("common.picture")' prop="imgUrl" :label-width="formLabelWidth">

        <imgUpload ref="imageUpload" :img=" dataItem.imgUrl" @event="changeUrl"></imgUpload>
        <!-- <imgUpload ref="imageUpload" :img=" dataItem.imgUrl && ossHost + dataItem.imgUrl" @event="changeUrl"></imgUpload> -->
      </el-form-item>
      <el-form-item :label=' $i18n.t("common.Activitytime")' prop="selectTime" :label-width="formLabelWidth">
        <el-date-picker v-model="dataItem.selectTime" type="datetimerange" :range-separator='$i18n.t("common.to")' :start-placeholder='$i18n.t("common.starttime")' :end-placeholder='$i18n.t("common.Endtime")'>
        </el-date-picker>
      </el-form-item>
      <el-form-item :label=' $i18n.t("common.Isthehomepagedisplayed")' prop="homeShow" :label-width="formLabelWidth">
        <el-switch v-model="dataItem.homeShow" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item :label=' $i18n.t("common.Enableornot")' prop="status" :label-width="formLabelWidth">
        <el-switch v-model="dataItem.status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>

      <el-form-item :label="$t('common.ApplicableUserType')" prop="userType" :label-width="formLabelWidth">
        <el-checkbox-group v-model="dataItem.userType">
          <el-checkbox v-for="(item, index) in dictionary.userType" :key="item.code" :label="item.code">
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label=' $i18n.t("common.Richtextornot")' prop="richText" :label-width="formLabelWidth">
        <el-switch v-model="dataItem.richText" active-color="#13ce66" inactive-color="#ff4949" @change="richTextChange"></el-switch>
      </el-form-item>
      <el-form-item :label=' $i18n.t("common.ActivityDescription")' prop="description" :label-width="formLabelWidth">
        <wangEditer ref="editer1" v-model="dataItem.description" v-if="dataItem.richText" editorId="editor1" style="margin-top:10px" />

        <el-input type="textarea" :autosize="{ minRows: 4 }" :placeholder="$t('common.EnterActivityDescription')" v-else v-model="dataItem.description"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button style="width: 20%;" @click="closeDialog(false)"> {{ $i18n.t("common.cancel") }}</el-button>
      <el-button style="width: 20%;" type="primary" @click="onSubmit('variantFrom')"> {{ $i18n.t("common.confirm") }}</el-button>
    </div>
  </div>
</template>
<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import dataRadio from '@/components/common/DataRadio.vue';
import imgUpload from '@/views/country/imgUpload';
import moment from 'moment';
import wangEditer from '@/components/common/WangEditer/simple';
import TenantBrand from '../../../utils/tenantBrand';
import dictionaryUtil from '@/utils/dictionaryUtil';
export default {
  components: { dataSelect, dataText, imgUpload, wangEditer, dataRadio },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    formItem: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      video: null,
      formLabelWidth: '120px',
      // form: { activityId: '', beginTime: '', endTime: '', description: '', status: false, imgUrl: '', activityType: 'Special' },
      dialogImageUrl: '',
      dialogVisible: false,
      languageFrom: {},
      imageUrl: '',
      activeIndex: null,
      activeName: '1',
      ossHost: process.env.VUE_APP_OSS_Head,
      dataItem: {
        status: true,
      },
      //选中数量
      rules: {
        title: [
          {
            required: true,
            message: this.$i18n.t("common.ActivityTitleCannotBeEmpty"),
            trigger: 'change'
          }, { min: 0, max: 200, message: this.$i18n.t("common.TheTitleCannotExceed200Characters",{num:200}), trigger: 'blur' }
        ],
        userType: {
          required: true,
          message: this.$i18n.t("common.PleaseSelectTheApplicableUserType"),
          trigger: 'change'
        },
        brandCode: [
          {
            required: true,
            message: this.$i18n.t("common.Pleaseselectabrand"),
            trigger: 'change'
          }
        ],
        countryCode: [
          {
            required: true,
            message: this.$i18n.t("common.Pleaseselectacountry"),
            trigger: 'change'
          }
        ],
        description: [
          {
            required: true,
            message: this.$i18n.t("common.PleaseEnterTheActivityDescription"),
            trigger: 'change'
          }
        ],
        selectTime: [
          {
            required: true,
            message: this.$i18n.t("common.ActivityTimeCannotBeEmpty"),
            trigger: 'change'
          }]

      },
      dictionary: {
        //适用用户类型
        userType: dictionaryUtil.getDict('user_type'),
      }

    }
      ;
  },
  created() {
    this.dataItem = Object.assign({}, this.formItem);
    // this.dataItem.richText = true
    delete this.dataItem.activityGoods;
    /*if (this.dataItem.richText) {
        this.$nextTick(()=>{
            this.$refs['editer1'].changeEditorContent();
        });
    }*/
    console.log("this.dataItem", this.dataItem)
  },
  methods: {
    closeDialog(status) {
      if (this.$refs['variantFrom'] !== undefined) {
        this.$refs['variantFrom'].resetFields();
      }
      this.$emit('closeDialog', status);
    },
    getActiveIndex(index) {
      this.activeIndex = index;
    },
    richTextChange() {
      if (this.dataItem.richText) {
        this.$nextTick(() => {
          this.$refs['editer1'].changeEditorContent();
        });
      } else {
        this.dataItem.description = this.$refs['editer1'].getHtml();
      }
    },
    //提交
    onSubmit(formName) {
      this.dataItem.beginTime = new Date(moment(this.dataItem.selectTime[0])).getTime();
      this.dataItem.endTime = new Date(moment(this.dataItem.selectTime[1])).getTime();
      // this.dataItem.description = this.$refs['editer1'].getHtml();
      /*if (this.dataItem.richText) {
          this.dataItem.description = this.$refs['editer1'].getHtml();
      }*/
      console.log('this.dataItem.description', this.dataItem.description)
      // if (this.dataItem.description != null && this.dataItem.description.length > 10000) {
      //   this.$message({
      //     message: this.$i18n.t("common.TheActivityDescriptionLimitIs10000Words"),
      //     type: 'error',
      //     duration: 1000
      //   });
      //   return
      // }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
            // confirmButtonText: '确定',
            // cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.dataItem.brandCode = TenantBrand.getBrand()
            //处理适用用户类型复选框
            if (this.dataItem.userType.includes('NEW') && this.dataItem.userType.includes('OLD')) {
              this.dataItem.userType = 'ALL'
            } else if (this.dataItem.userType.includes('NEW')) {
              this.dataItem.userType = 'NEW'
            } else {
              this.dataItem.userType = 'OLD'
            }
            console.log(123123, this.dataItem)
            if (this.isEdit) {
              this.$store.dispatch('updateActivity', this.dataItem).then(response => {
                this.$message({
                  message: this.$i18n.t("common.Successfullymodified"),
                  type: 'success',
                  duration: 1000
                });
                this.closeDialog(true);
              });
            } else {
              this.$store.dispatch('addActivity', this.dataItem).then(response => {
                this.$message({
                  message: this.$i18n.t("common.Successfullysubmitted"),
                  type: 'success',
                  duration: 1000
                });
                this.closeDialog(true);
              });
            }
          });
        } else {
          this.$message({
            message: this.$i18n.t("common.Verificationfailed"),
            type: 'error',
            duration: 1000
          });
          return false;
        }
      });
    },
    changeUrl(data) {
      console.log("数据", data)
      this.dataItem.imgUrl = data
      console.log("数据", this.dataItem.imgUrl)
    }
  }
};
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.input-width {
    width: 70%;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
