<template>
	<div>
		<el-upload class='avatar-uploader' :action='ossInfo.ossSignature.host' :show-file-list='false'
			:auto-upload='false' list-type='picture-card' ref='uploadImg' :on-change='handleChange'				
			:data='fileDataObj' accept='.jpg, .jpeg, .png, .gif'>
			<el-image ref="image" v-if='formListQuery.bannerFiles[index].imgUrl' class='avatar' :src='formListQuery.bannerFiles[index].imgUrl' fit='contain'></el-image>
			<i v-else class='el-icon-plus avatar-uploader-icon'></i>
		</el-upload>
		<div style='margin-left: 20px;display: flex;justify-content: flex-start;align-items: flex-end;'>
			<span>{{$i18n.t("common.Suggestedsize")}}：5*1024，≤5MB</span>
		</div>
	</div>
</template>

<script>
	import X2JS from 'x2js'; //解析xml
	export default {
	    data() {
	        return {
				host:process.env.VUE_APP_OSS_Head
	        }
	    },
		props:{
			formListQuery: {
				type: Object,
				default: () => {
					return {};
				}
			},
			index:{
				type: Number,
				default: 0
			},
			countryCode:{
				type: String,
				default: 'CN'
			}
		},
		data(){
			return{
				fileDataObj:{},
				imageUrl:'',
				ossInfo: {
					ossHost: process.env.VUE_APP_OSS_Head,				
					ossSignature: {
						host: ''
					},
					activityTranslates: [],
					langs: [],
					seoIndex: '0',
					newFiles: []
				}
			}
		},
		mounted() {
			this.getOssSignature();
		},
	    methods: {
			//获取OSS签名
			getOssSignature() {
				this.$store
					.dispatch('getSignature', {
						dir: 'b2c'
					})
					.then((res) => {
						this.ossInfo.ossSignature = res;
						this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
					});
			},
			handleChange(file, fileLis){
				const fileType = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
				if (fileType.indexOf(file.raw.type) == -1) {
					this.$message.error('上传图片只支持jpg、png、gif、jpeg');
					this.$refs.uploadImg.clearFiles();
					return;
				}
				if (file.size>1024 * 1024 * 5) {
					this.$message.error('上传图片大小不能超过 5MB!');
					this.$refs.uploadImg.clearFiles();
					return;
				}
				let dataObj = {
					name: file.name,
					key: this.ossInfo.ossSignature.dir +
						'/' +
						new Date().getFullYear() +
						'/' +
						this.randomString() +
						this.getSuffix(file.name),
					policy: this.ossInfo.ossSignature['policy'],
					OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
					success_action_status: '201',
					callback: '',
					signature: this.ossInfo.ossSignature['signature']
					// file: file.file,
				};
				file.type = 'add';
				this.fileDataObj = dataObj;
				setTimeout(() => {
					this.$refs.uploadImg.submit();
					let x2js = new X2JS();
					let jsonObj = x2js.xml2js(file.response);
					let ossUrl = jsonObj.PostResponse.Location;
					this.imageUrl = ossUrl;
					this.formListQuery.bannerFiles.forEach((item,index)=>{
						if(item.countryCode==this.countryCode){					
							this.$set(this.formListQuery.bannerFiles[index],'imgUrl',ossUrl)
                            if(this.formListQuery.bannerType !== 'Video'){
                                this.$set(this.formListQuery.bannerFiles[index], 'videoUrl', '')
                            }
							this.$set(this.formListQuery.bannerFiles[index],'countryCode',this.countryCode)
						}
					})
					this.$refs.uploadImg.clearFiles();
					this.$emit('handleFormData',this.formListQuery)	
				}, 500);
			}
			// uploadSuccess(file){
			// 	console.log('file++',file)
			// 	let testFile = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
			// 	const extension = testFile === 'jpg' || testFile === 'jpeg' || testFile === 'png' || testFile === 'gif';
			// 	const isLt1M=file.size>1024 * 1024
			// 	if (!extension) {
			// 	        this.$message.error('上传图片只支持jpg、png、gif、jpeg');
			// 	        this.$set(this.formListQuery.bannerFiles[this.index],'imgUrl','')
			// 	}
			// 	if (isLt1M) {
			// 		this.$message.error('上传图片大小不能超过 1MB!');
			// 		this.$set(this.formListQuery.bannerFiles[this.index],'imgUrl','')
			// 	}
			// 	return 	(extension) && isLt1M		
			// },
	  //       handleChange(file, fileLis) {
	  //       	console.log('file1', file)					        	
	  //       	let dataObj = {
	  //       		name: file.name,
	  //       		key: this.ossInfo.ossSignature.dir +
	  //       			'/' +
	  //       			new Date().getFullYear() +
	  //       			'/' +
	  //       			this.randomString() +
	  //       			this.getSuffix(file.name),
	  //       		policy: this.ossInfo.ossSignature['policy'],
	  //       		OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
	  //       		success_action_status: '201',
	  //       		callback: '',
	  //       		signature: this.ossInfo.ossSignature['signature']
	  //       		// file: file.file,
	  //       	};
	  //       	file.type = 'add';
	  //       	console.log('file.response',file.response)
	  //       	this.fileDataObj = dataObj;
	  //       	setTimeout(() => {
	  //       		console.log('%%%%%%',this.$refs.uploadImg)
	  //       		this.$refs.uploadImg.submit();
	  //       		let x2js = new X2JS();
	  //       		let jsonObj = x2js.xml2js(file.response);
	  //       		console.log(jsonObj);
	  //       		let ossUrl = jsonObj.PostResponse.Location;
	  //       		this.imageUrl = ossUrl;
	  //       		this.formListQuery.bannerFiles.forEach((item,index)=>{
	  //       			if(item.countryCode==this.countryCode){					
	  //       				this.$set(this.formListQuery.bannerFiles[index],'imgUrl',ossUrl)
	  //       				this.$set(this.formListQuery.bannerFiles[index],'videoUrl','')
	  //       				this.$set(this.formListQuery.bannerFiles[index],'countryCode',this.countryCode)
	  //       			}
	  //       		})
			// 		console.log('^^^^^^',this.formListQuery)
			// 		this.$emit('handleFormData',this.formListQuery)
	  //       	}, 500);
	  //       }
	    }
	}
</script>

<style scoped>
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		height: 178px;
		display: block;
	}
</style>