var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "flex-start" } },
    [
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "avatar-uploader",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "show-file-list": false,
            "auto-upload": false,
            "list-type": "picture-card",
            "on-change": _vm.handleChange,
            data: _vm.fileDataObj,
            "on-error": _vm.uploadError,
            accept: ".jpg, .jpeg, .png, .gif"
          }
        },
        [
          _vm.imageUrl
            ? _c("el-image", {
                staticClass: "avatar",
                attrs: {
                  src: _vm.imageUrl && _vm.ossHost + _vm.imageUrl,
                  fit: "contain"
                }
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
        ],
        1
      ),
      _c("span", { staticStyle: { "margin-left": "20px" } }, [
        _vm._v(_vm._s(_vm.$i18n.t("common.RecommendedSize100")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }