var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("filter-container", {
        attrs: {
          arg: _vm.arg,
          placeholder: _vm.placeholder,
          dictionary: _vm.dictionary,
          defaultListQuery: _vm.defaultListQuery
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch
        }
      }),
      _c(
        "div",
        { staticStyle: { margin: "20px 0px 20px 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: { click: _vm.add }
            },
            [_vm._v("    " + _vm._s(_vm.$i18n.t("common.Add1")))]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.pageData.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "activityId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "beginTime",
              label: _vm.$i18n.t("common.starttime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          new Date(row.beginTime).toLocaleString().split(" ")[0]
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTime",
              label: _vm.$i18n.t("common.Endtime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          new Date(row.endTime).toLocaleString().split(" ")[0]
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "publishStatus",
              label: _vm.$i18n.t("common.state"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.publishStatus == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.begininaminute")))
                        ])
                      : _vm._e(),
                    row.publishStatus == 1
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Inprogress")))
                        ])
                      : _vm._e(),
                    row.publishStatus == 2
                      ? _c("span", { staticStyle: { color: "gray" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Ended")))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "activityGoodsNum",
              label: _vm.$i18n.t("common.Firstfreeshippingitem"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(row.activityGoods.length) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$i18n.t("common.Enableornot"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        disabled: ""
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "theme",
              label: _vm.$i18n.t("common.Creationtime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(new Date(row.createTime).toLocaleString()))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "theme",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.updateTime == null
                            ? null
                            : new Date(row.updateTime).toLocaleString()
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateUserName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right",
              width: "150px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.deleteActivity(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.isEdit
                  ? _vm.$i18n.t("common.edit")
                  : _vm.$i18n.t("common.Add1"),
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("add-update-dialog", {
                ref: "addorupdatechild",
                attrs: {
                  typeName: _vm.typeName,
                  dictionary: _vm.dictionary,
                  form: _vm.formdata
                },
                on: {
                  handleSearchList2: _vm.handleSearchList2,
                  handleSearchList3: _vm.handleSearchList3,
                  closeDialog3: _vm.closeDialog3,
                  handleResetSearch2: _vm.handleResetSearch2
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }