var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "variantFrom", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("dataSelect", {
                staticClass: "filter-item",
                staticStyle: { width: "180px" },
                attrs: {
                  clearable: "",
                  codeType: "countrys",
                  placeholder: _vm.$i18n.t("common.country"),
                  disabled: _vm.isEdit
                },
                model: {
                  value: _vm.form.countryCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "countryCode", $$v)
                  },
                  expression: "form.countryCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.time"),
                prop: "arrayDate",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": _vm.$i18n.t("common.to"),
                  "start-placeholder": _vm.$i18n.t("common.starttime"),
                  "end-placeholder": _vm.$i18n.t("common.Endtime"),
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "picker-options": _vm.pickerOptions
                },
                model: {
                  value: _vm.valuePicker,
                  callback: function($$v) {
                    _vm.valuePicker = $$v
                  },
                  expression: "valuePicker"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce669" },
                model: {
                  value: _vm.form.status,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status"
                }
              })
            ],
            1
          ),
          _c("el-form-item", {
            attrs: {
              label: _vm.$i18n.t("common.Selectproduct"),
              prop: "brand",
              "label-width": _vm.formLabelWidth
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function($event) {
                            return _vm.changeGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.Selectproduct")))]
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.SelectedProduct"),
                "label-width": _vm.formLabelWidth,
                "close-on-click-modal": false
              }
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.selectGoods,
                    border: "",
                    "row-key": "tableGoods",
                    "header-cell-style": {
                      background: "#EAEEF9",
                      color: "#32353a"
                    }
                  },
                  on: { "selection-change": _vm.selectionChange2 }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsId",
                      label: _vm.$i18n.t("common.ProductID1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsCode",
                      label: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("span", [_vm._v("SKU:" + _vm._s(row.sku))]),
                            _c("div", [_vm._v("EAN:" + _vm._s(row.barCode))]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$i18n.t("common.coding")) +
                                  ":" +
                                  _vm._s(row.goodsCode)
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "imageUrl",
                      label: _vm.$i18n.t("common.Productimage1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.imageUrl
                              ? _c("el-image", {
                                  staticStyle: { height: "100px" },
                                  attrs: {
                                    src: _vm.ossHost + scope.row.imageUrl,
                                    fit: "fill"
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: _vm.$i18n.t("common.ProductName"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsSpecials",
                      label: _vm.$i18n.t("common.Countrieslisted"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.goodsSpecials, function(
                            s,
                            index
                          ) {
                            return _c("span", { key: index }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$dict.getDictValue(
                                      "countrys",
                                      s.countryCode
                                    )
                                  )
                              ),
                              _c("br")
                            ])
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "treeList",
                      label: _vm.$i18n.t("common.Category"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row &&
                            scope.row.treeList &&
                            scope.row.treeList.length > 0
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.treeList[0].title))
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsMergeListMgrDto",
                      label: _vm.$i18n.t("common.fixedprice"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row && scope.row.prices.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(scope.row.prices, function(s, index) {
                                    return _c("span", { key: index }, [
                                      s.resultPrice
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(s.countryCode) +
                                                ":" +
                                                _vm._s(s.resultPrice.toFixed(2))
                                            ),
                                            _c("br")
                                          ])
                                        : _vm._e()
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf",
                      label: _vm.$i18n.t("common.state"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.shelf == true
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#46a8a0" } },
                                  [_vm._v(_vm._s(_vm.$i18n.t("common.Listed")))]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$i18n.t("common.Notlisted"))
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: _vm.$i18n.t("common.operation"),
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow(
                                      scope.$index,
                                      scope.row
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                               " +
                                    _vm._s(_vm.$i18n.t("common.remove")) +
                                    "\n                            "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog("variantFrom")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Selectproduct"),
            visible: _vm.dialogFormTableVisible2,
            "append-to-body": "",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormTableVisible2 = $event
            }
          }
        },
        [
          _vm.dialogFormTableVisible2
            ? _c(
                "div",
                [
                  _c("FormTableDialog", {
                    ref: "FormTableDialog",
                    attrs: {
                      argFrom: _vm.argFrom2,
                      dictionary: _vm.dictionary,
                      selectedMount: _vm.selectedMount,
                      placeholderFrom: _vm.placeholderFrom2
                    },
                    on: {
                      handleSearchList: _vm.handleSearchList2,
                      handleSearchList3: _vm.handleSearchList3,
                      closeDialog3: _vm.closeDialog3,
                      handleResetSearch: _vm.handleResetSearch2,
                      handleSelected: _vm.handleSelected2
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tableSlot",
                          fn: function() {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "tableData",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "default-expand-all": "",
                                    data: _vm.pageData2.list,
                                    "tree-props": {
                                      children: "packageQtyList",
                                      hasChildren: "hasChildren"
                                    },
                                    border: "",
                                    "row-key": "goodsId",
                                    id: "goodsTable",
                                    "header-cell-style": {
                                      background: "#EAEEF9",
                                      color: "#32353a"
                                    }
                                  },
                                  on: {
                                    select: _vm.selectOfSelectable,
                                    "select-all": _vm.selectAllOfSelectable,
                                    "selection-change": _vm.selectionChange2
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "55",
                                      "reserve-selection": true,
                                      selectable: function(row) {
                                        return row.checkboxStatus
                                      }
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quantityType",
                                      label: "",
                                      width: "50px",
                                      align: "center"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsId",
                                      label: _vm.$i18n.t("common.ProductID"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsCode",
                                      label: _vm.$i18n.t(
                                        "common.SKU_EAN_Encoding"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c("span", [
                                                _vm._v("SKU:" + _vm._s(row.sku))
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  "EAN:" + _vm._s(row.barCode)
                                                )
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$i18n.t("common.coding")
                                                  ) +
                                                    ":" +
                                                    _vm._s(row.goodsCode)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      852501666
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "imageUrl",
                                      label: _vm.$i18n.t(
                                        "common.Productimage1"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.imageUrl
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      height: "100px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.ossHost +
                                                        scope.row.imageUrl,
                                                      fit: "fill"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      183490098
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packageQuantity",
                                      label: _vm.$i18n.t(
                                        "common.PackageQuantity"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "title",
                                      label: _vm.$i18n.t("common.ProductName"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsSpecials",
                                      label: _vm.$i18n.t(
                                        "common.Countrieslisted"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.goodsSpecials,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.$dict.getDictValue(
                                                            "countrys",
                                                            s.countryCode
                                                          )
                                                        )
                                                    ),
                                                    _c("br")
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3942833899
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "treeList",
                                      label: _vm.$i18n.t("common.Category"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row &&
                                              scope.row.treeList &&
                                              scope.row.treeList.length > 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.treeList[0]
                                                          .title
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      773071767
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsMergeListMgrDto",
                                      label: _vm.$i18n.t("common.fixedprice"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row &&
                                              scope.row.prices.length > 0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      scope.row.prices,
                                                      function(s, index) {
                                                        return _c(
                                                          "span",
                                                          { key: index },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  s.countryCode
                                                                ) +
                                                                ":" +
                                                                _vm._s(
                                                                  s.resultPrice.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                            ),
                                                            _c("br")
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      348374955
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "shelf",
                                      label: _vm.$i18n.t("common.state"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.shelf == true
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#46a8a0"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$i18n.t(
                                                            "common.Listed"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "common.Notlisted"
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2074187132
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-pagination", {
                                    attrs: {
                                      "current-page": _vm.listQuery2.page,
                                      "page-size": _vm.listQuery2.size,
                                      layout:
                                        "total, prev, pager, next, jumper",
                                      total: _vm.pageData2.total
                                    },
                                    on: {
                                      "current-change": _vm.handleCurrentChange
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      473906244
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }