<template>
    <div>
        <el-dialog :title=" $t('common.Pleaseselectaproduct')" v-bind="$attrs" v-on="$listeners" width="75%" :visible.sync="visible"
                   append-to-body>
            <el-form :inline="true">
                <el-form-item>
                    <el-input v-model="listQuery.idFuzzy" :placeholder='$i18n.t("common.SKU_EAN_Encoding")' 
                              style="width: 180px; margin-left: 10px" clearable></el-input>
                </el-form-item>
                <el-select v-model="listQuery.userPromotion"
                           clearable
                           v-if="activityId"
                           style="width: 180px; margin-left: 10px"

                           class="filter-item" :placeholder="$t('common.ApplicableUserRange')">
                    <el-option :label="$t('common.NewUserPromotion')" value="NEW"></el-option>
                    <el-option :label="$t('common.RegularUserPromotion')" value="OLD"></el-option>
                    <el-option :label="$t('common.nothing')" value="NO"></el-option>

                </el-select>
                <el-form-item>
                    <el-form-item>
                        <el-select
                                v-model="listQuery.effectShelf"
                                :placeholder='$i18n.t("common.Isitontheshelf")'
                                clearable
                                style="width: 180px; margin-left: 10px"
                                class="filter-item"
                        >
                            <el-option :label='$i18n.t("common.Listed")' value="true"></el-option>
                            <el-option  :label='$i18n.t("common.Notlisted")' value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-cascader v-model="listQuery.classifyIds"
                                     :options="treeData"
                                     :props="propsInfo"
                                     clearable></el-cascader>
                    </el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleCurrentChange(1)"
                               style="margin-left: 10px">
                               {{ $i18n.t("common.query") }}
                    </el-button>
                    <el-button
                            class="filter-item"
                            icon="el-icon-search"
                            type="primary"
                            @click="cleanQuery"
                            style="margin-left: 10px"
                            size="small"
                    >
                    {{ $i18n.t("common.Reset") }}
                    </el-button>
                </el-form-item>
            </el-form>
            <el-table
                    ref="tableData"
                    :data="pageData2.list"
                    :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
                    id="goodsTable"
                    @select="selectOfSelectable"
                    @select-all="selectAllOfSelectable"
                    style="width: 100%"
                    border
                    row-key="goodsId"
                    default-expand-all
                    @selection-change="handleSelectionChange1"
                    :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
            >
                <!--                <el-table-column width="55" align="center">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <div class="seRadio">-->
                <!--                            &lt;!&ndash; <el-radio v-model="selectionId" :label="scope.row.goodsId" @change="selectionChange($event, scope.row)">{{-->
                <!--                                ''-->
                <!--                            }}</el-radio> &ndash;&gt;-->
                <!--                            <el-checkbox-->
                <!--                                    :value="pageDataSelectList.indexOf(scope.row.goodsId) !== -1"-->
                <!--                                    @change="selectionChange($event, scope.row)"-->
                <!--                            ></el-checkbox>-->
                <!--                        </div>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->

                <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="55" :selectable="row => row.checkboxStatus"></el-table-column>
                <!-- 用于显示子表格张开按钮 -->
                <el-table-column prop="quantityType" label="" width="50px" align="center">
                </el-table-column>
                <el-table-column prop="goodsId" :label="$t('common.ProductID')" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="goodsCode" :label="$t('common.SKU_EAN_Encoding')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>SKU:{{ scope.row.sku }}</span><br/>
                        <span>EAN:{{ scope.row.barCode }}</span><br/>
                        <span>{{ $i18n.t("common.number") }}:{{ scope.row.goodsCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1") ' align="center">
                    <template slot-scope="scope">
<!--                        <el-image-->
<!--                                style="height: 100px"-->
<!--                                v-if="scope.row.imageUrl"-->
<!--                                :src="ossHost + scope.row.imageUrl"-->
<!--                                fit="fill"-->
<!--                        ></el-image>-->
                        <listImage :imgUrl="scope.row.imageUrl"></listImage>
                    </template>
                </el-table-column>

                <el-table-column prop="packageQuantity" :label="$t('common.PackingQuantity')" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="title"  :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="treeList" :label='$i18n.t("common.Category")' align="center" show-overflow-tooltip>

                    <template slot-scope="scope">
                        <div v-for="(item,index) in scope.row.treeList" :key="index">
						<span v-for="(item2,index2) in item.parents" :key="index2">
							<span>{{ item2.title }}></span>
						</span>
                            <span>{{ item.title }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsSpecials" :key="index" v-if="s.shelf">
                            {{ $dict.getDictValue('countrys', s.countryCode) }}<br/>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsSpecials" :label='$i18n.t("common.inventory")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsSpecials" :key="index"
                              v-if="s.shelf&&s.ecomRemainQty">
                            {{ $dict.getDictValue('countrys', s.countryCode) }}:{{ s.ecomRemainQty }}<br/>
                        </span>
                    </template>
                </el-table-column>

<!--                <el-table-column prop="brand" label="品牌" align="center" show-overflow-tooltip>

                    <template slot-scope="scope">
                        <div>
              <span v-if="scope.row.treeList && scope.row.treeList.length>0"> {{
                      scope.row.treeList[0].brandCode
                  }}</span>
                        </div>
                    </template>
                </el-table-column>-->
                <el-table-column prop="goodsSpecials" :label="$t('common.ProductPromotion')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.NewUserPromotion') }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                            </span>
                        </span>
                        <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.RegularUserPromotion') }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                            </span>
                        </span>
                        <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        v-if="activityId" prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-if="s.resultPrice">{{ s.countryCode }}:{{ s.resultPrice.toFixed(2) }}<br/></span>
                    </span>
                    </template>
                </el-table-column>
                <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{$i18n.t("common.Listed")}}</span>
                        <span v-else>{{$i18n.t("common.Notlisted")}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="listQuery.page"
                        :page-size="listQuery.size"
                        layout="total, prev, pager, next, jumper"
                        :total="pageData2.total"
                >
                </el-pagination>
                <span v-if="closeFooter == false" slot="footer"
                      style="display: flex;justify-content: flex-end;align-items: center;">
                    <span
                    >{{$i18n.t("common.Selected")}}:<span style="color: #008000;font-size: 16px;font-weight: 800;">{{
                            pageDataSelectList.length
                        }}</span></span
                    >
                    <el-button style="width: 20%;margin-left: 50px;" type="primary"
                               @click="handleSelected"> {{ $i18n.t("common.confirm") }} </el-button>
                </span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from "@/utils/treeTable";
import FilterContainer from '@/components/common/FilterContainer.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import modal_mixin from '@/components/common/modal_mixin.js';
import dataText from '@/components/common/DataText.vue';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
import listImage from '@/components/common/ListImage.vue';

export default {
    components: {
        dataText,
        FilterContainer,
        FormTableDialog,
        listImage
    },
    mixins: [modal_mixin],
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        typeName: {
            //数据
            type: String,
            default: ''
        },
        fromVisible: {
            //数据
            type: Boolean,
            default: false
        },
        closeFooter: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            listQuery: {
                effectShelf: 'true',
                homeModuleId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                userPromotion: '', //适用用户范围
                showPromotion: 'true', //显示促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                activityId: '',
                countryCodes: '',
                stockSort: '',
                activityFlag: '',
                classifyIds: [],
                page: 1,
                size: 10
            },
            defaultListQuery: {
                effectShelf: 'true',
                homeModuleId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                userPromotion: '', //适用用户范围
                showPromotion: 'true', //显示促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                countryCodes: '',
                stockSort: '',
                activityFlag: '',
                classifyIds: [],
                page: 1,
                size: 10
            },
            pageData2: {},
            ossHost: process.env.VUE_APP_OSS_Head,
            activeName: '',
            form: {},
            //被选中的商品
            selectedRow: {},
            //确认后的被选中的商品
            pageDataSelectList: [],
            // 选中的数量
            selectedMount: null,
            //选中的商品
            selectionId: '',
            selectionFlag: false,
            dialogFormTableVisible2: false,
            multiple: 1,
            homeModuleId: '',
            activityId: '',
            countryCode: '',
            brandCode: '',
            stockSort: '',
            activityFlag: '',
            treeData: [],
            propsInfo: {
                value: 'goodsClassifyId',
                label: 'title',
                children: 'children',
                multiple: false,
                checkStrictly: true
                // checkStrictly: true
            },
        };
    },
    methods: {
        selectAllOfSelectable(selection) {
            selectAllOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
        },
        selectOfSelectable(selection) {
            selectOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
        },
        handleInit(homeModuleId, multiple, activityId, countryCode, brandCode,stockSort,activityFlag) {
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.homeModuleId = homeModuleId;
            this.activityId = activityId;
            this.multiple = multiple;
            this.countryCode = countryCode;
            this.brandCode = brandCode;
            //  stockSort 是否库存按照当前活动专题国家库存由高到低排序
            this.stockSort = stockSort;
             //  activityFlag 是否允许同个产品添加至不同的活动专题中
            this.activityFlag = activityFlag;
            this.pageDataSelectList = [];
            //被选中的商品
            this.selectedRow = {};
            if (this.$refs.tableData) {
                this.$refs.tableData.clearSelection();
            }
            this.initTree(this.$tenantBrand.getBrand(), this.countryCode);
            this.cleanQuery();
        },
        initTree(brandCode, countryCode) {
            this.$store.dispatch('getClassTree', {
                status: 1,
                // brandCode: brandCode,
                countryCode: countryCode
            }).then(res => {
                this.treeData = this.getTreeData(res);
            });
        },
        getTreeData(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        loadlist() {
            // this.pageData2 = [];
            if (this.listQuery.classifyIds && this.listQuery.classifyIds.length > 0) {
                this.listQuery.classifyId = this.listQuery.classifyIds[this.listQuery.classifyIds.length - 1];
            } else {
                this.listQuery.classifyId = null;
            }
            this.$store.dispatch('getTinyVariant', this.listQuery).then(res => {
                this.pageData2 = res;

                if (this.$refs.tableData) {
                    showSelectOfSelectable(this.$refs.tableData, this.pageData2.list)
                }
            });
        },
        handleCurrentChange(val) {
            this.listQuery.page = val;
            this.loadlist();
        },
        handleSelectionChange(selection) {
            if (selection) {
                this.pageDataSelectList = [];
                this.selectedRow = Object.assign({}, selection);
                this.selectedMount = 1;
            }
        },
        handleSelectionChange1(val) {
            this.pageDataSelectList = val;
        },
        //单选获取选中的
        selectionChange(event, selection) {
            if (this.multiple) {
                let index = this.pageDataSelectList.indexOf(selection.goodsId);
                if (index === -1) {
                    this.pageDataSelectList.push(selection.goodsId);
                } else {
                    this.pageDataSelectList.splice(index, 1);
                }
            } else {
                this.pageDataSelectList = [selection.goodsId];
            }
        },
        //选择商品
        handleSelected() {
            if (this.multiple) {
                if (!this.pageDataSelectList || this.pageDataSelectList.length === 0) {
                    this.$confirm(this.$i18n.t("common.NoProductIsSelectedWhetherToClose"),   this.$i18n.t("common.prompt"), {
                        // confirmButtonText: '确定',
                        // cancelButtonText: '取消',
                        type: 'warning'
                    })
                            .then(() => {
                                this.handleResolve(this.pageDataSelectList);
                            })
                            .catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: this.$i18n.t("common.OperationCanceled")
                                });
                            });
                } else {
                    this.handleResolve(this.pageDataSelectList);
                }
            } else {
                this.handleResolve(this.selectedRow);
            }
        },
        cleanQuery() {
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.listQuery.homeModuleId = this.homeModuleId;
            this.listQuery.activityId = this.activityId;
            this.listQuery.brandCode = this.brandCode;
            this.listQuery.countryCodes = [this.countryCode];
            this.listQuery.stockSort = this.stockSort;
            this.listQuery.activityFlag = this.activityFlag;
            // console.log('this.listQuery',this.listQuery)
            this.loadlist();
        },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/deep/ .seRadio .el-radio__label {
    display: none;
}
</style>
