var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.dataItem, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.TopicName"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "350px" },
                attrs: {
                  placeholder: _vm.$i18n.t("common.name"),
                  maxlength: "200",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.dataItem.title,
                  callback: function($$v) {
                    _vm.$set(_vm.dataItem, "title", $$v)
                  },
                  expression: "dataItem.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("dataSelect", {
                staticClass: "filter-item",
                staticStyle: { width: "180px" },
                attrs: {
                  clearable: "",
                  codeType: "countrys",
                  placeholder: _vm.$i18n.t("common.country")
                },
                model: {
                  value: _vm.dataItem.countryCode,
                  callback: function($$v) {
                    _vm.$set(_vm.dataItem, "countryCode", $$v)
                  },
                  expression: "dataItem.countryCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.picture"),
                prop: "imgUrl",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("imgUpload", {
                ref: "imageUpload",
                attrs: { img: _vm.dataItem.imgUrl },
                on: { event: _vm.changeUrl }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Activitytime"),
                prop: "selectTime",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": _vm.$i18n.t("common.to"),
                  "start-placeholder": _vm.$i18n.t("common.starttime"),
                  "end-placeholder": _vm.$i18n.t("common.Endtime")
                },
                model: {
                  value: _vm.dataItem.selectTime,
                  callback: function($$v) {
                    _vm.$set(_vm.dataItem, "selectTime", $$v)
                  },
                  expression: "dataItem.selectTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Isthehomepagedisplayed"),
                prop: "homeShow",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.dataItem.homeShow,
                  callback: function($$v) {
                    _vm.$set(_vm.dataItem, "homeShow", $$v)
                  },
                  expression: "dataItem.homeShow"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.dataItem.status,
                  callback: function($$v) {
                    _vm.$set(_vm.dataItem, "status", $$v)
                  },
                  expression: "dataItem.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("common.ApplicableUserType"),
                prop: "userType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.dataItem.userType,
                    callback: function($$v) {
                      _vm.$set(_vm.dataItem, "userType", $$v)
                    },
                    expression: "dataItem.userType"
                  }
                },
                _vm._l(_vm.dictionary.userType, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Richtextornot"),
                prop: "richText",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                on: { change: _vm.richTextChange },
                model: {
                  value: _vm.dataItem.richText,
                  callback: function($$v) {
                    _vm.$set(_vm.dataItem, "richText", $$v)
                  },
                  expression: "dataItem.richText"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.ActivityDescription"),
                prop: "description",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _vm.dataItem.richText
                ? _c("wangEditer", {
                    ref: "editer1",
                    staticStyle: { "margin-top": "10px" },
                    attrs: { editorId: "editor1" },
                    model: {
                      value: _vm.dataItem.description,
                      callback: function($$v) {
                        _vm.$set(_vm.dataItem, "description", $$v)
                      },
                      expression: "dataItem.description"
                    }
                  })
                : _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      placeholder: _vm.$t("common.EnterActivityDescription")
                    },
                    model: {
                      value: _vm.dataItem.description,
                      callback: function($$v) {
                        _vm.$set(_vm.dataItem, "description", $$v)
                      },
                      expression: "dataItem.description"
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }