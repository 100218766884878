<template>
    <div style="height: 100%;min-height:350px;">
	 
		<div class="filter-container" shadow="never">
            <el-form :inline="true">
                <el-form-item>
					<el-input v-model="searchData.idFuzzy" placeholder="SKU/EAN" style="width: 180px;"
                            class="filter-item"  clearable ></el-input>
                </el-form-item>
                
                <el-form-item>
					<el-select v-model="searchData.countryCode"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder="$i18n.t('common.country')">
						<el-option
							v-for="item in dictionary.country"
							:key="item.id"
							:label="item.name"
							:value="item.code">
						</el-option>      
					</el-select>
                </el-form-item>
                

                <el-form-item>
					<el-select v-model="searchData.preSaleStatus"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder="$i18n.t('common.PresaleStatus')">
						<el-option
							v-for="item in dictionary.preSaleStatus"
							:key="item.id"
							:label="item.name"
							:value="item.code">
						</el-option>      
					</el-select>


                </el-form-item>

                
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick"
                               style="margin-left: 10px">
                        {{ $i18n.t("common.query") }}       
                    </el-button>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery"
                               style="margin-left: 10px" size="small">
                        {{ $i18n.t("common.Reset") }}       
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
		
 
        <div style="margin: 0px 0px 10px 20px;">
            <el-button @click="add()" type="success" size="small">{{ $i18n.t("common.Add") }}</el-button>
        </div>

        <el-table ref="pagedata" height="calc(100% - 150px)" :data="pageData.list" border v-loading="loading"  style="width: 100%" @sort-change="onSortChange"  >

            <el-table-column prop="goodsPreSaleConfigId" :label="$i18n.t('common.PreSalePromotionID')"  width="160"  align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="barCode" label="EAN" align="center" width="110"  show-overflow-tooltip></el-table-column>
			<el-table-column prop="countryCode" :label="$i18n.t('common.country')" align="center" width="70" show-overflow-tooltip>
                <!-- <template slot-scope="scope">
                    {{ $dict.getDictValue('countrys',  scope.row.countryCode)  }}
                </template> -->
            </el-table-column>

            <el-table-column prop="preSalePrice" :label="$i18n.t('common.price')" align="center" width="110" show-overflow-tooltip></el-table-column>
            <el-table-column prop="preSaleQuantity" :label="$i18n.t('common.PresaleQuantity')"  align="center"  width="90" show-overflow-tooltip></el-table-column>

            <el-table-column prop="endTime" :label="$i18n.t('common.TheRemainingAmount')" align="center"  width="90" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{  scope.row.preSaleQuantity-scope.row.salesCount }} 
                </template>
			</el-table-column>

            <el-table-column prop="depositValue" :label="$i18n.t('common.PreSaleDeposit')" align="center" width="90" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.depositType != 'FULL_PAYMENT'"> {{  scope.row.depositValue }}% </span> 
                    <span v-if="scope.row.depositType == 'FULL_PAYMENT'"> {{  scope.row.preSalePrice }} </span> 
                </template>
            </el-table-column>

			<el-table-column prop="preSaleStatus1" :label="$i18n.t('common.PresaleStatus')"  align="center" width="90" show-overflow-tooltip></el-table-column>

			<el-table-column prop="agbsInTransitOrderNo" :label="$i18n.t('common.AssociatedAGBSOnthewayOrders')"  width="130"  align="center" show-overflow-tooltip></el-table-column>
            

 
			<el-table-column prop="preSaleStartTime" :label="$i18n.t('common.PresaleStartTime')"  sortable="custom"  align="center" width="140"  :formatter="formatDate"  ></el-table-column>
			<el-table-column prop="preSaleEndTime" :label="$i18n.t('common.PresaleEndTime')" sortable="custom" align="center"  width="140" :formatter="formatDate"  ></el-table-column>
			<el-table-column prop="expectedShipmentTime" :label="$i18n.t('common.EstimatedDeliveryTime')" sortable="custom" align="center" width="120"  :formatter="formatDate1"  ></el-table-column> 

            <el-table-column prop="finalPaymentDeadlineTime" :label="$i18n.t('common.FinalPaymentDeadline')"   align="center"  width="140"  :formatter="formatDate"  ></el-table-column> 


			
            <el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')"   align="center"  width="140"  :formatter="formatDate"  ></el-table-column> 
            <el-table-column prop="updateName" :label="$i18n.t('common.Updater')"  align="center"   width="140"  ></el-table-column>


	 
 
			<el-table-column :label="$i18n.t('common.Operation')"  width="180" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-if ="scope.row.preSaleStatus != 'Complete'"  @click="edit(scope.row)" class="table-inner-button">{{ $i18n.t("common.edit") }}</el-button>
                    <el-button type="text" size="small" v-if ="scope.row.preSaleStatus == 'Complete'"  @click="view(scope.row)" class="table-inner-button">{{ $i18n.t("common.check") }}</el-button>
                    <el-button type="text" size="small" @click="orderHandle(scope.row)" class="table-inner-button">{{ $i18n.t("common.Presaleorder") }}</el-button>
                    <el-button type="text" size="small" @click="goodsHandle(scope.row)" class="table-inner-button">{{ $i18n.t("common.commodity") }}</el-button>     
                    <el-button type="text" size="small" :disabled="scope.row.preSaleStatus != 'NotStarted'" @click="del(scope.row)" class="table-inner-button">{{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>

 
        </el-table>

        <div class="block">
        	<el-pagination @current-change="handleCurrentChange" :current-page="searchData.page"  
				:page-sizes="[10, 20, 30, 50]"   @size-change="handleSizeChange"
        		:page-size="searchData.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageData.total">
        	</el-pagination>
        </div>
 


        <el-dialog :title='preSaleTitle' :visible.sync="dialogFormVisible" 
            :close-on-click-modal="false" v-if="dialogFormVisible" >

            <add-update-dialog :defaultDictionary="dictionary" :isEdit="isEdit" :operationType="operationType" :formItem="formItem" :defaultFormItem="defaultFormItem" @closePreSaleDialog="closePreSaleDialog" />

        </el-dialog>
		 
    </div>
</template>
<script>
 
import AddUpdateDialog from './components/AddUpdateDialog.vue';
import i18n from '@/i18n/i18n';

import moment from 'moment';
import dictionaryUtil from '@/utils/dictionaryUtil';
const defaultDictionary={
    // 国家
    country:dictionaryUtil.getDict('countrys'),
    // 预售状态
    preSaleStatus: [{
        id: 'NotStarted',
        name: i18n.t('common.begininaminute'),
        code: 'NotStarted'
    }, {
        id: 'UnderWay',
        name: i18n.t('common.Inprogress'),
        code: 'UnderWay'
    }, {
        id: 'Complete',
        name: i18n.t('common.Ended'),
        code: 'Complete'
    }, {
        id: 'Canceled',
        name: i18n.t('common.PromotionAbnormal'),
        code: 'Canceled'
    }],
};
export default {
	components: { moment,AddUpdateDialog },
    name: 'goodsPreSaleConfig_module',
    data() {
        return {	 
			loading:false,	 
			dictionary:Object.assign({}, defaultDictionary),
            isEdit: false,
            preSaleTitle: '',
            operationType: 'add',
            dialogFormVisible: false,
            formItem: {
                "goodsPreSaleConfigId": "",
                "goodsId": "",
                "countryCode": "",
                "preSaleStartTime": null,
                "preSaleEndTime": null,
                "preSaleDiscountType": "Discount",
                "preSaleValue": null,
                "preSaleQuantity": null,
                "salesCount": 0,
                "depositType": "DEPOSIT_AND_FINAL_PAYMENT",
                "depositValue": null,
                "agbsInTransitOrderNo": "",
                "expectedShipmentTime": null,
                "finalPaymentDeadlineTime": null,
                "finalPaymentDeadlineTimeDisabled": false,         
                "fixedPrice":  null,
                "resultPrice":  null,
                "salePriceEbp":  null,
                "time":[],
                "preSalePrice": null,
                "freightFeeType": "Fixed",
                "freightFee": null,
                "freeFreightFeePrice": null,
                "virtualQty": null,
                "virtualSalesVolume": 0,
            },
            defaultFormItem: {
                "goodsPreSaleConfigId": "",
                "goodsId": "",
                "countryCode": "",
                "preSaleStartTime": null,
                "preSaleEndTime": null,
                "preSaleDiscountType": "Discount",
                "preSaleValue": null,
                "preSaleQuantity": null,
                "salesCount": 0,
                "depositType": "DEPOSIT_AND_FINAL_PAYMENT",
                "depositValue": null,
                "agbsInTransitOrderNo": "",
                "expectedShipmentTime": null,
                "finalPaymentDeadlineTime": null,
                "finalPaymentDeadlineTimeDisabled": false,         
                "fixedPrice":  null,
                "resultPrice":  null,
                "salePriceEbp":  null,
                "time":[],
                "preSalePrice": null,
                "freightFeeType": "Fixed",
                "freightFee": null,
                "freeFreightFeePrice": null,
                "virtualQty": null,
                "virtualSalesVolume": 0,
            },
			 //查询项
			searchData: {
                idFuzzy: '',
                countryCode: '',
                preSaleStatus: '',
                sortColumn: null,
                sortAsc: null,
                page: 1,
                limit: 10
            },
            defaultSearchData: {
                idFuzzy: '',
                countryCode: '',
                preSaleStatus: '',
                sortColumn: null,
                sortAsc: null,
                page: 1,
                limit: 10
            },
			pageSizes: [10, 20, 30, 50],
            // 列表
			pageData: {}
        };
    }, 
    watch: {
        $route(to, from) {
            // 在mounted函数执行的方法，放到该处
            if (to.path === '/b2c/goodspresale/index') {
                var queryType = this.$route.params.queryType;
                if(queryType){
                    this.searchClick();
                }
            }
        }
    },
    created() {
    },
    mounted() {
        this.searchClick()  
    },
    methods: {
        // 查询
		searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        // 点击页数查询
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
         // 点击条数查询
		handleSizeChange(val) {
            this.searchData.limit = val;
            this.loadlist();
      	},
        // 重置
        cleanQuery() {
            this.searchData = Object.assign({}, this.defaultSearchData);
            this.loadlist();
        },
        // 组合查询
		loadlist() {
			this.loading=true
            var _this = this;
            this.$store.dispatch('getPresalePage', this.searchData).then(res => {
                _this.pageData = res;
              
                _this.pageData.list.forEach(element => {
                    if(element.preSaleStatus == 'NotStarted'){
                        element.preSaleStatus1 = this.$i18n.t("common.begininaminute");  // '即将开始'; 
                    }else if(element.preSaleStatus=='UnderWay'){
                        // element.preSaleStatus1 = this.$i18n.t("common.OnPresale");   //'预售中'; 
                        element.preSaleStatus1 = this.$i18n.t("common.Inprogress");   //'进行中'; 
                    }else if(element.preSaleStatus=='Complete'){
                        element.preSaleStatus1 = this.$i18n.t("common.Ended"); // '已结束'; 
                    }else if(element.preSaleStatus=='Canceled'){
                        element.preSaleStatus1 = this.$i18n.t("common.PromotionAbnormal"); // '活动异常'; 
                    }
                });
            }).finally(() => {
				this.loading = false;
			});
        },

        // 排序
        onSortChange( column ){
            console.log('onSortChange',column, column.prop, column.order)
            this.searchData.sortColumn= column.prop;

            let columnOrder = column.order;
            
            if( columnOrder == 'ascending'){
                this.searchData.sortAsc= 'ASC';
            }else if (columnOrder == 'descending'){
                this.searchData.sortAsc= 'DESC';
            }else{
                this.searchData.sortColumn= null;
                this.searchData.sortAsc= null;
            }
           
            this.searchClick();
        },

		 /**
         * 订单跳转 订单页面
         */
         orderHandle(row) {
            // 商品id , 订单类型
            this.$router.push({
                name: 'order_list',
                params: {
                    goodsId: row.goodsId,
                    orderType: 'PRE_SALE_ORDER',
                }
            });
            
        },
        /**
         *  跳转 商品管理页面
         */ 
         goodsHandle(row) {
            // let code = row.sku == null || row.sku == '' ?  row.barCode : row.sku;
            let code = row.sku ?  row.sku : row.barCode;
            console.log(code)
            this.$router.push({
                name: 'goods_list',
                params: {
                    code: code
                }
            });
        },
        add(){
            this.formItem = JSON.parse(JSON.stringify(this.defaultFormItem));
            this.isEdit = false;
            this.operationType= 'add';
            this.preSaleTitle = this.$i18n.t("common.AddPreSalePromotion")
            this.dialogFormVisible = true;
            
        },
        edit(row){
            this.formItem = JSON.parse(JSON.stringify(row));
            this.isEdit = true; 
            this.operationType= 'edit';
            this.preSaleTitle = this.$i18n.t("common.EditPreSalePromotion")
            this.dialogFormVisible = true;
        },
        view(row){
            this.formItem = JSON.parse(JSON.stringify(row));
            this.isEdit = false; 
            this.operationType= 'view';
            this.preSaleTitle = this.$i18n.t("common.check")
            this.dialogFormVisible = true;
        },
        closePreSaleDialog(status,isEdit){
            this.dialogFormVisible = false; 
            if(status){
                if(isEdit){
                    this.loadlist();
                } else {
                    this.searchClick();
                }   
            }
        },
        del(row){
            this.$confirm(this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t('common.confirm'),
				cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            }).then(() => {

                this.$store.dispatch('deletePresalesConfig', { goodsPreSaleConfigId: row.goodsPreSaleConfigId }).then((res) => {
                        this.$message.success(this.$i18n.t('common.Successfullydeleted'));
                        this.searchClick()
                });
            }).catch(() => {});  
        },
		 /**
         * 时间戳转码
         */
		 formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 时间戳转码
         */
		 formatDate1(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD');
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

 
</style>

