var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "avatar-uploader",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "show-file-list": false,
            "auto-upload": false,
            "list-type": "picture-card",
            "on-change": _vm.handleChange,
            data: _vm.fileDataObj,
            accept: ".jpg, .jpeg, .png, .gif"
          }
        },
        [
          _vm.formListQuery.bannerFiles[_vm.index].imgUrl
            ? _c("el-image", {
                ref: "image",
                staticClass: "avatar",
                attrs: {
                  src: _vm.formListQuery.bannerFiles[_vm.index].imgUrl,
                  fit: "contain"
                }
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "margin-left": "20px",
            display: "flex",
            "justify-content": "flex-start",
            "align-items": "flex-end"
          }
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$i18n.t("common.Suggestedsize")) + "：5*1024，≤5MB"
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }