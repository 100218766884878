<template>
    <div>
        <el-dialog :title='$i18n.t("common.commodity")' v-bind="$attrs" v-on="$listeners" width="80%" append-to-body>
            <div class="filter-container">
                <el-form :inline="true">
                    <el-form-item>
                        <el-input v-model="searchData.idFuzzy" :placeholder='$i18n.t("common.SKU_EAN_Encoding")'
                                  style="width: 180px; margin-left: 10px" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                                v-model="searchData.goodStatus"
                                :placeholder='$i18n.t("common.state")'
                                clearable
                                style="width: 180px; margin-left: 10px"
                                class="filter-item"
                        >
                            <el-option :label='$i18n.t("common.Listed")' value="true"></el-option>
                            <el-option :label='$i18n.t("common.Notlisted")' value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                                v-model="searchData.status"
                                :placeholder='$i18n.t("common.Enableornot")' 
                                clearable
                                style="width: 180px; margin-left: 10px"
                                class="filter-item"
                        >
                            <el-option :label='$i18n.t("common.yes")'  value="true"></el-option>
                            <el-option :label='$i18n.t("common.no")' value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick"
                                   style="margin-left: 10px">
                                   {{ $i18n.t("common.query") }}
                        </el-button>
                        <el-button
                                class="filter-item"
                                icon="el-icon-search"
                                type="primary"
                                @click="cleanQuery"
                                style="margin-left: 10px"
                                size="small"
                        >
                        {{ $i18n.t("common.Reset") }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="margin: 20px 0px 20px 20px">
                <el-button @click="add(null)" type="success" size="small">    {{ $i18n.t("common.Add1") }}</el-button>
                <el-button @click="delAll()" type="danger" size="small"> {{ $i18n.t("common.Batchdeletion") }}</el-button>
            </div>
            <el-table ref="pagedata" :data="pageData.list" border style="width: 100%"
                      @selection-change="handleSelectionChange">
<!--                <el-table-column width="55" align="center">-->
<!--                    <template slot-scope="scope">-->
<!--                        <div class="seRadio">-->
<!--                            <el-checkbox-->
<!--                                    :value="pageDataSelectList.indexOf(scope.row.activityGoodId) !== -1"-->
<!--                                    @change="selectionChange($event, scope.row)"-->
<!--                            ></el-checkbox>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        type="selection"
                        width="55"></el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.goodsId" :label="$t('common.ProductID')" align="center"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column
                        prop="goodsMergeListMgrDto.goodsCode"
                        :label="$t('common.SKU_EAN_Encoding')"
                        align="center"
                        show-overflow-tooltip
                >
                    <template v-slot="scope">
                        <span>SKU:{{ scope.row.goodsMergeListMgrDto.sku }}</span><br/>
                        <span>EAN:{{ scope.row.goodsMergeListMgrDto.barCode }}</span><br/>
                        <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsMergeListMgrDto.goodsCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.imageUrl" :label='$i18n.t("common.Productimage1")' align="center" width="125">
                    <template slot-scope="scope">
                        <listImage :imgUrl="scope.row.goodsMergeListMgrDto.imageUrl"></listImage>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.treeList" :label='$i18n.t("common.Category")' align="center" height="55"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-for="(item,index) in scope.row.goodsMergeListMgrDto.treeList" :key="index">
						<span v-for="(item2,index2) in item.parents" :key="index2">
							<span>{{ item2.title }}></span>
						</span>
                            <span>{{ item.title }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.title" :label='$i18n.t("common.ProductName")' align="center"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsMergeListMgrDto.goodsSpecials" :key="index"
                              v-if="s.shelf">
                            {{ $dict.getDictValue('countrys', s.countryCode) }}<br/>
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="goodsMergeListMgrDto.brandCode" :label='$i18n.t("common.brand")' align="center"

                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.goodsMergeListMgrDto.brandCode">{{
                                scope.row.goodsMergeListMgrDto.brandCode
                            }}</span>
                        <span v-else-if="scope.row.goodsMergeListMgrDto && scope.row.goodsMergeListMgrDto.treeList && scope.row.goodsMergeListMgrDto.treeList.length > 0">{{
                                scope.row.goodsMergeListMgrDto.treeList[0].brandCode
                            }}</span>
                    </template>
                </el-table-column>-->
                 <el-table-column prop="goodsMergeListMgrDto.goodsSpecials" :label="$t('common.inventory')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsMergeListMgrDto.goodsSpecials" :key="index">
                            {{ s.countryCode }}:{{ s.ecomRemainQty }}<br />
                        </span>
                    </template>

                </el-table-column>
                <el-table-column prop="goodsSpecials" :label="$t('common.ProductPromotion')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="isShowGoodsPromotion(scope.row.goodsMergeListMgrDto.promotionRuleCountrys, 'NEW')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.NewUserPromotion') }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.goodsMergeListMgrDto.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.goodsMergeListMgrDto.prices) }}<br/>
                            </span>
                        </span>
                        <span v-if="isShowGoodsPromotion(scope.row.goodsMergeListMgrDto.promotionRuleCountrys, 'OLD')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.RegularUserPromotion') }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.goodsMergeListMgrDto.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.goodsMergeListMgrDto.prices) }}<br/>
                            </span>
                        </span>
                        <span v-if="!scope.row.goodsMergeListMgrDto.promotionRuleCountrys || scope.row.goodsMergeListMgrDto.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.prices"  :label='$i18n.t("common.fixedprice")'  align="center" show-overflow-tooltip>

                    <template slot-scope="scope">
                        <span v-for="(s, index) in scope.row.goodsMergeListMgrDto.prices" :key="index">
                            {{ s.countryCode }}:{{ s.resultPrice.toFixed(2) }}<br/>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.goodsMergeListMgrDto.shelf == true" style="color: #46a8a0;">{{ $i18n.t("common.Listed") }}</span>
                        <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.Addtime")' prop="createTime" :render-header="renderCreateHeader">
                    <template slot-scope="scope">{{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="sort" :render-header="renderSortHeader" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.sort" oninput="value=value.replace(/[^0-9]/g,'')"
                                  @blur="updateActivitySort(scope.row)"
                                  @keyup.enter.native="updateActivitySort(scope.row)"
                                  :placeholder='$i18n.t("common.Pleaseentercontent")'></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="status" :label='$i18n.t("common.Enableornot")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="updateStatus(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="del(scope.row)" type="text" size="small">{{ $i18n.t("common.remove") }}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="searchData.page"
                        :page-size="searchData.size"
                        layout="total, prev, pager, next, jumper"
                        :total="pageData.total"
                ></el-pagination>
            </div>
        </el-dialog>
        <goods-dialog ref="goodsDialog" :typeName="typeName" :close-on-click-modal="false"/>
    </div>
</template>
<script>
import VaroantDialog from './AddUpdateDialog.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import GoodsDialog from '@/components/common/GoodsDialog.vue';
import moment from 'moment';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
import listImage from '@/components/common/ListImage.vue';

export default {
    components: {VaroantDialog, FormTableDialog, dataSelect, moment, dataText, GoodsDialog, listImage},
    name: 'activity_goods',
    props: {
        activityId: {
            type: String,
            default: () => {
                return 0;
            }
        },
        countryCode: {
            type: String,
            default: () => {
                return 0;
            }
        },
        brandCode: {
            type: String,
            default: () => {
                return 0;
            }
        }
    },
    data() {
        return {
            typeName: this.$i18n.t("common.ActivityRecommendation"),
            //查询项
            searchData: {
                goodStatus: '',
                countryCode: [],
                brandCode: '',
                status: '',
                recommendModule: '',
                page: 1,
                limit: 10
            },
            isEdit: false,
            //表单数据
            formItem: {},
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
            dialogFormTableVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
            homeModuleId: '',
            //确认后的被选中的商品
            pageDataSelectList: [],
        };
    },
    created() {
        console.log(this.activityId, this.brandCode, this.countryCode)
        this.pageDataSelectList = [];
        this.loadlist();
    },
    methods: {
        handleSelectionChange(val) {
            this.pageDataSelectList = val;
        },
        //单选获取选中的
        selectionChange(event, selection) {
            let index = this.pageDataSelectList.indexOf(selection.activityGoodId);
            if (index === -1) {
                this.pageDataSelectList.push(selection.activityGoodId);
            } else {
                this.pageDataSelectList.splice(index, 1);
            }
            this.pageDataSelectList = selection;
        },
        //自定义label
        renderSortHeader() {
            let sort = this.$i18n.t("common.sort")
            return (
                    <div>
                        <span class='sort-span' on-click={() => this.handleSort()} on-blur={() => this.handleSort()}> sort<span
                                class='el-icon-sort'></span></span>
                    </div>
            )
        },
        //自定义label
        renderCreateHeader() {
            let addtime = this.$i18n.t("common.Addtime")
            return (
                    <div>
                        <span class='sort-span' on-click={() => this.handleCreateTimeSort()}>addtime <span
                                class='el-icon-sort'></span></span>
                    </div>
            )
        },
        updateStatus(row) {
            this.$store
                    .dispatch("updateActivityStatus", {
                        id: row.activityGoodId,
                        status: row.status ? row.status : false
                    })
                    .then((res) => {
                        if (res) {
                            this.loadlist()
                        }
                    });
        },
        handleCreateTimeSort() {
            this.createTimeDesc = !this.createTimeDesc;
            this.searchData.desc = `createTime ${this.createTimeDesc ? 'desc' : 'asc'}, sort ${this.desc ? 'desc' : 'asc'}`
            setTimeout(() => {
                this.loadlist();
            })
        },
        //排序
        handleSort() {
            this.desc = !this.desc
            this.searchData.desc = `sort ${this.desc ? 'desc' : 'asc'}, createTime ${this.createTimeDesc ? 'desc' : 'asc'}`
            setTimeout(() => {
                this.loadlist();
            })
        },
        searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        loadlist() {
            var _this = this;
            this.$store.dispatch('getActivityGoods', {activityId: this.activityId, ...this.searchData}).then(res => {
                _this.pageData = res;
            });
        },
        async add() {
            if (this.$refs.goodsDialog) {
                const goodsList = await this.$refs.goodsDialog.open(null, true, this.activityId, this.countryCode, this.$tenantBrand.getBrand(),'DESC',false);
                if (goodsList && goodsList.length > 0) {
                    this.$store.dispatch('addActivityGoods', {
                        activityId: this.activityId,
                        goodsIds: goodsList.map(value => value.goodsId)
                    }).then(res => {
                      
                        this.$message.success(  this.$i18n.t("common.Successfullyadded2"));
                        this.loadlist();
                    });
                }
            }
        },
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
        cleanQuery() {
            this.searchData.position = '';
            this.searchData.countryCode = [];
            this.searchData.brandCode = '';
            this.searchData.status = null;
            this.searchData.recommendModule = '';
            this.searchData.goodStatus = '';
            this.searchData.idFuzzy = '';
            this.searchClick();
        },
        updateActivitySort(row) {
            const str = '^(\\d+)\\.(\\d{0}).*$'
            const reg = new RegExp(str)
            row.sort = row.sort.replace(/[^\d.]/g, "")
            row.sort = row.sort.replace(reg, '$1');
            this.$store
                    .dispatch("updateActivitySort", {
                        id: row.activityGoodId,
                        sort: row.sort ? row.sort : 0
                    })
                    .then((res) => {
                        if (res) {
                            this.loadlist()
                        }
                    });
        },
        del(row) {
            this.$confirm(this.$i18n.t("common.Areyousuretoremovethisproduct"),  this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                    .then(() => {
                        this.$store.dispatch('delActivityGoods', {
                            activityId: this.activityId,
                            id: row.activityGoodId
                        }).then(res => {
                            let totalPage = Math.ceil((this.pageData.total - 1) / this.searchData.limit)
                            let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                            this.searchData.page = currentPage < 1 ? 1 : currentPage;
                            this.$message.success(this.$i18n.t("common.Successfullyremoved"));
                            this.loadlist();
                        });
                    })
                    .catch(() => {
                    });
        },
        delAll() {
            let pageDataSelectids = this.pageDataSelectList.map(value => value.activityGoodId);

            if (!pageDataSelectids || pageDataSelectids.length == 0) {
                this.$message({
                    type: 'warning',
                    message: this.$i18n.t("common.Pleaseselectaproduct")
                });
                return;
            }
            this.$confirm(this.$i18n.t("common.Areyousuretoremovethisproduct"), this.$i18n.t("common.Prompt"), {
                confirmButtonText: this.$i18n.t("common.confirm1"),
                cancelButtonText: this.$i18n.t("common.cancel"),
                type: 'warning'
            })
                    .then(() => {
                        this.$store.dispatch('delAllActivityGoods', {
                            activityId: this.activityId,
                            activityGoodIds: pageDataSelectids,
                        }).then(res => {
                            this.pageDataSelectList = [];
                            let totalPage = Math.ceil((this.pageData.total - 1) / this.searchData.limit)
                            let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                            this.searchData.page = currentPage < 1 ? 1 : currentPage;
                            this.$message.success(this.$i18n.t("common.Successfullyremoved"));
                            this.loadlist();
                        });
                    })
                    .catch(() => {
                    });
        },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.my-content {
    background: #fde2e2;
}
</style>
