<template>
    <div>
        <el-form :model="form" :rules="rules" ref="variantFrom">

<!--            <el-form-item label="品牌" prop="brandCode" :label-width="formLabelWidth">

                <dataSelect
                    style="width: 180px"
                    class="filter-item"
                    v-model="form.brandCode"
                    clearable
                    :codeType="'brand'"
                    :placeholder='$i18n.t("common.brand")'
                    :disabled="isEdit"
                ></dataSelect>

            </el-form-item>-->
            <el-form-item :label='$i18n.t("common.country")'  prop="countryCode" :label-width="formLabelWidth">

                <dataSelect
                    style="width: 180px"
                    class="filter-item"
                    v-model="form.countryCode"
                    clearable
                    :codeType="'countrys'"
                    :placeholder='$i18n.t("common.country")'
                    :disabled="isEdit"
                ></dataSelect>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.time")' prop="arrayDate" :label-width="formLabelWidth">
                <el-date-picker
                    v-model="valuePicker"
                    type="daterange"
                    :range-separator='$i18n.t("common.to")'
                    :start-placeholder='$i18n.t("common.starttime")'
                    :end-placeholder='$i18n.t("common.Endtime")'
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Enableornot")'  prop="status" :label-width="formLabelWidth">
                <el-switch v-model="form.status" active-color="#13ce669"></el-switch>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Selectproduct")' prop="brand" :label-width="formLabelWidth">
                <template slot-scope="scope">
                    <el-button type="success" @click="changeGoods(scope.row)">{{$i18n.t("common.Selectproduct")}}</el-button>
                </template>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.SelectedProduct")' :label-width="formLabelWidth" :close-on-click-modal="false">
                <el-table
                    :data="selectGoods"
                    style="width: 100%"
                    border
                    row-key="tableGoods"
                    :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                    @selection-change="selectionChange2"
                >
                    <el-table-column prop="goodsId"  :label='$i18n.t("common.ProductID1")'  align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")'  align="center" show-overflow-tooltip>
                            <template slot-scope="{ row }">
                                <span>SKU:{{row.sku}}</span>
                                <div>EAN:{{row.barCode}}</div>
                                <div>{{$i18n.t("common.coding")}}:{{row.goodsCode}}</div>
                            </template>
                        </el-table-column>
                    <el-table-column prop="imageUrl"  :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-image
                                style="height: 100px"
                                v-if="scope.row.imageUrl"
                                :src="ossHost + scope.row.imageUrl"
                                fit="fill"
                            ></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")'  align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                            </span>
                        </template>
                    </el-table-column>

                    <!--<el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip></el-table-column>-->
                    <el-table-column prop="treeList" :label='$i18n.t("common.Category")'  align="center" show-overflow-tooltip>

                        <template slot-scope="scope">
                            <span v-if="scope.row && scope.row.treeList && scope.row.treeList.length > 0">{{
                                scope.row.treeList[0].title
                            }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="prices" label="库存" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                    {{ s.countryCode }}:{{ s.EComRemainQty }}<br />
                                </span>
                            </template>
                        </el-table-column> -->
                    <el-table-column prop="goodsMergeListMgrDto" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-if="scope.row && scope.row.prices.length > 0">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.resultPrice">{{ s.countryCode }}:{{ s.resultPrice.toFixed(2) }}<br
                                /></span></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{$i18n.t("common.Listed")}}</span>
                            <span v-else>{{$i18n.t("common.Notlisted")}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" :label='$i18n.t("common.operation")' width="120">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="deleteRow(scope.$index, scope.row)" type="text" size="small">
                               {{$i18n.t("common.remove")}}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%" @click="closeDialog('variantFrom')"> {{ $i18n.t("common.cancel") }}</el-button>
            <el-button style="width: 20%" type="primary" @click="onSubmit('variantFrom')"> {{ $i18n.t("common.confirm") }}</el-button>
        </div>

        <el-dialog :title='$i18n.t("common.Selectproduct")' :visible.sync="dialogFormTableVisible2" append-to-body :close-on-click-modal="false">
            <div v-if="dialogFormTableVisible2">
                <FormTableDialog
                :argFrom="argFrom2"
                :dictionary="dictionary"
                :selectedMount="selectedMount"
                :placeholderFrom="placeholderFrom2"
                @handleSearchList="handleSearchList2"
                @handleSearchList3="handleSearchList3"
                @closeDialog3="closeDialog3"
                @handleResetSearch="handleResetSearch2"
                @handleSelected="handleSelected2"
                ref="FormTableDialog"
            >
                <template v-slot:tableSlot>
                    <el-table
                        default-expand-all
                        ref="tableData"
                        :data="pageData2.list"
                        :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
                        style="width: 100%"
                        border
                        row-key="goodsId"
                        id="goodsTable"
                        @select="selectOfSelectable"
                        @select-all="selectAllOfSelectable"
                        :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                        @selection-change="selectionChange2"
                    >

                        <el-table-column type="selection" width="55" :reserve-selection="true" :selectable="row => row.checkboxStatus"> </el-table-column>
                        <!-- 用于显示子表格张开按钮 -->
                        <el-table-column prop="quantityType" label="" width="50px" align="center">
                        </el-table-column>
                        <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>

                            <template slot-scope="{ row }">
                                <span>SKU:{{row.sku}}</span>
                                <div>EAN:{{row.barCode}}</div>
                                <div>{{$i18n.t("common.coding")}}:{{row.goodsCode}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="imageUrl" 
                                    :label='$i18n.t("common.Productimage1")'
                                        align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-image
                                    style="height: 100px"
                                    v-if="scope.row.imageUrl"
                                    :src="ossHost + scope.row.imageUrl"
                                    fit="fill"
                                ></el-image>
                            </template>
                        </el-table-column>

                        <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackageQuantity")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")'   align="center" show-overflow-tooltip>

                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                    {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                                </span>
                            </template>
                        </el-table-column>

                        <!--<el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip></el-table-column>-->
                        <el-table-column prop="treeList" :label='$i18n.t("common.Category")' align="center" show-overflow-tooltip>

                            <template slot-scope="scope">
                                <span v-if="scope.row && scope.row.treeList && scope.row.treeList.length > 0">{{
                                    scope.row.treeList[0].title
                                }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="goodsMergeListMgrDto" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div v-if="scope.row && scope.row.prices.length > 0">
                                    <span v-for="(s, index) in scope.row.prices" :key="index">
                                        {{ s.countryCode }}:{{ s.resultPrice.toFixed(2)  }}<br
                                    /></span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{$i18n.t("common.Listed")}}</span>
                                <span v-else>{{$i18n.t("common.Notlisted")}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="block">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="listQuery2.page"
                            :page-size="listQuery2.size"
                            layout="total, prev, pager, next, jumper"
                            :total="pageData2.total"
                        >
                        </el-pagination>
                    </div>
                </template>
            </FormTableDialog>
            </div>
            
        </el-dialog>
    </div>
</template>
<script>
import FilterContainer from '@/components/common/FilterContainer.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import DataSelect from '@/components/common/DataSelect';
import SaleDialog from '@/components/common/SaleDialog.vue';
import moment from 'moment';
import { formatDate } from '@/utils/date';
import M from 'minimatch';
import i18n from '@/i18n/i18n.js';
import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from "@/utils/treeTable";

export default {
    components: {
        FilterContainer,
        FormTableDialog,
        DataSelect,
        SaleDialog,
        moment
    },
    props: {
        formListQuery: {
            type: Object,
            default: () => {
                return {};
            }
        },
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        placeholderFrom: {
            type: Object,
            default: () => {
                return {
                    name: i18n.t("common.IDName"),
                    state: i18n.t("common.Activitystatus"),
                    date: {
                        start: i18n.t("common.starttime"),
                        end: i18n.t("common.Endtime"),
                    },
                    date2: {
                        start: i18n.t("common.starttime"),
                        end: i18n.t("common.Endtime"),
                    }
                };
            }
        }
    },
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            listQuery2: {
                countryCode: '',
                classifyId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                page: 1,
                size: 10
            },
            pageData2: {},
            defaultFormListQuery: {
                title: '',
                brandCode: '',
                goodId: '',
                status: '',
                type: '1',
                pageDataSelectList: [],
                promotionRuleCountrys: []
            },
            pageDataSelectList:[],
            formListQuery3: {},
            isEdit: false,
            query: {},
            formLabelWidth: '120px',
            form: {},
            //被选中的商品
            selectedRow: {},
            //确认后的被选中的商品
            tableData: [],
            // 选中的数量
            selectedMount: null,
            //选中的商品
            selectionId: '',
            //组件标签是否显示
            argFrom2: {
                name: true,
                useState: true
            },
            //时间选择器
            valuePicker: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: i18n.t("common.today"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: i18n.t("common.Lastweek"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: i18n.t("common.Thismonth"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: i18n.t("common.Lastmonth1"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: i18n.t("common.thisyear"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },
            placeholderFrom2: {
                name: this.$i18n.t("common.SKU_EAN_Encoding"),
                receiveState: this.$i18n.t("common.state")
            },
            dialogFormTableVisible2: false,
            //选中数量
            rules: {
                //  brandCode: [
                //     { required: true, message: '请输入品牌', trigger: 'change' },
                //     { max: 50, message: '长度不能超过50个字符', trigger: 'change' }
                // ],
                //  countryCode: [
                //     { required: true, message: '请输入国家', trigger: 'change' },
                //     { max: 50, message: '长度不能超过50个字符', trigger: 'change' }
                // ],
                // arrayDate: [
                //     { required: true, message: '请选择时间', trigger: 'blur' }
                // ],
                title: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseenteraname"),
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: this.$i18n.t("common.Between1and50charactersinlength"),
                        trigger: 'blur'
                    }
                ],
                category: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectacategory"),
                        trigger: 'change'
                    }
                ]
            },
            pageData: {},
            goodsData: {},
            count: 1,
            goodsId: [],
            selectGoodsId: [],
            selectGoods: [],
            selectGoodsNow:[],
            selectGoodsOld:[]
        };
    },
    // mounted:{
    //     getNums()
    // },
    methods: {
        selectAllOfSelectable(selection) {
            selectAllOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
        },
        selectOfSelectable(selection) {
            selectOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
        },
        getPageData2(data){
            console.info(data)
            this.pageData2 = data
        },
        initData() {
            console.log("初始化前的数据",this.form);
            //之前选择的商品
            this.pageDataSelectList = this.form.activityGoods;
            console.log("==============",this.pageDataSelectList)
            this.valuePicker = [];
            this.selectGoods = [];
            this.selectGoodsId = [];
            if (this.form.activityGoods != null && this.form.activityGoods.length != 0) {
                this.form.activityGoods.forEach((element) => {
                    this.selectGoodsId.push(element.goodsId);
                });
                this.handleSelected2();
            }
            let arr = [];
            let type = 'yyyy-MM-dd';
            this.valuePicker[0] =this.$options.filters['formatDate']( this.form.beginTime, type);
            this.valuePicker[1] = this.$options.filters['formatDate'](this.form.endTime, type);

            this.form.arrayDate = arr;
            this.goodNums= this.selectGoods.length
            console.log("当前时间"+this.arrayDate);
        },
        deleteRow(index, row) {
             this.$confirm(this.$i18n.t("common.Thisoperationwillremovethefile"),  this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.selectGoodsId.splice(index, 1);
                    this.selectGoods.splice(index, 1);
                this.$message({
                    type: 'success',
                    message:   this.$i18n.t("common.Successfullydeleted")
                });
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message:  this.$i18n.t("common.Cancelleddeletion")
                });          
            });
        },
        handleSelected2(row) {
            console.log("this.selectGoods",this.selectGoods)
            this.selectGoodsNow.forEach(e=>{
                this.selectGoods.push(e);
            })
            console.log("this.selectGoods======",this.selectGoods)
            this.selectGoods.forEach(e=>{
                this.selectGoodsId.push(e.goodsId)
            })
            console.log("this.selectGoodsId",this.selectGoodsId)
            console.log("商品",this.selectGoodsId);
            this.$store.dispatch('getPromotionId', this.selectGoodsId).then((res) => {
                //已选择的商品
                this.selectGoods = res;
                this.selectGoodsId = [];
                console.log("商品",this.selectGoods);
                this.dialogFormTableVisible2 = false;
            });
        },
        handleCurrentChange(val) {
            this.listQuery2.page = val;
            //this.getGoods();
            this.handleSearchList2(this.listQuery2);
        },
        getGoods() {
            this.$store.dispatch('getTinyVariant', this.listQuery2).then((res) => {
                this.pageData2 = res;
                console.log('total', this.pageData2.total);
                console.log('this.pageData2.list', this.pageData2.list);
                if (this.pageData2.list) {
                    showSelectOfSelectable(this.$refs.tableData, this.pageData2.list)
                }
            });
        },
        //选择商品
        changeGoods(row) {
            this.dialogFormTableVisible2 = true;
            // this.listQuery2.countryCode = this.form.countryCode;
            // this.listQuery2.brandCode = this.form.brandCode;
            //this.getGoods();
            this.listQuery2 = {};
            this.handleSearchList2(this.listQuery2);
            console.log("选择商品初始值",this.selectGoods)
            // this.$nextTick( () => {
            //     this.$refs["FormTableDialog"].pageDataSelectList = new Array(this.pageDataSelectList.length);
            // })
            
            // console.log("选择商品",pageDataSelectList)
            console.log('changeGoods', this.dialogFormTableVisible2);
        },
        closeDialog(val) {
            this.$refs[val].resetFields();
            this.$emit('closeDialog3', val);
        },
        closeDialog3(val) {
            this.$emit('closeDialog3', val);
            // this.dialogFormTableVisible2 = false;
            // this.formListQuery=Object.assign({}, defaultFormListQuery)
            // this.dictionary=Object.assign({}, defaultDictionary)
        },
        /*
        handleSearchList2(val) {
            console.log('val',val)
            this.$emit('handleSearchList2', val);
        },*/
        handleSearchList2(listQuery) {
          this.listQuery2 = listQuery
          console.log("==============",listQuery);
          var effectShelf = ""
          if(listQuery.isUsed!=undefined){
            effectShelf=listQuery.isUsed==true?"true":"";
          }
          let queryData = {
            idFuzzy: listQuery.title,
            effectShelf: effectShelf, //是否上架
            page: listQuery.page?listQuery.page:1,
            size: 10
          };
          this.$store.dispatch('postGoodsSearch', queryData).then((res) => {
            this.pageData2 = res;
            console.log('this.pageData2.list', this.pageData2.list);
            if (this.$refs.tableData) {
              showSelectOfSelectable(this.$refs.tableData, this.pageData2.list)
            }
          });
        },
        handleSearchList3(val) {
            this.$emit('handleSearchList3', val);
        },
        handleResetSearch2() {
            //this.$emit('handleResetSearch2', val);
          this.listQuery2 = {};
          this.handleSearchList2(this.listQuery2);
        },
        //获取选中的
        selectionChange(row) {
            if (row) {
                this.selectedRow = row;
                this.selectedMount = 1;
                console.log('22', this.selectedRow);
            }
        },
        selectionChange2(row) {
            console.log("row",row)
            //当前选择的商品
            // this.selectGoods= row
            this.selectGoodsNow= row;
            // this.selectGoods.concat(this.selectGoodsNow)
            console.log("===============",this.selectGoodsNow)
            // this.$refs["FormTableDialog"].pageDataSelectList =  this.$refs['tableData'].selection;
            this.$nextTick( () => {
                // this.$refs["FormTableDialog"].pageDataSelectList = new Array(this.pageDataSelectList.length+this.$refs['tableData'].selection.length);
                this.$refs["FormTableDialog"].pageDataSelectList = new Array(this.$refs['tableData'].selection.length);
            })
            console.log(this.$refs["FormTableDialog"].pageDataSelectList);
        },
        //选择商品
        handleSelected() {
            this.dialogFormTableVisible2 = false;
            this.tableData = this.selectedRow;
            console.log('11', this.tableData);
        },
        //提交
        onSubmit(formName) {
            this.form.brandCode = this.$tenantBrand.getBrand();
            if(this.form.brandCode== undefined || this.form.brandCode == null && this.form.countryCode== undefined || this.form.countryCode == null){
                this.$message({
                        message: this.$i18n.t("common.Brandandcountrycannotbeempty"),
                        type: 'error',
                        duration: 1000
                    });
                return false;
            }
            if(this.valuePicker[0]== null || this.valuePicker[1] == null){
                this.$message({
                        message: this.$i18n.t("common.Pleaseselectatime"),
                        type: 'error',
                        duration: 1000
                    });
                return false;
            }
            console.log('111213141', this.form.arrayDate);
            console.log("===this.selectGoods",this.selectGoods)
            this.selectGoods.forEach(e=>{
                this.selectGoodsId.push(e.goodsId)
            })
            console.log("===this.selectGoodsId",this.selectGoodsId)
            let activityGoods = {
                goodsIds: this.selectGoodsId
            };
            if(this.form.status == undefined || this.form.status == null){
                this.form.status = false;
            }
            this.form.beginTime = moment(this.valuePicker[0]).unix()*1000;
            this.form.activityGoods = activityGoods;
            this.form.endTime = moment(this.valuePicker[1]).add(86399999,'milliseconds').unix()*1000;
            console.log(this.form.beginTime);
            console.log(this.form.endTime);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.form);
                    this.$confirm( this.$i18n.t("common.Whethertosubmitdata"),  this.$i18n.t("common.prompt"), {
                        // confirmButtonText: '确定',
                        // cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (this.isEdit) {
                            this.$store.dispatch('updateFreeFreight', this.form).then((res) => {
                                this.$message({
                                    type: 'success',
                                    message: this.$i18n.t("common.Successfullyedited")
                                });
                                this.handleSearchList3();
                            });
                        } else {
                            this.$store.dispatch('addFreeFreight', this.form).then((res) => {
                                this.$message({
                                    type: 'success',

                                    message: this.$i18n.t("common.Successfullyadded")

                                });
                            this.handleSearchList3();
                            });
                        }
                    });
                } else {
                    this.$message({
                        message:  this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        }
    },
    filters: {
        formatDate(time, type = 'yyyy-MM-dd') {
            if (time == null || time === '') {
                return 'N/A';
            }
            let date = new Date(time);
            return formatDate(date, type);
        }
    },
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seRadio .el-radio__label {
    display: none;
}
</style>
