<template>
    <div style="height: 100%;min-height:350px;">
		<filter-container :arg="arg" :placeholder='placeholder' :defaultListQuery="defaultListQuery" :dictionary="dictionary" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch' @handleChangeCategory="handleChangeCategory"></filter-container>
		<div style="margin: 20px 0px 20px 20px;">
			<el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)">{{ $i18n.t('common.Add') }}</el-button>
		</div>
        <el-table
            ref="pagedata"
            :data="pageData.list"
            border
            style="width: 100%"
			height="calc(100% - 185px)"
        >
            <el-table-column prop="variantId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="classifyName" :label="$i18n.t('common.Class')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="theme" :label="$i18n.t('common.Variantparameters')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
				    {{ scope.row.variantParameters.length }}
				</template>
			</el-table-column>         
            <el-table-column prop="createTime" :label="$i18n.t('common.Creationtime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.createTime | formatDate}}</span>
				</template>
			</el-table-column>
            <el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.updateTime | formatDate}}</span>
				</template>
			</el-table-column>
            <el-table-column :label="$i18n.t('common.operation')" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update',module_code)">{{ $i18n.t('common.edit') }}</el-button>
                    <el-button @click="changeVariant(scope.row)" type="text" size="small">{{ $i18n.t('common.VariantName') }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <div class="block">
        	<el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
        		:page-size="listQuery.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
        	</el-pagination>
        </div>
        </div>
        <el-dialog :title="isEdit?$i18n.t('common.edit'):$i18n.t('common.Add1')" :visible.sync="dialogFormVisible" :before-close="addcloseDialog2" :close-on-click-modal="false">
			<div v-if="dialogFormVisible">
				<varoant-dialog ref="varoant" :dictionary="dictionary" :formListQuery="formListQuery" :isEdit="isEdit" :classList="classList" :checkedPrpa2="checkedPrpa2" @addcloseDialog="addcloseDialog" @handleSearchList="handleSearchList"/>   
			</div>
        </el-dialog>
		<el-dialog :title="titleName" :visible.sync="dialogNameVisible" :close-on-click-modal="false">
			<div v-if="dialogNameVisible">
				<name-dialog :rowInfo="rowInfo"/>
			</div>	    
		</el-dialog>
    </div>
</template>
<script>
import config from '@/utils/config';
import tenantBrand from '@/utils/tenantBrand'
import VaroantDialog from './components/VaroantDialog.vue';
import NameDialog from './components/NameDialog.vue';
import FilterContainer from '@/components/common/FilterContainer.vue';
import dictionaryUtil from '@/utils/dictionaryUtil';
const defaultFormListQuery={
		"variantId":"",
		"title": '1',	
		"brandCode": tenantBrand.getBrand(),//'AigoStar',
		"classifyId": "",
		"parameterReqList": [{
			"variantParameterId":"",
			"classifyParameterId":"",
			"parameterId":"",
			"parameterName":""
		}]
	};
const defaultDictionary={
		brandCode:dictionaryUtil.getDict('brand'),
	};

export default {
    components: { VaroantDialog,FilterContainer,NameDialog },
    name: 'varoantAdmin',
    data() {
        return {
			module_code:config.module_code.banners,
			formListQuery:Object.assign({}, defaultFormListQuery),
			dictionary:Object.assign({}, defaultDictionary),
            listQuery:{
				'classifyId':"",
            	"variantId": "",
            	"title": "",
            	//"brandCode": "",
            	"beginTime":"",
            	"endTime":"",
            	"classSetId":"",
            	"page": 1,
            	"limit": 10
            },
            defaultListQuery:{
			'classifyId':"",
              "variantId": "",
              "title": "",
              //"brandCode": "",
              "beginTime":"",
              "endTime":"",
              "classSetId":"",
              "page": 1,
              "limit": 10
            },
			rowInfo:{},
			//组件标签是否显示
			arg:{
				//brand:true,
				category:true
			},
			category:'',
			placeholder: {
				name:this.$i18n.t('common.name')				
			},		
			titleName:this.$i18n.t('common.VariantName'),
			isEdit:false,
            //表单数据
            formItem: {},
			checkedPrpa2:[],
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
			dialogNameVisible:false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
			classList:[],
            webSiteUrl: process.env.VUE_APP_WEBSITE
        };
    },

    mounted() {
		this.handleSearchList()
		this.postVarAdSearchClassify()
    },
    methods: {
		//获取商品树类目
		postVarAdSearchClassify(){
			this.$store
				.dispatch('postVarAdSearchClassify', {
					level:1
				})
				.then(res => {
					this.$set(this.dictionary,"classifyTree",res)				
				});
		},
		handleChangeCategory(val){
			this.category=val[val.length-1]
		},	
		//搜索列表
		handleSearchList(listQuery){
			let type='yyyy-MM-dd hh:mm:ss'
			let beginTime=new Date(new Date(new Date().toLocaleDateString()).getTime())
			let endTime=new Date(new Date(new Date().toLocaleDateString()).getTime() +24 * 60 * 60 * 1000 -1)
			let nowTime=Date.now()
			let nowTime2=Date.now() + 24 * 60 * 60 * 1000 - 1		
			if(listQuery){
				this.listQuery.page=1
				// let beginTime1=new Date(listQuery.date[0]).getTime()
				// let endTime1=new Date(listQuery.date[1]).getTime()			
				this.listQuery={
					"classifyId": this.category?this.category:'',
					"variantId": "",
					"title": listQuery.title,
					//"brandCode": listQuery.brand,
					"classSetId":"",
					"page": this.listQuery.page,
					"limit": this.listQuery.limit
				}				
			}			
			this.$store
				.dispatch('getVarAdVariant', this.listQuery)
				.then(res => {					
					this.pageData=res
					this.pageData.list.forEach((item,index)=>{
						//状态
						if(item.beginTime>nowTime){
							item.status1=this.$i18n.t('common.begininaminute')
							item.statusColor='red'
						}else if(item.endTime<nowTime){
							item.status1=this.$i18n.t('common.Ended')
							item.statusColor='black'
						}else if(item.endTime>nowTime && item.beginTime<=nowTime){
							item.status1=this.$i18n.t('common.Inprogress')
							item.statusColor='green'
						}
						//位置
						if(item.bannerPosition==='HOMEPAGE'){
							item.position=this.$i18n.t('common.Homebanner')
						}else if(item.bannerPosition==='HOMERECOMMENDED'){
							item.position=this.$i18n.t('common.Homepagerecommendationposition')						
						}
						//bannerType
						if(item.bannerType==='PICTURE'){
							item.bannerType1=this.$i18n.t('common.picture')
						}else if(item.bannerType==='VIDEO'){
							item.bannerType1=this.$i18n.t('common.video')						
						}
					})
					
				});
		},
		//打开新增
		add(){
			this.isEdit=false
			this.checkedPrpa2=[]
            this.changeBrandCode()
			this.dialogFormVisible=true
		},
		//打开编辑
		edit(row){
			this.$nextTick(()=>{
				this.isEdit=true
				this.formListQuery= Object.assign({},row)
				this.$set(this.formListQuery,"variantId",row.variantId)
				this.checkedPrpa2=row.variantParameters
				this.changeBrandCode()
				this.dialogFormVisible=true
			})			
		},
		changeBrandCode(){
			this.$store
				.dispatch('postVarAdSearchClassify',{
					//brandCode:this.formListQuery.brandCode,
					level:1
				})
				.then(res => {					
						this.classList=res									
				});	
		},
		addcloseDialog(){
			this.dialogFormVisible=false
			this.formListQuery=Object.assign({}, defaultFormListQuery)
		},
		addcloseDialog2(){
			this.addcloseDialog()
		},
		handleResetSearch(){
			this.listQuery = Object.assign({}, this.defaultListQuery);
			this.category=''
			this.handleSearchList()
		},	
		//变更变体
		changeVariant(row){
			this.rowInfo=Object.assign({}, row)
			this.dialogNameVisible=true
		},
		handleCurrentChange(val) {
            this.listQuery.page = val;
            this.handleSearchList();
        },
    }
};
</script>
<style scoped>
</style>
