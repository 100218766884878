var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "140px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { padding: "auto" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          multiple: true,
                          codeType: "countrys",
                          placeholder: this.$i18n.t("common.country")
                        },
                        model: {
                          value: _vm.query.countryCodes,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "countryCodes", $$v)
                          },
                          expression: "query.countryCodes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": _vm.$i18n.t("common.to"),
                          "start-placeholder": _vm.$i18n.t("common.Startdate"),
                          "end-placeholder": _vm.$i18n.t("common.Enddate"),
                          clearable: false
                        },
                        model: {
                          value: _vm.valuePicker,
                          callback: function($$v) {
                            _vm.valuePicker = $$v
                          },
                          expression: "valuePicker"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          clearable: "",
                          codeType: "platforms",
                          placeholder: _vm.$i18n.t("common.platform")
                        },
                        model: {
                          value: _vm.query.platform,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "platform", $$v)
                          },
                          expression: "query.platform"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.handleQuery }
                        },
                        [
                          _vm._v(
                            "  " +
                              _vm._s(_vm.$i18n.t("common.search")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "el-icon-search",
                            type: "primary",
                            size: "small "
                          },
                          on: { click: _vm.cleanQuery }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.Reset")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading.fullscreen.lock",
                              value: _vm.fullscreenLoading,
                              expression: "fullscreenLoading",
                              modifiers: { fullscreen: true, lock: true }
                            }
                          ],
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "el-icon-download",
                            type: "primary",
                            size: "small"
                          },
                          on: { click: _vm.handleExport }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.export")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: { data: _vm.list, border: "", stripe: "" },
          on: { "selection-change": _vm.selectionChange }
        },
        [
          _c(
            "el-table-column",
            { attrs: { label: _vm.getStateHeader(), align: "left" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Date"),
                  prop: "stateDate",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.stateDate))])]
                    }
                  }
                ])
              }),
              _vm.query.stateType == 0
                ? [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$i18n.t("common.AddToCart"),
                        prop: "totalVisit",
                        align: "center",
                        "render-header": function(h, o) {
                          return _vm.renderHeader(
                            h,
                            o,
                            this$1.$i18n.t(
                              "common.DifferentIPNumbersForAddingItemsToTheShoppingCart"
                            )
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [_c("span", [_vm._v(_vm._s(row.addCart))])]
                            }
                          }
                        ],
                        null,
                        false,
                        256798273
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$i18n.t("common.EnterCheckout"),
                        prop: "newVisit",
                        align: "center",
                        "render-header": function(h, o) {
                          return _vm.renderHeader(
                            h,
                            o,
                            this$1.$i18n.t(
                              "common.DifferentIPNumbersForEnteringTheOrderConfirmationPage"
                            )
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("span", [_vm._v(_vm._s(row.enterAccount))])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1337417677
                      )
                    })
                  ]
                : [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$i18n.t("common.OfflineShoppingCart"),
                        prop: "totalVisit",
                        align: "center",
                        "render-header": function(h, o) {
                          return _vm.renderHeader(
                            h,
                            o,
                            this$1.$i18n.t(
                              "common.DifferentIPNumbersForAddingItemsToTheShoppingCart"
                            )
                          )
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [_vm._v(_vm._s(row.offlineCart))])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$i18n.t("common.UserLogin"),
                        prop: "newVisit",
                        align: "center",
                        "render-header": function(h, o) {
                          return _vm.renderHeader(
                            h,
                            o,
                            this$1.$i18n.t(
                              "common.DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage"
                            )
                          )
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [_c("span", [_vm._v(_vm._s(row.userLogin))])]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$i18n.t("common.UserRegistration"),
                        prop: "newVisit",
                        align: "center",
                        "render-header": function(h, o) {
                          return _vm.renderHeader(
                            h,
                            o,
                            this$1.$i18n.t(
                              "common.DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage"
                            )
                          )
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", [_vm._v(_vm._s(row.userRegister))])
                            ]
                          }
                        }
                      ])
                    })
                  ]
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }