var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: {
                title: _vm.$t("common.Pleaseselectaproduct"),
                width: "75%",
                visible: _vm.visible,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.visible = $event
                }
              }
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      placeholder: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                      clearable: ""
                    },
                    model: {
                      value: _vm.listQuery.idFuzzy,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "idFuzzy", $$v)
                      },
                      expression: "listQuery.idFuzzy"
                    }
                  })
                ],
                1
              ),
              _vm.activityId
                ? _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("common.ApplicableUserRange")
                      },
                      model: {
                        value: _vm.listQuery.userPromotion,
                        callback: function($$v) {
                          _vm.$set(_vm.listQuery, "userPromotion", $$v)
                        },
                        expression: "listQuery.userPromotion"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("common.NewUserPromotion"),
                          value: "NEW"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("common.RegularUserPromotion"),
                          value: "OLD"
                        }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("common.nothing"), value: "NO" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: {
                            width: "180px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            placeholder: _vm.$i18n.t("common.Isitontheshelf"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQuery.effectShelf,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "effectShelf", $$v)
                            },
                            expression: "listQuery.effectShelf"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Listed"),
                              value: "true"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Notlisted"),
                              value: "false"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.treeData,
                          props: _vm.propsInfo,
                          clearable: ""
                        },
                        model: {
                          value: _vm.listQuery.classifyIds,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "classifyIds", $$v)
                          },
                          expression: "listQuery.classifyIds"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleCurrentChange(1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                               " +
                          _vm._s(_vm.$i18n.t("common.query")) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$i18n.t("common.Reset")) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "tableData",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.pageData2.list,
                "tree-props": {
                  children: "packageQtyList",
                  hasChildren: "hasChildren"
                },
                id: "goodsTable",
                border: "",
                "row-key": "goodsId",
                "default-expand-all": "",
                "header-cell-style": { background: "#EAEEF9", color: "#32353a" }
              },
              on: {
                select: _vm.selectOfSelectable,
                "select-all": _vm.selectAllOfSelectable,
                "selection-change": _vm.handleSelectionChange1
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                  selectable: function(row) {
                    return row.checkboxStatus
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantityType",
                  label: "",
                  width: "50px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsId",
                  label: _vm.$t("common.ProductID"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsCode",
                  label: _vm.$t("common.SKU_EAN_Encoding"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v("SKU:" + _vm._s(scope.row.sku))]),
                        _c("br"),
                        _c("span", [
                          _vm._v("EAN:" + _vm._s(scope.row.barCode))
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$i18n.t("common.number")) +
                              ":" +
                              _vm._s(scope.row.goodsCode)
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "imageUrl",
                  label: _vm.$i18n.t("common.Productimage1"),
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("listImage", {
                          attrs: { imgUrl: scope.row.imageUrl }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "packageQuantity",
                  label: _vm.$t("common.PackingQuantity"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: _vm.$i18n.t("common.ProductName"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "treeList",
                  label: _vm.$i18n.t("common.Category"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.treeList, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _vm._l(item.parents, function(item2, index2) {
                              return _c("span", { key: index2 }, [
                                _c("span", [_vm._v(_vm._s(item2.title) + ">")])
                              ])
                            }),
                            _c("span", [_vm._v(_vm._s(item.title))])
                          ],
                          2
                        )
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$i18n.t("common.Countrieslisted"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.goodsSpecials, function(
                        s,
                        index
                      ) {
                        return s.shelf
                          ? _c("span", { key: index }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$dict.getDictValue(
                                      "countrys",
                                      s.countryCode
                                    )
                                  )
                              ),
                              _c("br")
                            ])
                          : _vm._e()
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$i18n.t("common.inventory"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.goodsSpecials, function(
                        s,
                        index
                      ) {
                        return s.shelf && s.ecomRemainQty
                          ? _c("span", { key: index }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$dict.getDictValue(
                                      "countrys",
                                      s.countryCode
                                    )
                                  ) +
                                  ":" +
                                  _vm._s(s.ecomRemainQty)
                              ),
                              _c("br")
                            ])
                          : _vm._e()
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$t("common.ProductPromotion"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.isShowGoodsPromotion(
                          scope.row.promotionRuleCountrys,
                          "NEW"
                        )
                          ? _c(
                              "span",
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "text-align": "left",
                                      display: "block"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("common.NewUserPromotion"))
                                    ),
                                    _c("br")
                                  ]
                                ),
                                _vm._l(
                                  scope.row.promotionRuleCountrys,
                                  function(promotion, index) {
                                    return promotion.userType == "NEW"
                                      ? _c("span", { key: index }, [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.$dict.getDictValue(
                                                  "countrys",
                                                  promotion.countryCode
                                                )
                                              ) +
                                              ":" +
                                              _vm._s(
                                                _vm.getPromotionGoodsPrice(
                                                  promotion,
                                                  scope.row.prices
                                                )
                                              )
                                          ),
                                          _c("br")
                                        ])
                                      : _vm._e()
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.isShowGoodsPromotion(
                          scope.row.promotionRuleCountrys,
                          "OLD"
                        )
                          ? _c(
                              "span",
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "text-align": "left",
                                      display: "block"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("common.RegularUserPromotion")
                                      )
                                    ),
                                    _c("br")
                                  ]
                                ),
                                _vm._l(
                                  scope.row.promotionRuleCountrys,
                                  function(promotion, index) {
                                    return promotion.userType == "OLD"
                                      ? _c("span", { key: index }, [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.$dict.getDictValue(
                                                  "countrys",
                                                  promotion.countryCode
                                                )
                                              ) +
                                              ":" +
                                              _vm._s(
                                                _vm.getPromotionGoodsPrice(
                                                  promotion,
                                                  scope.row.prices
                                                )
                                              )
                                          ),
                                          _c("br")
                                        ])
                                      : _vm._e()
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        !scope.row.promotionRuleCountrys ||
                        scope.row.promotionRuleCountrys.length == 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "common.ThereAreCurrentlyNoProductPromotions"
                                  )
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm.activityId
                ? _c("el-table-column", {
                    attrs: {
                      prop: "prices",
                      label: _vm.$i18n.t("common.fixedprice"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.prices, function(s, index) {
                              return _c("span", { key: index }, [
                                s.resultPrice
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(s.countryCode) +
                                          ":" +
                                          _vm._s(s.resultPrice.toFixed(2))
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e()
                              ])
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      4099906668
                    )
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "shelf",
                  label: _vm.$i18n.t("common.state"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.shelf == true
                          ? _c("span", { staticStyle: { color: "#46a8a0" } }, [
                              _vm._v(_vm._s(_vm.$i18n.t("common.Listed")))
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$i18n.t("common.Notlisted")))
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.listQuery.page,
                  "page-size": _vm.listQuery.size,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.pageData2.total
                },
                on: { "current-change": _vm.handleCurrentChange }
              }),
              _vm.closeFooter == false
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                        "align-items": "center"
                      },
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$i18n.t("common.Selected")) + ":"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#008000",
                              "font-size": "16px",
                              "font-weight": "800"
                            }
                          },
                          [_vm._v(_vm._s(_vm.pageDataSelectList.length))]
                        )
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%", "margin-left": "50px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSelected }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$i18n.t("common.confirm")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }