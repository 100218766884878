import { render, staticRenderFns } from "./PalletTranslateDialog.vue?vue&type=template&id=a64c50fc&scoped=true&"
import script from "./PalletTranslateDialog.vue?vue&type=script&lang=js&"
export * from "./PalletTranslateDialog.vue?vue&type=script&lang=js&"
import style0 from "./PalletTranslateDialog.vue?vue&type=style&index=0&id=a64c50fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a64c50fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\jenkinsworkspace\\Aigo.B2CAdmin.Publish\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a64c50fc')) {
      api.createRecord('a64c50fc', component.options)
    } else {
      api.reload('a64c50fc', component.options)
    }
    module.hot.accept("./PalletTranslateDialog.vue?vue&type=template&id=a64c50fc&scoped=true&", function () {
      api.rerender('a64c50fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pallet/components/PalletTranslateDialog.vue"
export default component.exports