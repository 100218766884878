var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "formItem", attrs: { model: _vm.formItem, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.name"),
                prop: "name",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$i18n.t("common.Pleaseenteraname") },
                model: {
                  value: _vm.formItem.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "name", $$v)
                  },
                  expression: "formItem.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "homePageCopyWriterCountrys",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.homePageCopyWriterCountrys,
                    callback: function($$v) {
                      _vm.homePageCopyWriterCountrys = $$v
                    },
                    expression: "homePageCopyWriterCountrys"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [
                      _vm._v(
                        "\n                     " +
                          _vm._s(item.name) +
                          "\n                 "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Displaytime"),
                prop: "time",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "-",
                  "start-placeholder": _vm.$i18n.t("common.starttime"),
                  "end-placeholder": _vm.$i18n.t("common.Endtime")
                },
                model: {
                  value: _vm.formItem.time,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "time", $$v)
                  },
                  expression: "formItem.time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.JumpType"),
                prop: "jumpType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formItem.jumpType,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "jumpType", $$v)
                    },
                    expression: "formItem.jumpType"
                  }
                },
                _vm._l(_vm.dictionary.jumpType, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: item.code, attrs: { label: item.code } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.name) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formItem.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "status", $$v)
                  },
                  expression: "formItem.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.sort"),
                prop: "sort",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  oninput: "value=value.replace(/[^0-9]/g,'')",
                  placeholder: _vm.$i18n.t("common.Topwithlargenumbers")
                },
                model: {
                  value: _vm.formItem.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "sort", $$v)
                  },
                  expression: "formItem.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Multilingualconfiguration"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.formItem.homePageCopyWriterLangs, function(
                  item,
                  index
                ) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: { label: item.name, name: item.countryCode }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.picture"),
                            prop:
                              "homePageCopyWriterLangs." + index + ".imgUrl",
                            "label-width": _vm.formLabelWidth2
                          }
                        },
                        [
                          _c("imgUpload", {
                            ref: "imageUpload",
                            refInFor: true,
                            attrs: { img: item.imgUrl, imgIndex: index },
                            on: { event: _vm.changeUrl }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "jumpUrlCss",
                          attrs: {
                            label: _vm.$i18n.t("common.CopywritingDisplay"),
                            prop: "homePageCopyWriterLangs." + index + ".title",
                            "label-width": _vm.formLabelWidth2
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: {
                              placeholder: _vm.$i18n.t("common.PleaseEnter")
                            },
                            model: {
                              value: item.title,
                              callback: function($$v) {
                                _vm.$set(item, "title", $$v)
                              },
                              expression: "item.title"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.formItem.jumpType == 1
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "jumpUrlCss",
                              attrs: {
                                label: _vm.$i18n.t("common.JumpLink"),
                                prop:
                                  "homePageCopyWriterLangs." +
                                  index +
                                  ".jumpUrl",
                                "label-width": _vm.formLabelWidth2
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  placeholder: _vm.$i18n.t(
                                    "common.Enterjumplink"
                                  )
                                },
                                model: {
                                  value: item.jumpUrl,
                                  callback: function($$v) {
                                    _vm.$set(item, "jumpUrl", $$v)
                                  },
                                  expression: "item.jumpUrl"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.onSubmit("formItem")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm1")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }