var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading"
        }
      ]
    },
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Selectproduct"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.changeGoods }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.Pleaseselectaproduct")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "umar-b10  upload-btn",
                      attrs: { type: "primary" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("common.ImportFile")) +
                          "\n                        "
                      ),
                      _c("div", { attrs: { id: "clone_input" } }, [
                        _c("input", {
                          ref: "file",
                          staticClass: "upload-btn-file",
                          attrs: { type: "file" },
                          on: { change: _vm.importClick }
                        })
                      ])
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.downloadImportTpl()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.DownloadImportTemplate")))]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableList && _vm.tableList.length > 0,
              expression: "tableList && tableList.length > 0"
            }
          ]
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "pageDataSelect",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "default-expand-all": "",
                        data: _vm.tableList,
                        "tree-props": {
                          children: "packageQtyList",
                          hasChildren: "hasChildren"
                        },
                        border: "",
                        "cell-style": _vm.productIdCellStyle
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "quantityType",
                          label: "",
                          width: "50px",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsId",
                          label: _vm.$t("common.ProductID"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsCode",
                          label: _vm.$t("common.SKU_EAN_Encoding"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v("SKU:" + _vm._s(scope.row.sku))
                                ]),
                                _c("br"),
                                _c("span", [
                                  _vm._v("EAN:" + _vm._s(scope.row.barCode))
                                ]),
                                _c("br"),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$i18n.t("common.coding")) +
                                      ":" +
                                      _vm._s(scope.row.goodsCode)
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "imageUrl",
                          label: _vm.$i18n.t("common.Productimage1"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.imageUrl
                                  ? _c("el-image", {
                                      staticStyle: { height: "100px" },
                                      attrs: {
                                        src: _vm.ossHost + scope.row.imageUrl,
                                        fit: "fill"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "packageQuantity",
                          label: _vm.$t("common.PackingQuantity"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: _vm.$i18n.t("common.ProductName"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsSpecials",
                          label: _vm.$i18n.t("common.Countrieslisted"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _vm._l(scope.row.goodsSpecials, function(
                                s
                              ) {
                                return _c("span", { key: s.countryCode }, [
                                  s.shelf == true
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$dict.getDictValue(
                                                "countrys",
                                                s.countryCode
                                              )
                                            )
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e()
                                ])
                              })
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "prices",
                          label: _vm.$i18n.t("common.inventory"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _vm._l(scope.row.goodsSpecials, function(
                                s,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(s.countryCode) +
                                      ":" +
                                      _vm._s(s.ecomRemainQty)
                                  ),
                                  _c("br")
                                ])
                              })
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brand",
                          label: _vm.$i18n.t("common.fixedprice"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _vm._l(scope.row.prices, function(
                                s,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  s.resultPrice
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(s.countryCode) +
                                            ":" +
                                            _vm._s(s.resultPrice.toFixed(2)) +
                                            _vm._s(
                                              _vm.$dict.getDictCurrencyValue(
                                                s.countryCode
                                              )
                                            )
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e()
                                ])
                              })
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brand",
                          label: _vm.$i18n.t("common.Fixedprice1"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _vm._l(scope.row.prices, function(
                                s,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  s.fixedPrice
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(s.countryCode) +
                                            ":" +
                                            _vm._s(
                                              Number(s.fixedPrice).toFixed(2)
                                            ) +
                                            _vm._s(
                                              _vm.$dict.getDictCurrencyValue(
                                                s.countryCode
                                              )
                                            )
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e()
                                ])
                              })
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "prices",
                          label: _vm.$i18n.t("common.Lowestprice"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _vm._l(scope.row.prices, function(
                                s,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  s.lowestPrice
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(s.countryCode) +
                                            ":" +
                                            _vm._s(
                                              Number(s.lowestPrice).toFixed(2)
                                            ) +
                                            _vm._s(
                                              _vm.$dict.getDictCurrencyValue(
                                                s.countryCode
                                              )
                                            )
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e()
                                ])
                              })
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "shelf",
                          label: _vm.$i18n.t("common.state"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.shelf == true
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#46a8a0" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$i18n.t("common.Listed"))
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.Notlisted"))
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.operation"),
                          fixed: "right",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setup(
                                          scope.row,
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t("common.Setuppromotions")
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.del(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialogFormTableVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Selectproduct"),
                visible: _vm.dialogFormTableVisible,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormTableVisible = $event
                },
                close: _vm.closeDialogGoods
              }
            },
            [
              _c("FormTableDialog", {
                attrs: {
                  argFrom: _vm.argFrom,
                  dictionary: _vm.dictionary,
                  pageDataSelectList: _vm.pageDataSelectList,
                  placeholderFrom: _vm.placeholderFrom
                },
                on: {
                  handleResetSearch: _vm.handleResetSearch,
                  handleChangeCategory: _vm.handleChangeCategory,
                  handleSelected: _vm.handleSelected,
                  handleSearchList: _vm.handleSearchList
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "tableSlot",
                      fn: function() {
                        return [
                          _c(
                            "h3",
                            {
                              staticStyle: { color: "red", margin: "10px 0px" }
                            },
                            [_vm._v(_vm._s(_vm.$i18n.t("common.Selectupto50")))]
                          ),
                          _c(
                            "el-table",
                            {
                              ref: "tableData",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.pageData.list,
                                height: "500",
                                border: "",
                                "row-key": "goodsId",
                                "default-expand-all": "",
                                "header-cell-style": {
                                  background: "#EAEEF9",
                                  color: "#32353a"
                                },
                                "tree-props": {
                                  children: "packageQtyList",
                                  hasChildren: "hasChildren"
                                },
                                id: "goodsTable"
                              },
                              on: {
                                select: _vm.selectOfSelectable,
                                "select-all": _vm.selectAllOfSelectable,
                                "selection-change": _vm.handleSelectionChange
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  "reserve-selection": true,
                                  type: "selection",
                                  width: "55",
                                  selectable: function(row) {
                                    return row.checkboxStatus
                                  }
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "quantityType",
                                  label: "",
                                  width: "50px",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "goodsId",
                                  label: _vm.$i18n.t("common.ProductID"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "goodsCode",
                                  label: _vm.$t("common.SKU_EAN_Encoding"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "SKU:" + _vm._s(scope.row.sku)
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _vm._v(
                                              "EAN:" + _vm._s(scope.row.barCode)
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$i18n.t("common.coding")
                                              ) +
                                                ":" +
                                                _vm._s(scope.row.goodsCode)
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1138614848
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "imageUrl",
                                  label: _vm.$i18n.t("common.Productimage1"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.imageUrl
                                            ? _c("el-image", {
                                                staticStyle: {
                                                  height: "100px"
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.ossHost +
                                                    scope.row.imageUrl,
                                                  fit: "fill"
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  183490098
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "packageQuantity",
                                  label: _vm.$t("common.PackingQuantity"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "title",
                                  label: _vm.$i18n.t("common.ProductName"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "goodsSpecials",
                                  label: _vm.$i18n.t("common.Countrieslisted"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return _vm._l(
                                          scope.row.goodsSpecials,
                                          function(s, index) {
                                            return _c("span", { key: index }, [
                                              s.shelf
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$dict.getDictValue(
                                                          "countrys",
                                                          s.countryCode
                                                        )
                                                      )
                                                    ),
                                                    _c("br")
                                                  ])
                                                : _vm._e()
                                            ])
                                          }
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2013868671
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "prices",
                                  label: _vm.$t("common.Promotionprice1"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm.isShowGoodsPromotion(
                                            scope.row.promotionRuleCountrys,
                                            "NEW"
                                          )
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        "text-align": "left",
                                                        display: "block"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.NewUserPromotion"
                                                          )
                                                        )
                                                      ),
                                                      _c("br")
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    scope.row
                                                      .promotionRuleCountrys,
                                                    function(promotion, index) {
                                                      return promotion.userType ==
                                                        "NEW"
                                                        ? _c(
                                                            "span",
                                                            { key: index },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$dict.getDictValue(
                                                                      "countrys",
                                                                      promotion.countryCode
                                                                    )
                                                                  ) +
                                                                  ":" +
                                                                  _vm._s(
                                                                    _vm.getPromotionGoodsPrice(
                                                                      promotion,
                                                                      scope.row
                                                                        .prices
                                                                    )
                                                                  )
                                                              ),
                                                              _c("br")
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm.isShowGoodsPromotion(
                                            scope.row.promotionRuleCountrys,
                                            "OLD"
                                          )
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        "text-align": "left",
                                                        display: "block"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.RegularUserPromotion"
                                                          )
                                                        )
                                                      ),
                                                      _c("br")
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    scope.row
                                                      .promotionRuleCountrys,
                                                    function(promotion, index) {
                                                      return promotion.userType ==
                                                        "OLD"
                                                        ? _c(
                                                            "span",
                                                            { key: index },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$dict.getDictValue(
                                                                      "countrys",
                                                                      promotion.countryCode
                                                                    )
                                                                  ) +
                                                                  ":" +
                                                                  _vm._s(
                                                                    _vm.getPromotionGoodsPrice(
                                                                      promotion,
                                                                      scope.row
                                                                        .prices
                                                                    )
                                                                  )
                                                              ),
                                                              _c("br")
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          !scope.row.promotionRuleCountrys ||
                                          scope.row.promotionRuleCountrys
                                            .length == 0
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$i18n.t(
                                                      "common.ThereAreCurrentlyNoProductPromotions"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  119494656
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "prices",
                                  label: _vm.$t("common.inventory"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return _vm._l(
                                          scope.row.goodsSpecials,
                                          function(s, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(s.countryCode) +
                                                  ":" +
                                                  _vm._s(s.ecomRemainQty)
                                              ),
                                              _c("br")
                                            ])
                                          }
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  590014020
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "prices",
                                  label: _vm.$i18n.t("common.fixedprice"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return _vm._l(
                                          scope.row.prices,
                                          function(s, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$dict.getDictValue(
                                                      "countrys",
                                                      s.countryCode
                                                    )
                                                  ) +
                                                  ":"
                                              ),
                                              s.resultPrice
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        s.resultPrice.toFixed(2)
                                                      )
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("br")
                                            ])
                                          }
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2658108795
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "brand",
                                  label: _vm.$i18n.t("common.price1"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return _vm._l(
                                          scope.row.prices,
                                          function(s, index) {
                                            return _c("span", { key: index }, [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        s.salePriceEbp ||
                                                        s.salePriceEbp >= 0,
                                                      expression:
                                                        "s.salePriceEbp || s.salePriceEbp>=0"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(s.countryCode) +
                                                      ":" +
                                                      _vm._s(
                                                        s.salePriceEbp.toFixed(
                                                          2
                                                        )
                                                      )
                                                  ),
                                                  _c("br")
                                                ]
                                              )
                                            ])
                                          }
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4254723024
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "prices",
                                  label: _vm.$i18n.t("common.Fixedprice1"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return _vm._l(
                                          scope.row.prices,
                                          function(s, index) {
                                            return _c("span", { key: index }, [
                                              s.resultPrice
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(s.countryCode) +
                                                        ":" +
                                                        _vm._s(
                                                          Number(
                                                            s.fixedPrice
                                                          ).toFixed(2)
                                                        )
                                                    ),
                                                    _c("br")
                                                  ])
                                                : _vm._e()
                                            ])
                                          }
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1892248305
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "prices",
                                  label: _vm.$i18n.t("common.Lowestprice"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return _vm._l(
                                          scope.row.prices,
                                          function(s, index) {
                                            return _c("span", { key: index }, [
                                              s.lowestPrice
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(s.countryCode) +
                                                        ":" +
                                                        _vm._s(
                                                          Number(
                                                            s.lowestPrice
                                                          ).toFixed(2)
                                                        ) +
                                                        _vm._s(
                                                          _vm.$dict.getDictCurrencyValue(
                                                            s.countryCode
                                                          )
                                                        )
                                                    ),
                                                    _c("br")
                                                  ])
                                                : _vm._e()
                                            ])
                                          }
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3335674497
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "shelf",
                                  label: _vm.$i18n.t("common.state"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.shelf == true
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#46a8a0"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "common.Listed"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$i18n.t(
                                                      "common.Notlisted"
                                                    )
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2074187132
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "block" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "current-page": _vm.listQuery.page,
                                  "page-size": _vm.listQuery.size,
                                  layout: "total, prev, pager, next, jumper",
                                  total: _vm.pageData.total
                                },
                                on: {
                                  "current-change": _vm.handleCurrentChange
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2448895581
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: { click: _vm.closeAddDialog }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }