var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v(_vm._s(_vm.$i18n.t("common.GlobalConfiguration")))
      ]),
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { "margin-left": "50px" },
          attrs: {
            "label-position": "left",
            size: "small",
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.langsArr, function(item, index) {
              return _c(
                "el-tab-pane",
                {
                  key: index,
                  attrs: { label: item.langName, name: item.code }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.PromotionalPpicture"),
                            prop: "imgUrl"
                          }
                        },
                        [
                          _c("imgUpload", {
                            ref: "imageUpload",
                            refInFor: true,
                            attrs: {
                              imgMessage: item.imageUrl,
                              imglimit: 1,
                              imgSize: 1,
                              countryCode: item.lang,
                              imgUrlList: item.imgUrlList
                            },
                            on: { event: _vm.changeUrl }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.cancel")))
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.saveGiftActivityPublicityChart }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }