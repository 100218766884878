<template>
 
    <div  v-if="isShow">
        <el-form :model="formItem" ref="formItem">

            <el-form-item :label='$i18n.t("common.ProductID")' prop="palletName" :label-width="formLabelWidth">
                <template>
                    <span>{{ formItem.palletName }}</span>
                </template>
            </el-form-item>
 

            <el-form-item   v-for="(item) in languageList" :key="item.id" :label="item.name" :prop="item.palletName"  :label-width="formLabelWidth">
                <el-input v-model="item.palletName"  :placeholder='$i18n.t("common.Pleaseentertheproducttitle")' @input="forceUpdate($event)" />
            </el-form-item>


        
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%;" @click="closeDialogTranslate(false)"> {{ $i18n.t("common.cancel") }}</el-button>
            <el-button style="width: 20%;" type="primary" :loading="loading" @click="onSubmit('formItem')"> {{ $i18n.t("common.confirm") }}</el-button>
        </div>
      
     

    </div>
</template>
<script>
 
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultlanguageList = dictionaryUtil.getDict('lang');
export default {
    components: {
         
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {  
        return {
            languageList: JSON.parse(JSON.stringify(defaultlanguageList)),
            formLabelWidth: '150px',      
            goodsDialogVisible: false,
            tableList:[],
            //组件标签是否显示
            argFrom: {
                name: true,
                category: true,
                useState: true
            },
            countrys:[],
            isShow: false,
            loading: false    
        };
    },
    created() {
         
    },
    mounted() {
        this.$nextTick(()=>{
            this.initData()
        })   
    },
    methods: {

        closeDialogTranslate(status) {
            this.$emit('closeDialogTranslate',status);
        },
        //强行刷新
        forceUpdate(e) {
            this.$forceUpdate()
        },

        initData() {
            let param = {
                'palletId': this.formItem.palletId
            }
            this.isShow = false
                this.$store.dispatch('getPalletTranslate', param).then(res => {
                // console.log(res)
                this.languageList.forEach((element)=>{   
                    res.forEach((item)=>{
                        if(item.lang==element.code){
                            element.palletName = item.palletName
                        }
                    }) 
                })
                this.isShow = true          
                // console.log('initData',this.languageList)
            });
        },
        //提交
        onSubmit(formName) {
            this.$confirm( this.$i18n.t("common.Whethertosubmitdata"),  this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {    
                this.loading=true 
                let param = {
                    'palletId': this.formItem.palletId,
                    'languages': []
                };
                this.languageList.forEach(element => {
                    if(element.palletName){
                        let item = {
                            'palletId': this.formItem.palletId,
                            'lang': element.code,
                            'palletName': element.palletName
                        }
                        param.languages.push(item)
                    }
                });
                this.$store.dispatch('addPalletTranslate', param).then(response => {
                    this.$message({
                        message:  this.$i18n.t("common.Successfullymodified"),
                        type: 'success',
                        duration: 1000
                    });
                    this.closeDialogTranslate(true);
                }).finally(() => {
                    this.loading = false;
                });
            }).catch(() => {
            });
 
        },
    }
};
</script>
<style scoped>
 

 
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
 
 
</style>
