var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "800px",
        visible: _vm.formVisible,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.formVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.formRules,
            model: _vm.form,
            size: "small",
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.name"), prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode"
              }
            },
            [
              _c("dataSelect", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  codeType: "countrys",
                  placeholder: _vm.$i18n.t("common.Pleaseselectacountry")
                },
                model: {
                  value: _vm.form.countryCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "countryCode", $$v)
                  },
                  expression: "form.countryCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "URL", prop: "sourceUrl" } },
            [
              _c("el-input", {
                attrs: { maxlength: "500", "show-word-limit": "" },
                model: {
                  value: _vm.form.sourceUrl,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sourceUrl", $$v)
                  },
                  expression: "form.sourceUrl"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$i18n.t("common.source"), prop: "sourceInfo" }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.sourceInfo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sourceInfo", $$v)
                      },
                      expression: "form.sourceInfo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.Remarks"), prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "1000",
                  "show-word-limit": "",
                  oninput: "if(value.length>1000)value=value.slice(0,1000)"
                },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.formVisible = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.doCreate()
                }
              }
            },
            [_vm._v("  " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }