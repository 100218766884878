<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <div style="padding: auto">
                    <el-form-item>
                        <dataSelect
                            style="width: 180px;"
                            class="filter-item"
                            v-model="query.countryCodes"
                            clearable
                            :multiple="true"
                            :codeType="'countrys'"
                           :placeholder='this.$i18n.t("common.country")'
                        ></dataSelect>
                    </el-form-item>

                    <el-form-item>
                        <el-date-picker
                                v-model="valuePicker"
                                type="daterange"
                                :range-separator='$i18n.t("common.to")'
                                :start-placeholder='$i18n.t("common.Startdate")'
                                :end-placeholder='$i18n.t("common.Enddate")'
                                :clearable="false"
                        >
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-input v-model="query.sourceUrlName" :placeholder='$i18n.t("common.Pleaseenterthesource")'
                                clearable
                                  style="width: 200px;margin-left: 10px" class="filter-item"
                                  oninput="value=value.replace(/[%]/g,'')"
                                  @keyup.enter.native="handleQuery"/>
                    </el-form-item>

                    <el-form-item>
                        <dataSelect
                                style="width: 180px;"
                                class="filter-item"
                                v-model="query.platform"
                                clearable
                                :codeType="'platforms'"
                                :placeholder='$i18n.t("common.platform")'
                        ></dataSelect>
                    </el-form-item>

                    <el-form-item>
                        <el-select v-model="query.stateType"
                                   style="width: 180px;"
                                   class="filter-item" :placeholder='$i18n.t("common.PleaseSelectTheStatisticalType")'>
                            <el-option :label='$i18n.t("common.StatisticalByDate")' value="0"></el-option>
                            <el-option :label='$i18n.t("common.StatisticalBySource")'   value="1"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> {{$i18n.t("common.search")}}
                        </el-button>
                        <el-button
                                class="filter-item"
                                icon="el-icon-search"
                                type="primary"
                                @click="cleanQuery"
                                style="margin-left: 10px"
                                size="small "
                        >
                        {{$i18n.t("common.Reset")}}
                        </el-button>

                        <el-button
                            class="filter-item"
                            icon="el-icon-download"
                            type="primary"
                            @click="handleExport"
                            style="margin-left: 10px"
                            size="small"
                            v-loading.fullscreen.lock="fullscreenLoading"
                        >
                        {{$i18n.t("common.export")}}
                        </el-button>
                    </el-form-item>
                </div>
            </el-form>

        </div>

        <el-table v-loading="listLoading" :data="list" @selection-change="selectionChange" border stripe
                  style="width: 100%;margin-top: 10px;">

            <el-table-column :label="getStateHeader()" align="left">
                <el-table-column :label='$i18n.t("common.Date")' prop="stateDate" align="center" v-if="stateType == '0'">
                    <template slot-scope="{ row }">
                        <span>{{ row.stateDate }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.source")' prop="sourceUrlName" align="center" v-else>
                    <template slot-scope="{ row }">
                        <span>{{ row.sourceUrlName }}</span>

                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.Visits")' prop="totalVisit" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.NumberOfDifferentIPsToAccessTheURL'))">
                    <template slot-scope="{ row }">
                        <span>{{ row.visit }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.NewVisitors")' prop="newVisit" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.TheNumberOfDifferentIPAddressesForTheFirstVisitToTheWebsite'))">
                    <template slot-scope="{ row }">
                        <span>{{ row.newVisit }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label='$i18n.t("common.NumberOfNewUserRegistrations")' prop="registerNum" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.RegisteredUsersWhoVsitTheWebsite'))">
                    <template slot-scope="{ row }">
                        <span>{{ row.registerNum }}</span>
                    </template>
                </el-table-column>
            </el-table-column>

        </el-table>

        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import Vue from "vue";

export default {
    name: 'UserAccess',
    components: {
        Pagination,
        DataSelect
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                size: 10,
                sourceUrlName: null,
                platform: null,
                countryCode: null,
                stateType: '0',
            },
            selectItems: [],
            valuePicker: [
                moment().add(-30, 'days'),
                moment().add(-1, 'days')
            ],
            state: {
                totalVisit: 0,
                totalNewVisit: 0,
                totalRegisterNum: 0
            },
            stateType: 0,
            fullscreenLoading: false,
        };
    },
    created() {
        this.doQueryList({pageNo: 1});
    },
    methods: {
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({pageNo: 1});
        },
        moment,
        /**
         * 执行列表查询
         */
        doQueryList({pageNo, pageSize}) {
            if (this.valuePicker != null && this.valuePicker.length > 0) {
                this.query.beginTime = moment(this.valuePicker[0]).startOf('d').unix() * 1000;
                this.query.endTime = moment(this.valuePicker[1]).endOf("d").unix() * 1000;
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }

            if (pageNo) {
                this.query.page = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getUserAccessPage', this.query)
                .then((res) => {
                    this.stateType = this.query.stateType;
                    this.list = res.list;
                    this.total = res.total;
                    if (res.list && res.list.length > 0) {
                        this.list.forEach(item => {
                            if ('直接访问' == item.sourceUrlName) {
                                item.sourceUrlName = this.$t('common.DirectVisit');
                            } else if ('其他' == item.sourceUrlName) {
                                item.sourceUrlName = this.$t('common.other');
                            }
                        })
                        const userAccess = res.list[0];
                        this.state = {
                            totalVisit: userAccess.totalVisit,
                            totalNewVisit: userAccess.totalNewVisit,
                            totalRegisterNum: userAccess.totalRegisterNum
                        }
                    } else {
                        this.state = {
                            totalVisit: 0,
                            totalNewVisit: 0,
                            totalRegisterNum: 0
                        }
                    }
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            (this.query.countryCodes = []),
                (this.query.sourceUrlName = null),
                (this.query.platform = null),
                (this.query.stateType = '0'),
                (this.valuePicker = [
                    moment().add(-30, 'days'),
                    moment().add(-1, 'days')
                ]);
            this.doQueryList({pageNo: 1});
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        getStateHeader() {
            return this.$i18n.t("common.Visits")+'：'+ this.state.totalVisit + '\xa0\xa0\xa0\xa0\xa0\xa0'+this.$i18n.t("common.NewVisitors")+'：' + this.state.totalNewVisit
                + '\xa0\xa0\xa0\xa0\xa0\xa0'+this.$i18n.t("common.NumberOfNewUserRegistrations")+'：' + this.state.totalRegisterNum;
        },
        renderHeader(h, { column, $index }, content) {
            return [column.label,h(
                'el-tooltip',
                {
                    props: {
                        content: content,
                        placement: 'top'
                    },
                },
                [h('span', { class: {'el-icon-question': true} })]
            )]
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = this.getFileName()
            this.$confirm(this.$i18n.t("common.Areyousuretoexportthefile"), this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.fullscreenLoading = true;
                    this.$store.dispatch('exportUserAccessExcel', this.query).then(res => {
                        this.fullscreenLoading = false;
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t("common.Exportsuccessful")
                        });
                    });
                })
                .catch(() => {
                    this.fullscreenLoading = false;
                });
        },
        /**
         * 获取文件名称
         */
        getFileName() {
            let fileName = this.$i18n.t("common.UserAccess");

            if (this.query.stateType == 0) {
                fileName += '-'+this.$i18n.t("common.StatisticalByDate");
            } else {
                fileName += '-'+this.$i18n.t("common.StatisticalBySource");
            }

            if (this.valuePicker && this.valuePicker.length > 0) {
                const beginTimeStr = moment(this.valuePicker[0]).format('yyyy-MM-DD');
                const endTimeStr = moment(this.valuePicker[1]).format('yyyy-MM-DD');
                fileName += '-' + beginTimeStr + '-' + endTimeStr;
            }
            fileName += '.xlsx';
            return fileName;
        }
    }
};
</script>

<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.filter-item {
    margin-right: 10px;
}
</style>
