<template>
   
    <div  >
        <FormTableDialog :argFrom='argFrom' :dictionary="dictionary" :pageDataSelectList="pageDataSelectList" :placeholderFrom="placeholderFrom"
            @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch' @handleSelected='handleSelected'>
            
            
            <template v-slot:tableSlot>
                <el-table @selection-change="handleSelectionChange" row-key="goodsId" ref="tableData" default-expand-all
                    :data="pageData.list" style="width: 100%" border
                    :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
                    id="goodsTable"
                    @select="selectOfSelectable"
                    @select-all="selectAllOfSelectable"
                    :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">

                    <el-table-column type="selection" width="55" :reserve-selection="true" align="center"


                        show-overflow-tooltip :selectable="row => row.checkboxStatus"></el-table-column>
                    <!-- 用于显示子表格张开按钮 -->
                    <el-table-column prop="quantityType" label="" width="50px" align="center">
                    </el-table-column>
                    <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")' align="center"

                        show-overflow-tooltip></el-table-column>

                    <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span>SKU:{{ scope.row.sku }}</span><br />
                            <span>EAN:{{ scope.row.barCode }}</span><br />
                            <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-image style="height: 100px" v-if="scope.row.imageUrl"
                                :src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
                        </template>
                    </el-table-column>

                    <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackingQuantity")'  align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center"

                        show-overflow-tooltip></el-table-column>

                    <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                            </span>
                        </template>
                    </el-table-column>


<!--                    <el-table-column prop="brandCode" label="品牌" align="center"
                        show-overflow-tooltip></el-table-column>-->


<!--                    <el-table-column prop="endTime" :label='$i18n.t("common.Promotionprice1")' align="center" show-overflow-tooltip>

                        <template slot-scope="scope" v-if="scope.row.prices.length > 0">
                            <span v-for="(s, index) in scope.row.prices" :key="index">
                                {{ $dict.getDictValue('countrys', s.countryCode) }}:<span
                                    v-if="s.distributionPrice">{{ s.distributionPrice.toFixed(2) }}</span><br />
                            </span>
                        </template>
                    </el-table-column>-->
                    <el-table-column prop="prices" :label='$i18n.t("common.Promotionprice1")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                                <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                    <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.NewUserPromotion") }}<br/></span>
                                    <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                    </span>
                                </span>
                            <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                    <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.RegularUserPromotion") }}<br/></span>
                                    <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                    </span>
                                    </span>
                            <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="(s, index) in scope.row.prices" :key="index"> {{ s.countryCode }}:{{
                                s.resultPrice.toFixed(2) }}<br /> </span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="shelf" :label='$i18n.t("common.state")'  align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.shelf == true" style="color: #46a8a0;">{{$i18n.t("common.Listed") }}</span>
                            <span v-else>{{$i18n.t("common.Notlisted") }}</span>
                        </template>
                    </el-table-column>

                </el-table>

                <div class="block">
                    <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
                        :page-size="listQuery.size" layout="total, prev, pager, next, jumper"
                        :total="pageData.total">
                    </el-pagination>
                </div>


            </template>


        </FormTableDialog>
    </div>


 
</template>
<script>
import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from "@/utils/treeTable";
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
export default {
    components: {
        FormTableDialog
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        goodsSelectList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            //组件标签是否显示
			argFrom: {
				code: true,
				promotion: true,
				state: true
			},
            pageDataSelectList: [],
            placeholderFrom: {
				promotion: this.$i18n.t("common.Whethertopromoteornot") ,
				state: this.$i18n.t("common.state")
			}, 
            pageData: {},
            listQuery: {
				"idFuzzy": "",
                "preSaleCountryFlag": false,
				"inPromotion": "", //是否促销
				"effectShelf": "",//是否上架
				"page": 1,
				"size": 10
			},
			defaultListQuery: {
				"idFuzzy": "",
                "preSaleCountryFlag": false,
				"inPromotion": "", //是否促销
				"effectShelf": "",//是否上架
				"page": 1,
				"size": 10
			},
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        selectAllOfSelectable(selection) {
            selectAllOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        selectOfSelectable(selection) {
            selectOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        // 页面加载
        initData() {
            this.pageDataSelectList=[];
            this.listQuery = Object.assign({}, this.defaultListQuery);
			this.loadlist();
            this.flag = true;
            if (this.goodsSelectList.length > 0) {
                this.goodsSelectList.forEach(item => {
                    this.$refs.tableData.toggleRowSelection(item)
                });
                this.pageDataSelectList = this.goodsSelectList
            }
            this.flag = false;
		},
        loadlist(){
			let temp = JSON.stringify(this.listQuery, (key, value) => {
				if (value !== null && value !== '') {
					return value;
				}
			});
			let curSates = JSON.parse(temp);
			this.$store.dispatch('postGoodsSearch', curSates).then(res => {
                this.pageData = res
                // console.log('goodsListSearch', this.pageData.list)

                showSelectOfSelectable(this.$refs.tableData, this.pageData.list)
            });
        },
        // 查询条件搜索
        handleSearchList(listQuery) {
            this.listQuery = Object.assign({}, this.defaultListQuery);
			if (listQuery) {
                this.listQuery.idFuzzy = listQuery.code;
                this.listQuery.inPromotion = listQuery.promotion;
                this.listQuery.effectShelf = listQuery.state;
			}		 
            this.loadlist();
		}, 
        // 点击重置
        handleResetSearch(){
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.loadlist();
        },
        // 点击分页
        handleCurrentChange(val){
			this.listQuery.page = val;
			this.loadlist();
        },
        // 点击确定
		handleSelected() {
            if (!this.pageDataSelectList || this.pageDataSelectList.length <= 0) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseselectaproductfirst") ,
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            this.$emit('handleSelected',JSON.parse(JSON.stringify(this.pageDataSelectList)));
		},
        // 选择商品
        handleSelectionChange(val) {
			      this.pageDataSelectList = val;
		    },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped>
 
 

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: 30px; */
}
  
  
 
 
</style>
