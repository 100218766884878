import { render, staticRenderFns } from "./giftActivitySort.vue?vue&type=template&id=f4bea7ba&"
import script from "./giftActivitySort.vue?vue&type=script&lang=js&"
export * from "./giftActivitySort.vue?vue&type=script&lang=js&"
import style0 from "./giftActivitySort.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\jenkinsworkspace\\Aigo.B2CAdmin.Publish\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f4bea7ba')) {
      api.createRecord('f4bea7ba', component.options)
    } else {
      api.reload('f4bea7ba', component.options)
    }
    module.hot.accept("./giftActivitySort.vue?vue&type=template&id=f4bea7ba&", function () {
      api.rerender('f4bea7ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/giftActivity/giftActivitySort.vue"
export default component.exports