var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c(
        "div",
        { staticClass: "filter-container", attrs: { shadow: "never" } },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: "SKU/EAN", clearable: "" },
                    model: {
                      value: _vm.searchData.idFuzzy,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "idFuzzy", $$v)
                      },
                      expression: "searchData.idFuzzy"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t("common.country")
                      },
                      model: {
                        value: _vm.searchData.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "countryCode", $$v)
                        },
                        expression: "searchData.countryCode"
                      }
                    },
                    _vm._l(_vm.dictionary.country, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t("common.PresaleStatus")
                      },
                      model: {
                        value: _vm.searchData.preSaleStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "preSaleStatus", $$v)
                        },
                        expression: "searchData.preSaleStatus"
                      }
                    },
                    _vm._l(_vm.dictionary.preSaleStatus, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.searchClick }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$i18n.t("common.query")) +
                          "       \n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$i18n.t("common.Reset")) +
                          "       \n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "0px 0px 10px 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function($event) {
                  return _vm.add()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.Add")))]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            height: "calc(100% - 150px)",
            data: _vm.pageData.list,
            border: ""
          },
          on: { "sort-change": _vm.onSortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "goodsPreSaleConfigId",
              label: _vm.$i18n.t("common.PreSalePromotionID"),
              width: "160",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "barCode",
              label: "EAN",
              align: "center",
              width: "110",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              width: "70",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "preSalePrice",
              label: _vm.$i18n.t("common.price"),
              align: "center",
              width: "110",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "preSaleQuantity",
              label: _vm.$i18n.t("common.PresaleQuantity"),
              align: "center",
              width: "90",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTime",
              label: _vm.$i18n.t("common.TheRemainingAmount"),
              align: "center",
              width: "90",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          scope.row.preSaleQuantity - scope.row.salesCount
                        ) +
                        " \n                "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "depositValue",
              label: _vm.$i18n.t("common.PreSaleDeposit"),
              align: "center",
              width: "90",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.depositType != "FULL_PAYMENT"
                      ? _c("span", [
                          _vm._v(" " + _vm._s(scope.row.depositValue) + "% ")
                        ])
                      : _vm._e(),
                    scope.row.depositType == "FULL_PAYMENT"
                      ? _c("span", [
                          _vm._v(" " + _vm._s(scope.row.preSalePrice) + " ")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "preSaleStatus1",
              label: _vm.$i18n.t("common.PresaleStatus"),
              align: "center",
              width: "90",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agbsInTransitOrderNo",
              label: _vm.$i18n.t("common.AssociatedAGBSOnthewayOrders"),
              width: "130",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "preSaleStartTime",
              label: _vm.$i18n.t("common.PresaleStartTime"),
              sortable: "custom",
              align: "center",
              width: "140",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "preSaleEndTime",
              label: _vm.$i18n.t("common.PresaleEndTime"),
              sortable: "custom",
              align: "center",
              width: "140",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "expectedShipmentTime",
              label: _vm.$i18n.t("common.EstimatedDeliveryTime"),
              sortable: "custom",
              align: "center",
              width: "120",
              formatter: _vm.formatDate1
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "finalPaymentDeadlineTime",
              label: _vm.$i18n.t("common.FinalPaymentDeadline"),
              align: "center",
              width: "140",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              width: "140",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateName",
              label: _vm.$i18n.t("common.Updater"),
              align: "center",
              width: "140"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Operation"),
              width: "180",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.preSaleStatus != "Complete"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                        )
                      : _vm._e(),
                    scope.row.preSaleStatus == "Complete"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.view(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.check")))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.orderHandle(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.Presaleorder")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.goodsHandle(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.commodity")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: {
                          type: "text",
                          size: "small",
                          disabled: scope.row.preSaleStatus != "NotStarted"
                        },
                        on: {
                          click: function($event) {
                            return _vm.del(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.searchData.limit,
              layout: "total,sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.preSaleTitle,
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("add-update-dialog", {
                attrs: {
                  defaultDictionary: _vm.dictionary,
                  isEdit: _vm.isEdit,
                  operationType: _vm.operationType,
                  formItem: _vm.formItem,
                  defaultFormItem: _vm.defaultFormItem
                },
                on: { closePreSaleDialog: _vm.closePreSaleDialog }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }