var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formItem, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("dataSelect", {
                staticClass: "filter-item",
                staticStyle: { width: "180px", "margin-left": "10px" },
                attrs: {
                  clearable: "",
                  codeType: "countrys",
                  placeholder: _vm.$i18n.t("common.country")
                },
                on: { change: _vm.treeChange },
                model: {
                  value: _vm.formItem.countryCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "countryCode", $$v)
                  },
                  expression: "formItem.countryCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Recommendedlocation"),
                prop: "position",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("dataSelect", {
                staticClass: "filter-item",
                staticStyle: { width: "180px", "margin-left": "10px" },
                attrs: {
                  clearable: "",
                  codeType: "position",
                  placeholder: _vm.$i18n.t("common.Recommendedlocation")
                },
                model: {
                  value: _vm.formItem.position,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "position", $$v)
                  },
                  expression: "formItem.position"
                }
              })
            ],
            1
          ),
          _vm.formItem.position == "HomePage"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Recommendationmodule"),
                    prop: "recommendModule",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      codeType: "recommend_module",
                      placeholder: _vm.$i18n.t("common.Recommendationmodule")
                    },
                    model: {
                      value: _vm.formItem.recommendModule,
                      callback: function($$v) {
                        _vm.$set(_vm.formItem, "recommendModule", $$v)
                      },
                      expression: "formItem.recommendModule"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formItem.position == "Classify"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Pleaseselectacategory"),
                    prop: "category",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-tree", {
                    ref: "treeForm",
                    attrs: {
                      props: _vm.defaultProps,
                      data: _vm.treeData,
                      "node-key": "goodsClassifyId",
                      accordion: "",
                      "expand-on-click-node": false
                    },
                    on: { "node-click": _vm.handleBucketClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "span",
                              { staticClass: "show-hide custom-tree-node" },
                              [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: data.goodsClassifyId },
                                        model: {
                                          value: _vm.formItem.goodClassId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formItem,
                                              "goodClassId",
                                              $$v
                                            )
                                          },
                                          expression: "formItem.goodClassId"
                                        }
                                      },
                                      [_c("br")]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(node.label) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      1944950212
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formItem.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "status", $$v)
                  },
                  expression: "formItem.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }