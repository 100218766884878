<template>
  <div style="height: 100%;min-height:350px;">

    <filter-container :arg="arg" :placeholder="placeholder" :dictionary="dictionary" :defaultListQuery="defaultListQuery" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch'></filter-container>
    <div style="margin: 10px 0px 10px 20px;">
      <el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)">
        {{ $t('common.Add1') }}
      </el-button>
    </div>

    <el-table ref="tableData" height="calc(100% - 175px)" :data="pageData.list" v-loading="loading" border @expand-change="handleExpandChange" @row-click="handleRowExpandDetail" style="width: 100%" :row-class-name="expandClassName">

      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-tabs style="margin-left: 30px;">
              <el-tab-pane :label="$t('common.NewUserPromotion')" v-if="scope.row.newUserStatus">
                <el-tabs type="border-card" tab-position="left" style="height: 220px;">
                  <el-tab-pane v-for="(s,index) in scope.row.newUserPromotionRuleCountrys" :key="index" :label="$dict.getDictValue('countrys', s.countryCode)">
                    <table-details :form-info="s" :prices="scope.row.goodsMergeListMgrDto.prices" :goodsSpecials="scope.row.goodsMergeListMgrDto.goodsSpecials">

                    </table-details>
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane>
              <el-tab-pane :label="$t('common.RegularUserPromotion')" v-if="scope.row.status">
                <el-tabs type="border-card" tab-position="left" style="height: 220px;">
                  <el-tab-pane v-for="(s,index) in scope.row.oldUserPromotionRuleCountrys" :key="index" :label="$dict.getDictValue('countrys', s.countryCode)">
                    <table-details :form-info="s" :prices="scope.row.goodsMergeListMgrDto.prices" :goodsSpecials="scope.row.goodsMergeListMgrDto.goodsSpecials"></table-details>
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="promotionId" label="ID" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" :label="$t('common.PromotionName')" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="EAN" :label="$t('common.barcode')" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>EAN: {{ scope.row.goodsMergeListMgrDto.barCode }} <br /></span>
          <span>SKU: {{ scope.row.goodsMergeListMgrDto.sku }} <br /></span>
        </template>
      </el-table-column>
      <el-table-column prop="bannerFiles" :label="$t('common.Productimage')" align="center" width="130px">
        <template slot-scope="scope">
          <listImage :imgUrl="scope.row.goodsMergeListMgrDto.imageUrl"></listImage>
        </template>
      </el-table-column>
      <el-table-column prop="goodsMergeListMgrDto.title" :label="$t('common.Productname1')" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="theme" :label="$t('common.PromotionCountry')" align="center" show-overflow-tooltip>

        <template slot-scope="scope">
          <span v-for="(s,index) in distinctPromotionCountry(scope.row.promotionRuleCountrys)" :key="index">
            {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="status" :label="$t('common.EnabledStatus')" align="center" show-overflow-tooltip>

        <template slot-scope="scope">
          <span>
            {{ $t('common.NewUserPromotion') }}:
            <span :style="{ color: scope.row.newUserStatus ? 'green' : 'red' }">
              {{ scope.row.newUserStatus ? $t('common.Enable') : $t('common.Disabled') }}
            </span><br />
          </span>
          <span>
            {{ $t('common.RegularUserPromotion') }}:
            <span :style="{ color: scope.row.status ? 'green' : 'red' }">
              {{ scope.row.status == 1 ? $t('common.Enable') : $t('common.Disabled') }}
            </span><br />
          </span>
        </template>

      </el-table-column>
      <el-table-column prop="updateName" :label="$t('common.Operator')" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateTime" :label="$t('common.OperatingTime')" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.updateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column  :label="$t('common.Operation')" align="center" width="150px">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update',module_code)">{{ $t('common.edit') }}
          </el-button>
          <el-button @click="deletePromotion(scope.row)" type="text" size="small" v-if="$common.hasPermission('Delete',module_code)">{{ $t('common.delete') }}
          </el-button>

        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page" :page-size="listQuery.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
      </el-pagination>
    </div>

    <el-dialog :title="$t('common.Add1')" :visible.sync="dialogAddVisible" @close="closeAddDialog" :close-on-click-modal="false">
      <div v-if="dialogAddVisible">
        <pro-add :dictionary="dictionary" :tableList="tableList" :preSaleCountryFlag="preSaleCountryFlag" @handleResetSearch='handleResetSearch2' @setup="setAdd" @closeAddDialog='closeAddDialog' @handleSearchList='handleSearchList' />
      </div>
    </el-dialog>
    <el-dialog :title="isEdit?$t('common.edit'):$t('common.Add1')" :visible.sync="dialogFormVisible" @close="closeDialog" :close-on-click-modal="false">
      <div v-if="dialogFormVisible">
        <promotion-dialog :isEdit="isEdit" :proAddData="proAddData" :priceSetting="priceSetting" :preSaleCountryFlag="preSaleCountryFlag" @queryPro="queryPro" @closeDialog='closeDialog' @handChangeSelectList="handChangeSelectList" @handleSearchList='handleSearchList' @handleResetSearch2='handleResetSearch2' :dictionary="dictionary" :formListQuery="formListQuery" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from '@/utils/date';
import config from '@/utils/config';
import { distinctPromotionCountry } from '@/utils/promotion';
import proAdd from '@/components/common/proAdd.vue';
import saleDialog from '@/components/common/SaleDialog.vue';
import promotionDialog from './components/promotionDialog.vue';
import FilterContainer from '@/components/common/FilterContainer.vue';
import tableDetails from './components/tableDetails.vue';
import moment from 'moment';
import i18n from '@/i18n/i18n';
import listImage from '@/components/common/ListImage.vue';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary = {
  // 国家
  country: dictionaryUtil.getDict('countrys'),
  //位置
  brandCode: dictionaryUtil.getDict('brand'),
  state: [{
    id: '1',
    name: i18n.t('common.InProgress'),
    value: '1'
  }, {
    id: '2',
    name: i18n.t('common.Upcoming'),
    value: '2'
  }, {
    id: '3',
    name: i18n.t('common.Ended'),
    value: '3'
  }],
  //是否启用
  enableStatus: [{
    id: '1',
    name: i18n.t('common.NewUserPromotionEnabled'),
    value: '1'
  }, {
    id: '2',
    name: i18n.t('common.NewUserPromotionDisabled'),
    value: '2'
  }, {
    id: '3',
    name: i18n.t('common.OldUserPromotionEnabled'),
    value: '3'
  }, {
    id: '4',
    name: i18n.t('common.OldUserPromotionDisabled'),
    value: '4'
  }],
  //是否秒杀
  seckill: [{
    id: '1',
    name: i18n.t('common.Yes'),
    value: true
  }, {
    id: '2',
    name: i18n.t('common.No'),
    value: false
  }],
  //是否有固定价格
  fixedPrice: [{
    id: '1',
    name: i18n.t('common.Yes'),
    value: true
  }, {
    id: '2',
    name: i18n.t('common.No'),
    value: false
  }],
  //是否免运费
  freeDelivery: [{
    id: '1',
    name: i18n.t('common.Yes'),
    value: true
  }, {
    id: '2',
    name: i18n.t('common.No'),
    value: false
  }],
  //是否促销
  promotion: [{
    id: '1',
    name: i18n.t('common.Inpromotion'),
    value: true
  }, {
    id: '2',
    name: i18n.t('common.whole'),
    value: false
  }],
  //使用状态
  isUsed: [{
    id: '1',
    name: i18n.t('common.Notlisted'),
    value: false
  }, {
    id: '2',
    name: i18n.t('common.Listed'),
    value: true
  }]
};

export default {
  components: { promotionDialog, FilterContainer, moment, proAdd, saleDialog, tableDetails, listImage },
  name: 'varoantAdmin',
  data() {
    return {

      module_code: config.module_code.promotion,
      tableList: [],
      proAddQuery: {},
      loading: false,
      dictionary: Object.assign({}, defaultDictionary),
      // proAdd传过来的数据
      proAddData: {},

      //查询项
      listQuery: {
        "title": "",
        "brandCode": "",
        "goodId": "",
        "status": "",
        "endTime": "",
        "beginTime": "",
        "page": 1,
        "limit": 10,
        "state": ""
      },
      defaultListQuery: {
        "title": "",
        "brandCode": "",
        "goodId": "",
        "status": "",
        "endTime": "",
        "beginTime": "",
        "page": 1,
        "limit": 10,
        "state": ""
      },

      formListQuery: {
        "title": "",
        "brandCode": "",
        "goodId": "",
        "status": true,
        "type": "1",
        'pageDataSelectList': [],
        "promotionRuleCountrys": []
      },
      defaultFormListQuery: {
        "title": "",
        "brandCode": "",
        "goodId": "",
        "status": true,
        "type": "1",
        'pageDataSelectList': [],
        "promotionRuleCountrys": []
      },
      //促销新增页面回传的改变选中的商品数据
      changeSelectObj: {},
      //组件标签是否显示
      arg: {
        name: true,
        brand: false,
        country: true,
        state: true,
        enableStatus: true,
        seckill: true,
      },

      placeholder: {
        name: i18n.t("common.PromotionName_ProductID_Barcode"),
        country: i18n.t("common.PromotionCountry"),
        enabledState: '启用状态'
      },
      //编辑or新增
      isEdit: false,

      //表单数据
      formItem: {},
      platformList: [],
      pageData: {},
      dialogFormVisible: false,
      dialogAddVisible: false,
      title: '',
      ossHost: process.env.VUE_APP_OSS_Head,
      //字典下拉框
      brandDicts: [],
      countrysDicts: [],
      platformDicts: [],
      webSiteUrl: process.env.VUE_APP_WEBSITE,
      preSaleCountryFlag: false,
      priceSetting: []
    };
  },
  filters: {
    formatDate(time, type = 'yyyy-MM-dd hh:mm:ss') {
      if (time == null || time === '') {
        return 'N/A';
      }
      let date = new Date(time);
      return formatDate(date, type)
    },
  },
  mounted() {
    this.handleSearchList()
  },
  methods: {

    //搜索列表
    handleSearchList(listQuery) {
      this.loading = true
      let type = 'yyyy-MM-dd hh:mm:ss'
      let nowTime = Date.now()
      if (listQuery) {
        this.listQuery.page = 1
        let seckill = ''
        if (listQuery.seckill === true) {
          seckill = "true"
        } else if (listQuery.seckill === false) {
          seckill = "false"
        }
        //进行中
        if (listQuery.state === '1') {
          this.listQuery.beginTime = moment().unix() * 1000;
          this.listQuery.endTime = moment().unix() * 1000;
          //即将开始
        } else if (listQuery.state === '2') {
          this.listQuery.beginTime = moment().unix() * 1000;
          this.listQuery.endTime = null;
          //已结束
        } else if (listQuery.state === '3') {
          this.listQuery.beginTime = null;
          this.listQuery.endTime = moment().unix() * 1000;
        } else {
          this.listQuery.beginTime = null;
          this.listQuery.endTime = null;
        }

        let status = null
        let newUserStatus = null
        //启用状态
        if (listQuery.enableStatus === '1') {
          //新用户启用
          newUserStatus = 'true';
        } else if (listQuery.enableStatus === '2') {
          //新用户禁用
          newUserStatus = 'false';
        } else if (listQuery.enableStatus === '3') {
          //老用户启用
          status = 'true';
        } else if (listQuery.enableStatus === '4') {
          //老用户禁用
          status = 'false';
        }

        this.listQuery = {
          "title": listQuery.title,
          "brandCode": listQuery.brand,
          "goodId": "",
          "newUserStatus": newUserStatus,
          "status": status,
          "seckill": seckill,
          "countryCodes": listQuery.country,
          "endTime": this.listQuery.endTime,
          "beginTime": this.listQuery.beginTime,
          "page": this.listQuery.page,
          "limit": this.listQuery.limit
        }
      }

      this.$store
        .dispatch('getPromotion', this.listQuery)
        .then(res => {
          this.pageData = res
          this.pageData.list.forEach((item) => {
            let newUserPromotionRuleCountrys = []
            let oldUserPromotionRuleCountrys = []
            item.promotionRuleCountrys.forEach((item2) => {
              //产品连涵韬提新要求不按库存判断状态，只按时间
              if (item2.beginTime > nowTime) {
                item2.activeStatus = this.$i18n.t("common.Upcoming")
                item2.activeStatusColor = 'red'
              } else if (item2.endTime < nowTime) {
                item2.activeStatus = this.$i18n.t("common.Ended")
                item2.activeStatusColor = 'grey'
              } else if (item2.endTime > nowTime && item2.beginTime <= nowTime) {
                item2.activeStatus = this.$i18n.t("common.InProgress")
                item2.activeStatusColor = 'green'
              }
              //处理新老用户数据
              if (item2.userType == 'NEW') {
                newUserPromotionRuleCountrys.push(item2)
              } else {
                oldUserPromotionRuleCountrys.push(item2)
              }
            })
            item.newUserPromotionRuleCountrys = newUserPromotionRuleCountrys
            item.oldUserPromotionRuleCountrys = oldUserPromotionRuleCountrys
          })
          this.loading = false
        });
    },
    updateStatus(row) {
      let status = ""
      row.status == true ? status = "1" : status = "0"
      this.$store.dispatch('putPromotionStatus', {
        status,
        id: row.promotionId,
      }).then(res => {
        this.handleSearchList()
      })
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, this.defaultListQuery);
      this.handleSearchList()
    },
    handleResetSearch2() {
      this.listQuery = Object.assign({}, this.defaultListQuery);
    },

    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.handleSearchList();
    },
    deletePromotion(row) {
      // 二次确认删除
      this.$confirm(this.$i18n.t("common.Areyousureyouwanttodeleteit"), this.$i18n.t("common.Prompt"), {
        type: 'warning'
      })
        .then(() => {
          this.$store
            .dispatch("deletePromotion", {
              id: row.promotionId
            }).then(res => {
              this.$message({
                message: this.$i18n.t("common.Successfullydeleted"),
                type: 'success',
                duration: 1000
              });
              this.handleSearchList()
            })
        })
        .catch(() => {
        });
    },
    //打开新增
    add() {
      this.dialogAddVisible = true
    },
    //设置促销
    setAdd(obj) {
      this.isEdit = false
      this.formListQuery = Object.assign({}, this.defaultFormListQuery)
      this.formListQuery.pageDataSelectList = obj.val
      this.proAddData = Object.assign({}, obj)
      this.tableList = obj.tableList
      this.dialogFormVisible = true
    },
    queryPro(obj) {
//      this.tableList[obj.index].formListQuery = obj.formListQuery
        this.$set(this.tableList[obj.index], "formListQuery", obj.formListQuery )
    },
    handChangeSelectList(obj) {
      this.$set(this.tableList, obj.index, obj.val)
    },
    //打开编辑
    edit(row) {
      this.isEdit = true
      row.promotionRuleCountrys.forEach((item, index) => {
        item.time = []
        if (item.beginTime && item.endTime) {
          let type = 'yyyy-MM-dd hh:mm:ss'
          let beginTime = this.$options.filters['formatDate'](item.beginTime, type)
          let endTime = this.$options.filters['formatDate'](item.endTime, type)
          item.time.push(beginTime)
          item.time.push(endTime)
        }
      })
      // this.formListQuery= Object.assign({},row)
      this.formListQuery = JSON.parse(JSON.stringify(row))
      setTimeout(() => {
        this.dialogFormVisible = true
      });
    },
    //关闭窗口
    closeDialog() {
      this.dialogFormVisible = false
      this.formListQuery = Object.assign({}, this.defaultFormListQuery)
    },
    //关闭窗口
    closeAddDialog() {
      this.dialogAddVisible = false
    },
    handleExpandChange(row, rows) {
      let that = this
      if (rows.length > 1) {
        that.expands = []
        if (row) {
          that.expands.push(row);
        }
        this.$refs.tableData.toggleRowExpansion(rows[0]);
      } else {
        that.expands = [];
      }
    },
    handleRowExpandDetail(row, column, event) {
      //启用才能展开
      if (row.newUserStatus || row.status) {
        this.$refs.tableData.toggleRowExpansion(row)
      }
    },
    //促销国家（去重）
    distinctPromotionCountry,
    //新用户和老用户未启用隐藏展开按钮
    expandClassName({ row, index }) {
      return row.newUserStatus || row.status ? '' : 'hide';
    },

  },
  watch: {
    // 打开新增编辑对话框，请求接口
    dialogFormVisible: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.$store.dispatch('listPriceSettings').then((res) => {
            this.priceSetting = res
          });
        }
      }
    }
  }
};
</script>

<style scoped>
/deep/ .hide .el-table__expand-column .cell {
    display: none;
}
</style>
