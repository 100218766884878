<template>
    <div>



        <div style="display: flex; justify-content: flex-start">
            <el-upload
                    class="avatar-uploader2"
                    :action="ossInfo.ossSignature.host"
                    list-type="picture-card"
                    :auto-upload="false"
                    ref="uploadImg"
                    :show-file-list="false"
                    accept=".jpg, .jpeg, .png, .gif"
                    :file-list="fileList"
                    :data="fileDataObj"
                    :limit="imglimit"
                    :on-change="handleChange"
            >
                <el-button type="primary" :loading="loading" :disabled="fileList.length>=imglimit" >{{ $i18n.t('common.SelectImage') }}</el-button>
            </el-upload>
            <div>
                <span style="margin-left: 10px;">{{ $i18n.t('common.RecommendedSizeX',{'XXX*XXX': '1920*435'}) }}</span><br>
                <span style="margin-left: 10px;">{{ $i18n.t('common.FileSize1MB') }}</span>
            </div>

        </div>


        <div  class="avatar-uploader" >
            <ul class="el-upload-list el-upload-list--picture-card">
                <li tabindex="0" class="el-upload-list__item is-success"  v-for="(item, idx) in fileList" :key="item.url + idx" >
                    <div>
                        <img
                                class="el-upload-list__item-thumbnail img-center"
                                :src="item.url" alt=""
                        >
                        <span class="el-upload-list__item-actions">
                            <span
                                    class="el-upload-list__item-preview"
                                    @click="handlePictureCardPreview(item)"
                            >
                            <i class="el-icon-zoom-in"></i>
                            </span>
                            <!-- <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(item)"
                            >
                            <i class="el-icon-download"></i>
                            </span> -->
                            <span
                                    v-if="!disabled"
                                    class="el-upload-list__item-delete"
                                    @click="handleRemove(item)"
                            >
                            <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </li></ul>

        </div>



        <el-dialog  :append-to-body="true" width="700px" v-if="dialogVisible" :visible.sync="dialogVisible">
            <img width="100%"   :src="dialogImageUrl" >
        </el-dialog>

    </div>
</template>

<script>

    import X2JS from 'x2js'; //解析xml
    export default {
        props: {
            imgMessage:{
                type: String,
                default: ''
            },
            imgSize:{
                type: Number,
                default: 5
            },
            imglimit:{
                type: Number,
                default: 1
            },
            imgUrlList: {
                type: Array,
                default: () => {
                    return []
                }
            },
            countryCode: {
                type: String,
                default: ''
            },
            disabled:{
                type:Boolean,
                default:false
            },
        },
        data() {
            return {
                // 图片预览
                dialogImageUrl: '',
                dialogVisible: false,
                fileDataObj: {},
                ossInfo: {
                    ossHost: process.env.VUE_APP_OSS_Head,
                    ossSignature: { host: '' },
                    activityTranslates: [],
                    langs: [],
                    seoIndex: '0',
                    newFiles: []
                },
                ossHost: process.env.VUE_APP_OSS_Head,
                fileList: [],
                loading:false
            };
        },
        created() {
            this.getOssSignature();
        },
        mounted() {
            this.initFileList();
        },
        methods: {
            initFileList(){
                this.fileList = [];
                if(this.imgUrlList != null && this.imgUrlList.length > 0){
                    for( var imageUrl of this.imgUrlList  ){
                        let item = {
                            url: this.ossHost + imageUrl
                        }
                        this.fileList.push(item);
                    }
                }
            },
            handleRemove(file) {
                let index =  this.getIndexByFileList(file);
                if(index!=-1){
                    this.imgUrlList.splice(index, 1);
                    this.fileList.splice(index, 1);
                    this.event();
                }
            },

            handlePictureCardPreview(file) {

                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleDownload(file) {
                // console.log(file);
                window.location.href=file.url;
            },
            event() {

                var message = {
                    imgUrlList: this.imgUrlList,
                    countryCode: this.countryCode
                };
                this.$emit('event', message);
            },
            getIndexByFileList(file){
                let index = this.fileList.findIndex((item) => {
                    return item.uid === file.uid
                })
                return index;
            },
            // 新增，修改
            handleFileList(file,imageUrl){
                // console.log('handleFileList',this.fileList)
                let index =  this.getIndexByFileList(file);
                console.log('handleFileList',file,index)
                if(index == -1){
                    // console.log('getIndexByFileList')
                    index = this.fileList.length
                    this.imgUrlList.push(imageUrl)
                    let item = {
                        url : this.ossHost + imageUrl
                    }
                    this.fileList.push(item)
                }else{
                    this.imgUrlList[index] = imageUrl;
                    this.fileList[index].url = this.ossHost + imageUrl;
                }

            },

            handleChange(file, fileList) {
                // console.log('handleChange',file,fileList);
                const isLt = file.size / 1024 / 1024 <= this.imgSize;
                if (!isLt) {
                    // this.$refs.uploadImg.clearFiles();
                    let index = fileList.findIndex((item) => {
                        return item.uid === file.uid
                    })
                    if(index!=-1){
                        fileList.splice(index, 1);
                    }
                    this.$message.error(this.$i18n.t("common.TheUploadedImageSizeCannotExceed5MB",{num:this.imgSize}));

                    return;
                }
                let dataObj = {
                    name: file.name,
                    key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                    policy: this.ossInfo.ossSignature['policy'],
                    OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                    success_action_status: '201',
                    callback: '',
                    signature: this.ossInfo.ossSignature['signature']
                    // file: file.file,
                };
                file.type = 'add';
                this.fileDataObj = dataObj;
                // console.log('dataObj', this.ossInfo.ossHost);
                let that = this;
                setTimeout(() => {
                    this.loading = true
                    this.$refs.uploadImg.submit();
                    this.loading = false
                    var x2js = new X2JS();
                    var jsonObj = x2js.xml2js(file.response);
                    let ossUrl = jsonObj.PostResponse.Location;
                    let zi = 'com';
                    if (ossUrl.indexOf(zi) !== -1) {
                        var imageUrl = ossUrl.substr(ossUrl.lastIndexOf(zi) + zi.length);
                        this.handleFileList(file,imageUrl)
                        this.event();
                    }
                }, 500);
            },
            //获取OSS签名
            getOssSignature() {
                this.$store
                    .dispatch('getSignature', {
                        dir: 'b2c'
                    })
                    .then((res) => {
                        console.log(res);
                        this.ossInfo.ossSignature = res;
                        this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                    });
            },
            /**
             * 生成随机文件名
             * @param len
             * @returns {string}
             */
            randomString(len) {
                len = len || 32;
                let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
                let maxPos = chars.length;
                let pwd = '';
                for (let i = 0; i < len; i++) {
                    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
                }
                return pwd;
            },

            getSuffix(filename) {
                let pos = filename.lastIndexOf('.');
                let suffix = '';
                if (pos != -1) {
                    suffix = filename.substring(pos);
                }
                return suffix;
            }
        }
    };
</script>

<style>

    .avatar-uploader2 .el-upload {
        border: 0px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 80px;
        height: 32px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }


    .img-center {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain
    }


</style>