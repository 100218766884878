var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormTableDialog", {
        attrs: {
          argFrom: _vm.argFrom,
          dictionary: _vm.dictionary,
          pageDataSelectList: _vm.pageDataSelectList,
          placeholderFrom: _vm.placeholderFrom
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch,
          handleSelected: _vm.handleSelected
        },
        scopedSlots: _vm._u([
          {
            key: "tableSlot",
            fn: function() {
              return [
                _c(
                  "el-table",
                  {
                    ref: "tableData",
                    staticStyle: { width: "100%" },
                    attrs: {
                      "row-key": "goodsId",
                      "default-expand-all": "",
                      data: _vm.pageData.list,
                      border: "",
                      "tree-props": {
                        children: "packageQtyList",
                        hasChildren: "hasChildren"
                      },
                      id: "goodsTable",
                      "header-cell-style": {
                        background: "#EAEEF9",
                        color: "#32353a"
                      }
                    },
                    on: {
                      "selection-change": _vm.handleSelectionChange,
                      select: _vm.selectOfSelectable,
                      "select-all": _vm.selectAllOfSelectable
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        "reserve-selection": true,
                        align: "center",
                        "show-overflow-tooltip": "",
                        selectable: function(row) {
                          return row.checkboxStatus
                        }
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "quantityType",
                        label: "",
                        width: "50px",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsId",
                        label: _vm.$i18n.t("common.ProductID"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsCode",
                        label: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v("SKU:" + _vm._s(scope.row.sku))
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v("EAN:" + _vm._s(scope.row.barCode))
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$i18n.t("common.coding")) +
                                    ":" +
                                    _vm._s(scope.row.goodsCode)
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "imageUrl",
                        label: _vm.$i18n.t("common.Productimage1"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.imageUrl
                                ? _c("el-image", {
                                    staticStyle: { height: "100px" },
                                    attrs: {
                                      src: _vm.ossHost + scope.row.imageUrl,
                                      fit: "fill"
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "packageQuantity",
                        label: _vm.$i18n.t("common.PackingQuantity"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "title",
                        label: _vm.$i18n.t("common.ProductName"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsSpecials",
                        label: _vm.$i18n.t("common.Countrieslisted"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.goodsSpecials, function(
                              s,
                              index
                            ) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$dict.getDictValue(
                                        "countrys",
                                        s.countryCode
                                      )
                                    )
                                ),
                                _c("br")
                              ])
                            })
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "prices",
                        label: _vm.$i18n.t("common.Promotionprice1"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm.isShowGoodsPromotion(
                                scope.row.promotionRuleCountrys,
                                "NEW"
                              )
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                            "text-align": "left",
                                            display: "block"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "common.NewUserPromotion"
                                              )
                                            )
                                          ),
                                          _c("br")
                                        ]
                                      ),
                                      _vm._l(
                                        scope.row.promotionRuleCountrys,
                                        function(promotion, index) {
                                          return promotion.userType == "NEW"
                                            ? _c("span", { key: index }, [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.$dict.getDictValue(
                                                        "countrys",
                                                        promotion.countryCode
                                                      )
                                                    ) +
                                                    ":" +
                                                    _vm._s(
                                                      _vm.getPromotionGoodsPrice(
                                                        promotion,
                                                        scope.row.prices
                                                      )
                                                    )
                                                ),
                                                _c("br")
                                              ])
                                            : _vm._e()
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.isShowGoodsPromotion(
                                scope.row.promotionRuleCountrys,
                                "OLD"
                              )
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                            "text-align": "left",
                                            display: "block"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "common.RegularUserPromotion"
                                              )
                                            )
                                          ),
                                          _c("br")
                                        ]
                                      ),
                                      _vm._l(
                                        scope.row.promotionRuleCountrys,
                                        function(promotion, index) {
                                          return promotion.userType == "OLD"
                                            ? _c("span", { key: index }, [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.$dict.getDictValue(
                                                        "countrys",
                                                        promotion.countryCode
                                                      )
                                                    ) +
                                                    ":" +
                                                    _vm._s(
                                                      _vm.getPromotionGoodsPrice(
                                                        promotion,
                                                        scope.row.prices
                                                      )
                                                    )
                                                ),
                                                _c("br")
                                              ])
                                            : _vm._e()
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              !scope.row.promotionRuleCountrys ||
                              scope.row.promotionRuleCountrys.length == 0
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "common.ThereAreCurrentlyNoProductPromotions"
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "prices",
                        label: _vm.$i18n.t("common.fixedprice"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.prices, function(s, index) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  " " +
                                    _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(s.resultPrice.toFixed(2))
                                ),
                                _c("br")
                              ])
                            })
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "shelf",
                        label: _vm.$i18n.t("common.state"),
                        align: "center",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.shelf == true
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#46a8a0" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.Listed"))
                                      )
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$i18n.t("common.Notlisted"))
                                    )
                                  ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.listQuery.page,
                        "page-size": _vm.listQuery.size,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.pageData.total
                      },
                      on: { "current-change": _vm.handleCurrentChange }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }