var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticStyle: { "margin-top": "0px" } },
          [
            _c(
              "el-form",
              {
                ref: "searchFrom",
                attrs: {
                  inline: true,
                  model: _vm.searchData,
                  size: "small",
                  "label-width": "140px"
                }
              },
              [
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t("common.Emailaddress")
                      },
                      model: {
                        value: _vm.searchData.email,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "email", $$v)
                        },
                        expression: "searchData.email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        oninput: "value=value.replace(/[^0-9]/g,'')",
                        maxlength: "25",
                        placeholder: _vm.$i18n.t("common.Ordernumber")
                      },
                      model: {
                        value: _vm.searchData.orderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "orderNo", $$v)
                        },
                        expression: "searchData.orderNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t("common.Paymentcode")
                      },
                      model: {
                        value: _vm.searchData.paymentOrderId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "paymentOrderId", $$v)
                        },
                        expression: "searchData.paymentOrderId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        oninput: "value=value.replace(/[^0-9]/g,'')",
                        placeholder: _vm.$i18n.t("common.UserID")
                      },
                      model: {
                        value: _vm.searchData.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "userId", $$v)
                        },
                        expression: "searchData.userId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        oninput: "value=value.replace(/[^0-9]/g,'')",
                        placeholder: _vm.$i18n.t("common.barcode")
                      },
                      model: {
                        value: _vm.searchData.barCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "barCode", $$v)
                        },
                        expression: "searchData.barCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        oninput: "value=value.replace(/[^0-9]/g,'')",
                        placeholder: _vm.$i18n.t("common.ProductID1")
                      },
                      model: {
                        value: _vm.searchData.goodsId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "goodsId", $$v)
                        },
                        expression: "searchData.goodsId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("dataSelect", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        codeType: "countrys",
                        placeholder: _vm.$i18n.t("common.country")
                      },
                      model: {
                        value: _vm.searchData.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "countryCode", $$v)
                        },
                        expression: "searchData.countryCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "activity" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: _vm.$i18n.t("common.ActivityID"),
                          clearable: "",
                          oninput: "value=value.replace(/[^0-9]/g,'')"
                        },
                        model: {
                          value: _vm.searchData.activityId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "activityId", $$v)
                          },
                          expression: "searchData.activityId"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              slot: "prepend",
                              placeholder: _vm.$i18n.t("common.activity"),
                              clearable: ""
                            },
                            slot: "prepend",
                            model: {
                              value: _vm.searchData.activityType,
                              callback: function($$v) {
                                _vm.$set(_vm.searchData, "activityType", $$v)
                              },
                              expression: "searchData.activityType"
                            }
                          },
                          _vm._l(_vm.activityOptions, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("dataSelect", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        codeType: "order_status",
                        placeholder: _vm.$i18n.t("common.OrderStatus")
                      },
                      model: {
                        value: _vm.searchData.orderStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "orderStatus", $$v)
                        },
                        expression: "searchData.orderStatus"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$i18n.t(
                            "common.Pleaseselectpaymentmethod"
                          )
                        },
                        model: {
                          value: _vm.searchData.payType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "payType", $$v)
                          },
                          expression: "searchData.payType"
                        }
                      },
                      _vm._l(_vm.paymentList, function(item) {
                        return _c("el-option", {
                          key: item.pay_plat_id,
                          attrs: {
                            label: _vm.handleLabel(item),
                            value: item.pay_plat_id
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("dataSelect", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        codeType: "delivery_status",
                        placeholder: _vm.$i18n.t("common.Shipmentstatus")
                      },
                      model: {
                        value: _vm.searchData.deliveryStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "deliveryStatus", $$v)
                        },
                        expression: "searchData.deliveryStatus"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("dataSelect", {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        clearable: "",
                        codeType: "transfer_status",
                        placeholder: _vm.$i18n.t(
                          "common.Remittanceconfirmation"
                        )
                      },
                      model: {
                        value: _vm.searchData.transferStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "transferStatus", $$v)
                        },
                        expression: "searchData.transferStatus"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t(
                            "common.Whethertomanuallyship"
                          ),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.isManualDeliver,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "isManualDeliver", $$v)
                          },
                          expression: "searchData.isManualDeliver"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.yes"),
                            value: "1"
                          }
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t("common.Originalordertype"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.orderType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "orderType", $$v)
                          },
                          expression: "searchData.orderType"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Presaleorder"),
                            value: "PRE_SALE_ORDER"
                          }
                        }),
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Officialorder"),
                            value: "SALE_ORDER"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t("common.RebateOrNot"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.rebateFlag,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "rebateFlag", $$v)
                          },
                          expression: "searchData.rebateFlag"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.yes"),
                            value: "1"
                          }
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t("common.UseAigoCoinsOrNot"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.useCoinsFlag,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "useCoinsFlag", $$v)
                          },
                          expression: "searchData.useCoinsFlag"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.yes"),
                            value: "1"
                          }
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t("common.Preorderstatus"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.preSaleOrderStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "preSaleOrderStatus", $$v)
                          },
                          expression: "searchData.preSaleOrderStatus"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Deposittobepaid"),
                            value: "DEPOSIT_UN_PAY"
                          }
                        }),
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Depositpaid"),
                            value: "DEPOSIT_PAID"
                          }
                        }),
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Finalpaymenttobemade"),
                            value: "FINAL_PAYMENT_UN_PAY"
                          }
                        }),
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Finalpaymentpaid"),
                            value: "FINAL_PAYMENT_PAID"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t(
                            "common.Presalepaymentmethod"
                          ),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.preSalePayType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "preSalePayType", $$v)
                          },
                          expression: "searchData.preSalePayType"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Finaldeposit"),
                            value: "DEPOSIT_AND_FINAL_PAYMENT"
                          }
                        }),
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.Fullpayment"),
                            value: "FULL_PAYMENT"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t(
                            "common.Whetherthepresaleorderisconvertedtoformal"
                          ),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.formal,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "formal", $$v)
                          },
                          expression: "searchData.formal"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.yes"),
                            value: "1"
                          }
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          placeholder: _vm.$i18n.t("common.Combinedorders"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchData.pallet,
                          callback: function($$v) {
                            _vm.$set(_vm.searchData, "pallet", $$v)
                          },
                          expression: "searchData.pallet"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: _vm.$i18n.t("common.yes"),
                            value: "1"
                          }
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$i18n.t("common.Creationtime") } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": _vm.$i18n.t("common.to"),
                        "start-placeholder": _vm.$i18n.t("common.Startdate"),
                        "end-placeholder": _vm.$i18n.t("common.Enddate")
                      },
                      model: {
                        value: _vm.searchData.selectCreateTime,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "selectCreateTime", $$v)
                        },
                        expression: "searchData.selectCreateTime"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$i18n.t("common.Paymenttime") } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": _vm.$i18n.t("common.to"),
                        "start-placeholder": _vm.$i18n.t("common.Startdate"),
                        "end-placeholder": _vm.$i18n.t("common.Enddate")
                      },
                      model: {
                        value: _vm.searchData.selectPayTime,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "selectPayTime", $$v)
                        },
                        expression: "searchData.selectPayTime"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    !_vm.scene.isAigoSmartApp
                      ? _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            staticStyle: {
                              width: "180px",
                              "margin-left": "10px"
                            },
                            attrs: {
                              placeholder: _vm.$i18n.t("common.platform"),
                              clearable: ""
                            },
                            model: {
                              value: _vm.searchData.platform,
                              callback: function($$v) {
                                _vm.$set(_vm.searchData, "platform", $$v)
                              },
                              expression: "searchData.platform"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "PC", value: "PC" }
                            }),
                            _c("el-option", {
                              attrs: { label: "H5", value: "H5" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { icon: "el-icon-search", type: "primary" },
                        on: { click: _vm.searchClick }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$i18n.t("common.query")) +
                            "\n                    "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          icon: "el-icon-search",
                          type: "primary",
                          size: "small"
                        },
                        on: { click: _vm.cleanQuery }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$i18n.t("common.Reset")) +
                            "\n                    "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading.fullscreen.lock",
                            value: _vm.fullscreenLoading,
                            expression: "fullscreenLoading",
                            modifiers: { fullscreen: true, lock: true }
                          }
                        ],
                        staticClass: "filter-item",
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          icon: "el-icon-download",
                          type: "primary",
                          size: "small"
                        },
                        on: { click: _vm.handleExport }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$i18n.t("common.export")) +
                            "\n                    "
                        )
                      ]
                    ),
                    !_vm.scene.isNonSelf
                      ? _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleEMonads }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$i18n.t("common.Positiveconversionsingle")
                                ) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.openOrderSettingDialog }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$i18n.t("common.OrderSettings")) +
                            "\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            height: "calc(100% - 310px)",
            "row-key": "orderNo",
            data: _vm.pageData.list,
            "tree-props": {
              children: "preSaleOrderMgrDtos",
              hasChildren: "hasChildren"
            },
            border: ""
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              selectable: _vm.selectable,
              align: "center",
              width: "55"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "preSalePayType",
              label: "",
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.preSalePayType &&
                    row.orderNo &&
                    row.orderNo.indexOf("-") != -1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$dict.getPreSalePayType(row.preSalePayType)
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderNo",
              label: _vm.$i18n.t("common.Ordernumber"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "countrys" },
                      model: {
                        value: scope.row.countryCode,
                        callback: function($$v) {
                          _vm.$set(scope.row, "countryCode", $$v)
                        },
                        expression: "scope.row.countryCode"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliveryCountry",
              label: _vm.$i18n.t("common.CountryOfShipmentAddress"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "countrys" },
                      model: {
                        value: scope.row.deliveryCountry,
                        callback: function($$v) {
                          _vm.$set(scope.row, "deliveryCountry", $$v)
                        },
                        expression: "scope.row.deliveryCountry"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userId",
              label: _vm.$i18n.t("common.UserID"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "email",
              label: _vm.$i18n.t("common.Useremail"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "source",
              label: _vm.$i18n.t("common.activity"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "totalAmt",
              label: _vm.$i18n.t("common.money"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "dataText",
                      {
                        attrs: { codeType: "currency_code" },
                        model: {
                          value: scope.row.currencyCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "currencyCode", $$v)
                          },
                          expression: "scope.row.currencyCode"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.totalAmt) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderStatus",
              label: _vm.$i18n.t("common.OrderStatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "order_status" },
                      model: {
                        value: scope.row.orderStatus,
                        callback: function($$v) {
                          _vm.$set(scope.row, "orderStatus", $$v)
                        },
                        expression: "scope.row.orderStatus"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$i18n.t("common.Creationtime"),
              align: "center",
              formatter: _vm.formatDate,
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "eans",
              label: "EAN",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "skus",
              label: "SKU",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "nums",
              label: _vm.$i18n.t("common.Numberofproductmodels"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "payTypeDesc",
              label: _vm.$i18n.t("common.Paymentmethod"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paymentOrderId",
              label: _vm.$i18n.t("common.Paymentcode"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "transferStatus",
              label: _vm.$i18n.t("common.Remittanceconfirmation"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "transfer_status" },
                      model: {
                        value: scope.row.transferStatus,
                        callback: function($$v) {
                          _vm.$set(scope.row, "transferStatus", $$v)
                        },
                        expression: "scope.row.transferStatus"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paymentTime",
              label: _vm.$i18n.t("common.Paymenttime"),
              formatter: _vm.formatDate,
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliveryStatus",
              label: _vm.$i18n.t("common.Shipmentstatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "delivery_status" },
                      model: {
                        value: scope.row.deliveryStatus,
                        callback: function($$v) {
                          _vm.$set(scope.row, "deliveryStatus", $$v)
                        },
                        expression: "scope.row.deliveryStatus"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliverTime",
              label: _vm.$i18n.t("common.Deliverytime"),
              formatter: _vm.formatDate,
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "isManualDeliver",
              label: _vm.$i18n.t("common.Whethertomanuallyship"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.isManualDeliver == true
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                      : _vm._e(),
                    row.isManualDeliver != true &&
                    row.deliveryStatus == "HaveDelivered"
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderType",
              label: _vm.$i18n.t("common.Originalordertype"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.orderType
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$dict.getOrderType(row.orderType)))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rebateFlag",
              label: _vm.$i18n.t("common.RebateOrNot"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.rebateFlag === true
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                      : _vm._e(),
                    row.rebateFlag === false
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "giftActivityFlag",
              label: _vm.$i18n.t("common.IsThereAGiftEvent"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.giftActivityFlag === true
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                      : _vm._e(),
                    row.giftActivityFlag === false
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "useCoinsFlag",
              label: _vm.$i18n.t("common.UseAigoCoinsOrNot"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.useCoinsFlag === true
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                      : _vm._e(),
                    row.useCoinsFlag === false
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rebateAmt",
              label: _vm.$i18n.t("common.RebateAmount"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "dataText",
                      {
                        attrs: { codeType: "currency_code" },
                        model: {
                          value: scope.row.currencyCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "currencyCode", $$v)
                          },
                          expression: "scope.row.currencyCode"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.rebateAmt) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "formal",
              label: _vm.$i18n.t("common.Whethertotransfertoformal"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.orderType == "PRE_SALE_ORDER" && row.formal == true
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                      : _vm._e(),
                    row.orderType == "PRE_SALE_ORDER" && row.formal == false
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "preSalePayType",
              label: _vm.$i18n.t("common.Presalepaymentmethod"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.orderType == "PRE_SALE_ORDER" && row.preSalePayType
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$dict.getPreSalePayType(row.preSalePayType)
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rightsItemCount",
              label: _vm.$i18n.t("common.Returnsandexchanges"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.postRouterHandle(scope.row.orderNo)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.rightsItemCount) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unReplyConsultNum",
              label: _vm.$i18n.t("common.Tobereplied_consulted"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderNo.indexOf("-") == -1
                      ? _c(
                          "div",
                          [
                            scope.row.unReplyConsultNum < 10
                              ? _c(
                                  "el-tag",
                                  { attrs: { type: "info" } },
                                  [
                                    scope.row.consultNum > 0
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.consultRouterHandle(
                                                  scope.row.orderNo
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.unReplyConsultNum
                                              ) +
                                                " /\n                            " +
                                                _vm._s(scope.row.consultNum) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.unReplyConsultNum
                                            ) +
                                              " / " +
                                              _vm._s(scope.row.consultNum)
                                          )
                                        ])
                                  ],
                                  1
                                )
                              : _c(
                                  "el-tag",
                                  { attrs: { type: "danger" } },
                                  [
                                    scope.row.consultNum > 0
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.consultRouterHandle(
                                                  scope.row.orderNo
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.unReplyConsultNum
                                              ) +
                                                " /\n                            " +
                                                _vm._s(scope.row.consultNum) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.unReplyConsultNum
                                            ) +
                                              " / " +
                                              _vm._s(scope.row.consultNum)
                                          )
                                        ])
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          !_vm.scene.isAigoSmartApp
            ? _c("el-table-column", {
                attrs: {
                  prop: "sourceUrlName",
                  label: _vm.$i18n.t("common.source"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.showSourceTranslate(row.sourceUrlName))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  316653420
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "sourceUrlName",
              label: _vm.$i18n.t("common.OrderSource"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.orderSourceUrlName))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              formatter: _vm.formatDate,
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateByName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.updateByName
                      ? _c("span", [_vm._v(_vm._s(row.updateByName))])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$i18n.t("common.SystemAutoUpdate")))
                        ])
                  ]
                }
              }
            ])
          }),
          !_vm.scene.isAigoSmartApp
            ? _c("el-table-column", {
                attrs: {
                  prop: "platform",
                  label: _vm.$i18n.t("common.platform"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderNo &&
                    scope.row.orderNo.indexOf("-") == -1 &&
                    scope.row.orderType == "PRE_SALE_ORDER"
                      ? _c("div", [
                          scope.row.formal == false
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.edit(
                                            scope.row,
                                            "dialogFormVisible"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.detailed"))
                                      )
                                    ]
                                  ),
                                  scope.row.orderStatus == "Paid"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleEMonad(scope.row)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "common.Positiveconversionsingle"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          scope.row.formal == true
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.edit(
                                            scope.row,
                                            "dialogFormVisible"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.detailed"))
                                      )
                                    ]
                                  ),
                                  _vm.isManualDeliverBtnShow(scope.row)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openManualDeliver(
                                                scope.row,
                                                "manualDeliverForm"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "common.Manualshipping"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.istransferConfirmBtnShow(scope.row)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openTransferConfirm(
                                                scope.row,
                                                "transferConfirmForm"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "common.Remittanceconfirmation"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : scope.row.orderNo &&
                        scope.row.orderNo.indexOf("-") == -1 &&
                        scope.row.orderType != "PRE_SALE_ORDER"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.edit(
                                      scope.row,
                                      "dialogFormVisible"
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$i18n.t("common.detailed")))]
                            ),
                            _vm.isManualDeliverBtnShow(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openManualDeliver(
                                          scope.row,
                                          "manualDeliverForm"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t("common.Manualshipping")
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.istransferConfirmBtnShow(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openTransferConfirm(
                                          scope.row,
                                          "transferConfirmForm"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "common.Remittanceconfirmation"
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.OrderDetails"),
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("varoant-dialog", {
                attrs: { formItem: _vm.formItem },
                on: { closeDialog: _vm.closeWindow }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-center",
          attrs: {
            title: _vm.$i18n.t("common.Manuallogisticsshipment"),
            visible: _vm.manualDeliverDialogVisible,
            width: "600px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.manualDeliverDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "manualDeliverForm",
              attrs: {
                rules: _vm.manualDeliverFormRules,
                "label-position": _vm.labelPosition,
                "label-width": "100px",
                model: _vm.manualDeliverFormData
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.logisticscompany"),
                    prop: "logisticsCompanyId"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t(
                          "common.Pleaseselectalogisticscompany"
                        )
                      },
                      model: {
                        value: _vm.manualDeliverFormData.logisticsCompanyId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.manualDeliverFormData,
                            "logisticsCompanyId",
                            $$v
                          )
                        },
                        expression: "manualDeliverFormData.logisticsCompanyId"
                      }
                    },
                    _vm._l(_vm.logisticsCompanyList, function(item) {
                      return _c("el-option", {
                        key: item.logisticsCompanyId,
                        attrs: {
                          label: item.title,
                          value: item.logisticsCompanyId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Logisticstrackingnumber"),
                    prop: "logisticsNo"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.manualDeliverFormData.logisticsNo,
                      callback: function($$v) {
                        _vm.$set(_vm.manualDeliverFormData, "logisticsNo", $$v)
                      },
                      expression: "manualDeliverFormData.logisticsNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Deliverytime"),
                    prop: "deliverTime"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      type: "datetime",
                      placeholder: _vm.$i18n.t("common.SelectDateTime")
                    },
                    model: {
                      value: _vm.manualDeliverFormData.deliverTime,
                      callback: function($$v) {
                        _vm.$set(_vm.manualDeliverFormData, "deliverTime", $$v)
                      },
                      expression: "manualDeliverFormData.deliverTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Shippingremarks"),
                    prop: "deliverRemark"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$i18n.t("common.Pleasenote"),
                      autosize: { minRows: 5 },
                      maxlength: "1000",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.manualDeliverFormData.deliverRemark,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.manualDeliverFormData,
                          "deliverRemark",
                          $$v
                        )
                      },
                      expression: "manualDeliverFormData.deliverRemark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("manualDeliverForm")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.manualDeliver("manualDeliverForm")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-center",
          attrs: {
            title: _vm.$i18n.t("common.Remittanceconfirmation"),
            visible: _vm.transferConfirmDialogVisible,
            width: "600px",
            close: "",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.transferConfirmDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "transferConfirmForm",
              attrs: {
                model: _vm.transferConfirmFormData,
                rules: _vm.transferConfirmFormRules,
                "label-position": _vm.labelPosition,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.state"),
                    prop: "transferStatus"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Pleaseselect")
                      },
                      model: {
                        value: _vm.transferConfirmFormData.transferStatus,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.transferConfirmFormData,
                            "transferStatus",
                            $$v
                          )
                        },
                        expression: "transferConfirmFormData.transferStatus"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        staticStyle: { width: "400px" },
                        attrs: {
                          placeholder: _vm.$i18n.t(
                            "common.Pleaseselectastatus"
                          ),
                          label: item.label,
                          value: item.value
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Remarks"),
                    prop: "confirmRemark"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 5 },
                      placeholder: _vm.$i18n.t("common.Pleasenote"),
                      maxlength: "1000",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.transferConfirmFormData.confirmRemark,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transferConfirmFormData,
                          "confirmRemark",
                          $$v
                        )
                      },
                      expression: "transferConfirmFormData.confirmRemark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("transferConfirmForm")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.transferConfirm("transferConfirmForm")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-center",
          attrs: {
            title: _vm.$i18n.t("common.OrderSettings"),
            visible: _vm.orderSettingDialogVisible,
            width: "40%",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.orderSettingDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "orderSettingForm",
                  attrs: {
                    center: "",
                    model: _vm.orderSettingFormData,
                    rules: _vm.orderSettingFormRules,
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.NormalOrdersExceed"),
                        prop: "orderAutoClose"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "250px" },
                          attrs: {
                            oninput:
                              "if(value){value=value.replace(/[^\\d]/g,1);\n                            if(Number(value)<=0){value=1}} if(Number(value)>60*24){value=60*24}"
                          },
                          model: {
                            value: _vm.orderSettingFormData.orderAutoClose,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.orderSettingFormData,
                                "orderAutoClose",
                                $$v
                              )
                            },
                            expression: "orderSettingFormData.orderAutoClose"
                          }
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(_vm.$i18n.t("common.Minutes")))
                          ])
                        ],
                        2
                      ),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$i18n.t("common.OrderSautomaticallyClosed")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.OrdeIsShipped"),
                        prop: "orderAutoFinish"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "250px" },
                          attrs: {
                            oninput:
                              "if(value){value=value.replace(/[^\\d]/g,1);\n                            if(Number(value)<=0){value=1}} if(Number(value)>90){value=90}"
                          },
                          model: {
                            value: _vm.orderSettingFormData.orderAutoFinish,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.orderSettingFormData,
                                "orderAutoFinish",
                                $$v
                              )
                            },
                            expression: "orderSettingFormData.orderAutoFinish"
                          }
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(_vm.$i18n.t("common.Days")))
                          ])
                        ],
                        2
                      ),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("common.AutomaticallyComplete"))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeOrderSetting } }, [
                _vm._v(" " + _vm._s(_vm.$i18n.t("common.Cancel")) + " ")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.orderSettingConfirm("orderSettingForm")
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("common.Submit")) + "\n            "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }