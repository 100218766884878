<template>
    <div>
        <el-form :model='formListQuery' :rules='rules' ref='variantFrom'>
            <el-form-item :label='$i18n.t("common.name")' prop='title' :label-width='formLabelWidth'>
                <el-input v-model='formListQuery.title' class='input-width' :placeholder='$t("common.name")'></el-input>
            </el-form-item>

            <!--
            <el-form-item label='品牌' prop='brandCode' :label-width='formLabelWidth'>
                <dataSelect v-model='formListQuery.brandCode' :codeType="'brand'" clearable></dataSelect>
            </el-form-item>
            -->


            <el-form-item :label='$i18n.t("common.position") '  prop='bannerPosition' :label-width='formLabelWidth'>
                <dataSelect @change='changeBannerPosition' v-model='formListQuery.bannerPosition'
                            :codeType="'banner_position'" clearable></dataSelect>
            </el-form-item>

            <el-form-item :label='$t("common.ApplicableUserType")' v-if="formListQuery.bannerPosition == 'HomePage'" prop='bannerUserTypeList' :label-width='formLabelWidth'>
                <el-checkbox-group v-model='formListQuery.bannerUserTypeList' >
                    <el-checkbox v-for='(item,index) in dictionary.userType' :key='item.code' :label='item.code'>
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item :label='$i18n.t("common.type")'  prop='bannerType' :label-width='formLabelWidth'>

                <el-radio-group v-model='formListQuery.bannerType' @change='changeCannerType'>
                    <el-radio v-for='(item,index) in dictionary.type' :key='item.id' :label='item.value'>
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.country")' prop='bannerCountriesList' :label-width='formLabelWidth'>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{ $t("common.SelectAll") }}
				</el-checkbox>
				<div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model='formListQuery.bannerCountriesList' @change="handleCheckedCitiesChange">
                    <el-checkbox v-for='(item,index) in dictionary.country' :key='item.code' :label='item.code'>
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Displaytime")' prop='time' :label-width='formLabelWidth'>
                <el-date-picker v-model='formListQuery.time' clearable @input="clearValue" :key="itemKey"
                                type='datetimerange' :range-separator='$i18n.t("common.to")' :start-placeholder='$i18n.t("common.Startdate")'
                                :end-placeholder='$i18n.t("common.Enddate")'>
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="formListQuery.bannerPosition != 'SubPopNotification'" :label='$i18n.t("common.JumpType")' prop='jump' :label-width='formLabelWidth'>
                <el-radio-group v-model='formListQuery.bannerJumpType' @change='changeCannerType'>
                    <el-radio v-for='(item,index) in bannerJumpTypeList' :key='item.code' :label='item.code'>
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item v-if="formListQuery.bannerPosition == 'SubPopNotification'" :label='$i18n.t("common.PopupDelayTime")'  prop='popDelayTime' :label-width='formLabelWidth'>
                <el-input-number v-model='formListQuery.popDelayTime'  :min="0" :max="3600" style="width: 200px" class='input-width' 
                       ></el-input-number> 秒
            </el-form-item>


            <!--            <el-form-item label='跳转类型' prop='jump' :label-width='formLabelWidth'>-->
            <!--                <el-radio-group v-model='formListQuery.jump' @change='changeBannerJump'>-->
            <!--                    <el-radio v-for='(item,index) in dictionary.jump' :key='item.id' :label='item.value'>-->
            <!--                        {{ item.name }}-->
            <!--                    </el-radio>-->
            <!--                </el-radio-group>-->
            <!--            </el-form-item>-->
            <el-form-item   :label='$i18n.t("common.ProductID1")' prop='associationId' v-if="formListQuery.bannerJumpType==='Goods'"
                          :label-width='formLabelWidth'>
                <el-input v-model='formListQuery.associationId'
                          oninput="value=value.replace(/[^0-9]/g,'')"
                          maxlength="20" class='input-width' :placeholder='$i18n.t("common.ProductID1")'></el-input>
            </el-form-item>
            <el-form-item :label='$t("common.ActivityID")' prop='associationId' v-if="formListQuery.bannerJumpType==='Activity'"
                          :label-width='formLabelWidth'>
                <el-input v-model='formListQuery.associationId'
                          oninput="value=value.replace(/[^0-9]/g,'')"
                          maxlength="20" class='input-width' :placeholder='$t("common.ActivityID")'></el-input>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Enableornot")' prop='status' :label-width='formLabelWidth'>
                <el-switch v-model='formListQuery.status' active-color='#13ce66' inactive-color='#ff4949'>
                </el-switch>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.sort")' prop='sort' :label-width='formLabelWidth'>
                <el-input v-model='formListQuery.sort' oninput="value=value.replace(/[^0-9]/g,'')"
                          @blur='salaryChange' class='input-width' :placeholder='$i18n.t("common.Topwithlargenumbers")'></el-input>
            </el-form-item>
        </el-form>
        <el-tabs v-model='activeName' @tab-click='handleClick'>
            <el-tab-pane v-for='(item,index) in formListQuery.bannerFiles' :key='item.countryCode' :label='item.name'
                         :name='item.countryCode'>
                <div>
                    <div style='display: flex;justify-content: flex-start;'>
                        <div :class="[formListQuery.bannerType==='Video' || formListQuery.bannerPosition==='SubPopNotification' ?'uploadTitleChoice':'uploadTitle']">
                           {{ $i18n.t("common.picture") }}
                        </div>
                        <image-upload :formListQuery='formListQuery' :index='index' :countryCode='countryCode'
                                      @handleFormData='handleFormData'></image-upload>

                        <!-- <el-upload class='avatar-uploader' :action='ossInfo.ossSignature.host' :show-file-list='false'
                            :auto-upload='false' list-type='picture-card' ref='uploadImg' :on-change='handleChange'
                            :on-success='upLoadSuccess'
                            :data='fileDataObj' accept='.jpg, .jpeg, .png, .gif'>
                            <el-image v-if='item.imgUrl' class='avatar' :src='item.imgUrl' fit='contain'></el-image>
                            <i v-else class='el-icon-plus avatar-uploader-icon'></i>
                        </el-upload>	 -->
                    </div>
                    <div v-if="formListQuery.bannerPosition!='HomeRecommended' && formListQuery.bannerType=='Video'"
                         style='display: flex;justify-content: flex-start;'>
                        <div class='uploadTitle'>
                           {{  $i18n.t("common.video") }}
                        </div>
                        <video-upload :formListQuery='formListQuery' :index='index' :countryCode='countryCode'
                                      @handleFormData='handleFormData'></video-upload>
                        <!-- <el-upload v-if="item.videoUrl==''" class='avatar-uploader' :action='ossInfo.ossSignature.host'
                            :show-file-list='false' :auto-upload='false' list-type='picture-card' ref='uploadVideo'
                            :on-progress='uploadVideoProcess' :on-change='handleChange2' :data='fileDataObj2' accept='.mp4'>
                            <i v-if='videoUploadPercent==0' class='el-icon-plus avatar-uploader-icon'></i>
                            <el-progress v-else-if='videoUploadPercent>0' type='circle' :percentage='(videoUploadPercent)'
                                style='margin-top:30px;width: 178px;height: 178px;'></el-progress>
                        </el-upload> -->
                        <!-- <video class='float-l' style='width: 178px;height: 178px;' v-else :src='item.videoUrl'
                            controls='controls'>
                            您的浏览器不支持视频播放
                        </video>
                        <div style='margin-left: 20px;display: flex;justify-content: flex-start;align-items: flex-end;'>
                            <span v-if="item.videoUrl==''">支持mp4格式，≤500MB</span>
                            <el-button v-else type='primary' @click='backVideo' plain>重新上传</el-button>
                        </div> -->
                    </div>
                    <div v-show='formListQuery.bannerJumpType==="Link"'
                         style='display: flex;justify-content: flex-start;margin-top: 20px;'>
                        <div class='uploadTitle'>
                            {{  $i18n.t("common.JumpLink") }}
                        </div>
                        <el-input v-model='item.jumpUrl' class='input-width' :placeholder='$i18n.t("common.Enterjumplink")'>
                        </el-input>
                    </div>
                </div>
                <el-form :model='formListQuery' :rules='rules'>
                    <el-form-item style='width: 20%;display: none;'>
                        <div class='dialog-footer'>
                            <el-button type='primary' @click="onSubmit1('variantFrom')">{{  $i18n.t("common.confirm") }} </el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>

        <div class='dialog-footer'>
            <el-button style='width: 20%;' @click='closeDialog'>{{  $i18n.t("common.cancel") }}</el-button>
            <el-button style='width: 20%;' type='primary' @click='onSubmit'>{{  $i18n.t("common.confirm") }}</el-button>
        </div>
    </div>
</template>
<script>
import {
    formatDate
} from '@/utils/date';
import X2JS from 'x2js'; //解析xml
import {
    enumeration
} from '@/utils/common2';
import DataSelect from '@/components/common/DataSelect';
import ImageUpload from '@/components/common/ImageUpload';
import VideoUpload from '@/components/common/VideoUpload';
import moment from 'moment';
import dictionaryUtil from '@/utils/dictionaryUtil';
import Scene from '@/utils/scene';
export default {
    components: {
        DataSelect,
        ImageUpload,
        VideoUpload, moment
    },
    props: {
        formListQuery: {
            type: Object,
            default: () => {
                return {};
            }
        },
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        bannerId: {
            type: String,
            default: ''
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    filters: {
        formatDate(time) {
            if (time == null || time === '') {
                return 'N/A';
            }
            let date = new Date(time);
            return formatDate(date, 'yyyy-MM-dd');
        },
        enumeration(val) {
            return enumeration(val);
        }
    },
    data() {
        return {
            checkAll: false,
            isIndeterminate: false,
            itemKey: null, // 关键参数key
            video: null,
            ossUrl: '',
            videoFlag: 'false',
            videoUploadPercent: 0,
            ossVideoUrl: '',
            formLabelWidth: '120px',
            languageFrom: {},
            imageUrl: '',
            videoUrl: '',
            activeIndex: 0,
            activeName: 'ES',
            countryCode: 'ES',
            dialogVisible: false,
            fileList: [],
            fileDataObj: {},
            fileDataObj2: {},
            limit: 1,
            //组件标签是否显示
            arg: {
                code: true,
                country: true,
                status: true
            },
            //查询项
            searchData: {
                searchStr: '',
                saleCountry: [],
                platformCode: [],
                enabled: null,
                arrayDate: [],
                page: 1,
                limit: 10
            },
            //选中数量
            rules: {
                title: [{
                    required: true,
                    message: this.$i18n.t("common.Pleaseenteraname") ,
                    trigger: 'blur'
                },
                    {
                        min: 1,
                        max: 50,
                        message: this.$i18n.t("common.Between1and50charactersinlength"),
                        trigger: 'blur'
                    }
                ],

//                 brandCode: [{
//                     required: true,
//                     message:  this. $i18n.t("common.Pleaseselectabrand") ,
//                     trigger: 'change'
//                 }],

                bannerPosition: [{
                    required: true,
                    message: this.$i18n.t("common.PleaseSelectThePosition"),
                    trigger: 'change'
                }],
                bannerType: [{
                    required: true,
                    message: this. $i18n.t("common.Pleaseselectatype") ,
                    trigger: 'change'
                }],
                bannerUserTypeList: [{
                    required: true,
                    message: this.$i18n.t("common.PleaseSelectAtLeastTheApplicableUserType"),
                    trigger: 'change'
                }],
                bannerCountriesList: [{
                    required: true,
                    message: this. $i18n.t("common.Pleaseselectatleastonecountry") ,
                    trigger: 'change'
                }],
                time: [{
                    required: true,
                    message: this. $i18n.t("common.Pleaseselectadate") ,
                    trigger: 'change'
                }],
                bannerJumpType: [{
                    required: true,
                    message: this.$i18n.t("common.PleaseSelectTheJumpType"),
                    trigger: 'change'
                }],
                status: [{
                    required: true,
                    message: this.$i18n.t("common.PleaseSelectWhetherToEnable"),
                    trigger: 'change'
                }],
                associationId: [{
                    required: true,
                    message: this.$i18n.t("common.PleaseEnterTheAssociatedID"),
                    trigger: 'blur'
                }],
                popDelayTime: [{
                    required: true,
                    message: this.$i18n.t("common.MeansNoPopupDelay"),
                    trigger: 'blur'
                }],
                targetUrl: [{
                    required: true,
                    message: this.$i18n.t("common.Pleaseenteraname") ,
                    trigger: 'blur'
                },
                    {
                        min: 1,
                        max: 50,
                        message: this.$i18n.t("common.Between1and50charactersinlength") ,
                        trigger: 'blur'
                    }
                ]
            },
            countrys: dictionaryUtil.getDict('countrys'),
            bannerJumpTypeList: [],
            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: {
                    host: ''
                },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            }
        };
    },
    methods: {
        //全选
		handleCheckAllChange(val) {		
			let arr=this.dictionary.country.map((item)=>{
				return item.code
			})       
			this.formListQuery.bannerCountriesList = val ? arr : [];
			this.isIndeterminate = false;
		},
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.dictionary.country.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.dictionary.country.length;
        },
        clearValue(val) {
            this.itemKey = Math.random() //给key赋随机值
            let type = 'yyyy-MM-dd hh:mm:ss';
            if (!val) {
                this.$set(this.formListQuery, 'time', [])
                this.$set(this.formListQuery, 'beginTime', null)
                this.$set(this.formListQuery, 'endTime', null)
            } else {
                this.$set(this.formListQuery.time, 0, val[0])
                this.$set(this.formListQuery.time, 1, val[1])
            }
        },
        salaryChange(e) {
            this.formListQuery.sort = e.target.value;
        },
        initImg() {
            this.countrys = dictionaryUtil.getDict('countrys')
            if (!this.isEdit) {
                this.formListQuery.bannerFiles = []
                this.countrys.forEach((item, index) => {
                    let data = {
                        bannerFileId: null,
                        bannerId: this.bannerId,
                        countryCode: item.code,
                        imgUrl: "",
                        jumpUrl: "",
                        videoUrl: "",
                        name: item.name
                    }
                    this.formListQuery.bannerFiles.push(data)
                    if(index == 0){
                        // console.log('item.code',item.code)
                        this.activeName = item.code
                        this.countryCode = item.code
                    }
                })
            } else {
                this.countrys.forEach((item, index) => {
                    var exist = false;
                    this.formListQuery.bannerFiles.forEach((item2, index2) => {
                        if (item.code == item2.countryCode) {
                            exist = true;
                            this.formListQuery.bannerFiles[index2].name = item.name
                        }
                    })
                    if(!exist){
                        let data = {
                            bannerFileId: null,
                            bannerId: this.bannerId,
                            countryCode: item.code,
                            imgUrl: "",
                            jumpUrl: "",
                            videoUrl: "",
                            name: item.name
                        }
                        this.formListQuery.bannerFiles.push(data)
                    }
                })


                for(var item of this.countrys ){
                    if(this.formListQuery.bannerCountriesList.includes(item.code)){
                        this.activeName = item.code
                        this.countryCode = item.code
                        break
                    }
                }

            }
            // if (this.formListQuery.bannerPosition == 'HomeRecommended') {
            //     this.dictionary.type.splice(1, 1)
            // } else {
            //     this.dictionary.type = [{
            //         id: '1',
            //         value: 'Picture',
            //         name: '图片'
            //     }, {
            //         id: '2',
            //         value: 'Video',
            //         name: '视频'
            //     }, {
            //         id: '3',
            //         value: 'Notification',
            //         name: '弹窗'
            //     }]
            // }
            this.changeBannerPosition(this.formListQuery.bannerPosition);
            this.formListQuery.bannerFiles.forEach((item, index) => {
                this.formListQuery.bannerFiles[index].sort = index
            })
            console.log('this.formListQuery.bannerFiles', this.formListQuery.bannerFiles)
        },
        //改变跳转类型
        changeBannerJump(val) {
            this.$set(this.formListQuery, 'jump', val);
        },
        //选择位置
        changeBannerPosition(val) {
            this.$set(this.formListQuery, 'popDelayTime', 0) 
            if (val == 'HomeRecommended') {
                let type = [{
                    id: '1',
                    value: 'Picture',
                    name:this. $i18n.t("common.picture")
                }];
                this.$set(this.dictionary, 'type', type)
                this.$set(this.formListQuery, 'bannerType', 'Picture')
            }else if(val=='Notification'){
                let type = [{
                    id:'3',
                    value:'Notification',
                    name: this. $i18n.t("common.popupnotification")
                }];
                this.$set(this.dictionary, 'type', type)
                this.$set(this.formListQuery, 'bannerType', 'Notification')
            }else if(val=='SubPopNotification'){
                // 订阅弹窗
                let type = [{
                    id:'3',
                    value:'SubPopNotification',
                    name: this. $i18n.t("common.SubscriptionPopup")
                }];
                this.$set(this.dictionary, 'type', type)
                this.$set(this.formListQuery, 'bannerType', 'SubPopNotification')
                this.$set(this.formListQuery, 'popDelayTime', 10) 
                // 调整类型 隐藏 默认 无跳转
                this.$set(this.formListQuery, 'bannerJumpType', 'None')
            }else{
                let type = [{
                    id: '1',
                    value: 'Picture',
                    name: this. $i18n.t("common.picture")
                }, {
                    id: '2',
                    value: 'Video',
                    name: this. $i18n.t("common.video")
                }];
                this.$set(this.dictionary, 'type', type)
                if(this.formListQuery.bannerType === 'Notification' || this.formListQuery.bannerType === 'SubPopNotification'){
                    this.$set(this.formListQuery, 'bannerType', 'Picture')
                }
            }
            this.initBannerJumpTypeList();
        },
        changeCannerType(val) {
            console.log('va', val, this.formListQuery.bannerPosition, this.formListQuery)
            if (val == 'Picture') {
                this.$set(this.formListQuery, 'bannerType', 'Picture')
            } else if (val == 'Video') {
                this.$set(this.formListQuery, 'bannerType', 'Video')
            }
        },
        //获取OSS签名
        getOssSignature() {
            this.$store
                    .dispatch('getSignature', {
                        dir: 'b2b'
                    })
                    .then((res) => {
                        this.ossInfo.ossSignature = res;
                        this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                    });
        },
        upLoadSuccess() {
            // 拿到upload的dom
            this.$refs.uploadImg[this.activeIndex].clearFiles()
            this.$forceUpdate()
            console.log('Success', this.$refs.uploadImg[this.activeIndex])
        },
        handleFormData(val) {
            this.formListQuery = val
        },
        handleChange(file, fileLis) {
            console.log('file', file)
            const fileType = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
            if (fileType.indexOf(file.raw.type) == -1) {
                this.$message.error(this. $i18n.t("common.Uploadingimagesonlysupports"));
                return;
            }
            if (file.size > 1024 * 1024 * 5) {
                this.$message.error(this.$i18n.t("common.TheUploadedImageSizeCannotExceed5MB",{num:5}));
                return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir +
                        '/' +
                        new Date().getFullYear() +
                        '/' +
                        this.randomString() +
                        this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            console.log('file.response', file.response)
            this.fileDataObj = dataObj;
            setTimeout(() => {
                this.$refs.uploadImg[this.activeIndex].submit();
                let x2js = new X2JS();
                let jsonObj = x2js.xml2js(file.response);
                console.log(jsonObj);
                let ossUrl = jsonObj.PostResponse.Location;
                this.imageUrl = ossUrl;
                this.formListQuery.bannerFiles.forEach((item, index) => {
                    if (item.countryCode === this.countryCode) {
                        this.$set(this.formListQuery.bannerFiles[index], 'imgUrl', ossUrl)
                        if(this.formListQuery.bannerType !== 'Video'){
                            this.$set(this.formListQuery.bannerFiles[index], 'videoUrl', '')
                        }
                        this.$set(this.formListQuery.bannerFiles[index], 'countryCode', this.countryCode)
                    }
                })

            }, 500);
        },
        handleChange2(file, fileLis) {
            const fileType = ['video/mp4']
            if (fileType.indexOf(file.raw.type) == -1) {
                this.$message.error(this.$i18n.t("common.OnlyMp4FormatIsSupportedForVideoUpload"));
                return;
            }
            if (file.size > 1024 * 1024 * 500) {
                this.$message.error(this.$i18n.t("common.TheUploadedVideoSizeCannotExceed500MB",{num:500}));
                return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir +
                        '/' +
                        new Date().getFullYear() +
                        '/' +
                        this.randomString() +
                        this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            this.fileDataObj2 = dataObj;
            setTimeout(() => {
                this.$refs.uploadVideo.submit();
                let x2js = new X2JS();
                console.log('file.response', file.response)
                let jsonObj = x2js.xml2js(file.response);
                console.log(jsonObj);
                let ossUrl = jsonObj.PostResponse.Location;
                this.ossVideoUrl = ossUrl;
                this.formListQuery.bannerFiles.forEach((item, index) => {
                    if (item.countryCode === this.countryCode) {
                        this.$set(this.formListQuery.bannerFiles[index], 'videoUrl', this.ossVideoUrl)
                        this.$set(this.formListQuery.bannerFiles[index], 'countryCode', this.countryCode)
                    }
                })

            }, 500);
        },
        /**
         * 生成随机文件名
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },

        getSuffix(filename) {
            let pos = filename.lastIndexOf('.');
            let suffix = '';
            if (pos != -1) {
                suffix = filename.substring(pos);
            }
            return suffix;
        },
        handleAvatarSuccessVideo(res, file) {
            var timestamp = new Date().getTime();
            var fileName = timestamp + '_' + parseInt(Math.round(Math.random() * 100));
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/fulltext/' + fileName + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature'],
                file: file
            };

            this.$store
                    .dispatch('postOss', {
                        params: dataObj,
                        url: this.ossInfo.host
                    })
                    .then((res) => {
                        //解析xml
                        let x2js = new X2JS();
                        let jsonObj = x2js.xml2js(res);
                        let ossName = jsonObj.PostResponse.Key;
                        this.videoUrl = URL.createObjectURL(file.raw);
                        this.ossVideoUrl = jsonObj.PostResponse.Location;
                    });
        },
        uploadVideoProcess(event, file, fileList) {
            this.videoFlag = true;
            this.videoUploadPercent = parseInt(file.percentage.toFixed(0));
        },
        handleClick(val) {
            this.countryCode = val.name;
            this.showFiles = true
        },
        getActiveIndex(index) {
            this.activeIndex = index;
        },
        onSubmit() {
            this.onSubmit1('variantFrom');
        },
        //提交
        onSubmit1(formName) {
            
            if(this.formListQuery.bannerPosition != 'HomePage'){
                this.formListQuery.bannerUserTypeList=[];
                this.formListQuery.bannerUserTypes=[]
            }
            this.formListQuery.brandCode = this.$tenantBrand.getBrand();
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formListQuery.bannerType == 'Picture' || this.formListQuery.bannerType == 'Notification') {
                        let flag = true
                        this.formListQuery.bannerFiles.forEach((item, index) => {
                            this.formListQuery.bannerCountriesList.forEach((item2, index2) => {
                                if (item2 == item.countryCode) {
                                    if (item.imgUrl == '') {
                                        flag = false
                                    }
                                }
                            })
                        })
                        if (flag == false) {
                            this.$message({
                                message: this. $i18n.t("common.Pleaseuploadpictures") ,
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                    } else if (this.formListQuery.bannerPosition != 'HomeRecommended' && this.formListQuery.bannerType == 'Video') {
                        let flag = true
                        this.formListQuery.bannerFiles.forEach((item, index) => {
                            this.formListQuery.bannerCountriesList.forEach((item2, index2) => {
                                if (item2 == item.countryCode) {
                                    if (item.videoUrl == '') {
                                        flag = false
                                    }
                                }
                            })
                        })
                        if (flag == false) {
                            this.$message({
                                message: this.$i18n.t("common.PleaseUploadTheVideoMP4"),
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                    }

                    if (this.formListQuery.bannerJumpType === "Link") {
                        let flag = true
                        let flagReg = true
                        let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
                        this.formListQuery.bannerFiles.forEach((item, index) => {
                            this.formListQuery.bannerCountriesList.forEach((item2, index2) => {
                                if (item2 == item.countryCode) {
                                    if (item.jumpUrl == '') {
                                        console.log('index', index)
                                        flag = false
                                    }
                                }
                            })
                        })

                        this.formListQuery.bannerFiles.forEach((item, index) => {
                            this.formListQuery.bannerCountriesList.forEach((item2, index2) => {
                                if (item2 == item.countryCode) {
                                    if (item.jumpUrl) {
                                        if (!reg.test(item.jumpUrl)) {
                                            flagReg = false
                                        }
                                    }
                                }
                            })
                        })
                        if (flag == false) {
                            this.$message({
                                message: this.$i18n.t("common.PleaseEnterTheJumpLink"),
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                        if (flagReg == false) {
                            this.$message({
                                message: this.$i18n.t("common.PleaseEnterTheCorrectLink"),
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                    } else {
                        if (this.formListQuery.bannerFiles.length > 0) {
                            this.formListQuery.bannerFiles.forEach((item, index) => {
                                this.formListQuery.bannerFiles[index].jumpUrl = ''
                            })
                        }
                    }

                    this.$confirm( this. $i18n.t("common.Whethertosubmitdata"),  this.$i18n.t("common.prompt"), {
                        // confirmButtonText: '确定',
                        // cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.formListQuery.beginTime = moment(this.formListQuery.time[0]).unix() * 1000;
                        this.formListQuery.endTime = moment(this.formListQuery.time[1]).unix() * 1000;
                        console.log('this.formListQuery.time', this.formListQuery)
                        this.formListQuery.bannerCountries = this.formListQuery.bannerCountriesList
                                .map((item) => {
                                    return {
                                        countryCode: item
                                    };
                                });
                        this.formListQuery.bannerUserTypes = this.formListQuery.bannerUserTypeList
                            .map((item) => {
                                return {
                                    userType: item
                                };
                            });

                        if (this.formListQuery.bannerJumpType !== "Link") {
                            this.formListQuery.bannerFiles.forEach((item, index) => {
                                if (item.countryCode === this.countryCode) {
                                    this.formListQuery.bannerFiles[index].jumpUrl = '';
                                }
                            })
                        }
                        if (this.formListQuery.bannerType === 'Picture') {
                            this.formListQuery.bannerFiles.forEach((item, index) => {
                                this.formListQuery.bannerFiles[index].videoUrl = ''
                            })
                        }
                        // else if(this.formListQuery.bannerType=='videoUrl'){
                        // 	this.formListQuery.bannerFiles.forEach((item,index)=>{
                        // 		this.formListQuery.bannerFiles[index].imgUrl=''
                        // 	})
                        // }
                        console.log('this.formListQuery', this.formListQuery);
                        if (this.isEdit) {
                            this.$store
                                    .dispatch('updateBanner', this.formListQuery).then(res => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullysubmitted"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog();
                                this.handleSearchList();
                            });
                        } else {
                            this.formListQuery.bannerId = '';
                            this.formListQuery.bannerId = this.bannerId;
                            this.$store
                                    .dispatch('addBanner', this.formListQuery).then(res => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullysubmitted"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog();
                                this.handleSearchList();
                            });
                        }
                    });

                } else {
                    this.$message({
                        message: this.$i18n.t("common.VerificationFailedPleaseEnterTheRequiredFieldsFirst"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        },
        //关闭视频
        backVideo() {
            this.videoUploadPercent = 0;
            this.ossVideoUrl = '';
        },
        //关闭窗口
        closeDialog() {
            this.videoUploadPercent = 0;
            this.$emit('closeDialog');
        },
        handleSearchList() {
            this.$emit('handleSearchList');
        },
        initBannerJumpTypeList(){
            this.bannerJumpTypeList=[];
            let bannerJumpTypes = JSON.parse(JSON.stringify(this.dictionary.bannerJumpTypeList));
            if (this.formListQuery.bannerPosition == 'HomePage' ) {
                this.bannerJumpTypeList =  bannerJumpTypes;
            } else {
                this.bannerJumpTypeList = bannerJumpTypes.filter((x) => x.code != 'MemberReg');
            }
            if(Scene.isAigoSmartNonSelf){
                this.bannerJumpTypeList=this.bannerJumpTypeList.filter(item=>item.code!='MemberReg')
            }         
        }
    },
    mounted() {               
        if(this.isEdit){
            let checkedCount = this.formListQuery.bannerCountriesList.length;
            this.checkAll = checkedCount === this.dictionary.country.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.dictionary.country.length;
        } 
        this.getOssSignature();
        let popDelayTime= this.formListQuery.popDelayTime;
        this.initImg();
        if(this.isEdit){
            this.formListQuery.popDelayTime = popDelayTime;
        } 
        this.initBannerJumpTypeList();
    }
};
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}

/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}

/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}

/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}

.avatar {
    height: 178px;
    display: block;
}

.uploadTitleChoice {
    width: 120px;
    text-align: end;
    padding-right: 12px;
    box-sizing: border-box;
}

.uploadTitle {
    width: 120px;
    text-align: end;
    padding-right: 12px;
    box-sizing: border-box;
}

.uploadTitle:before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}

.input-width {
    width: 70%;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
