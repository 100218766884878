<template>
    <div>
        <el-form :model="formItem" :rules="rules"  ref="formItem">

            <el-form-item :label='$i18n.t("common.name")' prop="name" :label-width="formLabelWidth">
                <el-input v-model="formItem.name"  :placeholder='$i18n.t("common.Pleaseenteraname")' ></el-input>
            </el-form-item>
 

            <el-form-item :label='$i18n.t("common.country")' prop='homePageCopyWriterCountrys' :label-width='formLabelWidth'>                
                 <el-checkbox-group v-model="homePageCopyWriterCountrys" >
                     <el-checkbox v-for='(item,index) in dictionary.country' :key='item.code' :label='item.code'>
                         {{ item.name }}
                     </el-checkbox>
                 </el-checkbox-group>
             </el-form-item>
    

            <el-form-item :label='$i18n.t("common.Displaytime")' prop="time" :label-width="formLabelWidth">
                <el-date-picker
                    v-model="formItem.time"
                    clearable
                  
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    range-separator="-"
                    :start-placeholder='$i18n.t("common.starttime")'
                    :end-placeholder='$i18n.t("common.Endtime")'
                >
                </el-date-picker>
            </el-form-item>

            <el-form-item  :label='$i18n.t("common.JumpType")' prop='jumpType' :label-width='formLabelWidth'>
                <el-radio-group v-model='formItem.jumpType'  >
                    <el-radio v-for='(item,index) in dictionary.jumpType' :key='item.code' :label='item.code'>
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>


            <el-form-item  :label='$i18n.t("common.Enableornot")' prop='status' :label-width='formLabelWidth'>
                <el-switch v-model="formItem.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
            </el-form-item>


 
            <el-form-item :label='$i18n.t("common.sort")' prop='sort' :label-width='formLabelWidth'>
                <el-input v-model='formItem.sort' oninput="value=value.replace(/[^0-9]/g,'')"
                     class='input-width' :placeholder='$i18n.t("common.Topwithlargenumbers")'></el-input>
            </el-form-item>
             
            <el-form-item :label='$i18n.t("common.Multilingualconfiguration")'  :label-width='formLabelWidth'  >
                <el-tabs v-model='activeName'  >
                    <el-tab-pane v-for='(item, index) in formItem.homePageCopyWriterLangs' :key='index' :label='item.name'
                                :name= "item.countryCode"   >
                                
                        <el-form-item :label='$i18n.t("common.picture")' :prop="'homePageCopyWriterLangs.'+ index +'.imgUrl'" :label-width='formLabelWidth2' >
                            <imgUpload ref="imageUpload"   :img="item.imgUrl" :imgIndex="index"    @event="changeUrl"></imgUpload>
                        </el-form-item>
                        
                        <el-form-item :label='$i18n.t("common.CopywritingDisplay")'  :prop="'homePageCopyWriterLangs.'+ index +'.title'"  :label-width='formLabelWidth2'  class="jumpUrlCss"  >
                            <el-input v-model="item.title"    class='input-width' :placeholder='$i18n.t("common.PleaseEnter")'></el-input>
                        </el-form-item>
                               
                        <el-form-item :label='$i18n.t("common.JumpLink")' v-if="formItem.jumpType==1" :prop="'homePageCopyWriterLangs.'+ index +'.jumpUrl'" :label-width='formLabelWidth2'  
                            class="jumpUrlCss"   >
                            <el-input v-model="item.jumpUrl"   class='input-width' :placeholder='$i18n.t("common.Enterjumplink")'></el-input>
                        </el-form-item>
                        
                    </el-tab-pane>
                </el-tabs>

            </el-form-item>
 
 
        
        </el-form>

 
        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%;" @click="closeDialog(false)"> {{ $i18n.t("common.cancel") }}</el-button>
          
            <el-button style="width: 20%;" type="primary" :loading="loading" @click="onSubmit('formItem')"> {{ $i18n.t("common.confirm1") }}</el-button>
        </div>
      

    </div>
</template>
<script>
 
import imgUpload from './imgUpload';
 

export default {
    components: {
        imgUpload
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        },
        countrylang: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        // var validateJumpUrl = (rule, value, callback) => {
        //     if(this.formItem.jumpType==1){
        //         if (!value) {
        //             return callback(new Error( this.$i18n.t("common.Enterjumplink") ));
        //         }      
        //     }
            
        //     callback();
        // };
        var validateCountry = (rule, value, callback) => {
            if (this.homePageCopyWriterCountrys.length == 0) {
                callback(new Error(this. $i18n.t("common.Pleaseselectacountry")));
            } else {
                callback();
            }
        };
        return {
            homePageCopyWriterCountrys:[],
            activeName:"ES",
            formLabelWidth: '150px',
            formLabelWidth2: '80px',
            rules: {
                name: [ 
                    { required: true, message: this.$i18n.t("common.Pleaseenteraname"), trigger: 'blur' }
                    
                ],
                homePageCopyWriterCountrys: [
                    { type: 'array', validator: validateCountry, required: true, trigger: 'change' }
                    // { required: true, message: this.$i18n.t("common.Pleaseselectacountry"), trigger: 'change' }
                ],
                time: [
                    { 
                        type: 'array', required: true, message: this.$i18n.t("common.Pleaseselectatime"), trigger: 'change',
                        fields: {
                            0: { type: 'string', required: true, message: this.$i18n.t("common.Pleaseselectastartdate") },
                            1: { type: 'string', required: true, message: this.$i18n.t("common.Pleaseselecttheenddate") },
                        }
                    }
                ], 
                jumpType: [
                    { required: true, message: this.$i18n.t("common.JumpType"), trigger: 'change' }
                ],
                status: [
                    { required: true, message: this.$i18n.t("common.Enableornot"), trigger: 'change' }
                ],
 

            }, 
            loading: false,

        };
    },
    created() {
        this.initData();
    },
    mounted() {
         
    },
    methods: {
        initData(){
            this.homePageCopyWriterCountrys = this.formItem.homePageCopyWriterCountrys;
        },
        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        },
        //提交
        onSubmit(formName) { 
            
            this.$refs[formName].validate((valid,Object) => {
                if (valid) {
                    this.formItem.homePageCopyWriterCountrys = this.homePageCopyWriterCountrys;
                    console.log('this.formItem',this.formItem)
                    //多语言校验
                    var countryLangs = this.formItem.homePageCopyWriterCountrys
                    // for(var item of this.formItem.homePageCopyWriterCountrys){
                    //     for (var langItem of this.countrylang) {
                    //         if (item == langItem.code) {
                    //             countryLangs.push(langItem.name);
                    //         }
                    //     }
                    // }
                 

                    for (var item of this.formItem.homePageCopyWriterLangs) {

                        if (countryLangs.includes(item.countryCode) && item.title == '' ) {
                            this.activeName = item.countryCode;
                            this.$message({
                                message: this.$i18n.t("common.CompleteMultiLanguage"),  
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                        if (countryLangs.includes(item.countryCode) && item.jumpUrl == '' && this.formItem.jumpType==1) {
                            this.activeName = item.countryCode;
                            this.$message({
                                message: this.$i18n.t("common.CompleteMultiLanguage"),
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                        if (countryLangs.includes(item.countryCode) && item.jumpUrl != '' && this.formItem.jumpType==1) {

                            let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;   
                            if (!reg.test(item.jumpUrl)) {
                                this.activeName = item.countryCode;
                                this.$message({
                                    message: this.$i18n.t("common.PleaseEnterTheCorrectLink"),
                                    type: 'warning',
                                    duration: 1000
                                });
                                return;
                            }
                        }


                    }

                    this.formItem.startTime = new Date(this.formItem.time[0]).getTime();
                    this.formItem.endTime = new Date(this.formItem.time[1]).getTime();


                    for (var item of this.formItem.homePageCopyWriterLangs) {
                        if(this.formItem.jumpType!=1){
                            item.jumpUrl = ''
                        }
                    }

                    console.log("onsubmit",this.formItem)
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),this.$i18n.t("common.prompt"), {
                            type: 'warning'
                        }).then(() => {   
                            this.loading=true                           
                           
                            if (this.isEdit) {
                                this.$store.dispatch('putHomePageCopyWriter', this.formItem).then(response => {
                                    this.$message({
                                        message: this.$i18n.t("common.Successfullymodified"),
                                        type: 'success',
                                        duration: 1000
                                    });
                                    this.closeDialog(true,this.isEdit);
                                }).finally(() => {
                                    this.loading = false;
                                });
                            } else {
                                this.$store.dispatch('addHomePageCopyWriter', this.formItem).then(response => {
                                    this.$message({
                                        message:  this.$i18n.t("common.Successfullysubmitted"),
                                        type: 'success',
                                        duration: 1000
                                    });
                                    this.closeDialog(true,this.isEdit);
                                }).finally(() => {
                                    this.loading = false;
                                });
                            }
                        }).catch(() => {});
                          
                } else {
     
                    this.$message({
                        message:  this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        },
           
        changeUrl(data,index) {
            console.log("数据", data)
            this.formItem.homePageCopyWriterLangs[index].imgUrl= data 
            console.log("数据", this.formItem.homePageCopyWriterLangs)
        }
    
    }
};
</script>
<style scoped>
 
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.jumpUrlCss /deep/ .el-form-item__label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}


 
</style>
